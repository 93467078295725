import axios from 'axios';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import Cookie from 'universal-cookie';

import { BcArrow, BorderDiv, Description, NoticeWrapper, Title } from '../css/styles';

import styled from 'styled-components';
import breadcrumbArrow from '../asset/img/breadcrumbarrow.png';
import DeleteSearch from '../asset/img/deletesearch.png';
import SelectArrow from '../asset/img/selectarrow.png';
import AskList from '../components/AskList';
import Pagination from '../components/Pagination';
import SearchIndexBox from '../components/SearchIndexBox';

import Dotbogi from '../asset/img/dotbogi.png';

const cookies = new Cookie();

const ColumnBox = styled.div`
    display: flex;
    flex-direction: column;
`;

const SearchBox = styled.div`
    padding: 2% 0;
    display: flex;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    column-gap: 15px;
    position: relative;
    & > div {
        display: flex;
        align-items: center;
        & > img {
            margin-top: 10px;
            margin-left: 7px;
            width: 20px;
        }
        & > p {
            font-size: 16px;
            margin-top: 9px;
            line-height: 23px;
            color: #c4c4c4;
        }
    }
`;

const SelectBox = styled.select`
    cursor: pointer;
    appearance: none;
    border: 1px solid #c4c4c4;
    border-radius: 10px; 
    padding: 7px 15px;
    font-size: 16px;
    line-height: 23px;
    background-image: url(${SelectArrow});
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: 87%;
    &:focus {
        outline: none;
    }
`;

const SearchInput = styled.input`
    width: 97%;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    background-image: url(${Dotbogi});
    background-repeat: no-repeat;
    background-size: 17px;
    background-position: 97%;
    padding: 7px 10px;
    font-size: 16px;
    line-height: 23px;
    color: #7e7e7e;
`;

const FlexBox = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    column-gap: 10px;
    & > div {
        display: flex;
        align-items: center;
        width: 100px;
        & > img {
            cursor: pointer;
        }
    }
`;

const Table = styled.table`
    margin-top : 30px !important;
    & > thead > tr {
        & > th:nth-child(1) {
            width : 7% !important;
        }
        & > th:nth-child(2) {
            width : 10% !important;
        }
        & > th:nth-child(3) {
            width : 10%;
            text-align : center !important;
            padding-left : 0 !important;
        }
        & > th:nth-child(4) {
            width : 40%;
            & > div {
                justify-content : flex-start;
                padding-left: 30px;
            }
        }
        & > th:nth-child(5) {
            width : 12% !important;
        }
    }
`

const PaginationWrapper = styled.div`
    display : flex;
    justify-content : center !important;
`

const ThSelectBox = styled(SelectBox)`
    border : none;
    background-color : white;
    font-weight : 700;
    background-position: 100%;
`

function ManageAdminUser() {
    const { no } = useParams();
    const [noticeInfo, setNoticeInfo] = React.useState([]);
    const [noticeListInfo, setNoticeListInfo] = React.useState([]);
    const [searchInfo , setSearchInfo] = React.useState({
        searchData : '',
        searchType : null,
        startDate : '' ,
        endDate : null,
        answerState : null,
    });

    const onStartDateChange = (e) => {
        setSearchInfo({...searchInfo , startDate : e.target.value});
    };

    const onEndDateChange = (e) => {
        setSearchInfo({...searchInfo , endDate : e.target.value});
    };

    useEffect(() => {
        axios({
            method: 'get',
            url: `${window.Config.G_API_URL}/admin/ask`,
            params: {
                page_num: no.replace(':', ''),
                token: cookies.get('token'),
                search_data : searchInfo.searchData,
                search_type : searchInfo.searchType,
                start_date: searchInfo.startDate,
                end_date: searchInfo.endDate,
                answer_status : searchInfo.answerState === '' ? null : searchInfo.answerState,
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    setNoticeInfo(response.data.data.result);
                    setNoticeListInfo(response.data.data.result.list);
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    }, [no , searchInfo.searchData , searchInfo.searchType , searchInfo.answerState , searchInfo.startDate , searchInfo.endDate]);

    const handleSearchData = (e) => {
        setSearchInfo({...searchInfo , searchData : e.target.value});
    }

    const handleSearchType = (e) => {
        setSearchInfo({...searchInfo , searchType : e.target.value});
    }

    const handleStartDate = () => {

    }
 
    const handleEndDate = () => {
        
    }
    const onReset = () => {
        setSearchInfo({...searchInfo , searchData : ''});
    }

    const handleAnswerState = (e) => {
        setSearchInfo({...searchInfo , answerState : e.target.value});
    }

    const handleDate = (e) => {
        var date = new Date();
        var currentDate1 = date.toISOString().substring(0,10);
        switch(e.target.id){
            case 'all':
                setSearchInfo({...searchInfo , startDate : null ,endDate : currentDate1});
                break;
            case 'week':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 7));
                var currentDate = newDate.toISOString().substring(0,10);
                setSearchInfo({...searchInfo , startDate : currentDate,endDate : currentDate1});
                break;
            case 'halfMonth':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 14));
                var currentDate = newDate.toISOString().substring(0,10);
                setSearchInfo({...searchInfo , startDate : currentDate,endDate : currentDate1});
                break;
            case 'month':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 30));
                var currentDate = newDate.toISOString().substring(0,10);
                setSearchInfo({...searchInfo , startDate : currentDate,endDate : currentDate1});
                break;
            case 'threeMonth':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 91));
                var currentDate = newDate.toISOString().substring(0,10);
                setSearchInfo({...searchInfo , startDate : currentDate,endDate : currentDate1});
                break;
            case 'halfYear':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 182));
                var currentDate = newDate.toISOString().substring(0,10);
                setSearchInfo({...searchInfo , startDate : currentDate,endDate : currentDate1});
                break;
            case 'year':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 365));
                var currentDate = newDate.toISOString().substring(0,10);
                setSearchInfo({...searchInfo , startDate : currentDate,endDate : currentDate1});
                break;
        }
    }

    return (
        <NoticeWrapper>
            <Title>문의</Title>
            <Description>
                게시판 관리 <BcArrow src={breadcrumbArrow} /> 문의
            </Description>
            <SearchBox>
                <ColumnBox>
                    <FlexBox>
                        <SelectBox name="searchselect" id="serachselect" onChange={handleSearchType}>
                            <option value={null}>전체</option>
                            <option value="문의 제목">문의 제목</option>
                            <option value="문의 등록자">문의 등록자</option>
                            <option value="답변 관리자">답변 관리자</option>
                        </SelectBox>
                        <SearchInput type="text" value={searchInfo.searchData} placeholder={'검색어 입력'} onChange={handleSearchData}/>
                        <div onClick={onReset}>
                            <img src={DeleteSearch} alt="" />
                            <p>초기화</p>
                        </div>
                    </FlexBox>
                    <SearchIndexBox
                        startChange={onStartDateChange}
                        endChange={onEndDateChange}
                        handleDate={handleDate}
                        startDate={searchInfo.startDate}
                        endDate={searchInfo.endDate}
                    />
                </ColumnBox>
            </SearchBox>
            <Table>
                <thead>
                    <tr>
                        <th>
                            글번호
                        </th>
                        <th>
                            <BorderDiv>등록일</BorderDiv>
                        </th>
                        <th>
                            문의 등록자
                        </th>
                        <th>
                            <BorderDiv>제목</BorderDiv>
                        </th>
                        <th>
                            <ThSelectBox name="searchselect" id="serachselect" onChange={handleAnswerState}>
                                <option value=''>답변 여부</option>
                                <option value="검토 중">검토 중</option>
                                <option value="답변 완료">답변 완료</option>
                            </ThSelectBox>
                        </th>
                        <th>
                            <BorderDiv>답변자</BorderDiv>
                        </th>
                        <th>
                            답변일
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {noticeListInfo.map((item, key, index) => (
                        <AskList
                            key={key}
                            no={item.ask_id}
                            title={item.ask_title}
                            date={item.ask_insert_date}
                            exposure={item.exposure}
                            admin={item.admin_no}
                            user={item.ask_user_name}
                            adminId={item.ask_comment_user_id}
                            complete={item.ask_comment_complete}
                            commentDate={item.insert_date}
                        />
                    ))}
                </tbody>
            </Table>
            <PaginationWrapper>
                <Pagination
                    no={no.replace(':', '')}
                    url="/admin/ask/:"
                    pages={noticeInfo['pages']}
                    isFirstPage={noticeInfo['isFirstPage']}
                    isLastPage={noticeInfo['isLastPage']}
                    prePage={noticeInfo['prePage']}
                    nextPage={noticeInfo['nextPage']}
                    hasPrePage={noticeInfo['hasPreviousPage']}
                    hasNextPage={noticeInfo['hasNextPage']}
                />
            </PaginationWrapper>
        </NoticeWrapper>
    );
}

export default ManageAdminUser;
