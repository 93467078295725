import React from 'react';
import {
    Description,
    SearchGroupDetailWrapper,
    Title,
    BcArrow,
} from '../css/styles';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookie from 'universal-cookie';
import ManageAdminRegisterAdminTable from '../components/ManageAdminRegisterAdminTable';
import GrayQuestionMark from '../asset/img/grayquestionmark.png';
import EmptyValueModal from '../components/EmptyValueModal';
import breadcrumbArrow from '../asset/img/breadcrumbarrow.png';
import AuthCheckModal from '../components/AuthCheckModal';
import Modal from '../components/Modal';
const SubTitle = styled.h1`
    font-size: 32px;
    padding-top: 40px;
    font-family: notosansbold;
`;

const ButtonWrapper = styled.div`
    & > button:first-child {
        padding: 5px 20px !important;
    }
    & > button:last-child {
        padding: 5px 20px !important;
        color: white;
        background-color: #df1f26;
    }
`;

const CaptionWrapper = styled.div`
    display: flex;
    justify-content: flex-start !important;
    align-items: center;
    margin-top: 7px !important;
    column-gap: 5px;
    & > img {
        width: 18px;
    }
    & > p {
        font-size: 14px;
        line-height: 160%;
        letter-spacing: -0.035em;
        color: #999999;
    }
`;


function ManageAdminRegisterAdmin({ props }) {
    const cookies = new Cookie();
    // table 용 state
    const [formInfo, setFormInfo] = React.useState({
        name: null,
        adminId: null,
        phoneNum: null,
        password: null,
        adminLevel: null,
    });

    const [toggleEmptyModal , setToggleEmptyModal] = React.useState(false);

    const [toggleAuthModal , setToggleAuthModal] = React.useState(false);
    const [toggleModal , setToggleModal] = React.useState(false);
    const handleToggleModal = () => {
        setToggleModal(!toggleModal);
    }
    const handleToggleAuthModal = () => {
        setToggleAuthModal(!toggleAuthModal);
    }
    const handleToggleEmptyModal = () => {
        setToggleEmptyModal(!toggleEmptyModal);
    }

    const navigate = useNavigate();

    const onRegister = () => {
        axios({
            method: 'post',
            url: `${window.Config.G_API_URL}/admin`,
            params: {
                token: cookies.get('token'),
                admin_id: formInfo.adminId,
                admin_level: formInfo.adminLevel,
                admin_name: formInfo.name,
                admin_password: formInfo.password,
                admin_phone: formInfo.phoneNum,
            },
        })
            .then((response) => {
                console.log(response.data.result['code']);
                if (response.data.result['code'] === '200') {
                    window.location.href='/admin/manageadminuser/:1/:1/:1/:1/:1'
                } else if(response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else if (response.data.result['code'] === '400'){
                    handleToggleModal();
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                handleToggleEmptyModal();
            });
    };

    const tableInputChange = (e) => {
        let { name, value } = e.target;
        switch (name) {
            case 'name':
                setFormInfo({ ...formInfo, name: value });
                break;
            case 'adminId':
                setFormInfo({ ...formInfo, adminId: value });
                break;
            case 'phoneNum':
                setFormInfo({ ...formInfo, phoneNum: value });
                break;
            case 'password':
                setFormInfo({ ...formInfo, password: value });
                break;
            case 'adminLevel':
                setFormInfo({ ...formInfo, adminLevel: value });
                break;
        }
    };

    return (
        <SearchGroupDetailWrapper>
            <Title>관리자 등록</Title>
            <Description>
                관리자 설정 <BcArrow src={breadcrumbArrow} /> 관리자 회원 관리{' '}
                <BcArrow src={breadcrumbArrow} /> 관리자 등록
            </Description>
            <SubTitle>관리자 등록</SubTitle>
            <ManageAdminRegisterAdminTable
                onTableInputChange={tableInputChange}
            />
            <CaptionWrapper>
                <img src={GrayQuestionMark} alt="" />
                <p>등록하신 전화번호로 2차 인증에 필요한 QR코드가 전송(최초 1회)되니 보안을 위해 반드시 정확한 전화번호를 입력해주세요.</p>
            </CaptionWrapper>
            <ButtonWrapper>
                <button onClick={() => navigate(-1)}>취소</button>
                <button onClick={onRegister}>등록</button>
            </ButtonWrapper>

            <EmptyValueModal
                toggle={toggleEmptyModal}
                toggleFunc={handleToggleEmptyModal}
            />
            <AuthCheckModal
                toggle={toggleAuthModal}
                toggleFunc={handleToggleAuthModal}
            />
            <Modal 
                toggle={toggleModal}
                toggleFunc={handleToggleModal}
            />
        </SearchGroupDetailWrapper>
    );
}

export default ManageAdminRegisterAdmin;
