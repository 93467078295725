import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import {
    ManageUserDetailWrapper,
    Title,
    Description,
    BcArrow,
} from '../css/styles';
import axios from 'axios';
import Cookie from 'universal-cookie';
import { useParams } from 'react-router';
import ManageAdminUpdateTable from '../components/ManageAdminUpdateTable';
import styled from 'styled-components';
import DeleteUserModal from '../components/DeleteUserModal';

import breadcrumbArrow from '../asset/img/breadcrumbarrow.png';

const cookies = new Cookie();

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

function ManageUserDetail(props) {
    const { adminid } = useParams();
    const [managerInfo, setManagerInfo] = React.useState([]);
    const [toggleDeleteModal, setToggleDeleteModal] = React.useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        axios({
            method: 'get',
            url: `${window.Config.G_API_URL}/admin/detail/`,
            params: {
                token: cookies.get('token'),
                admin_id: adminid.replace(':', ''),
                page_num: '1',
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    setManagerInfo(response.data.data.data);
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    }, []);

    const onSubmit = () => {
        axios({
            method: 'put',
            url: `${window.Config.G_API_URL}/admin`,
            params: {
                token: cookies.get('token'),
                admin_id: adminid.replace(':', ''),
                admin_level : managerInfo.admin_level,
                admin_name : managerInfo.admin_name,
                admin_phone : managerInfo.admin_phone,
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    window.location.href = `/admin/manageadminuser/detail/${adminid}/:1`;
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    };

    const handleChange = (event) => {
        const {name , value} = event.target;
        setManagerInfo({...managerInfo , [name] : value});
    }

    return (
        <ManageUserDetailWrapper>
            <Title>관리자 정보 수정</Title>
            <Description>
                관리자 설정 <BcArrow src={breadcrumbArrow} /> 관리자 회원 관리{' '}
                <BcArrow src={breadcrumbArrow} /> 관리자 상세 <BcArrow src={breadcrumbArrow} /> 관리자 정보 수정
            </Description>
            <ManageAdminUpdateTable managerInfo={managerInfo} onChange={handleChange}/>
            <ButtonWrapper>
                <button onClick={() => navigate(-1)}>취소</button>
                <button onClick={onSubmit}>수정 완료</button>
            </ButtonWrapper>

        </ManageUserDetailWrapper>
    );
}

export default ManageUserDetail;
