import React, { useEffect } from 'react';
import axios from 'axios';
import Cookie from 'universal-cookie';

import { useParams } from 'react-router';
import {
    NoticeWriteWrapper,
    PinButton,
    WriteBoardForm,
    NoticeWriteButtonWrapper,
    Title,
    Description,
    BcArrow,
} from '../css/styles';
import CancelModal from '../components/CancelModal';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import Pin from '../asset/img/pin.png';
import Bar from '../asset/img/bar.png';
import WhitePin from '../asset/img/whitepin.png';
import Qr from '../asset/img/qr.png';
import breadcrumbArrow from '../asset/img/breadcrumbarrow.png';
import ImageModal from '../components/ImageModal';

import EmptyValueModal from '../components/EmptyValueModal';
import AuthCheckModal from '../components/AuthCheckModal';

const cookies = new Cookie();

const ContentWrapper = styled(WriteBoardForm)`
height : auto;
    & > div:first-child{
        padding : 1% 2%;
        font-size: 32px;
        line-height: 46px;
    }
    & > div:nth-child(2){
        display : flex;
        align-items : center;
        column-gap : 20px;
        padding : 0.5% 2%;
        & > img {
            height : 26px;
        }
        & > div{
            display : flex !important;
            column-gap : 16px;
            align-items : center;
            & > p:first-child{
                font-family : notosansbold;
            }
            & > p:last-child{
                color : #df1f26;
                font-size: 12px;
                border: 1px solid #DF1F26;
                border-radius: 8px;
                padding : 1px 5px 2px 5px;
                cursor : pointer;
            }
        }
        & > p:last-child {
            display : flex;
            align-items : center;
            column-gap: 10px;
            & > img {
                width : 18px;
            }
        }
    }
    & > div:last-child{
        padding : 2% 2%;
        border-bottom : none;
        line-height: 160%;
    }
`

const CommentWrapper = styled(WriteBoardForm)`
 & > div {
    display : flex;
    align-items : center;
    padding-left : 2%;
    & > input {
        padding-left : 10px !important;
        font-size: 32px;
    }
    & > p {
        font-size: 32px;
    }
 }

`

const Button = styled.button`
    border: 1px solid #C4C4C4 !important;
    border-radius: 10px;
    background-color: white !important;
    color : #df1f26 !important;
`

const AskImg = styled.div`
    display : flex;
    flex-direction : row;
    padding-bottom : 20px; 
    column-gap: 10px;
    & > img {
        width : 60px;
        height : 60px;
        border-radius: 10px;
        cursor : pointer;
    }
`

const P = styled.p`
    color : ${props => props.name === null ? '#df1f26' : ''};
`

function AskComment() {
    const { no } = useParams();
    const [title, setTitle] = React.useState(null);
    const [content, setContent] = React.useState(null);
    const [isCheck, setIsCheck] = React.useState(false);
    const [exposure, setExposure] = React.useState('N');
    const [cancelModalToggle, setCancelModalToggle] = React.useState(false);
    const [askDetailInfo , setAskDetailInfo] = React.useState([]);

    const [toggleModal1 , setToggleModal1] = React.useState(false);
    const [toggleModal2 , setToggleModal2] = React.useState(false);
    const [toggleModal3 , setToggleModal3] = React.useState(false);
      
    const [toggleEmptyModal , setToggleEmptyModal] = React.useState(false);
    const [toggleAuthModal , setToggleAuthModal] = React.useState(false);

    const handleToggleAuthModal = () => {
        setToggleAuthModal(!toggleAuthModal);
    }

    const handleToggleEmptyModal = () => {
        setToggleEmptyModal(!toggleEmptyModal);
    }

    const handleToggleModal1 = () => {
        setToggleModal1(!toggleModal1);
    }
     
    const handleToggleModal2 = () => {
        setToggleModal2(!toggleModal2);
    }   

    const handleToggleModal3 = () => {
        setToggleModal3(!toggleModal3);
    }

    useEffect(()=>{
        axios({
            method: 'get',
            url: `${window.Config.G_API_URL}/admin/trouble/detail`,
            params: {
                token: cookies.get('token'),
                trouble_id: no.replace(':', ''),
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    setAskDetailInfo(response.data.data);
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    }, []);

    const onSubmit = () => {

        axios({
            method: 'post',
            url: `${window.Config.G_API_URL}/admin/trouble/comment`,
            params: {
                token: cookies.get('token'),
                title: title.replace(/\[/g, '%5B').replace(/]/g, '%5D'),
                contents: content.replace(/(?:\r\n|\r|\n)/g , '\r\n').replace(/\[/g, '%5B').replace(/]/g, '%5D'),
                trouble_id: no.replace(':',''),
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    console.log(response);
                    window.location.href = '/admin/trouble/:1';
                } else if(response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                handleToggleEmptyModal();
            });
    };

    const handleCancelModal = () => {
        setCancelModalToggle(!cancelModalToggle);
    };

    const goToUserDetail = () => {
        window.location.href = `/admin/manageuser/detail/:${askDetailInfo.ask_user_id}/:1/pointmanage/:1`
    }

    const handleTitle = (e) => {
        setTitle(e.target.value);
    };

    const handleContent = (e) => {
        setContent(e.target.value);
    };

    const navigate = useNavigate();

    return (
        <NoticeWriteWrapper>
            <Title>신고 답변 작성</Title>
            <Description>
                게시판 관리 <BcArrow src={breadcrumbArrow} /> 신고 상세{' '}
                <BcArrow src={breadcrumbArrow} /> 신고 답변 작성
            </Description>
            <ContentWrapper>
                <div>
                    Q. {askDetailInfo.trouble_title && askDetailInfo.trouble_title.replace(/\%5B/g , '[').replace(/\%5D/g, ']')
}
                </div>
                <div>
                    <P name={askDetailInfo.us_name}>{askDetailInfo.us_name === null ? '탈퇴한 사용자 입니다.' : askDetailInfo.us_name}</P>
                    <img src={Bar} alt="" />
                    <div><p>회원 고유 번호</p> <p>{askDetailInfo.trouble_user_id}</p><p onClick={goToUserDetail}>회원 상세</p></div>
                    <img src={Bar} alt="" />
                    <p>{askDetailInfo.trouble_insert_date}</p>
                    {
                        askDetailInfo.trouble_station_id === '' ? 
                        ''
                        :
                        <>
                            <img src={Bar} alt="" />
                            <p><img src={Qr}/>{askDetailInfo.trouble_station_id} 스테이션 {askDetailInfo.trouble_station_port}번 포트</p>
                        </>
                    }
                </div>
                <div>
                    <AskImg>
                        {
                            askDetailInfo.trouble_image_path_1 === '' ?
                            ''
                            :
                            <>
                                <img src={`${window.Config.G_API_URL}/images/trouble/${askDetailInfo.trouble_image_path_1}`} alt="" onClick={handleToggleModal1}/>
                                <ImageModal
                                    toggle={toggleModal1}
                                    toggleFunc={handleToggleModal1}
                                    url={`${window.Config.G_API_URL}/images/trouble/`}
                                    img={askDetailInfo.trouble_image_path_1}
                                />
                            </>
                        }
                        {
                            askDetailInfo.trouble_image_path_2 === '' ?
                            ''
                            :
                            <>
                            <img src={`${window.Config.G_API_URL}/images/trouble/${askDetailInfo.trouble_image_path_2}`} alt="" onClick={handleToggleModal2} />
                                <ImageModal
                                    toggle={toggleModal2}
                                    toggleFunc={handleToggleModal2}
                                    url={`${window.Config.G_API_URL}/images/trouble/`}
                                    img={askDetailInfo.trouble_image_path_2}
                                />
                            </>
                        }
                        {
                            askDetailInfo.trouble_image_path_3 === '' ?
                            ''
                            :
                            <>
                            <img src={`${window.Config.G_API_URL}/images/trouble/${askDetailInfo.trouble_image_path_3}`} alt="" onClick={handleToggleModal3} />
                                <ImageModal
                                    toggle={toggleModal1}
                                    toggleFunc={handleToggleModal3}
                                    url={`${window.Config.G_API_URL}/images/trouble/`}
                                    img={askDetailInfo.trouble_image_path_3}
                                />
                            </>
                        }
                    </AskImg>
                    {askDetailInfo.trouble_contents && askDetailInfo.trouble_contents.replace(/\%5B/g , '[').replace(/\%5D/g, ']')}
                </div>
            </ContentWrapper>
            <CommentWrapper>
                <div>
                    <p>A.</p>
                    <input
                        type="text"
                        placeholder="답변 제목을 입력하세요."
                        value={title}
                        onChange={handleTitle}
                    />
                </div>
                <textarea placeholder='답변을 입력해 주세요.' value={content} onChange={handleContent}/>
            </CommentWrapper>
            <NoticeWriteButtonWrapper>
            <button onClick={handleCancelModal}>취소</button>
                <div>
                    <Button onClick={onSubmit}>작성 완료</Button>
                </div>
            </NoticeWriteButtonWrapper>

            <CancelModal
                toggle={cancelModalToggle}
                toggleFunc={handleCancelModal}
            />

            <EmptyValueModal
                toggle={toggleEmptyModal}
                toggleFunc={handleToggleEmptyModal}
            />
            <AuthCheckModal
                toggle={toggleAuthModal}
                toggleFunc={handleToggleAuthModal}
            />
        </NoticeWriteWrapper>
    );
}

export default AskComment;
