import styled from 'styled-components';

//SideMenu 스타일 시트
export const SideMenuWrapper = styled.div`
    &::-webkit-scrollbar {
        display: none;
    }
    overflow-y: auto;
    width: 15%;
    padding: 3% 1%;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: #221e1f;
    & > div {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
    }
    & > a > img {
        width: 125px;
        height: auto;
        padding: 0 5% 40px 0;
    }
    & > p {
        color: white;
        font-size: 14px;
        line-height: 19px;
        margin-top: 50px;
        padding-left: 5%;
        & > span {
            padding: 0px 7px 1px;
            background: #e5e5e5;
            border-radius: 37px;
            font-size: 13px;
            line-height: 18px;
            color: #221e1f;
            margin-right: 10px;
        }
    }
`;

//SideMenuList 스타일 시트
export const SideMenuListWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    border-radius: 10px;
    cursor: pointer;
    & > div {
        display: flex;
        align-items: center;
        column-gap: 10px;
        & > img {
            width: 20px;
            height: auto;
        }
        & > p {
            font-weight: bold;
            font-size: 16px;
            line-height: 23px;
            color: #c4c4c4;
        }
    }
    & > img {
        display: ${(props) => (props.arrow ? '' : 'none')};
        width: 5px;
        transform: ${(props) => (props.state ? 'rotate(90deg)' : '')};
        transition: transform 0.5s;
    }
    &:hover {
        background-color: #fff;
        & > div > p {
            color: #221e1f;
        }
    }
`;

export const DrawerWrapper = styled.div`
    cursor: pointer;
    & > div {
        display: flex;
        justify-content: center;
        max-height: ${(props) =>
            props.state ? `${(props) => props.children}px` : '0px'};
        position: relative;
        overflow: hidden;
        padding: ${(props) => (props.state ? ' 10px 0 0 0' : '0')};
        transform: ${(props) => (props.state ? 'scaleY(1)' : 'scaleY(0)')};
        transform-origin: 0 0 0;
        transition: transform 0.5s, padding 0.5s, max-height 0.5s;
        flex-direction: column;
        row-gap: 20px;
        font-size: 16px;
        line-height: 23px;
        color: #c4c4c4;
        // 세부사항 링크 모두 구현시 p 부분 지우기
        & > p {
            font-size: 16px;
            line-height: 23px;
            font-weight: bold;
            padding: 10px 45px;
            border-radius: 10px;
            &:hover {
                background-color: #fff;
                color: #221e1f;
            }
        }
        & > a {
            font-size: 16px;
            color: #c4c4c4;
            line-height: 23px;
            font-weight: bold;
            padding: 10px 45px;
            border-radius: 10px;
            &:hover {
                background-color: #fff;
                color: #221e1f;
            }
        }
    }
`;
// 여러군데 사용되는 스타일 시트
export const Title = styled.h1`
    font-family : notosansbold;
    font-size: 24px;
    line-height: 35px;
    padding-bottom: 8px;
`;
export const Description = styled.p`
    font-size: 16px;
    line-height: 23px;
    color: #7e7e7e;
`;
//Notice 스타일 시트
export const NoticeWrapper = styled.div`
    margin-left: 17%;
    padding: 5% 1.5%;
    & > table {
        width: 100%;
        text-align: center;
        margin: 93px 0 31px 0;
        & > thead > tr {
            line-height: 20px;
            border-bottom: 2px solid #e5e5e5;
            & > th:first-child {
                width: 5%;
                line-height: 60px;
                border-bottom: 2px solid #e5e5e5;
                & > div > label {
                    cursor: pointer;
                }
            }
            & > th:nth-child(2) {
                width: 7%;
            }
            & > th:nth-child(3) {
                text-align: left;
                padding-left: 30px;
            }
        }
        & > tbody {
            & > tr {
                line-height: 40px;
                border-top: 1px solid #e5e5e5;
                border-bottom: 1px solid #e5e5e5;
                & >  td:first-child > div > label {
                    cursor: pointer;
                }
                & > td:nth-child(3) {
                    text-align: left;
                    cursor: pointer;
                    padding-left: 30px;
                }
                &:hover{
                    background-color: #f6f6f6;
                }
            }
            &>tr:nth-child(${(props) => props.pinCount}){
                border-bottom: 2px solid #e5e5e5;
            }
            
        }
    }
    & > div {
        & > table {
        width: 100%;
        text-align: center;
        margin: 93px 0 31px 0;
        & > thead > tr {
            line-height: 20px;
            border-bottom: 2px solid #e5e5e5;
            & > th:first-child {
                width: 5%;
                line-height: 60px;
                border-bottom: 2px solid #e5e5e5;
                & > div > label {
                    cursor: pointer;
                }
            }
            & > th:nth-child(2) {
                width: 7%;
            }
            & > th:nth-child(3) {
                text-align: left;
                padding-left: 30px;
            }
        }
        & > tbody {
            & > tr {
                line-height: 40px;
                border-top: 1px solid #e5e5e5;
                border-bottom: 1px solid #e5e5e5;
                & >  td:first-child > div > label {
                    cursor: pointer;
                }
                & > td:nth-child(3) {
                    text-align: left;
                    cursor: pointer;
                    padding-left: 30px;
                }
                &:hover{
                    background-color: #f6f6f6;
                }
            }
            &>tr:nth-child(${(props) => props.pinCount}){
                border-bottom: 2px solid #e5e5e5;
            }
            
        }
    }& > table {
        width: 100%;
        text-align: center;
        margin: 93px 0 31px 0;
        & > thead > tr {
            line-height: 20px;
            border-bottom: 2px solid #e5e5e5;
            & > th:first-child {
                width: 5%;
                line-height: 60px;
                border-bottom: 2px solid #e5e5e5;
                & > div > label {
                    cursor: pointer;
                }
            }
            & > th:nth-child(2) {
                width: 7%;
            }
            & > th:nth-child(3) {
                text-align: left;
                padding-left: 30px;
            }
        }
        & > tbody {
            & > tr {
                line-height: 40px;
                border-top: 1px solid #e5e5e5;
                border-bottom: 1px solid #e5e5e5;
                & >  td:first-child > div > label {
                    cursor: pointer;
                }
                & > td:nth-child(3) {
                    text-align: left;
                    cursor: pointer;
                    padding-left: 30px;
                }
                &:hover{
                    background-color: #f6f6f6;
                }
            }
            &>tr:nth-child(${(props) => props.pinCount}){
                border-bottom: 2px solid #e5e5e5;
            }
            
        }
    }
        display: flex;
        justify-content: space-between;
        align-items: center;
        & > button:first-child {
            font-size: 16px;
            line-height: 23px;
            border: 1px solid #c4c4c4;
            padding: 7px 20px;
            background-color: #fff;
            border-radius: 10px;
        }
        & > button:last-child {
            font-size: 16px;
            line-height: 23px;
            color: #df1f26;
            padding: 7px 20px;
            background-color: #fff;
            border-radius: 10px;
            border: 1px solid #c4c4c4;
        }
    }
`;

export const DoubleArrowImg = styled.img`
    width: 8px;
    height: auto;
    margin-top: 2px;
    padding: 5px;
    cursor: pointer;
`;

export const ArrowImg = styled.img`
    width: 4px;
    height: auto;
    margin-top: 2px;
    padding: 5px;
    cursor: pointer;
`;

export const RightDoubleArrowImg = styled.img`
    width: 8px;
    height: auto;
    transform: rotate(180deg);
    margin-top: 2px;
    padding: 5px;
    cursor: pointer;
`;

export const RightArrowImg = styled.img`
    width: 4px;
    height: auto;
    transform: rotate(180deg);
    margin-top: 2px;
    padding: 5px;
    cursor: pointer;
`;

//Pagination 스타일 시트
export const PaginationWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > div {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 12px;
        line-height: 17px;
        column-gap: 30px;
        & > .active {
            color: #df1f26;
            font-weight: 900;
        }
    }
`;

//NoticeWrite 스타일 시트
export const NoticeWriteWrapper = styled.div`
    margin-left: 17%;
    padding: 5% 1.5%;
`;

export const PinButton = styled.label`
    font-size: 16px;
    line-height: 23px;
    border: 1px solid #c4c4c4;
    padding: 7px 13px;
    background-color: ${(props) => (props.state ? '#DF1F26' : 'white')};
    border-radius: 10px;
    color: ${(props) => (props.state ? 'white' : 'black')};
    & > input {
        display: none;
    }
    & > img {
        width: 11px;
        margin-left: 6px;
    }
`;

export const WriteBoardForm = styled.form`
    width: 100%;
    height: 50vh;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    margin: 30px 0;
    & > div {
        border-bottom: 1px solid #e5e5e5;
        & > input {
            box-sizing: border-box;
            padding: 18px 26px;
            border: none;
            width: 100%;
            border-radius: 10px;
            font-size: 20px;
            font-family: notosansbold;
            &:focus {
                outline: none;
            }
            &::placeholder{
                color : #c4c4c4;
            }
        }
    }
    & > textarea {
        width: 90%;
        padding: 18px 26px;
        border: none;
        height: 70%;
        resize: none;
        font-size: 16px;
        &:focus {
            outline: none;
        }
        &::placeholder{
            color : #c4c4c4;
        }
    }
`;

export const NoticeWriteButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > button {
        font-size: 16px;
        line-height: 23px;
        border: 1px solid #c4c4c4;
        padding: 7px 20px;
        background-color: #fff;
        border-radius: 10px;
    }
    & > div > button {
        font-size: 16px;
        line-height: 23px;
        border: none;
        padding: 7px 20px;
        background-color: #df1f26;
        border-radius: 10px;
        margin-left: 20px;
        color: white;
    }
`;

export const NoticeDetailButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > button {
        font-size: 16px;
        line-height: 23px;
        border: 1px solid #c4c4c4;
        padding: 7px 20px;
        background-color: #fff;
        border-radius: 10px;
    }
    & > div > button:first-child {
        font-size: 16px;
        line-height: 23px;
        border: 1px solid #df1f26;
        padding: 7px 20px;
        background-color: white;
        border-radius: 10px;
        margin-left: 20px;
        color: #df1f26;
    }
    & > div > button:last-child {
        font-size: 16px;
        line-height: 23px;
        border: 1px solid #c4c4c4;
        padding: 7px 20px;
        background-color: white;
        border-radius: 10px;
        margin-left: 20px;
    }
`;

// ManageUser  스타일 시트
export const ManageUserWrapper = styled.div`
    margin-left: 17%;
    padding: 5% 1.5%;
    & > table {
        width: 100%;
        text-align: center;
        margin: 2% 0 31px 0;
        & > thead > tr {
            line-height: 20px;
            border-bottom: 2px solid #e5e5e5;
            & > th:first-child > div > label {
                cursor: pointer;
            }
            & > th:first-child {
                width: 5%;
                & > div {
                    display: flex;
                    justify-content: center;
                }
            }
            & > th:nth-child(2) {
                width: 4%;
                & > div {
                    display: flex;
                    justify-content: center;
                }
            }
            & > th:nth-child(3) {
                width: 6%;
                text-align: left;
                & > div {
                    display: flex;
                    justify-content: center;
                }
            }
            & > th:nth-child(4) {
                width: 20%;
                & > div {
                    display: flex;
                    justify-content: center;
                }
            }
            & > th:nth-child(5) {
                width: 10%;
                & > div {
                    display: flex;
                    justify-content: center;
                }
            }
            & > th:nth-child(6) {
                width: 13%;
                & > div {
                    display: flex;
                    justify-content: center;
                }
            }
            & > th:nth-child(7) {
                width: 10%;
                & > div {
                    display: flex;
                    justify-content: center;
                }
            }
            & > th:nth-child(8) {
                width: 13%;
                & > div {
                    display: flex;
                    justify-content: center;
                }
            }
            & > th:nth-child(9) {
                width: 10%;
                & > div {
                    display: flex;
                    justify-content: center;
                }
            }
            & > th:last-child {
                width: 10%;
            }
        }
        & > tbody {
            & > tr {
                line-height: 40px;
                border-top: 1px solid #e5e5e5;
                border-bottom: 1px solid #e5e5e5;
                cursor: pointer;
                & > td:first-child > div > label {
                    cursor: pointer;
                }
                &:hover{
                  background-color: #f6f6f6;
                }
            }
        }
    }
    & > div {
        display: flex;
        justify-content: space-between;
        & > div {
            display: flex;
        }
    }
`;

export const SearchBox = styled.div`
    padding: 2% 0 0.5% 0;
    display: flex;
    justify-content: flex-start !important;
    align-items: center;
    & > div {
        display: flex;
        align-items: center;
        cursor: pointer;
        & > img {
            margin-left: 7px;
            width: 20px;
        }
        & > p {
            font-size: 16px;
            line-height: 23px;
            color: #c4c4c4;
        }
    }
`;

// ManageUserDetail 스타일 시트
export const ManageUserDetailWrapper = styled.div`
    margin-left: 17%;
    padding: 5% 1.5%;
    overflow-y: scroll;
    & > table {
        width: 100%;
        margin-top: 50px;
        & > tbody > tr {
            height: 40px;
            border-top: 1px solid #e5e5e5;
            border-bottom: 1px solid #e5e5e5;
            &:first-child {
                border-top: 2px solid #e5e5e5;
            }
            &:last-child {
                border-bottom: 2px solid #e5e5e5;
            }
            & > td:first-child {
                padding-left: 10px;
                vertical-align: middle;
                width: 13%;
                font-weight: bold;
                font-size: 16px;
                line-height: 23px;
            }
            & > td:last-child {
                vertical-align: middle;
                font-weight: 500;
                font-size: 16px;
                line-height: 23px;
                & > div {
                    display: flex;
                    align-items: center;
                    column-gap: 10px;
                    & > button {
                        margin-top: 3px;
                        font-size: 12px;
                        line-height: 17px;
                        border: 1px solid #df1f26;
                        border-radius: 8px;
                        color: #df1f26;
                        background-color: white;
                    }
                }
            }
        }
    }
    & > div {
        margin-top: 20px;
        & > button {
            cursor: pointer;
            font-weight: normal;
            font-size: 16px;
            line-height: 23px;
            border: 1px solid #c4c4c4;
            border-radius: 10px;
            background-color: white;
            padding: 7px 20px;
        }
    }
`;

// SearchGroup 스타일 시트
export const SearchGroupWrapper = styled(NoticeWrapper)`
    & > table {
        margin-top : 50px;
    }
    & > table > thead > tr {
        & > th:first-child > div > label {
            cursor: pointer;
        }
        & > th:nth-child(3) {
            justify-content: flex-start;
        }
        & > th:nth-child(4) {
            width: 15%;
        }
        & > th:nth-child(5) {
            width: 15%;
        }
    }
    & > table > tbody > tr > td:first-child > div > label {
        cursor: pointer;
    }
    & > div > button {
        cursor: pointer;
    }
`;

export const SearchGroupDetailWrapper = styled(ManageUserDetailWrapper)`
    & > div {
        display: flex;
        justify-content: space-between;
        & > button {
            padding: 7px 20px;
            font-size: 16px !important;
            line-height: 23px;
            cursor: pointer;
        }
        & > div > button {
            margin-left: 10px;
            background-color: white;
            border: 1px solid #c4c4c4;
            padding: 7px 10px;
            border-radius: 10px;
            font-size: 16px;
            line-height: 23px;
            cursor: pointer;
        }
        & > div > button:last-child {
            color: #df1f26;
        }
    }
`;

// breadcrumbArrow 스타일시트
export const BcArrow = styled.img`
    width: 6px;
    height: 12px;
`;

export const BorderDiv = styled.div`
    display: flex;
    justify-content: space-around;
    border-right: solid 1px #e5e5e5;
    border-left: solid 1px #e5e5e5;
    margin: 10px 0;
`;

export const BorderDivR = styled.div`
    display: flex;
    justify-content: space-around;
    border-right: solid 1px #e5e5e5;
    margin: 10px 0;
`;

export const BorderDivL = styled.div`
    display: flex;
    justify-content: flex-start;
    padding-left : 2%;
    border-left: solid 1px #e5e5e5;
    margin: 10px 0;
`;

// FAQ 스타일 시트
export const FaqWrapper = styled(NoticeWrapper)`
        & > table {
            margin-top : 35px;
        }
    & > table > thead > tr {
        & > th:first-child > div > label {
            cursor: pointer;
        }
        & > th:nth-child(4) {
        }
        & > th:nth-child(6) {
            width : 13%;
        }
    }
    & > table > tbody > tr > td:first-child > div > label {
        cursor: pointer;
    }
    & > div > button {
        cursor: pointer;
    }
`;