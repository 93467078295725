import axios from 'axios';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Cookie from 'universal-cookie';
import breadcrumbArrow from '../asset/img/breadcrumbarrow.png';
import Delete from '../asset/img/deletemanager.png';
import AuthCheckModal from '../components/AuthCheckModal';
import EmptyValueModal from '../components/EmptyValueModal';
import SearchStationRegisterTable from '../components/SearchStationRegisterTable';
import {
    BcArrow,
    Description,
    SearchGroupDetailWrapper,
    Title,
} from '../css/styles';

const cookies = new Cookie();

const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start !important;
    & > input {
        font-weight: bold;
        font-size: 32px;
        line-height: 46px;
        border: none;
        border-bottom: 2px solid #e5e5e5;
        &:focus {
            outline: none;
        }
    }
    & > img {
        cursor: pointer;
        width: 20px;
    }
`;

const ButtonWrapper = styled.div`
    & > button:first-child {
        cursor: pointer;
        padding: 5px 20px !important;
    }
    & > button:last-child {
        cursor: pointer;
        padding: 5px 10px !important;
        color: white;
        background-color: #df1f26;
    }
`;

function SearchStationRegister({ props }) {

    const [toggleAddressModal, setToggleAddressModal] = React.useState(false);

    const [title, setTitle] = React.useState('');
    const [address, setAddress] = React.useState(null);
    const [outside, setOutside] = React.useState(null);
    const [stationID, setStationID] = React.useState(null);
    const [maxPort, setMaxPort] = React.useState('4');

    const [location , setLocation] = React.useState(null);
    const [lat, setLat] = React.useState('');
    const [lon, setLon] = React.useState('');

    const [stationKind, setStationKind] = React.useState(null);
    const [privateStation, setPrivateStation] = React.useState('Y');

    const [toggleEmptyModal , setToggleEmptyModal] = React.useState(false);

    const [toggleAuthModal , setToggleAuthModal] = React.useState(false);

    const handleToggleAuthModal = () => {
        setToggleAuthModal(!toggleAuthModal);
    }
    const handleToggleEmptyModal = () => {
        setToggleEmptyModal(!toggleEmptyModal);
    }

    const navigate = useNavigate();

    const tableInputChange = (e) => {
        let name = e.target.name;
        switch (name) {
            case 'address':
                setAddress(e.target.value);
                break;
            case 'outside':
                setOutside(e.target.value);
                break;
            case 'stationId':
                setStationID(e.target.value);
                break;
            case 'maxPort':
                setMaxPort(e.target.value);
                break;
            case 'lat':
                setLat(e.target.value);
                break;
            case 'lon':
                setLon(e.target.value);
                break;
            case 'stationKind':
                setStationKind(e.target.value);
                break;
            case 'privateStation':
                setPrivateStation(e.target.value);
                break;
        }
    };

    const handleToggleAddressModal = () => {
        setToggleAddressModal(!toggleAddressModal);
    }

    const onTitleChange = (e) => {
        setTitle(e.target.value);
    };

    const onRegister = () => {
        axios({
            method: 'post',
            url: `${window.Config.G_API_URL}/admin/station`,
            params: {
                token: cookies.get('token'),
                station_id: stationID,
                address: location,
                latitude: lat,
                longitude: lon,
                maxinum_port: maxPort,
                outside: outside,
                private_station: privateStation,
                station_name: title,
                charger_status : stationKind,
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    window.location.href = '/admin/searchstation/:1';
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else if(response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                handleToggleEmptyModal();
            });
    };

    return (
        <SearchGroupDetailWrapper>
            <Title>스테이션 등록</Title>
            <Description>
                스테이션 관리 <BcArrow src={breadcrumbArrow} /> 스테이션 검색{' '}
                <BcArrow src={breadcrumbArrow} /> 스테이션 등록
            </Description>
            <TitleWrapper>
                <input
                    type="text"
                    placeholder="스테이션 이름"
                    value={title||''}
                    onChange={onTitleChange}
                />
                <img src={Delete} alt="" />
            </TitleWrapper>
            <SearchStationRegisterTable
                address={address}
                outside={outside}
                stationID={stationID}
                maxPort={maxPort}
                lat={lat}
                lon={lon}
                setLat={setLat}
                setLon={setLon}
                stationKind={stationKind}
                privateStation={privateStation}
                onChange={tableInputChange}
                location={location}
                setLocation={setLocation}
                handleToggleAddressModal={handleToggleAddressModal}
            />
            <ButtonWrapper>
                <button onClick={() => navigate(-1)}>취소</button>
                <button onClick={onRegister}>등록 완료</button>
            </ButtonWrapper>

            <EmptyValueModal
                toggle={toggleEmptyModal}
                toggleFunc={handleToggleEmptyModal}
            />
            <AuthCheckModal
                toggle={toggleAuthModal}
                toggleFunc={handleToggleAuthModal}
            />
        </SearchGroupDetailWrapper>
    );
}

export default SearchStationRegister;
