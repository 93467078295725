import React from 'react';
import CheckBox from "./CheckBox";
import styled from "styled-components";

const Tr = styled.tr`
  & > td:first-child{
    width: 3%;
  }
  & > td:nth-child(2){
    width: 7%;
  }
  & > td:nth-child(3){
    width: 13%;
  }
`

function StationUsageModalList(props) {
    return (
        <Tr>
            <td>{props.rownum}</td>
            <td>{props.coupon_name}</td>
            <td>{props.date_time}</td>
            <td>{props.usage_status}</td>
            <td>{props.station_id === "" ? '-' : props.station_id}</td>
            <td>{props.station_name === "" ? '-' : props.station_name}</td>
            <td>{props.port === "" ? '-' : props.port}</td>
            <td>{props.usage_date_time === "" ? '-' : props.usage_date_time }</td>
            <td>{props.coupon_start_date === "" ? '-' : props.coupon_start_date }</td>
            <td>{props.coupon_end_date === "" ? '-' : props.coupon_end_date }</td>
        </Tr>
    );
}

export default StationUsageModalList;