import axios from 'axios';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import Cookie from 'universal-cookie';
// import qs from "https://cdn.skypack.dev/qs";
import { CSVLink } from "react-csv";
import { BcArrow, BorderDiv, Description, NoticeWrapper, Title } from '../css/styles';

import styled from 'styled-components';
import breadcrumbArrow from '../asset/img/breadcrumbarrow.png';
import DeleteSearch from '../asset/img/deletesearch.png';
import SelectArrow from '../asset/img/selectarrow.png';
import CheckBox from '../components/CheckBox';
import GroupSettlementList from '../components/GroupSettlementList';
import PaginationUsage from '../components/PaginationUsage';
import SearchIndexBox from '../components/SearchIndexBox';
import StationSettlementList from '../components/StationSettlementList';

import Dotbogi from '../asset/img/dotbogi.png';

import StationSettlementUsageModal from '../components/StationSettlementUsageModal';

const cookies = new Cookie();

const ColumnBox = styled.div`
    display: flex;
    flex-direction: column;
`;

const SearchBox = styled.div`
    padding: 2% 0;
    display: flex;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    column-gap: 15px;
    position: relative;
    & > div {
        display: flex;
        align-items: center;
        & > img {
            margin-top: 10px;
            margin-left: 7px;
            width: 20px;
        }
        & > p {
            font-size: 16px;
            margin-top: 9px;
            line-height: 23px;
            color: #c4c4c4;
        }
    }
`;

const SelectBox = styled.select`
    cursor: pointer;
    appearance: none;
    border: 1px solid #c4c4c4;
    border-radius: 10px; 
    padding: 7px 15px;
    font-size: 16px;
    line-height: 23px;
    background-image: url(${SelectArrow});
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: 87%;
    &:focus {
        outline: none;
    }
`;

const SearchInput = styled.input`
    width: 40%;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    background-image: url(${Dotbogi});
    background-repeat: no-repeat;
    background-size: 17px;
    background-position: 97%;
    padding: 7px 10px;
    font-size: 16px;
    line-height: 23px;
    color: #7e7e7e;
`;

const FlexBox = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    column-gap: 10px;
    padding : 1% 0 1.5% 0;
    & > div {
            display: flex;
            width : 50%;
            align-items: center;
        & > div {
            display: flex;
            align-items: center;
            margin-left : 10px;
                cursor: pointer;
            & > img {
            }
        }
    }
`;

const Table = styled.table`
    width : 100%;
    margin: 0px !important;
    & > thead > tr {
        & > th:nth-child(1) {
            width : 5% !important;
        }
        & > th:nth-child(2) {
            width : 7% !important;
        }
        & > th:nth-child(3) {
            width : 18%;
            text-align : center !important;
            padding-left : 0 !important;
        }
        & > th:nth-child(4) {
            width : 11%;
            text-align : center !important;
            & > div {
                justify-content : center;
            }
        }
        & > th:nth-child(5) {
            width : 13%;
        }
        & > th:nth-child(6) {
            width : 11%;
        }
        & > th:nth-child(7) {
            width : 13%;
        }
    }
`

const Table1 = styled.table`
    width : 100%;
    margin: 0px !important;
    & > thead > tr {
        & > th:nth-child(1) {
            width : 15% !important;
        }
        & > th:nth-child(2) {
            width : 15% !important;
        }
        & > th:nth-child(3) {
            width : 13%;
            text-align : center !important;
            padding-left : 0 !important;
        }
        & > th:nth-child(4) {
            width : 10%;
            text-align : center !important;
            & > div {
                justify-content : center;
            }
        }
        & > th:nth-child(5) {
            width : 10%;
        }
        & > th:nth-child(6) {
            width : 10%;
        }
        & > th:nth-child(7) {
            width : 10%;
        }
    }
`

const PaginationWrapper = styled.div`
    display : flex;
    justify-content : space-between !important;
    padding-top : 2%;
`

const ButtonWrapper = styled(PaginationWrapper)`
    justify-content : flex-end !important;
    padding-top : 0%;
    padding-bottom : 2%;
`

const ThSelectBox = styled(SelectBox)`
    border : none;
    background-color : white;
    font-weight : 700;
    background-position: 100%;
`

const SubTitle = styled(Title)`
    font-size : 28px;
`

const BorderBox = styled.div`
    border: 1px solid #C4C4C4;
    display : block !important;
    border-radius : 10px;
    padding : 0 3% 0 3%;
    margin-bottom : 30px;
    height : 350px !important;
    overflow : scroll;
`

function ManageAdminUser() {
    const { no } = useParams();
    const [checked, setChecked] = React.useState(false);
    const [checkAll, setCheckAll] = React.useState(false);
    const [checkList, setCheckList] = React.useState([]);

    const [groupInfo , setGroupInfo] = React.useState([])

    var date = new Date();
    var currentDate1 = date.toISOString().substring(0,10);

    const [groupSearchInfo , setGroupSearchInfo] = React.useState({
        searchData : '',
        sortType : null,
        startDate : '2022-01-01' ,
        endDate : currentDate1,
    });  

    const [stationInfo , setStationInfo] = React.useState([])
    const [stationExcelInfo , setStationExcelInfo] = React.useState([])
    const [stationPageInfo , setStationPageInfo] = React.useState([])

    const [stationSearchInfo , setStationSearchInfo] = React.useState({
        searchData : '',
        searchType : null,
        sortType : null,
    });  

    const [toggleUsageModal , setToggleUsageModal] = React.useState(false);
    const [stationProps, setStationProps] = React.useState({});

    const handleToggleUsageModal = (props) => {
        setStationProps(props);
        setToggleUsageModal(!toggleUsageModal);
    }

    const onStartDateChange = (e) => {
        setGroupSearchInfo({...groupSearchInfo , startDate : e.target.value});
    };

    const onEndDateChange = (e) => {
        setGroupSearchInfo({...groupSearchInfo , endDate : e.target.value});
    };

    const groupCsvHeader = [
        {label : 'No.' , key: 'group_id'},
        {label : '그룹명' , key: 'group_name'},
        {label : '충전 시간 (분)' , key: 'charging'},
        {label : '충전 요금 (원)' , key: 'charging_price'},
        {label : '거치 시간 (분)' , key: 'holding'},
        {label : '거치 요금 (원)' , key: 'holding_price'},
        {label : '쿠폰 금액 (원)' , key: 'coupon_amount'},
        {label : '총 요금 (원)' , key: 'amount'}
    ];

    const stationCsvHeader = [
        {label : '스테이션 명' , key: 'station_name'},
        {label : '스테이션 ID' , key: 'station_id'},
        {label : '그룹명' , key: 'group_name'},
        {label : '충전 시간 (분)' , key: 'charging'},
        {label : '충전 요금 (원)' , key: 'charging_price'},
        {label : '거치 시간 (분)' , key: 'holding'},
        {label : '거치 요금 (원)' , key: 'holding_price'},
        {label : '쿠폰 금액 (원)' , key: 'coupon_amount'},
        {label : '총 요금 (원)' , key: 'amount'}
    ];

    useEffect(() => {
        axios({
            method: 'get',
            url: `${window.Config.G_API_URL}/admin/settlement/group`,
            params: {
                start_date : groupSearchInfo.startDate,
                end_date : groupSearchInfo.endDate,
                token: cookies.get('token'),
                search_data: groupSearchInfo.searchData,
                search_type: groupSearchInfo.searchType,
                sort_type: groupSearchInfo.sortType,
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    setGroupInfo(response.data.data);
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    }, [no , groupSearchInfo.startDate , groupSearchInfo.endDate , groupSearchInfo.searchData , groupSearchInfo.sortType]);

    useEffect(() => {
        axios({
            method: 'get',
            url: `${window.Config.G_API_URL}/admin/settlement/station`,
            params: {
                token: cookies.get('token'),
                start_date : groupSearchInfo.startDate,
                end_date : groupSearchInfo.endDate,
                search_data: stationSearchInfo.searchData,
                search_type: stationSearchInfo.searchType,
                sort_type: stationSearchInfo.sortType,
                groupArr : checkList,
                page_num: no.replace(':', ''),
            },
            paramsSerializer: function (paramObj) {
                const params = new URLSearchParams();
                for (const key in paramObj) {
                    params.append(key, paramObj[key] === 'null' ? null : paramObj[key]);
                }

                return params.toString();
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    setStationInfo(response.data.data.result.list);
                    setStationPageInfo(response.data.data.result);
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    }, [no , checkList, groupSearchInfo.startDate , groupSearchInfo.endDate , stationSearchInfo.searchData , stationSearchInfo.sortType]);

    useEffect(()=>{
        axios({
            method: 'get',
            url: `${window.Config.G_API_URL}/admin/settlement/station/excel`,
            params: {
                token: cookies.get('token'),
                start_date : groupSearchInfo.startDate,
                end_date : groupSearchInfo.endDate,
                search_data: stationSearchInfo.searchData,
                search_type: stationSearchInfo.searchType,
                sort_type: stationSearchInfo.sortType,
                groupArr : checkList,
            },
            paramsSerializer: function (paramObj) {
                const params = new URLSearchParams();
                for (const key in paramObj) {
                    params.append(key, paramObj[key] === 'null' ? null : paramObj[key]);
                }

                return params.toString();
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    setStationExcelInfo(response.data.data);
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    }, [checkList, groupSearchInfo.startDate , groupSearchInfo.endDate , stationSearchInfo.searchData , stationSearchInfo.sortType])

    const handleSearchData = (e) => {
        setGroupSearchInfo({...groupSearchInfo , searchData : e.target.value});
    }

    const handleSearchData1 = (e) => {
        setStationSearchInfo({...stationSearchInfo , searchData : e.target.value});
    }

    const handleSearchType = (e) => {
        setGroupSearchInfo({...groupSearchInfo , sortType : e.target.value});
    }

    const handleSearchType1 = (e) => {
        setStationSearchInfo({...stationSearchInfo , searchType : e.target.value});
    }
    const handleSearchType2 = (e) => {
        setStationSearchInfo({...stationSearchInfo , sortType : e.target.value});
    }

    const onReset = () => {
        setGroupSearchInfo({...groupSearchInfo , searchData : ''});
        console.log('reset')
    }

    const onReset1 = () => {
        setStationSearchInfo({...stationSearchInfo , searchData : ''});
        console.log('reset')
    }

    const handleCheckAll = (e) => {
        setCheckAll(!checkAll);
    };

    const handleDate = (e) => {
        console.log('handleDate')
        var date = new Date();
        var currentDate1 = date.toISOString().substring(0,10);
        switch(e.target.id){
            case 'all':
                setGroupSearchInfo({...groupSearchInfo , startDate : '2022-01-01' ,endDate : currentDate1});
                break;
            case 'week':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 7));
                var currentDate = newDate.toISOString().substring(0,10);
                setGroupSearchInfo({...groupSearchInfo , startDate : currentDate,endDate : currentDate1});
                break;
            case 'halfMonth':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 14));
                var currentDate = newDate.toISOString().substring(0,10);
                setGroupSearchInfo({...groupSearchInfo , startDate : currentDate,endDate : currentDate1});
                break;
            case 'month':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 30));
                var currentDate = newDate.toISOString().substring(0,10);
                setGroupSearchInfo({...groupSearchInfo , startDate : currentDate,endDate : currentDate1});
                break;
            case 'threeMonth':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 91));
                var currentDate = newDate.toISOString().substring(0,10);
                setGroupSearchInfo({...groupSearchInfo , startDate : currentDate,endDate : currentDate1});
                break;
            case 'halfYear':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 182));
                var currentDate = newDate.toISOString().substring(0,10);
                setGroupSearchInfo({...groupSearchInfo , startDate : currentDate,endDate : currentDate1});
                break;
            case 'year':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 365));
                var currentDate = newDate.toISOString().substring(0,10);
                setGroupSearchInfo({...groupSearchInfo , startDate : currentDate,endDate : currentDate1});
                break;
        }
    }

    useEffect(()=>{
        if(checkAll){
            let arr = [];
            groupInfo.map((item)=>{
                    arr.push(`${item.group_id}`);
            });
            setCheckList(arr);
        } else if(checkList.length==groupInfo.length) {
            setCheckList([]);
        }
    },[checkAll]);

    useEffect(()=>{
        if(checkList.length == groupInfo.length){
            setCheckAll(true);
        } else {
            setCheckAll(false);
        }
        // console.log(checkList);
    },[checkList,groupInfo]);

    const handleCheckEach = (e) => {
        const { id, checked } = e.target;
        let arr = [...checkList];

        if (arr.includes(id)) {
            for (let i = 0; i < arr.length; i++) {
                if (arr[i] == id) {
                    arr.splice(i, 1);
                    setChecked(!checked);
                    break;
                }
            }
            setCheckList(arr);
        } else {
            setChecked(!checked);
            setCheckList([...checkList, id]);
        }
    };

    return (
        <NoticeWrapper>
            <Title>그룹 및 스테이션별 관리</Title>
            <Description>
                정산 관리 <BcArrow src={breadcrumbArrow} /> 그룹 및 스테이션별 관리
            </Description>
            <SearchBox>
                <ColumnBox>
                    <SearchIndexBox
                        startChange={onStartDateChange}
                        endChange={onEndDateChange}
                        handleDate={handleDate}
                        startDate={groupSearchInfo.startDate}
                        endDate={groupSearchInfo.endDate}
                    />
                </ColumnBox>
            </SearchBox>
            <SubTitle>
                그룹 리스트
            </SubTitle>
            <FlexBox>
                <div>
                    <SearchInput type="text" value={groupSearchInfo.searchData||''} placeholder={'검색어 입력'} onChange={handleSearchData}/>
                    <div onClick={onReset}>
                        <img src={DeleteSearch} alt="" />
                        <p>초기화</p>
                    </div>
                </div>
                <SelectBox name="searchselect" id="serachselect" onChange={handleSearchType}>
                    <option value={null}>전체</option>
                    <option value="그룹명 순">그룹명 순</option>
                    <option value="총 요금 높은 순">총 요금 높은 순</option>
                    <option value="총 요금 낮은 순">총 요금 낮은 순</option>
                </SelectBox>
            </FlexBox>
            <BorderBox>
                <Table>
                    <thead>
                        <tr>
                            <th><CheckBox
                                    no="checkAll"
                                    checked={checkAll}
                                    onChange={handleCheckAll}
                                /></th>
                            <th><BorderDiv>No.</BorderDiv></th>
                            <th>그룹명</th>
                            <th><BorderDiv>충전 시간 (분)</BorderDiv></th>
                            <th>충전 금액 (원)</th>
                            <th><BorderDiv>거치 시간 (분)</BorderDiv></th>
                            <th>거치 금액 (원)</th>
                            <th><BorderDiv>쿠폰 적용 (원)</BorderDiv></th>
                            <th>총 요금(원)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {groupInfo.map((item, key) => (
                            <GroupSettlementList
                                key={key}
                                amount={item.amount}
                                charging={item.charging}
                                charging_price={item.charging_price}
                                no={item.group_id}
                                group_name={item.group_name}
                                coupon_amount={item.coupon_amount}
                                holding={item.holding}
                                holding_price={item.holding_price}
                                checked={checkList.includes(`${item.group_id}`)}
                                onChange={handleCheckEach}
                            />
                        ))}
                    </tbody>
                </Table>
            </BorderBox>
            <ButtonWrapper>
                <button>
                    <CSVLink data={groupInfo} headers={groupCsvHeader} filename={'그룹별 정산데이터'}>CSV 다운로드</CSVLink>
                </button>
            </ButtonWrapper>
            <SubTitle>
                스테이션 리스트
            </SubTitle>
            <FlexBox>
                <div>
                    <SelectBox name="searchselect" id="serachselect" onChange={handleSearchType1}>
                        <option value={null}>전체</option>
                        <option value="스테이션명">스테이션명</option>
                        <option value="스테이션ID">스테이션 ID</option>
                    </SelectBox>
                    <SearchInput type="text" value={stationSearchInfo.searchData||''} placeholder={'검색어 입력'} onChange={handleSearchData1} style={{marginLeft : '10px'}}/>
                    <div onClick={onReset1}>
                        <img src={DeleteSearch} alt="" />
                        <p>초기화</p>
                    </div>
                </div>
                <SelectBox name="searchselect" id="serachselect" onChange={handleSearchType2}>
                    <option value={null}>전체</option>
                    <option value="스테이션명 순">스테이션명 순</option>
                    <option value="총 요금 높은 순">총 요금 높은 순</option>
                    <option value="총 요금 낮은 순">총 요금 낮은 순</option>
                </SelectBox>
            </FlexBox> 
            <Table1>
                    <thead>
                        <tr>
                            <th>
                                스테이션 명
                            </th>
                            <th>
                                <BorderDiv>스테이션 ID</BorderDiv>
                            </th>
                            <th>
                                그룹명
                            </th>
                            <th>
                                <BorderDiv>충전 시간 (분)</BorderDiv>
                            </th>
                            <th>
                                충전 요금 (원)
                            </th>
                            <th>
                                <BorderDiv>거치 시간 (분)</BorderDiv>
                            </th>
                            <th>
                                거치 금액 (원)
                            </th>
                            <th>
                                <BorderDiv>쿠폰 적용 (원)</BorderDiv>
                            </th>
                            <th>
                                총 요금(원)
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                        stationInfo.map((item, key) => (
                            <StationSettlementList
                                key={key}
                                amount={item.amount}
                                charging={item.charging}
                                charging_price={item.charging_price}
                                no={item.station_id}
                                station_name={item.station_name}
                                group_name={item.group_name}
                                holding={item.holding}
                                holding_price={item.holding_price}
                                coupon_amount={item.coupon_amount}
                                checked={checkList.includes(`${item.group_id}`)}
                                onChange={handleCheckEach}
                                pagenum={no.replace(':', '')}
                                startDate={groupSearchInfo.startDate}
                                endDate={groupSearchInfo.endDate}
                                handleToggleUsageModal={handleToggleUsageModal}
                            />
                        ))}
                    </tbody>
                </Table1>
                {toggleUsageModal ? 
                <StationSettlementUsageModal
                    toggle={toggleUsageModal}
                    toggleFunc={handleToggleUsageModal}
                    stationId={stationProps.no}
                    stationName={stationProps.station_name}
                    pagenum={stationProps.pagenum}
                    startDate={stationProps.startDate}
                    endDate={stationProps.endDate}
                />
                :
                ''
                }                
            <PaginationWrapper>
                <p/>
                <PaginationUsage
                    no={no.replace(':', '')}
                    url="/admin/groupstation/:"
                    pages={stationPageInfo['pages']}
                    isFirstPage={stationPageInfo['isFirstPage']}
                    isLastPage={stationPageInfo['isLastPage']}
                    prePage={stationPageInfo['prePage']}
                    nextPage={stationPageInfo['nextPage']}
                    hasPrePage={stationPageInfo['hasPreviousPage']}
                    hasNextPage={stationPageInfo['hasNextPage']}
                />
                <button>
                    <CSVLink data={stationExcelInfo} headers={stationCsvHeader} filename={'스테이션별 정산데이터'}>CSV 다운로드</CSVLink>
                </button>
            </PaginationWrapper>
        </NoticeWrapper>
    );
}

export default ManageAdminUser;
