import React , { useEffect }  from 'react';
import CheckBox from "./CheckBox";
import styled from "styled-components";

import Cookie from 'universal-cookie';
const cookies = new Cookie();
const Tr = styled.tr`
  & > td:first-child{
    width: 7%;
  }
  & > td:nth-child(2){
    width: 7%;
  }
  & > td:nth-child(3){
    width: 13%;
  }
  & > td:nth-child(7){
    width: 13%;
    text-align : right;
    padding-right : 10px;
  }
`

function StationUsageModalList(props) {
  const [levelAuth , setLevelAuth] = React.useState(false);

  const maskingPhone = (originStr) => {
    let maskingStr = '';
    let phoneStr = originStr.length < 11 ? originStr.match(/\d{10}/gi) : originStr.match(/\d{11}/gi);
  
  if(originStr.length < 11)
  { // 1.1) 0110000000
    return maskingStr = originStr.toString().replace(phoneStr, phoneStr.toString().replace(/(\d{3})(\d{3})(\d{4})/gi,'$1***$3'));
  }
  else
  { // 1.2) 01000000000
    return maskingStr = originStr.toString().replace(phoneStr, phoneStr.toString().replace(/(\d{3})(\d{4})(\d{4})/gi,'$1****$3'));
  }
}
    
const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

useEffect(()=>{
    if(cookies.get('level')<=0){
        setLevelAuth(true);
    }
},[])

    return (
        <Tr>
            <td><CheckBox no={props.us_id} onChange={props.onChange} checked={props.checked}/></td>
            <td>{props.rownum}</td>
            <td>{props.us_id}</td>
            <td>{props.us_name}</td>
            <td>{levelAuth ? props.us_phone : maskingPhone(props.us_phone)}</td>
            <td>{props.us_birth}</td>
            <td>{props.amount === undefined ? props.amount : numberWithCommas(props.amount)}</td>
        </Tr>
    );
}

export default StationUsageModalList;