import React from 'react';
import styled from "styled-components";
import SelectArrow from "../asset/img/selectarrow.png";

const SelectBox = styled.select`
  cursor: pointer;
  appearance:none;
  border: 1px solid #C4C4C4;
  border-radius: 10px;
  padding: 7px 20px 7px 10px;
  font-size: 16px;
  line-height: 23px;
  background-image: url(${SelectArrow});
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: 85%;
  &:focus{
    outline: none;
  }
`

const BigInput = styled.input`
  border: 1px solid #C4C4C4;
  width: 25%;
  padding: 8px;
  border-radius: 10px;
`

function ManageUserDetailTable(props) {

    return (
        <table>
            <tbody>
                <tr>
                    <td>이름</td>
                    <td>
                        <BigInput type='text' name={'admin_name'} value={props.managerInfo.admin_name||''} onChange={props.onChange}/>
                    </td>
                </tr>
                <tr>
                    <td>아이디</td>
                    <td>
                        {props.managerInfo.admin_id}
                    </td>
                </tr>
                <tr>
                    <td>연락처</td>
                    <td>
                        <BigInput type='text' name={'admin_phone'} value={props.managerInfo.admin_phone||''} onChange={props.onChange}/>
                    </td>
                </tr>
                <tr>
                    <td>등록일</td>
                    <td>{props.managerInfo.date_time}</td>
                </tr>
                <tr>
                    <td>관리자 등급</td>
                    <td>
                        {
                            props.managerInfo.admin_level === '4' ?
                                '업체관리자'
                                :
                                <SelectBox name="admin_level" id="state" value={props.managerInfo.admin_level||undefined} onChange={props.onChange}>
                                    <option value="" hidden>선택</option>
                                    <option value="0">슈퍼관리자</option>
                                    <option value="1">최고관리자</option>
                                    <option value="2">고객센터 관리자</option>
                                    <option value="3">고객센터</option>
                                </SelectBox>
                        }
                    </td>
                </tr>
                {
                    props.managerInfo.admin_level === '4' ? 
                    <tr>
                        <td>업체명</td>
                        <td>{props.managerInfo.company}</td>
                    </tr>
                    : 
                    null
                }
            </tbody>
        </table>
    );
}

export default ManageUserDetailTable;