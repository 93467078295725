import React from 'react';
import styled from 'styled-components';

import Check from '../asset/img/checkboxcheck.png';

const LabelWrapper = styled.div`
    position: relative;
    top: ${props =>  props.relTop};
    & > input {
      display: none;
      &:checked + label{
        background-color: #DF1F26;
        border: none;
      }
    }
    & > label {
      display: inline-block;
      width: 24px;
      height : 24px;
      border: 1px solid #000000;
      box-sizing: border-box;
      border-radius: 9px;
    }
    & > label > img {
      position: absolute;
      width: 11px;
      top: ${props => props.no === 'checkAll' ? '32%' : props.top};
      left: ${props =>  props.left}
    }
`

function CheckBox(props) {
    return (
        <LabelWrapper no={props.no} top={props.top} left={props.left} relTop={props.relTop}>
            <input type="checkbox" id={props.no} value={props.id} name={props.name} onChange={props.onChange} checked={props.checked} readOnly/>
            <label htmlFor={props.no}><img src={Check} alt=""/></label>
        </LabelWrapper>
    );
}

CheckBox.defaultProps = {
    top : '30%',
    left : '42%',
    relTop : '6px',
}

export default CheckBox;