import React from 'react'

import Pin from '../asset/img/pin.png';
import CheckBox from "./CheckBox";

import styled from 'styled-components';

const TdRight = styled.td`
    text-align : right;
`

function SearchGroupList(props) {

    const goToDetail = () => {
        window.location.href = `/admin/searchgroup/detail/:${props.no}/:1`
    }
    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };
    return (
        <tr>
            <td><CheckBox no={props.no} onChange={props.onChange} checked={props.checked}/></td>
            <td>{props.no}</td>
            <td onClick={goToDetail}>
                {props.title}
                {
                    props.exposure === 'Y' ? <img src={Pin} alt='' style={{width : '11px' , marginLeft : '6px'}}/> : ''
                }
            </td>
            <TdRight>{props.chargePrice === undefined ? props.chargePrice : numberWithCommas(props.chargePrice)}</TdRight>
            <TdRight>{props.holderPrice === undefined ? props.holderPrice : numberWithCommas(props.holderPrice)}</TdRight>
            <td>{props.stationCount}</td>
            <td>{props.status}</td>
        </tr>
    )
}

export default SearchGroupList