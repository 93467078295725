import React, {useEffect} from 'react';
import styled from "styled-components";
import SelectArrow from "../asset/img/selectarrow.png";
import Cookie from "universal-cookie";
import Postcode from "./Postcode";
const cookies = new Cookie();

const Table = styled.table`
  & > tbody > tr > td:first-child{
    width: 17% !important;
  }
  & > tbody > tr:last-child{
    border-bottom: 2px solid #E5E5E5 !important;
  }
`

const BigInput = styled.input`
  border: 1px solid #C4C4C4;
  width: 25%;
  padding: 7px;
  border-radius: 10px;
`

const AddressInput = styled.input`
  border: 1px solid #C4C4C4;
  width: 25%;
  padding: 7px;
  border-radius: 10px;
  &:focus{
    outline: none;
  }
`

const SelectBox = styled.select`
  appearance:none;
  border: 1px solid #C4C4C4;
  border-radius: 10px;
  padding: 4px 12px;
  font-size: 16px;
  line-height: 23px;
  width: 7%;
  background-image: url(${SelectArrow});
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: 87%;
  &:focus{
    outline: none;
  }
`

const RedBox = styled.label`
  color : #DF1F26;
  font-size: 12px;
  border-radius: 10px;
  border: 1px solid #DF1F26;
  padding : 0 7px 1px 7px;
  width: 45px;
  margin-top: 10px;
  cursor: pointer;
`

const ImgInput = styled.input`
    display: none;
`

const TableColCell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  row-gap: 10px;
`

const UploadImg = styled.img`
  width: 50%;
`

const RowBox = styled.div`
 & > p {
   color : #DF1F26;
   font-size: 12px;
   border-radius: 10px;
   border: 1px solid #DF1F26;
   padding : 0 7px 1px 7px;
   cursor: pointer;
 }
`

function AdvertisementUpdateTable(props) {

    const [preImg , setPreImg] = React.useState('');

    const handleFile = (e) => {
        let reader = new FileReader();

        props.setImgModify(true);

        reader.onloadend = () => {
            const base64 = reader.result;
            if(base64){
                props.setImgBase(base64.toString());
                setPreImg(base64.toString());
            }
        }

        if(e.target.files[0]){
            reader.readAsDataURL(e.target.files[0]);
            props.setImgFile(e.target.files[0]);
        }
    }

    return (
        <Table>
            <tbody>
            <tr>
                <td>광고명</td>
                <td colSpan='2'><BigInput type='text' value={props.info.ad_name} name='title' onChange={props.onTableInputChange} placeholder={'광고명을 입력해주세요.'}/></td>
            </tr>
            <tr>
                <td>광고 파일 첨부</td>
                <td colSpan='2'>
                    <TableColCell>
                        <RedBox for={'file'}>파일첨부</RedBox>
                        <ImgInput type="file" id={'file'} onChange={handleFile}/>
                        <UploadImg src={preImg === '' ? `${window.Config.G_API_URL}/images/advertisement/${props.info.ad_image_url}` : preImg} alt=""/>
                    </TableColCell>
                </td>
            </tr>
            <tr>
                <td>광고 시작일</td>
                <td colSpan='2'>{props.info.ad_exposure_start_date}</td>
            </tr>
            <tr>
                <td>광고 종료일</td>
                <td colSpan='2'><BigInput type='date' value={props.info.ad_exposure_expire_date} name='EndDate' onChange={props.onTableInputChange}/></td>
            </tr>
            <tr>
                <td>위치</td>
                <td>
                    {props.info.ad_address}
                </td>
            </tr>
            <tr>
                <td>위도</td>
                <td>
                    {props.info.ad_loc_latitude}
                </td>
            </tr>
            <tr>
                <td>경도</td>
                <td>
                    {props.info.ad_loc_longitude}
                </td>
            </tr>
            <tr>
                <td>타입</td>
                <td>
                    <SelectBox name="type" id="type" value={props.info.ad_type} onChange={props.onTableInputChange}>
                        <option value={null} hidden>선택</option>
                        <option value="map">배너</option>
                        <option value="popup">전면</option>
                    </SelectBox>
                </td>
            </tr>
            </tbody>
        </Table>
    )
}

export default AdvertisementUpdateTable;