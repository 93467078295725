import React from 'react'
import styled from 'styled-components';

const Tr = styled.tr`
`

const Td = styled.td`
    color : ${props => props.color === '미납' ? '#df1f26' : '#000000'};
`

function CompanyList(props) {  

  // const goToDetail = () => {
  //   window.location.href = `/admin/company/detail/:${props.company}/:1`
  // }

  return (
    <Tr >
        <td>{props.company}</td>
        <td>{props.date}</td>
        <td>{props.settlementDate}일</td>
        <Td color={props.settlementStatus}>{props.settlementStatus}</Td>
        <td>{props.outstandingPay}</td>
        <td>{props.settlementAmt}</td>
    </Tr>
  )
}

export default CompanyList