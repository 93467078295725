import axios from "axios";
import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { useParams } from "react-router";
import styled from 'styled-components';
import Cookie from "universal-cookie";
import DeleteSearch from "../asset/img/deletesearch.png";
import Dotbogi from "../asset/img/dotbogi.png";
import SelectArrow from "../asset/img/selectarrow.png";
import AuthCheckModal from '../components/AuthCheckModal';
import { BorderDiv, BorderDivR, SearchBox } from "../css/styles";
import CheckBox from "./CheckBox";
import SearchManagerList from "./SearchManagerList";

const cookies = new Cookie();

const Title = styled.h1`
    font-weight: bold;
    font-size: 24px;
    line-height: 35px;
    color: #221E1F;
`

const SelectBox = styled.select`
  cursor: pointer;
  appearance:none;
  text-align : center;
  border: 1px solid #C4C4C4;
  border-radius: 10px;
  padding: 7px 0;
  font-size: 16px;
  line-height: 23px;
  background-image: url(${SelectArrow});
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: 85%;
  &:focus{
    outline: none;
  }
`


const SearchInput = styled.input`
  margin-left: 21px;
  width: 60%;
  border: 1px solid #C4C4C4;
  border-radius: 10px;
  background-image: url(${Dotbogi});
  background-repeat: no-repeat;
  background-size: 17px;
  background-position: 97%;
  padding: 7px 10px;
  font-size: 16px;
  line-height: 23px;
  color: #7E7E7E;
`

const ButtonContainer = styled.div`
    display : flex;
    justify-content : space-between;
  padding-top: 20px;
`

const RedButton = styled.a`
    background: #DF1F26;
    border-radius: 10px;
    border : none;
    color : white;
    padding : 10px 0 ;
    font-weight: bold;
    font-size: 14px;
    width : 11%;
    text-align : center;
`

const Button = styled.button`
    background-color: white;
    border-radius: 10px;
    border: 1px solid #C4C4C4;
    width : 11%;
    padding : 10px 0 ;
    font-weight: bold;
    font-size: 14px;
    color: #221E1F;
`

const Table = styled.table`
  width: 100%;
  line-height: 40px;
  text-align: center;
  & > thead > tr{
    border-bottom: 2px solid #e5e5e5;
    line-height: 20px;
    & > th:first-child > div > label {
        cursor: pointer;
    }
  }
  & > tbody > tr{
    border-bottom: 1px solid #e5e5e5;
    & > td > div > label {
        cursor: pointer;
    }
    & > td:first-child{
      width: 10%;
    }
  }
`

const ScrollDiv = styled.div`
  height: 65%;
  overflow-y: scroll;
`

function SearchManagerModal(props) {

    const {no} = useParams();
    const [managerInfo , setManagerInfo] = React.useState([]);

    const [managerChecked, setmanagerChecked] = React.useState(false);
    const [managerCheckAll, setmanagerCheckAll] = React.useState(false);
    const [managerCheckList , setmanagerCheckList] = React.useState([]);
    const [searchData , setSearchData] = React.useState(null);
    const [searchType , setSearchType] = React.useState('이름');

    const [toggleAuthModal , setToggleAuthModal] = React.useState(false);

    const handleToggleAuthModal = () => {
        setToggleAuthModal(!toggleAuthModal);
    }

    useEffect(()=>{
        setmanagerCheckList([]);
        axios({
            method : 'get',
            url : `${window.Config.G_API_URL}/admin/station/group/administrator`,
            params : {
                token : cookies.get('token'),
                group_id : no.replace(':' , ''),
                search_type : searchType,
                search_data : searchData,
            }
        })
            .then(response => {
                if(response.data.result['code'] === '200'){
                    setManagerInfo(response.data.data)
                    console.log(response.data.data);
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            }).catch(function(error){
            alert(error);
        })
    }, [searchType , searchData]);

    useEffect(()=>{
        if(managerCheckAll){
            let arr = [];
            managerInfo.map((item)=>{
                    arr.push(`${item.admin_id}`);
            });
            setmanagerCheckList(arr);
        } else if(managerCheckList.length==managerInfo.length) {
            setmanagerCheckList([]);
        }
    },[managerCheckAll]);

    useEffect(()=>{
        if(managerCheckList.length == managerInfo.length){
            setmanagerCheckAll(true);
        } else {
            setmanagerCheckAll(false);
        }
        console.log(managerCheckList);
    },[managerCheckList,managerInfo]);

    const regManager = () => {
        axios({
            method : 'post',
            url : `${window.Config.G_API_URL}/admin/station/group/admin`,
            params : {
                token : cookies.get('token'),
                group_id : no.replace(':' , ''),
                admin_id : managerCheckList
            },
            // no : managerCheckList 파라미터 [] 없애는 옵션
            paramsSerializer : function(paramObj) {
                const params = new URLSearchParams()
                for (const key in paramObj) {
                    params.append(key, paramObj[key])
                }
                console.log(params.toString());
                return params.toString()
            }
        })
            .then(response => {
                if(response.data.result['code'] === '200'){
                    window.location.reload()
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                }  else if (response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                }  else {
                    alert(response.data.result['message']);
                }
            }).catch(function(error){
            alert(error);
        })
    }

    const onTypeChange = (e) => {
        setSearchType(e.target.value);
    }

    const onSearchDataChange = (e) => {
        setSearchData(e.target.value);
    }

    const handlemanagerCheckAll = (e) => {
        setmanagerCheckAll(!managerCheckAll);
    };

    const handleCheckEach = (e) => {
        const {checked, id, name, value} = e.target;
        let arr = [...managerCheckList];
        if (arr.includes(value)) {
            for (let i = 0; i < arr.length; i++) {
                if (arr[i] == value) {
                    arr.splice(i, 1);
                    setmanagerChecked(!checked);
                    break;
                }
            }
            setmanagerCheckList(arr);
        } else {
            setmanagerChecked(!checked);
            setmanagerCheckList([...managerCheckList, value]);
        }
    }

    return (
        <Modal isOpen={props.toggle} onRequestClose={props.toggleFunc}
               ariaHideApp={false}
               style={{
                   overlay :{
                       backgroundColor : 'rgba(34, 30, 31, 0.5)'
                   },
                   content : {
                       height : '52%',
                       left : '25%',
                       right : '25%',
                       top : '20%',
                       borderRadius : '10px',
                   }
               }}
        >
            <Title>관리자 검색</Title>
            <SearchBox>
                <SelectBox name="searchselect" id="serachselect" onChange={onTypeChange}>
                    <option value="이름">이름</option>
                    <option value="관리자 아이디">아이디(이메일)</option>
                    <option value="연락처">연락처</option>
                </SelectBox>
                <SearchInput type="text" placeholder={'검색어 입력'} onChange={onSearchDataChange}/>
                <div>
                    <img src={DeleteSearch} alt=""/>
                    <p>초기화</p>
                </div>
            </SearchBox>
            <ScrollDiv>
                <Table>
                    <thead>
                        <tr>
                            <th><CheckBox no='checkAll2' checked={managerCheckAll} onChange={handlemanagerCheckAll}/></th>
                            <th><BorderDiv>No.</BorderDiv></th>
                            <th>이름</th>
                            <th><BorderDiv>관리자 아이디</BorderDiv></th>
                            <th><BorderDivR>연락처</BorderDivR></th>
                            <th>등록일시</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        managerInfo.map((item, key) => (
                            <SearchManagerList
                                key={key}
                                no={item.admin_no}
                                id={item.admin_id}
                                name={item.admin_name}
                                phone={item.admin_phone}
                                date={item.date_time}
                                checked={managerCheckList.includes(`${item.admin_id}`)}
                                onChange={handleCheckEach}
                            />
                        ))
                    }
                    </tbody>
                </Table>
            </ScrollDiv>
            <ButtonContainer>
                <Button onClick={props.toggleFunc}>취소</Button>
                <RedButton onClick={regManager}>확인</RedButton>
            </ButtonContainer>
            <AuthCheckModal
                toggle={toggleAuthModal}
                toggleFunc={handleToggleAuthModal}
            />
        </Modal>
    );
}

export default SearchManagerModal;
