import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

const Title = styled.h1`
  font-weight: bold;
  font-size: 24px;
  line-height: 35px;
  padding-bottom : 1%;
  color: #221E1F;
`
const ButtonContainer = styled.div`
  display : flex;
  justify-content : center;
  padding-top: 27px;
`

const Button = styled.button`
  background-color: white;
  border-radius: 10px;
  border: 1px solid #C4C4C4;
  width : 11%;
  padding : 10px 0 ;
  font-weight: bold;
  font-size: 14px;
  color: #221E1F;
`

const QrImgContainer = styled.div`
  display : flex;
  justify-content : center;
  padding-top: 20px;
  min-height: 350px;
  flex-direction: column;
`
const QrImgDiv = styled.div`
  text-align: center;
`

const QrImg = styled.img`
  width: 350px;
`

const QrImgUrlDiv = styled.div`
  width: 400px;
  word-wrap: break-word;
  & a:hover {
    color:blue;
  }
`

function QrImgModal(props) {
  const qrImageUrl = `${window.Config.G_API_URL}/qr_image/${props.qrData.file_name}`;
return (
    <Modal isOpen={props.toggle} onRequestClose={props.toggleFunc}
               ariaHideApp={false}
               style={{
                   overlay :{
                       backgroundColor : 'rgba(34, 30, 31, 0.5)'
                   },
                   content : {
                       height : '62%',
                       left : '38%',
                       right : '38%',
                       top : '20%',
                       borderRadius : '10px',
                   }
               }}
        >
            <Title>QR Image</Title>
            <QrImgContainer>
                <QrImgDiv>
                  <QrImg src={props.qrData.base64file}></QrImg>
                </QrImgDiv>
                <QrImgUrlDiv><a href={qrImageUrl} target='_blank' rel="noopener noreferrer">{qrImageUrl}</a></QrImgUrlDiv>
            </QrImgContainer>
            <ButtonContainer>
                <Button onClick={props.toggleFunc}>닫기</Button>
            </ButtonContainer>            
    </Modal>
)
}

export default QrImgModal;