import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import SelectArrow from "../asset/img/selectarrow.png";
import axios from "axios";
import Cookie from "universal-cookie";

const cookies = new Cookie();

const Title = styled.h1`
  font-weight: bold;
  font-size: 24px;
  line-height: 35px;
  color: #221E1F;
`

const Container = styled.div`
    display : flex;
    justify-content : space-between;
  align-items: center;
  & > div {
    display: flex;
    width: 50%;
    justify-content: flex-end;
    align-items: center;
    column-gap: 7px;
    margin-top: 4px;
    & > input {
      width: 45%;
      border: 1px solid #C4C4C4;
      border-radius: 10px;
      padding: 5px 10px;
      font-size: 16px;
      line-height: 23px;
      text-align: center;
    }
  }
`

const ButtonContainer = styled.div`
    display : flex;
    justify-content : flex-end;
  column-gap: 10px;
  margin-top: 40px;
`

const RedButton = styled.a`
  background: #DF1F26;
  border-radius: 10px;
  border : none;
  color : white;
  padding : 10px 0 ;
  margin-top : 17px;
  font-weight: bold;
  font-size: 14px;
  width : 25%;
  text-align : center;
`

const Button = styled.button`
    background-color: white;
    border-radius: 10px;
    border: 1px solid #C4C4C4;
   width : 25%;
    padding : 10px 0 ;
    font-weight: bold;
    font-size: 14px;
    margin-top : 17px;
    color: #221E1F;
`
function PointDeduceModal(props) {

    return (
        <Modal isOpen={props.toggle} onRequestClose={props.toggleFunc}
               style={{
                   overlay :{
                       backgroundColor : 'rgba(34, 30, 31, 0.5)'
                   },
                   content : {
                       height : '16%',
                       left : '40%',
                       right : '37%',
                       top : '35%',
                       borderRadius : '10px',
                       border: '1px solid #C4C4C4' ,
                   }
               }}
        >
            <Container>
                <Title>
                    포인트 차감
                </Title>
                <div>
                    <p>-</p>
                    <input type="text" value={props.amount} onChange={props.setAmount}/>
                    <p>P</p>
                </div>
            </Container>
            <ButtonContainer>
                <Button onClick={props.toggleFunc}>취소</Button>
                <RedButton onClick={props.toggleNext}>확인</RedButton>
            </ButtonContainer>
        </Modal>
    );
}

export default PointDeduceModal;
