import React from 'react';
import styled from 'styled-components';

const Table = styled.table`
  & > tbody{
    & > tr:nth-child(9){
      border-top: none;
      border-bottom: none;
    }
    & > tr:nth-child(10){
      border-top: none;
      border-bottom: none;
    }
    & > tr:nth-child(11){
      border-top: none;
    }
    & > tr > td:first-child{
      width: 17% !important;
    }
  }
`

const Color = styled.span`
  color : ${props => props.state === 'Y' ? '' : '#df1f26' } ;
`

const Tr = styled.tr`
  border-top: none !important;
  border-bottom: none !important;
`

const Span = styled.span`
    font-size: 12px;
`

const TableBox = styled.div`
& > p {
    color : #df1f26;
    font-size: 12px;
    border: 1px solid #DF1F26;
    border-radius: 8px;
    padding : 0px 8px 1px 8px;
    cursor : pointer;
}
`

function DeviceDetailTable(props) {

    const goToGroupDetail = () => {
        window.location.href = `/admin/searchgroup/detail/:${props.groupId}/:1`
    }
    
    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };
    return (
        <Table admin={props.admin && props.admin}>
            <tbody>
            <tr>
                <td>주소 / 위치</td>
                <td>{props.address} / {props.outside === 'Y' ? '실외' : '실내'}</td>
            </tr>
            <tr>
                <td>스테이션 ID</td>
                <td>{props.stationId}</td>
            </tr>
            <tr>
                <td>종류</td>
                <td>{props.private === 'Y' ? '민영' : '공영'}</td>
            </tr>
            <tr>
                <td>충전기 종류</td>
                <td>{props.charger_status === 'KR' ? '한국' : '중국'}</td>
            </tr>
            <tr>
                <td>전원</td>
                <td><Color state={props.running}>{props.running === 'Y' ? 'ON' : 'OFF'}</Color></td>
            </tr>
            <tr>
                <td>위도</td>
                <td>{props.lat}</td>
            </tr>
            <tr>
               <td>경도</td>
                <td>{props.lon}</td>
            </tr>
            <tr>
                <td>그룹명</td>
                <td>{props.groupName === null ? '' : <TableBox>{props.groupName}<p onClick={goToGroupDetail}>그룹 상세</p></TableBox> }</td>
            </tr>
            <tr>
                <td>운영시간</td>
                <td>평일 {props.weekStart}:{props.weekStartMin}~{props.weekEnd}:{props.weekEndMin}</td>
            </tr>
            <tr>
                <td/>
                <td>토요일 {props.satStart}:{props.satStartMin}~{props.weekEnd}:{props.weekEndMin}</td>
            </tr>
            <tr>
                <td/>
                <td>일요일/공휴일 {props.holStart}:{props.holStartMin}~{props.weekEnd}:{props.weekEndMin}</td>
            </tr>
            {
                props.admin && props.admin.length === 0 ? 
                <Tr>
                    <td>관리자</td>
                    <td></td>
                </Tr>
                :
                props.admin && props.admin.map((item, index) => (
                    <Tr key={index}>
                        <td>관리자</td>
                        <td>{item.admin_name}</td>
                    </Tr>
                ))
            }
            <tr>
                <td>충전 요금 / <Span>10분(원)</Span></td>
                <td>{props.chargePrice == null ? props.chargePrice : numberWithCommas(props.chargePrice)}</td>
            </tr>
            <tr>
                <td>거치 요금 / <Span>10분(원)</Span></td>
                <td>{props.holderPrice == null ? props.holderPrice : numberWithCommas(props.holderPrice)}</td>
            </tr>
            </tbody>
        </Table>
    );
}

export default DeviceDetailTable;