import axios from 'axios';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Cookie from 'universal-cookie';
import breadcrumbArrow from '../asset/img/breadcrumbarrow.png';
import AuthCheckModal from '../components/AuthCheckModal';
import ChargingStationModal from '../components/ChargingStationModal';
import CheckBox from '../components/CheckBox';
import DeleteConfirmModal from '../components/DeleteConfirmModal';
import DeleteFromGroupModal from '../components/DeleteFromGroupModal';
import DeleteInDetailModal from '../components/DeleteInDetailModal';
import DeviceRegisterModal from '../components/DeviceRegisterModal';
import Pagination from '../components/Pagination';
import SearchGroupDetailInnerTable from '../components/SearchGroupDetailInnerTable';
import SearchGroupDetailTable from '../components/SearchGroupDetailTable';
import SearchManagerModal from '../components/SearchManagerModal';
import {
    BcArrow,
    BorderDiv,
    Description,
    SearchGroupDetailWrapper,
    Title,
} from '../css/styles';

const cookies = new Cookie();

const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    & > p {
        font-family : notosansbold;
        font-size: 28px;
        line-height: 46px;
    }
    & > a {
        font-size: 16px;
        line-height: 20px;
        border: 1px solid #c4c4c4;
        border-radius: 10px;
        color: #df1f26;
        padding: 7px 10px !important;
    }
`;

const InnerTable = styled.div`
    & > table {
        width: 100%;
        text-align: center;
        & > thead {
            & > tr {
                line-height: 20px;
                border-bottom: 2px solid #e5e5e5;
                & > th:first-child > div > label {
                    cursor: pointer;
                }
                & > th:nth-child(3) {
                    text-align: left;
                    padding-left: 30px;
                }
            }
        }
        & > tbody {
            & > tr {
                line-height: 40px;
                border-bottom: 1px solid #e5e5e5;
                & > td:nth-child(3) {
                    text-align: left;
                    padding-left: 30px;
                }
            }
            & > tr > td:first-child > div > label {
                cursor: pointer;
            }
        }
    }
`;

const ButtonWrapper = styled.div`
    & > a {
        font-size: 16px;
        line-height: 20px;
        border: 1px solid #c4c4c4;
        border-radius: 10px;
        padding: 7px 10px !important;
        margin-left : 20px;
        cursor : pointer;
    }
    & > a:last-child {
    color: #df1f26;
}
`

function SearchGroupDetail(props) {
    const { no, num } = useParams();

    const [groupInfo, setGroupInfo] = React.useState([]);
    const [toggleManagerModal, setToggleManagerModal] = React.useState(false);
    const [toggleDeleteModal, setToggleDeleteModal] = React.useState(false);
    const [toggleDeleteConfirmModal, setToggleDeleteConfirmModal] =
        React.useState(false);
    const [toggleDeviceRegModal, setToggleDeviceRegModal] =
        React.useState(false);
        const [deleteDetailModalToggle, setDeleteDetailModalToggle] = React.useState(false);
    const [stationInfo, setStationInfo] = React.useState([]);
    const [stationListInfo, setStationListInfo] = React.useState([]);
    const [checked, setChecked] = React.useState(false);
    const [checkAll, setCheckAll] = React.useState(false);
    const [checkList, setCheckList] = React.useState([]);
    const [toggleChargingModal , setToggleChargingModal] = React.useState(false);

    const [toggleAuthModal , setToggleAuthModal] = React.useState(false);

    const handleToggleAuthModal = () => {
        setToggleAuthModal(!toggleAuthModal);
    }
    const handleToggleChargingModal = () => {
        setToggleChargingModal(!toggleChargingModal);
    }

    useEffect(() => {
        setCheckList([]);

        axios({
            method: 'get',
            url: `${window.Config.G_API_URL}/admin/station/group`,
            params: {
                token: cookies.get('token'),
                group_id: no.replace(':', ''),
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    setGroupInfo(response.data.data);
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    }, [no]);

    useEffect(() => {
        axios({
            method: 'get',
            url: `${window.Config.G_API_URL}/admin/station/group/stations`,
            params: {
                token: cookies.get('token'),
                group_id: no.replace(':', ''),
                page_num: num.replace(':', ''),
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    setStationListInfo(response.data.data.result);
                    setStationInfo(response.data.data.result.list);
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    }, [num]);

    useEffect(() => {
        if (checkAll) {
            let arr = [];
            stationInfo.map((item) => {
                arr.push(`${item.station_id}`);
            });
            setCheckList(arr);
        } else if (checkList.length == stationInfo.length) {
            setCheckList([]);
        }
    }, [checkAll]);

    useEffect(() => {
        if (checkList.length == stationInfo.length) {
            setCheckAll(true);
        } else {
            setCheckAll(false);
        }
        console.log(checkList);
        console.log(groupInfo.admin);
    }, [checkList, stationInfo]);

    const handleToggleDeleteModal = () => {
        setToggleDeleteModal(!toggleDeleteModal);
    };

    const handleToggleDeleteConfirmModal = () => {
        setToggleDeleteConfirmModal(!toggleDeleteConfirmModal);
    };

    const handleCheckAll = (e) => {
        setCheckAll(!checkAll);
    };

    const handleToggleDeviceRegModal = () => {
        setToggleDeviceRegModal(!toggleDeviceRegModal);
    };

    const handleDetailDeleteModal = () => {
        setDeleteDetailModalToggle(!deleteDetailModalToggle);
    };

    const handleCheckEach = (e) => {
        const { id, checked } = e.target;
        let arr = [...checkList];
        if (arr.includes(id)) {
            for (let i = 0; i < arr.length; i++) {
                console.log(arr[i]);
                console.log(id);
                if (arr[i] == id) {
                    arr.splice(i, 1);
                    setChecked(!checked);
                    break;
                }
            }
            setCheckList(arr);
        } else {
            setChecked(!checked);
            setCheckList([...checkList, id]);
        }
    };

    const deleteFromGroup = () => {
        axios({
            method: 'put',
            url: `${window.Config.G_API_URL}/admin/station/group/stations`,
            params: {
                token: cookies.get('token'),
                station_id: checkList,
            },
            // no : checkList 파라미터 [] 없애는 옵션
            paramsSerializer: function (paramObj) {
                const params = new URLSearchParams();
                for (const key in paramObj) {
                    params.append(key, paramObj[key]);
                }

                return params.toString();
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    handleToggleDeleteModal();
                    handleToggleDeleteConfirmModal();
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                }  else if (response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                }  else if (response.data.result['code'] === '403'){
                    handleToggleChargingModal();
                }  else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    };

    const handleManagerModal = () => {
        setToggleManagerModal(!toggleManagerModal);
    };

    const onDelete = () => {
        axios({
            method: 'delete',
            url: `${window.Config.G_API_URL}/admin/station/group`,
            params: {
                token: cookies.get('token'),
                group_id: no.replace(':', ''),
            },

        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    window.location.href = '/admin/searchgroup/:1'
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                }  else if (response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                }   else if (response.data.result['code'] === '403'){
                    handleToggleChargingModal();
                }   else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    };

    const navigate = useNavigate();

    return (
        <SearchGroupDetailWrapper>
            <Title>그룹 상세</Title>
            <Description>
                스테이션 관리 <BcArrow src={breadcrumbArrow} /> 그룹 검색{' '}
                <BcArrow src={breadcrumbArrow} /> 그룹 상세
            </Description>
            <TitleWrapper>
                <p>{groupInfo.group_name}</p>
                <ButtonWrapper>
                    <a onClick={handleDetailDeleteModal}>그룹 삭제</a>
                    <a href={`/admin/searchgroup/detail/${no}/update`}>그룹 수정</a>
                </ButtonWrapper>
            </TitleWrapper>
            <SearchGroupDetailTable
                chargePrice={groupInfo.charge_price}
                holderPrice={groupInfo.holder_price}
                admin={groupInfo.admin}
                status={groupInfo.operation_status}
                stationCount={groupInfo.station_count}
                weekDayStart={groupInfo.weekdays_hours_start}
                weekDayStartMin={groupInfo.weekdays_hours_start_min}
                weekDayEnd={groupInfo.weekdays_hours_end}
                weekDayEndMin={groupInfo.weekdays_hours_end_min}
                satDayStart={groupInfo.saturday_hours_start}
                satDayStartMin={groupInfo.saturday_hours_start_min}
                satDayEnd={groupInfo.saturday_hours_end}
                satDayEndMin={groupInfo.saturday_hours_end_min}
                holiDayStart={groupInfo.holiday_hours_start}
                holiDayStartMin={groupInfo.holiday_hours_start_min}
                holiDayEnd={groupInfo.holiday_hours_end}
                holiDayEndMin={groupInfo.holiday_hours_end_min}
                toggleModal={handleManagerModal}
            />
            <div>
                <button onClick={() => window.location.href = '/admin/searchgroup/:1'}>목록</button>
            </div>
            <InnerTable>
                <table>
                    <thead>
                        <tr>
                            <th>
                                <CheckBox
                                    no="checkAll"
                                    checked={checkAll}
                                    onChange={handleCheckAll}
                                />
                            </th>
                            <th>
                                <BorderDiv>No.</BorderDiv>
                            </th>
                            <th>스테이션명</th>
                            <th>
                                <BorderDiv>스테이션 ID</BorderDiv>
                            </th>
                            <th>설치일</th>
                            <th>
                                <BorderDiv>전원</BorderDiv>
                            </th>
                            <th>이용 현황</th>
                        </tr>
                    </thead>
                    <tbody>
                        {stationInfo.map((item,key) => (
                            <SearchGroupDetailInnerTable
                                key={key}
                                rowNum={item.rownum}
                                stationId={item.station_id}
                                stationName={item.station_name}
                                running={item.running}
                                usesPort={item.uses_port}
                                maxPort={item.maxinum_port}
                                dateTime={item.date_time}
                                checked={checkList.includes(
                                    `${item.station_id}`
                                )}
                                onChecked={handleCheckEach}
                            />
                        ))}
                    </tbody>
                </table>
            </InnerTable>
            <div>
                <button onClick={handleToggleDeleteModal}>선택 삭제</button>
                <Pagination
                    no={num.replace(':', '')}
                    url={`/admin/searchgroup/detail/${no}/:`}
                    pages={stationListInfo['pages']}
                    isFirstPage={stationListInfo['isFirstPage']}
                    isLastPage={stationListInfo['isLastPage']}
                    prePage={stationListInfo['prePage']}
                    nextPage={stationListInfo['nextPage']}
                    hasPrePage={stationListInfo['hasPreviousPage']}
                    hasNextPage={stationListInfo['hasNextPage']}
                />
                <div>
                    <button>엑셀 입력</button>
                    <button>엑셀 출력</button>
                    <button onClick={handleToggleDeviceRegModal}>
                        스테이션 추가
                    </button>
                </div>
            </div>

            <DeleteFromGroupModal
                toggle={toggleDeleteModal}
                toggleFunc={handleToggleDeleteModal}
                deleteFunc={deleteFromGroup}
                length={checkList.length}
                content={'스테이션을 그룹에서'}
            />

            <DeleteInDetailModal
                toggle={deleteDetailModalToggle}
                toggleFunc={handleDetailDeleteModal}
                deleteFunc={onDelete}
                content={'그룹을'}
            />

            <DeleteConfirmModal
                toggle={toggleDeleteConfirmModal}
                location={`/admin/searchgroup/detail/${no}/:1`}
            />
            <DeviceRegisterModal
                no={no}
                toggle={toggleDeviceRegModal}
                toggleFunc={handleToggleDeviceRegModal}
            />

            <SearchManagerModal
                toggle={toggleManagerModal}
                toggleFunc={handleManagerModal}
            />

            <ChargingStationModal
                toggle={toggleChargingModal}
                toggleFunc={handleToggleChargingModal}
            />
            <AuthCheckModal
                toggle={toggleAuthModal}
                toggleFunc={handleToggleAuthModal}
            />
        </SearchGroupDetailWrapper>
    );
}

export default SearchGroupDetail;
