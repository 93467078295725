import React , { useEffect } from 'react'
import { useParams } from 'react-router';
import axios from 'axios';
import Cookie from 'universal-cookie';
import styled from 'styled-components';

import CompanyList from '../components/CompanyList';

import {
    NoticeWrapper,
    Title,
    Description,
    BcArrow,
    BorderDiv,
} from '../css/styles';

const cookies = new Cookie();

const Table = styled.table`
    & > thead > tr {
        font-family : notosansbold;
        font-size : 16px;
        & > th:first-child {
            width : 13% !important;
        }
        & > th:nth-child(2) {
            width : 12% !important;
        }
        & > th:nth-child(3) {
            width : 12% !important;
            text-align : center !important;
            padding-left : 0 !important;
        }
        & > th:nth-child(4) {
            width : 12% !important;
        }
        & > th:nth-child(5) {
            width : 12% !important;
        }
        & > th:nth-child(6) {
            width : 17% !important;
        }
        & > th:nth-child(7) {
            width : 17% !important;
        }
    }
    & > tbody > tr {
        font-size: 15px;
        cursor : auto;
        &:nth-child(6){
            border-bottom: 2px solid #E5E5E5 !important;
        }
        &:last-child{
            border-bottom: 2px solid #E5E5E5 !important;
        }
        & > td:nth-child(3){
            padding-left : 0 !important;
            text-align : center !important;
        }
        & > td:last-child {
            padding-left : 0 !important;
            text-align : center !important;
        }
    }
`

const ColoredTd = styled.td`
    font-family : notosansbold;
    color : #DF1F26;
`

const ButtonContainer = styled.div`
    display : flex;
    justify-content : flex-end !important;
    & > button {
        cursor : auto;
    }
`

function Company() {

    const [companyList , setCompanyList] = React.useState([]);
    const [totalOutstanding , setTotalOutstanding] = React.useState(0);
    const [totalUnpayed , setTotalUnpayed] = React.useState(0);

    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    useEffect(() => {
        axios({
            method: 'get',
            url: `${window.Config.G_API_URL}/company`,
            params: {
                token: cookies.get('token'),
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    setCompanyList(response.data.data['company']);
                    setTotalOutstanding(response.data.data['total_outstanding_payment'])
                    setTotalUnpayed(response.data.data['total_settlement_amount'])
                    console.log(response.data.data);
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    }, []);

  return (
    <NoticeWrapper>
    <Title>업체 관리</Title>
    <Description>
        정산 관리
    </Description>
        <Table>
            <thead>
                <tr>
                    <th>
                        업체명
                    </th>
                    <th>
                        <BorderDiv>업체 등록일</BorderDiv>
                    </th>
                    <th>
                        정산 예정일
                    </th>
                    <th>
                        <BorderDiv>미납 여부</BorderDiv>
                    </th>
                    <th>
                    <BorderDiv>누적 미납금</BorderDiv>
                    </th>
                    <th>
                        금월 정산 예정 금액
                    </th>
                </tr>
            </thead>
            <tbody>
                {
                    companyList.map((item) => (
                        <CompanyList
                            company={item.company}
                            date={item.date_time}
                            settlementDate={item.settlement_date}
                            settlementStatus={item.settlement_status}
                            outstandingPay={item.outstanding_payment}
                            settlementAmt={item.settlement_amount}
                        />
                    ))
                }
                <tr>
                    <td colSpan={4}/>
                    <ColoredTd>{totalOutstanding === '' ? '-' : numberWithCommas(totalOutstanding)} ￦</ColoredTd>
                    <ColoredTd>{totalUnpayed === '' ? '-' : numberWithCommas(totalUnpayed) } ₩</ColoredTd>
                </tr>
            </tbody>
        </Table>
        <ButtonContainer>
            <button>
                업체 등록
            </button>
        </ButtonContainer>
    </NoticeWrapper>
  )
}

export default Company