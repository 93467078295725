import React, {useEffect} from 'react';
import {BcArrow, Description, SearchGroupDetailWrapper, Title} from "../css/styles";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useParams } from 'react-router';
import Cookie from "universal-cookie";
import CouponManageUpdateTable from "../components/CouponManageUpdateTable";
import breadcrumbArrow from "../asset/img/breadcrumbarrow.png";
import AuthCheckModal from '../components/AuthCheckModal';

const ButtonWrapper = styled.div`
  & > button:first-child{
    padding : 5px 20px !important;
  }
  & > button:last-child{
    padding : 5px 20px !important;
    color: white;
    background-color: #DF1F26;
  }
`

function AdvertisementUpdate({props}) {

    const cookies = new Cookie();
    const { no, num } = useParams();
    const [adInfo, setAdInfo] = React.useState([]);
    // table 용 state
    const [formInfo , setFormInfo] = React.useState({
        title: adInfo.ad_name,
        startDate : '',
        type : adInfo.ad_type,
    })
    const [toggleAuthModal , setToggleAuthModal] = React.useState(false);

    const handleToggleAuthModal = () => {
        setToggleAuthModal(!toggleAuthModal);
    }


    const [imgModify , setImgModify] = React.useState(false);

    //파일 전송 state
    const [imgBase64, setImgBase64] = React.useState(null)

    const navigate = useNavigate()

    const onRegister = () => {
        alert(adInfo.coupon_company)
        var formdata = {};
        formdata.coupon_id = no.replace(':','');
        formdata.coupon_image_path = imgBase64;
        formdata.coupon_name = adInfo.coupon_name;
        formdata.coupon_company = adInfo.coupon_company;
        formdata.coupon_image_modify = imgModify;
        formdata.coupon_prev_image_name = adInfo.coupon_image_path;
        formdata.coupon_start_date = adInfo.coupon_start_date;
        formdata.coupon_end_date = adInfo.coupon_end_date;
        formdata.coupon_amount = adInfo.coupon_amount;
        formdata.coupon_context = adInfo.coupon_context;
        formdata.coupon_issuance_count = adInfo.coupon_issuance_count;
        formdata.token = cookies.get('token');

        axios({
            method : 'put',
            url : `${window.Config.G_API_URL}/admin/coupon`,
            data :  formdata,
        })
        .then(response => {
            console.log(response)
            if(response.data.result['code'] === '200'){
                window.location.href = '/admin/couponmanage/:1/:1'
            } else if(response.data.result['code'] === '402'){
                handleToggleAuthModal();
            } else if (response.data.result['code'] === '401'){
                alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                window.location.href = '/'
            } else {
                alert(response.data.result['message']);
            }
        }).catch(function(error){
            alert(error);
        })
    }

    useEffect(() => {
        axios({
            method: 'get',
            url: `${window.Config.G_API_URL}/admin/coupon/detail`,
            params: {
                token: cookies.get('token'),
                coupon_id: no.replace(':', ''),
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    setAdInfo(response.data.data);
                    // setAdInfo({...formInfo , company : response.data.data.coupon_company})
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    }, [no]);

    const tableInputChange = (e) => {
        let {name , value} = e.target;
        switch (name){
            case 'title' :
                setAdInfo({...adInfo , coupon_name : value})
                break;
            case 'content' :
                setAdInfo({...adInfo , coupon_context : value})
                break;
            case 'amount' :
                setAdInfo({...adInfo , coupon_amount : value})
                break; 
            case 'startDate' :
                setAdInfo({...adInfo , coupon_start_date : value})
                break;
            case 'endDate' :
                setAdInfo({...adInfo , coupon_end_date : value})
                break;
            case 'issAmount' :
                setAdInfo({...adInfo , coupon_issuance_count : value})
                break;
            case 'company' :
                if(value === '전원'){
                    setAdInfo({...adInfo , coupon_company : null})
                } else {
                    setAdInfo({...adInfo , coupon_company : value})
                }
                break;     
        }
    }

    return (
        <SearchGroupDetailWrapper>
            <Title>
                쿠폰 수정
            </Title>
            <Description>
                쿠폰 관리 <BcArrow src={breadcrumbArrow} /> 쿠폰 상세 <BcArrow src={breadcrumbArrow} /> 쿠폰 수정
            </Description>
            <CouponManageUpdateTable
                onTableInputChange={tableInputChange}
                imgBase={imgBase64}
                setImgBase={setImgBase64}
                info={adInfo}
                title={formInfo.title}
                setImgModify={setImgModify}
            />
            <ButtonWrapper>
                <button onClick={() => navigate(-1)}>취소</button>
                <button onClick={onRegister}>등록</button>
            </ButtonWrapper>
            <AuthCheckModal
                toggle={toggleAuthModal}
                toggleFunc={handleToggleAuthModal}
            />
        </SearchGroupDetailWrapper>
    );
}

export default AdvertisementUpdate;