import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    ManageUserDetailWrapper,
    Title,
    Description,
    BcArrow,
} from '../css/styles';
import axios from 'axios';
import Cookie from 'universal-cookie';
import { useParams } from 'react-router';
import styled from 'styled-components';
import AuthCheckModal from '../components/AuthCheckModal';
import ManageAdminAuthTable from '../components/ManageAdminAuthTable';

import breadcrumbArrow from '../asset/img/breadcrumbarrow.png';

const cookies = new Cookie();

const SubTitle = styled.h1`
    padding-top: 40px;
    font-family: notosansbold;
    font-size: 32px;
    line-height: 46px;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    & > button:last-child {
        background-color: #df1f26;
        color: white;
    }
`;

function ManageAdminAuth(props) {
    const { level } = useParams();

    const [authInfo, setAuthInfo] = React.useState([]);

    const [checkList, setCheckList] = React.useState([]);
    const [toggleAuthModal , setToggleAuthModal] = React.useState(false);

    const handleToggleAuthModal = () => {
        setToggleAuthModal(!toggleAuthModal);
    }

    const navigate = useNavigate();

    useEffect(() => {
        axios({
            method: 'get',
            url: `${window.Config.G_API_URL}/admin/authority/`,
            params: {
                admin_level: level.replace(':', ''),
                token: cookies.get('token'),
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    setAuthInfo(response.data.data);
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    }, []);

    useEffect(() => {
        let checkedList = [];
        for (let i in authInfo) {
            if (authInfo[i] === 'Y') {
                checkedList.push(i)
            }
        }
        setCheckList(checkedList)
    }, [authInfo]);

    const returnLevel = () => {
        switch (level.replace(':', '')) {
            case '0':
                return '슈퍼관리자';
            case '1':
                return '최고관리자';
            case '2':
                return '고객센터 관리자';
            case '3':
                return '고객센터';
            case '4':
                return '업체 관리자';
        }
    };

    const handleCheckEach = (e) => {
        const { id } = e.target;
        if (checkList.includes(id)) {
            if(id.includes('read')){
                setCheckList(checkList.filter(item => item !== id).filter(item => item !== id.replace('read' , 'write')))
            } else {
                setCheckList(checkList.filter(item => item !== id))
            }
        } else {
            if(id.includes('write')){
                setCheckList([...checkList, id , id.replace('write' , 'read')]);
            } else {
                setCheckList([...checkList, id]);
            }
        }
    };

    const updateAuth = () => {
        axios({
            method: 'put',
            url: `${window.Config.G_API_URL}/admin/authority`,
            params: {
                token: cookies.get('token'),
                authority_list: checkList,
                admin_level: level.replace(':', ''),
            },
            // no : checkList 파라미터 [] 없애는 옵션
            paramsSerializer: function (paramObj) {
                const params = new URLSearchParams();
                for (const key in paramObj) {
                    params.append(key, paramObj[key]);
                }

                return params.toString();
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    console.log(response.data.data.result);
                    navigate(-1);
                } else if(response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    };

    return (
        <ManageUserDetailWrapper>
            <Title>{returnLevel()} 권한 편집</Title>
            <Description>
                관리자 설정 <BcArrow src={breadcrumbArrow} /> 관리자 회원 관리{' '}
                <BcArrow src={breadcrumbArrow} /> {returnLevel()} 권한 편집
            </Description>
            <SubTitle>{returnLevel()}</SubTitle>
            <ManageAdminAuthTable
                onChange={handleCheckEach}
                authInfo={authInfo}
                checkedList={checkList}
            />
            <ButtonWrapper>
                <button onClick={() => navigate(-1)}>목록</button>
                <button onClick={updateAuth}>저장</button>
            </ButtonWrapper>
            <AuthCheckModal
                toggle={toggleAuthModal}
                toggleFunc={handleToggleAuthModal}
            />
        </ManageUserDetailWrapper>
    );
}

export default ManageAdminAuth;
