import axios from "axios";
import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { useParams } from "react-router";
import styled from 'styled-components';
import Cookie from "universal-cookie";
import DeleteSearch from "../asset/img/deletesearch.png";
import Dotbogi from "../asset/img/dotbogi.png";
import SelectArrow from "../asset/img/selectarrow.png";
import { SearchBox } from "../css/styles";
import PaginationUsage from "./PaginationUsage";
import SearchIndexBox from "./SearchIndexBox";
import StationUsageModalList from "./StationUsageModalList";

const cookies = new Cookie();

const Title = styled.h1`
    font-weight: bold;
    font-size: 24px;
    line-height: 35px;
    color: #221E1F;
`

const SelectBox = styled.select`
  cursor: pointer;
  appearance:none;
  border: 1px solid #C4C4C4;
  border-radius: 10px;
  padding: 7px 10px;
  width: 10%;
  font-size: 16px;
  line-height: 23px;
  background-color: white;
  background-image: url(${SelectArrow});
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: 85%;
  &:focus{
    outline: none;
  }
`


const SearchInput = styled.input`
  margin-left: 21px;
  width: 60%;
  border: 1px solid #C4C4C4;
  border-radius: 10px;
  background-image: url(${Dotbogi});
  background-repeat: no-repeat;
  background-size: 17px;
  background-position: 97%;
  padding: 7px 10px;
  font-size: 16px;
  line-height: 23px;
  color: #7E7E7E;
`

const ButtonContainer = styled.div`
    display : flex;
    justify-content : space-between;
  padding-top: 40px;
`

const Button = styled.button`
  background-color: white;
  border-radius: 10px;
  border: 1px solid #C4C4C4;
  width : 11%;
  padding : 10px 0 ;
  font-weight: bold;
  font-size: 14px;
  color: #221E1F;
`

const Table = styled.table`
  width: 100%;
  line-height: 40px;
  text-align: center;
  & > thead > tr{
    border-bottom: 2px solid #e5e5e5;
    line-height: 23px;
    & > th {
      vertical-align: middle;
    }
  }
  & > tbody > tr{
    border-bottom: 1px solid #e5e5e5;
  }
`
const TableDiv = styled.div`
  height: 65%;
  padding-top: 20px;
`

const ModalSearchBox = styled(SearchBox)`
  width: 100%;
`


function StationUsageModal(props) {

    const {no , usage} = useParams();
    const [deviceInfo , setDeviceInfo] = React.useState([]);
    const [deviceListInfo , setDeviceListInfo] = React.useState([]);

    const [searchData , setSearchData] = React.useState(null);
    const [searchType , setSearchType] = React.useState(null);

    const [startDate , setStartDate] = React.useState(null);
    const [endDate , setEndeDate] = React.useState(null);

    useEffect(()=>{
        axios({
            method : 'get',
            url : `${window.Config.G_API_URL}/admin/station/usage`,
            params : {
                token : cookies.get('token'),
                station_id : no.replace(':', ''),
                page_num : usage.replace(':',''),
                search_type : searchType,
                search_data : searchData,
                start_date : startDate,
                end_date : endDate,
            }
        })
            .then(response => {
                if(response.data.result['code'] === '200'){
                    console.log(response.data.data.result);
                    setDeviceInfo(response.data.data.result.list)
                    setDeviceListInfo(response.data.data.result)
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function(error){
                alert(error);
            })
    },[searchType , searchData , usage , startDate , endDate ])


    const onTypeChange = (e) => {
        if(e.target.value === 'null'){
            setSearchType(null)
        } else {
            setSearchType(e.target.value);
        }
    }

    const onSearchDataChange = (e) => {
        setSearchData(e.target.value);
    }

    const onReset = () => {
        setSearchData('');
    }

    const onStartDateChange = (e) => {
        setStartDate(e.target.value);
    }

    const onEndDateChange = (e) => {
        setEndeDate(e.target.value);
    }

    const handleDate = (e) => {
        var date = new Date();
        var currentDate = date.toISOString().substring(0,10);
        setEndeDate(currentDate);
        switch(e.target.id){
            case 'all':
                setStartDate(null);
                break;
            case 'week':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 7));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate(currentDate);
                break;
            case 'halfMonth':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 14));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate(currentDate);
                break;
            case 'month':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 30));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate(currentDate);
                break;
            case 'threeMonth':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 91));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate(currentDate);
                break;
            case 'halfYear':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 182));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate(currentDate);
                break;
            case 'year':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 365));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate(currentDate);
                break;
        }
    }

    return (
        <Modal isOpen={props.toggle} onRequestClose={props.toggleFunc}
               ariaHideApp={false}
               style={{
                   overlay :{
                       backgroundColor : 'rgba(34, 30, 31, 0.5)'
                   },
                   content : {
                       height : '85%',
                       left : '20%',
                       right : '10%',
                       top : '5%',
                       borderRadius : '10px',
                   }
               }}
        >
            <Title>{props.name}의 이용 내역</Title>
            <ModalSearchBox>
                    <SelectBox name="searchselect" id="serachselect" onChange={onTypeChange}>
                        <option value={'null'}>선택</option>
                        <option value="포트">포트</option>
                        <option value="고유 번호">고유 번호</option>
                    </SelectBox>
                    <SearchInput type="text" placeholder={'검색어 입력'} onChange={onSearchDataChange}/>
                    <div onClick={onReset}>
                        <img src={DeleteSearch} alt=""/>
                        <p>초기화</p>
                    </div>
            </ModalSearchBox>
            <SearchIndexBox
                width={'90'}
                startChange={onStartDateChange}
                endChange={onEndDateChange}
                handleDate={handleDate}
                startDate={startDate}
                endDate={endDate}
            />
            <TableDiv>
                <Table>
                    <thead>
                    <tr>
                        <th>No.</th>
                        <th>포트</th>
                        <th>회원 / 킥보드 <br/> 고유번호</th>
                        <th>업체명</th>
                        <th>사용 시작 일시</th>
                        <th>사용 종료 일시</th>
                        <th>충전 시간 (분)</th>
                        <th>거치 시간 (분)</th>
                        <th>쿠폰 적용 (원)</th>
                        <th>요금 (원)</th>
                    </tr>
                    </thead>
                    <tbody>
                    {deviceInfo.map((item, index) => (
                        <StationUsageModalList key={index}
                            port={item.port}
                            rowNum={item.rownum}
                            number={item.number}
                            company={item.company}
                            coupon_amount={item.coupon_amount}
                            startTime={item.start_time}
                            endTime={item.end_time}
                            charging={item.charging}
                            holding={item.holding}
                            amount={item.amount}
                        />
                    ))}
                    </tbody>
                </Table>
            </TableDiv>
            <ButtonContainer>
                <p/>
                <PaginationUsage
                    no={usage.replace(':', '')}
                    url={`/admin/devicedetail/${no}/:`}
                    pages={deviceListInfo['pages']}
                    isFirstPage={deviceListInfo['isFirstPage']}
                    isLastPage={deviceListInfo['isLastPage']}
                    prePage={deviceListInfo['prePage']}
                    nextPage={deviceListInfo['nextPage']}
                    hasPrePage={deviceListInfo['hasPreviousPage']}
                    hasNextPage={deviceListInfo['hasNextPage']}
                />
                <Button onClick={props.toggleFunc}>닫기</Button>
            </ButtonContainer>
        </Modal>
    );
}

export default StationUsageModal;
