import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    ManageUserDetailWrapper,
    Title,
    Description,
    BcArrow,
} from '../css/styles';
import axios from 'axios';
import Cookie from 'universal-cookie';
import { useParams } from 'react-router';
import AdminLoginLog from '../components/AdminLoginLog';
import ManageAdminDetailTable from '../components/ManageAdminDetailTable';
import styled from 'styled-components';
import DeleteUserModal from '../components/DeleteUserModal';

import breadcrumbArrow from '../asset/img/breadcrumbarrow.png';

const cookies = new Cookie();

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    & > div > button:first-child {
        font-size: 16px;
        line-height: 23px;
        border: 1px solid #df1f26;
        padding: 7px 20px;
        color : #df1f26;
        background-color: #fff;
        border-radius: 10px;
        margin-left : 15px;
    }
    & > div > button:last-child {
        font-size: 16px;
        line-height: 23px;
        border: 1px solid #c4c4c4;
        padding: 7px 20px;
        background-color: #fff;
        border-radius: 10px;
        margin-left : 15px;
    }
`;

const ButtonUpperWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    & > button {
        border : none !important;
        background-color : #df1f26 !important;
        color : white;
    }
`;

const SubTitle = styled.div`
    font-size: 32px;
    font-family : notosansbold;
    padding-top : 20px;
`

const FooterContainer = styled.div`
 & > div > div:first-child {
    margin-top : 0 !important;
 }
`

function ManageUserDetail(props) {
    const { adminid , no } = useParams();
    const [managerInfo, setManagerInfo] = React.useState([]);
    const [loginInfo, setLoginInfo] = React.useState([]);
    const [pageInfo, setPageInfo] = React.useState([]);
    const [toggleDeleteModal, setToggleDeleteModal] = React.useState(false);
    const [startDate , setStartDate] = React.useState(null);
    const [endDate , setEndeDate] = React.useState(null);

    const navigate = useNavigate();

    const handleDate = (e) => {
        var date = new Date();
        var currentDate = date.toISOString().substring(0,10);
        setEndeDate(currentDate);
        switch(e.target.id){
            case 'all':
                setStartDate(null);
                break;
            case 'week':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 7));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate(currentDate);
                break;
            case 'halfMonth':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 14));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate(currentDate);
                break;
            case 'month':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 30));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate(currentDate);
                break;
            case 'threeMonth':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 91));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate(currentDate);
                break;
            case 'halfYear':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 182));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate(currentDate);
                break;
            case 'year':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 365));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate(currentDate);
                break;
        }
    }

    useEffect(() => {
        axios({
            method: 'get',
            url: `${window.Config.G_API_URL}/admin/detail/`,
            params: {
                token: cookies.get('token'),
                admin_id: adminid.replace(':', ''),
                page_num: no.replace(':', ''),
                start_date : startDate,
                end_date : endDate,
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    setManagerInfo(response.data.data.data);
                    setLoginInfo(response.data.data.loginData.list)
                    setPageInfo(response.data.data.loginData);
                    console.log(response.data);
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    }, [no , startDate ,endDate]);

    const deleteUser = () => {
        axios({
            method: 'delete',
            url: `${window.Config.G_API_URL}/admin`,
            params: {
                token: cookies.get('token'),
                admin_id: adminid.replace(':', ''),
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    navigate(-1);
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    };

    const handleToggleDeleteModal = () => {
        setToggleDeleteModal(!toggleDeleteModal);
    };

    const onStartDateChange = (e) => {
        setStartDate(e.target.value);
    }

    const onEndDateChange = (e) => {
        setEndeDate(e.target.value);
    }

    return (
        <ManageUserDetailWrapper>
            <Title>관리자 상세</Title>
            <Description>
                관리자 설정 <BcArrow src={breadcrumbArrow} /> 관리자 회원 관리{' '}
                <BcArrow src={breadcrumbArrow} /> 관리자 상세
            </Description>
            <ButtonUpperWrapper>
                <button onClick={handleToggleDeleteModal}>관리자 삭제</button>
            </ButtonUpperWrapper>
            <ManageAdminDetailTable managerInfo={managerInfo} />
            <ButtonWrapper>
                <button onClick={() => window.location.href = '/admin/manageadminuser/:1/:1/:1/:1/:1'}>목록</button>
                <div>
                    <button onClick={() => window.location.href = `/admin/manageadminuser/updatepw/${adminid}`}>관리자 비밀번호 수정</button>
                    <button onClick={() => window.location.href = `/admin/manageadminuser/update/${adminid}`}>관리자 정보 수정</button>
                </div>
            </ButtonWrapper>

            <FooterContainer>
                <SubTitle>로그인 내역</SubTitle>
                <AdminLoginLog
                    loginInfo={loginInfo}
                    loginPageInfo={managerInfo}
                    pageInfo={pageInfo}
                    handleDate={handleDate}
                    onStartDateChange={onStartDateChange}
                    onEndDateChange={onEndDateChange}
                    startDate={startDate}
                    endDate={endDate}
                />
            </FooterContainer>

            <DeleteUserModal
                content={'정말 삭제하시겠습니까?'}
                toggle={toggleDeleteModal}
                toggleFunc={handleToggleDeleteModal}
                deleteFunc={deleteUser}
            />
        </ManageUserDetailWrapper>
    );
}

export default ManageUserDetail;
