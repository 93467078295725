import React, { useEffect } from 'react';
import styled from 'styled-components';
import GrayQuestionMark from "../asset/img/grayquestionmark.png";
import SelectArrow from "../asset/img/selectarrow.png";
import Postcode from "./Postcode";

const Table = styled.table`
  & > tbody{
    & > tr:nth-child(7){
      & > td:nth-child(2){
        vertical-align: middle !important;
      }
    }
    & > tr:nth-child(8){
      border-top: none;
      border-bottom: none;
      & > td:nth-child(2){
        vertical-align: middle !important;
      }
    }
    & > tr:nth-child(9){
      border-top: none;
      border-bottom: none;
      & > td:nth-child(2){
        vertical-align: middle !important;
      }
    }
    & > tr:nth-child(10){
      border-top: 1px solid #e5e5e5 !important;
      & > td:first-child:after{
        content: '관리자';
      }
    }
    & > tr > td:first-child{
      width: 17% !important;
    }
  }
`

const BigInput = styled.input`
  border: 1px solid #C4C4C4;
  width: 25%;
  padding: 8px;
  border-radius: 10px;
`

const Select = styled.select`
  cursor: pointer;
  appearance:none;
  border: 1px solid #C4C4C4;
  border-radius: 10px;
  padding : 4px 10px;
  width: 90px;
  font-size: 16px;
  line-height: 23px;
  background-image: url(${SelectArrow});
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: 85%;
  &:focus{
    outline: none;
  }
`

const Td = styled.td`
position : relative;
& > div {
  display : flex;
  width : 100%;
  align-items : center;
  column-gap : 10px;
  & > img {
    width : 18px;
    cursor : pointer;
  }
}
`

const ToolTop = styled.div`
  display : ${props => props.hover ? 'block !important' : 'none !important'};
  position : absolute;
  background-color: white;
  border: 1px solid #C4C4C4;
  border-radius: 5px;
  left : 120px;
  top : 20px;
  width : 500px !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding : 3%;
  & > p:last-child{
    margin-top : 15px;
  }
`

const RowBox = styled.div`
  display : flex;
  align-items : center;
  width : 40%;
  column-gap: 10px; 
  & > input {
    width : 73%;
  }
 & > p {
   color : #DF1F26;
   font-size: 12px;
   border-radius: 10px;
   border: 1px solid #DF1F26;
   padding : 0 7px 1px 7px;
   cursor: pointer;
 }
`

const AddressInput = styled.input`
  border: 1px solid #C4C4C4;
  width: 25%;
  padding: 7px;
  border-radius: 10px;
  &:focus{
    outline: none;
  }
`

function SearchStationRegisterTable(props) {

  const { kakao } = window;
  const [onHover , setOnHover] = React.useState(false);
  const [location , setLocation] = React.useState('');
  const [lat , setLat] = React.useState('');
  const [long , setLon] = React.useState('');
  const [toggleAddressModal , setToggleAddressModal] = React.useState(false);

  const handleInOnHover = () => {
    setOnHover(true);
  }
  const handleOutOnHover = () => {
    setOnHover(false);
  }

  useEffect(() => {
    if(kakao) {
      const geocoder = new kakao.maps.services.Geocoder();
      geocoder.addressSearch(location, function (results, status) {
          // 정상적으로 검색이 완료됐으면
          if(parseFloat(results[0].y).toFixed(7) === 'NaN'){
              setLat('-');
              setLon('-');
          } else {
              props.setLat(parseFloat(results[0].y).toFixed(7))
              setLat(parseFloat(results[0].y).toFixed(7))
              props.setLon(parseFloat(results[0].x).toFixed(7))
              setLon(parseFloat(results[0].x).toFixed(7))
          }
          console.log(results[0].y , results[0].x)
      });
    }
    //광고 등록 위치 스테이트
    props.setLocation(location);
  } , [location , lat , long])

  const handleToggleAddressModal = () => {
    setToggleAddressModal(!toggleAddressModal);
}
    return (
        <Table>
            <tbody>
            <tr>
                <td>주소 / 위치</td>
                <td colSpan='2'>
                    <div>
                    <RowBox>
                        <AddressInput type='text' name='location' value={location||''} onChange={props.onTableInputChange} readOnly/>
                        <p onClick={handleToggleAddressModal}>주소찾기</p>
                        {
                            toggleAddressModal &&
                                <Postcode toggle={toggleAddressModal} setLocation={setLocation} toggleFunc={handleToggleAddressModal}/>
                        }
                    </RowBox>
                    </div>
                </td>
            </tr>
            <tr>
              <td>위치</td>
              <td>
                <Select name="outside" id=""  onChange={props.onChange}>
                    <option value={null}>전체</option>
                    <option value="Y">실외</option>
                    <option value="N">실내</option>
                </Select>
              </td>
            </tr>
            <tr>
                <td>종류</td>
                <td>
                    <Select name="privateStation" id=""  onChange={props.onChange}>
                        <option value="Y">민영</option>
                        <option value="N">공영</option>
                    </Select>
                </td>
            </tr>
            <tr>
                <Td>
                  <div>
                    <p>충전기 종류</p>
                    <img src={GrayQuestionMark} alt="" onMouseEnter={handleInOnHover} onMouseLeave={handleOutOnHover}/>
                  </div>
                  <ToolTop hover={onHover}>
                  <p>충전기 종류를 잘못 선택시에 스테이션이 원활하게 작동하지 않을 수 있습니다. 정확한 충전기 종류를 선택해주세요.</p>
                  <p>스테이션 등록이 완료되면 충전기 종류를 수정할 수 없습니다.</p>
                  </ToolTop>
                </Td>
                <td>
                    <Select name="stationKind" id=""  onChange={props.onChange}>
                        <option value={null}>선택</option>
                        <option value="KR">한국</option>
                        <option value="CN">중국</option>
                    </Select>
                </td>
            </tr>
            <tr>
                <td>스테이션 ID</td>
                <td><BigInput type='text' name={'stationId'} value={props.stationID||''} onChange={props.onChange}/></td>
            </tr>
            <tr>
                <td>최대 포트 수</td>
                <td>
                    <Select name="maxPort" id=""  onChange={props.onChange}>
                        <option value={4}>4</option>
                        <option value={8}>8</option>
                        <option value={16}>16</option>
                    </Select>
                </td>
            </tr>
            <tr>
                <td>위도</td>
                <td>{props.lat === '' ? '-' : props.lat}</td>
            </tr>
            <tr>
                <td>경도</td>
                <td>{props.lon === '' ? '-' : props.lon}</td>
            </tr>
            </tbody>
        </Table>
    );
}

export default SearchStationRegisterTable;