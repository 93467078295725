import axios from 'axios';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import Cookie from 'universal-cookie';
import ManageUserList from '../components/ManageUserList';
import Pagination from '../components/Pagination';
import {
    BcArrow,
    BorderDiv,
    BorderDivR,
    Description,
    ManageUserWrapper,
    SearchBox,
    Title,
} from '../css/styles';

import breadcrumbArrow from '../asset/img/breadcrumbarrow.png';
import DeleteSearch from '../asset/img/deletesearch.png';
import Dotbogi from '../asset/img/dotbogi.png';
import RedMoney from '../asset/img/redmoney.png';
import Redmsg from '../asset/img/redmsg.png';
import RedProfile from '../asset/img/redprofile.png';
import SelectArrow from '../asset/img/selectarrow.png';
import SelectUserModal from '../components/SelectUserModal';

import { useParams } from 'react-router';
import AuthCheckModal from '../components/AuthCheckModal';
import ChargingUserModal from '../components/ChargingUserModal';
import CheckBox from '../components/CheckBox';
import MessageGroupSendModal from '../components/MessageGroupSendModal';
import PointChangeConfirmModal from '../components/PointChangeConfirmModal';
import PointChargeConfirmModal from '../components/PointChargeConfirmModal';
import PointChargeModal from '../components/PointChargeModal';
import PointDeduceConfirmModal from '../components/PointDeducceConfirmModal';
import PointDeduceModal from '../components/PointDeduceModal';
import UserComebackModal from '../components/UserComebackModal';
import UserDeleteConfirmModal from '../components/UserDeleteConfirmModal';
import UserStopModal from '../components/UserStopModal';

const cookies = new Cookie();

const SelectBox = styled.select`
    cursor: pointer;
    appearance: none;
    text-align: center;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    padding: 7px 0;
    font-size: 16px;
    line-height: 23px;
    background-image: url(${SelectArrow});
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: 85%;
    &:focus {
        outline: none;
    }
`;

const SendSelectBox = styled(SelectBox)`
    background-image: url(${Redmsg}), url(${SelectArrow});
    background-size: 17px, 10px;
    background-position: 7%, 90%;
    padding: 7px 27px;
`;

const MoneySelectBox = styled(SendSelectBox)`
    cursor: pointer;
    background-image: url(${RedMoney}), url(${SelectArrow});
    margin-left: 13px;
    background-color: white;
`;

const UserSelectBox = styled(MoneySelectBox)`
    cursor: pointer;
    background-image: url(${RedProfile}), url(${SelectArrow});
    padding: 7px 35px;
    background-color: white;
`;

const SearchInput = styled.input`
    margin-left: 21px;
    width: 27%;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    background-image: url(${Dotbogi});
    background-repeat: no-repeat;
    background-size: 17px;
    background-position: 97%;
    padding: 7px 10px;
    font-size: 16px;
    line-height: 23px;
    color: #7e7e7e;
`;

const MsgButton = styled.button`
  cursor: pointer;
  padding : 0 35px;
  background-image: url(${Redmsg});
  background-size: 17px, 10px;
  background-position: 10%, 90%;
  background-repeat: no-repeat;
  background-color: white;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  font-size: 16px;
  line-height: 23px;
`

function ManageUser(props) {
    const { no } = useParams();
    const [manageUserInfo, setManageUserInfo] = React.useState([]);
    const [manageUserResult, setManageUserResult] = React.useState([]);

    const [checked, setChecked] = React.useState(false);
    const [checkList, setCheckList] = React.useState([]);
    const [checkAll , setCheckAll] = React.useState(false);

    const [toggleConfirmDeleteModal, setToggleConfirmDeleteModal] =
        React.useState(false);

    const [toggleStopModal, setToggleStopModal] = React.useState(false);
    const [toggleComebackModal, setToggleComebackModal] = React.useState(false);
    const [amount, setAmount] = React.useState('');
    const [toggleChargePointModal, setToggleChargePointModal] =
        React.useState(false);
    const [toggleDeducePointModal, setToggleDeducePointModal] =
        React.useState(false);
    const [toggleChargePointConfirmModal, setToggleChargePointConfirmModal] =
        React.useState(false);
    const [toggleDeducePointConfirmModal, setToggleDeducePointConfirmModal] =
        React.useState(false);
    const [togglePointConfirmModal, setTogglePointConfirmModal] =
        React.useState(false);
    const [toggleChargingModal , setToggleChargingModal] = React.useState(false);
    const [toggleMsgModal , setToggleMsgModal] = React.useState(false);
    const [toggleEmptyModal, setToggleEmptyModal] = React.useState(false);
    const [textMsg , setTextMsg] = React.useState('');

    const [searchData, setSearchData] = React.useState(null);
    const [searchType, setSearchType] = React.useState('이름');

    const [toggleAuthModal , setToggleAuthModal] = React.useState(false);

    const handleToggleAuthModal = () => {
        setToggleAuthModal(!toggleAuthModal);
    }

    const handleToggleChargingModal = () => {
        setToggleChargingModal(!toggleChargingModal);
    }

    useEffect(() => {
        setCheckList([]);
        axios({
            method: 'get',
            url: `${window.Config.G_API_URL}/admin/users/`,
            params: {
                page_num: no.replace(':', ''),
                token: cookies.get('token'),
                search_type: searchType,
                search_data: searchData,
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    setManageUserResult(response.data.data.result);
                    setManageUserInfo(response.data.data.result.list);
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                }else if(response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                }  else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    }, [no, searchType, searchData]);

    useEffect(()=>{
        if(checkAll){
            let arr = [];
            manageUserInfo.map((item)=>{
                    arr.push(`${item.us_id}`);
            });
            setCheckList(arr);
        } else if(checkList.length==manageUserInfo.length) {
            setCheckList([]);
        }
    },[checkAll]);

    useEffect(()=>{
        if(checkList.length == manageUserInfo.length){
            setCheckAll(true);
        } else {
            setCheckAll(false);
        }
        // console.log(checkList);
    },[checkList, manageUserInfo]);

    const handleCheck = (e) => {
        handleCheckEach(e)
    }

    const handleCheckEach = (e) => {
        const { id, checked } = e.target;
        let arr = [...checkList];

        if (arr.includes(id)) {
            for (let i = 0; i < arr.length; i++) {
                if (arr[i] == id) {
                    arr.splice(i, 1);
                    setChecked(!checked);
                    break;
                }
            }
            setCheckList(arr);
        } else {
            setChecked(!checked);
            setCheckList([...checkList, id]);
        }
    };

    const handleCheckAll = () => {
        setCheckAll(!checkAll);
        // console.log(checkList)
    }

    //회원 select onchange
    const onUserManageChange = (e) => {
        switch (e.target.value) {
            case 'stop':
                handleToggleStopModal();
                break;
            case 'comeback':
                handleToggleComebackModal();
                break;
            case 'breakout':
                handleToggleDeleteConfirmModal();
                break;
            default:
                break;
        }
    };

    const handleToggleStopModal = () => {
        if(checkList.length==0){
            setToggleEmptyModal(!toggleEmptyModal);
            return;
        }
        setToggleStopModal(!toggleStopModal);
    };

    const handleToggleComebackModal = () => {
        if(checkList.length==0){
            setToggleEmptyModal(!toggleEmptyModal);
            return;
        }
        setToggleComebackModal(!toggleComebackModal);
    };

    const handleToggleDeleteConfirmModal = () => {
        if(checkList.length==0){
            setToggleEmptyModal(!toggleEmptyModal);
            return;
        }
        setToggleConfirmDeleteModal(!toggleConfirmDeleteModal);
    };

    //회원 탈퇴 함수
    const breakUser = () => {
        axios({
            method: 'delete',
            url: `${window.Config.G_API_URL}/admin/user`,
            params: {
                token: cookies.get('token'),
                us_id: checkList,
            },
            // no : checkList 파라미터 [] 없애는 옵션
            paramsSerializer: function (paramObj) {
                const params = new URLSearchParams();
                for (const key in paramObj) {
                    params.append(key, paramObj[key]);
                }
                return params.toString();
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    console.log(response);
                    window.location.href = `/admin/manageuser/${no}`;
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else if(response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                } else if (response.data.result['code'] === '403'){
                    handleToggleChargingModal();
                }  
                else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    };

    //금액 관리 select onchange
    const onChargeManageChange = (e) => {
        switch (e.target.value) {
            case 'pointplus':
                handleToggleChargePointModal();
                break;
            case 'pointminus':
                handleToggleDeducePointModal();
                break;
            case 'coupon':
                break;
            default:
                break;
        }
    };

    const handleToggleChargePointModal = () => {
        if(checkList.length==0){
            setToggleEmptyModal(!toggleEmptyModal);
            return;
        }
        setToggleChargePointModal(!toggleChargePointModal);
    };

    const handleToggleDeducePointModal = () => {
        if(checkList.length==0){
            setToggleEmptyModal(!toggleEmptyModal);
            return;
        }
        setToggleDeducePointModal(!toggleDeducePointModal);
    };

    const handleToggleChargePointConfirmModal = () => {
        handleToggleChargePointModal();
        setToggleChargePointConfirmModal(!toggleChargePointConfirmModal);
    };

    const handleToggleDeducePointConfirmModal = () => {
        handleToggleDeducePointModal();
        setToggleDeducePointConfirmModal(!toggleDeducePointConfirmModal);
    };

    const handleTogglePointConfirmModal = () => {
        setTogglePointConfirmModal(!togglePointConfirmModal);
    };

    const handleToggleEmptyModal = () => {
        setToggleEmptyModal(!toggleEmptyModal);
    }

    const handleAmount = (e) => {
        setAmount(e.target.value);
    };

    const chargePoint = () => {
        axios({
            method: 'post',
            url: `${window.Config.G_API_URL}/admin/user/point/charge`,
            params: {
                token: cookies.get('token'),
                us_id: checkList,
                point: amount,
            },
            // no : checkList 파라미터 [] 없애는 옵션
            paramsSerializer: function (paramObj) {
                const params = new URLSearchParams();
                for (const key in paramObj) {
                    params.append(key, paramObj[key]);
                }
                return params.toString();
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    console.log(response);
                    handleTogglePointConfirmModal();
                } else if(response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    };

    const deducePoint = () => {
        axios({
            method: 'post',
            url: `${window.Config.G_API_URL}/admin/user/point/deduct`,
            params: {
                token: cookies.get('token'),
                us_id: checkList,
                point: amount,
            },
            // no : checkList 파라미터 [] 없애는 옵션
            paramsSerializer: function (paramObj) {
                const params = new URLSearchParams();
                for (const key in paramObj) {
                    params.append(key, paramObj[key]);
                }
                return params.toString();
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    console.log(response);
                    handleTogglePointConfirmModal();
                } else if(response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    };

    const handdleToggleMsgModal = () => {
        setToggleMsgModal(!toggleMsgModal);
    }

    const handleTextMsg = (e) => {
        setTextMsg(e.target.value)
    }

    const onTypeChange = (e) => {
        setSearchType(e.target.value);
    };

    const onSearchDataChange = (e) => {
        setSearchData(e.target.value);
    };

    const handleResetClearAll = () => {
        setSearchData('')
    }

    return (
        <ManageUserWrapper>
            <Title>회원 관리</Title>
            <Description>
                회원 관리 <BcArrow src={breadcrumbArrow} /> 회원 관리
            </Description>
            <SearchBox>
                <SelectBox
                    name="searchData"
                    id="searchData"
                    onChange={onTypeChange}
                >
                    <option value="이름">이름</option>
                    <option value="아이디(이메일)">아이디(이메일)</option>
                    <option value="연락처">연락처</option>
                </SelectBox>
                <SearchInput
                    type="text"
                    placeholder={'검색어 입력'}
                    value={searchData||''}
                    onChange={onSearchDataChange}
                />
                <div onClick={handleResetClearAll}>
                    <img src={DeleteSearch} alt="" />
                    <p>초기화</p>
                </div>
            </SearchBox>
            <table>
                <thead>
                    <tr>
                        <th>
                            <CheckBox no={'checkAll'} checked={checkAll} onChange={handleCheckAll}/>
                        </th>
                        <th>
                            <BorderDiv>No.</BorderDiv>
                        </th>
                        <th>
                            <div>이름</div>
                        </th>
                        <th>
                            <BorderDiv>아이디(메일)</BorderDiv>
                        </th>
                        <th>
                            <div>가입경로</div>
                        </th>
                        <th>
                            <BorderDiv>연락처</BorderDiv>
                        </th>
                        <th>
                            <div>보유 포인트</div>
                        </th>
                        <th>
                            <BorderDiv>이용 내역 (건)</BorderDiv>
                        </th>
                        <th>
                            <BorderDivR>가입일</BorderDivR>
                        </th>
                        <th>회원 상태</th>
                    </tr>
                </thead>
                <tbody>
                    {manageUserInfo.map((item, key) => (
                        <ManageUserList
                            key={item.us_id}
                            no={toggleMsgModal ? '' : item.us_id}
                            num={no.replace(':','')}
                            title={item.title}
                            date={item.date_time.substr(0, 10)}
                            status={item.status}
                            type={item.type}
                            birth={item.us_birth}
                            count={item.use_count}
                            gender={item.us_gender}
                            name={item.us_name}
                            phone={item.us_phone}
                            point={item.us_point}
                            email={item.us_email}
                            checked={checkList.includes(`${item.us_id}`)}
                            onChange={handleCheck}
                        />
                    ))}
                </tbody>
            </table>
            <div>
                <div>
                    <MsgButton onClick={handdleToggleMsgModal}>
                        문자 발송
                    </MsgButton>
                    <MoneySelectBox
                        name="money"
                        id="money"
                        onChange={onChargeManageChange}
                    >
                        <option value="money" hidden>
                            금액 관리
                        </option>
                        <option value="pointplus">포인트 충전</option>
                        <option value="pointminus">포인트 차감</option>
                    </MoneySelectBox>
                </div>
                <Pagination
                    no={no.replace(':', '')}
                    url="/admin/manageuser/:"
                    pages={manageUserResult['pages']}
                    isFirstPage={manageUserResult['isFirstPage']}
                    isLastPage={manageUserResult['isLastPage']}
                    prePage={manageUserResult['prePage']}
                    nextPage={manageUserResult['nextPage']}
                    hasPrePage={manageUserResult['hasPreviousPage']}
                    hasNextPage={manageUserResult['hasNextPage']}
                />
                <UserSelectBox
                    name="userstate"
                    id="userstate"
                    onChange={(e) => onUserManageChange(e)}
                >
                    <option value="userstate" hidden>
                        회원 상태 관리
                    </option>
                    <option value="stop">이용 정지</option>
                    <option value="comeback">정지 해제</option>
                    <option value="breakout">탈퇴</option>
                </UserSelectBox>
            </div>

            <UserStopModal
                toggle={toggleStopModal}
                toggleFunc={handleToggleStopModal}
                length={checkList.length}
                no={no}
                checkList={checkList}
            />

            <UserComebackModal
                toggle={toggleComebackModal}
                toggleFunc={handleToggleComebackModal}
                no={no}
                length={checkList.length}
                checkList={checkList}
            />

            <UserDeleteConfirmModal
                toggle={toggleConfirmDeleteModal}
                toggleFunc={handleToggleDeleteConfirmModal}
                confirm={breakUser}
                length={checkList.length}
                content={'회원을'}
            />

            <PointChargeModal
                toggle={toggleChargePointModal}
                toggleFunc={handleToggleChargePointModal}
                toggleNext={handleToggleChargePointConfirmModal}
                checkList={checkList}
                amount={amount}
                setAmount={handleAmount}
                no={no}
            />

            <PointChargeConfirmModal
                toggle={toggleChargePointConfirmModal}
                toggleFunc={handleToggleChargePointConfirmModal}
                length={checkList.length}
                confirm={chargePoint}
            />

            <PointDeduceModal
                toggle={toggleDeducePointModal}
                toggleFunc={handleToggleDeducePointModal}
                toggleNext={handleToggleDeducePointConfirmModal}
                checkList={checkList}
                amount={amount}
                setAmount={handleAmount}
                no={no}
            />

            <PointDeduceConfirmModal
                toggle={toggleDeducePointConfirmModal}
                toggleFunc={handleToggleDeducePointConfirmModal}
                length={checkList.length}
                confirm={deducePoint}
            />

            <PointChangeConfirmModal
                toggle={togglePointConfirmModal}
                toggleFunc={handleTogglePointConfirmModal}
                no={no}
            />

            {/* <MessageSendModal
                toggle={toggleMsgModal}
                toggleFunc={handdleToggleMsgModal}
                confirm={sendMsg}
                setTextMsg={handleTextMsg}
            /> */}

            <MessageGroupSendModal
                toggle={toggleMsgModal}
                toggleFunc={handdleToggleMsgModal}
            />

            <SelectUserModal
                toggle={toggleEmptyModal}
                toggleFunc={handleToggleEmptyModal}
                content={'회원을'}
            />
            <ChargingUserModal
                toggle={toggleChargingModal}
                toggleFunc={handleToggleChargingModal}
            />
            <AuthCheckModal
                toggle={toggleAuthModal}
                toggleFunc={handleToggleAuthModal}
            />
        </ManageUserWrapper>
    );
}

export default ManageUser;
