import React, { useEffect } from 'react';
import styled from 'styled-components';

import DeleteSearch from '../asset/img/deletesearch.png';
import SelectArrow from '../asset/img/selectarrow.png';
import Dotbogi from '../asset/img/dotbogi.png';
import SearchIndexBox from './SearchIndexBox';
import axios from 'axios';
import Cookie from 'universal-cookie';
import { useParams } from 'react-router';
import Pagination from './Pagination';
import StationUsageList from './StationUsageList';
import { BorderDiv, BorderDivR } from '../css/styles';

const cookies = new Cookie();

const PointManageWrapper = styled.div`
    margin-top: 0 !important;
    & > table {
        width: 100%;
        text-align: center;
        & > tbody > tr:first-child {
            line-height: 20px;
            border-bottom: 2px solid #e5e5e5;
        }
        & > tbody > tr {
            line-height: 40px;
            border-bottom: 1px solid #e5e5e5;
            font-weight: 500;
            font-size: 16px;
        }
    }
    & > div {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }
`;

const SearchBox = styled.div`
    padding: 2% 0;
    display: flex;
    justify-content: flex-start !important;
    align-items: flex-start;
    column-gap: 15px;
    & > div {
        display: flex;
        align-items: center;
        & > img {
            margin-top: 10px;
            margin-left: 7px;
            width: 20px;
        }
        & > p {
            font-size: 16px;
            margin-top: 9px;
            line-height: 23px;
            color: #c4c4c4;
        }
    }
`;

const ColumnBox = styled.div`
    display: flex;
    flex-direction: column;
`;

const SelectBox = styled.select`
    cursor: pointer;
    appearance: none;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    padding: 7px 15px;
    font-size: 16px;
    line-height: 23px;
    width: 10%;
    background-image: url(${SelectArrow});
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: 87%;
    &:focus {
        outline: none;
    }
`;

const SearchInput = styled.input`
    width: 97%;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    background-image: url(${Dotbogi});
    background-repeat: no-repeat;
    background-size: 17px;
    background-position: 97%;
    padding: 7px 10px;
    font-size: 16px;
    line-height: 23px;
    color: #7e7e7e;
`;

function StationUsage(props) {
    const { no, num, page } = useParams();
    const [stationInfo, setStationInfo] = React.useState([]);
    const [stationPageInfo, setStationPageInfo] = React.useState([]);
    const [searchData, setSearchData] = React.useState(null);
    const [searchType, setSearchType] = React.useState('장비 제목');
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndeDate] = React.useState(null);

    useEffect(() => {
        axios({
            method: 'get',
            url: `${window.Config.G_API_URL}/admin/user/history/station`,
            params: {
                token: cookies.get('token'),
                us_id: no.replace(':', ''),
                page_num: num.replace(':', ''),
                search_type: searchType,
                search_data: searchData,
                start_date: startDate,
                end_date: endDate,
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    console.log(response.data.data);
                    setStationPageInfo(response.data.data.result);
                    setStationInfo(response.data.data.result.list);
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    }, [num, searchType, searchData, startDate, endDate]);

    const onTypeChange = (e) => {
        setSearchType(e.target.value);
    };

    const onSearchDataChange = (e) => {
        setSearchData(e.target.value);
    };

    const onStartDateChange = (e) => {
        setStartDate(e.target.value);
    };

    const onEndDateChange = (e) => {
        setEndeDate(e.target.value);
    };

    useEffect(() => {
        console.log(stationInfo);
    }, [stationInfo]);

    const handleDate = (e) => {
        var date = new Date();
        var currentDate = date.toISOString().substring(0,10);
        setEndeDate(currentDate);
        switch(e.target.id){
            case 'all':
                setStartDate(null);
                break;
            case 'week':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 7));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate(currentDate);
                break;
            case 'halfMonth':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 14));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate(currentDate);
                break;
            case 'month':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 30));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate(currentDate);
                break;
            case 'threeMonth':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 91));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate(currentDate);
                break;
            case 'halfYear':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 182));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate(currentDate);
                break;
            case 'year':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 365));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate(currentDate);
                break;
        }
    }
    
    return (
        <PointManageWrapper>
            <SearchBox>
                <SelectBox
                    name="searchselect"
                    id="serachselect"
                    onChange={onTypeChange}
                >
                    {/*<option value="all">전체</option>*/}
                    <option value="장비 제목">스테이션명</option>
                    <option value="장비 정보">스테이션 ID</option>
                </SelectBox>
                <ColumnBox>
                    <SearchInput
                        type="text"
                        placeholder={'검색어 입력'}
                        onChange={onSearchDataChange}
                    />
                    <SearchIndexBox
                        startChange={onStartDateChange}
                        endChange={onEndDateChange}
                        handleDate={handleDate}
                        startDate={startDate}
                        endDate={endDate}
                    />
                </ColumnBox>
                <div>
                    <img src={DeleteSearch} alt="" />
                    <p>초기화</p>
                </div>
            </SearchBox>
            <table>
                <tbody>
                    <tr>
                        <th>
                            <BorderDivR>No.</BorderDivR>
                        </th>
                        <th>포트</th>
                        <th>
                            <BorderDiv>스테이션명</BorderDiv>
                        </th>
                        <th>스테이션 ID</th>
                        <th>
                            <BorderDiv>사용 시작 일시</BorderDiv>
                        </th>
                        <th>사용 종료 일시</th>
                        <th>
                            <BorderDiv>충전 시간 (분)</BorderDiv>
                        </th>
                        <th>
                            거치 시간 (분)
                        </th>
                        <th>
                            <BorderDiv>쿠폰 적용 (원)</BorderDiv>
                        </th>
                        <th>요금 (원)</th>
                    </tr>
                    {stationInfo.map((item, key) => (
                        <StationUsageList
                            rowNum={item.rownum}
                            key={key}
                            port={item.port}
                            amount={item.amount}
                            stationName={item.station_name}
                            stationId={item.station_id}
                            couponAmount={item.coupon_amount}
                            start={item.start_time}
                            end={item.end_time}
                            hold={item.holding}
                            charge={item.charging}
                        />
                    ))}
                </tbody>
            </table>
            <div>
                <Pagination
                    no={num.replace(':', '')}
                    url={`/admin/manageuser/detail/${no}/${page}/stationusage/:`}
                    pages={stationPageInfo['pages']}
                    isFirstPage={stationPageInfo['isFirstPage']}
                    isLastPage={stationPageInfo['isLastPage']}
                    prePage={stationPageInfo['prePage']}
                    nextPage={stationPageInfo['nextPage']}
                    hasPrePage={stationPageInfo['hasPreviousPage']}
                    hasNextPage={stationPageInfo['hasNextPage']}
                />
            </div>
        </PointManageWrapper>
    );
}

export default StationUsage;
