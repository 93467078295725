import React, { useEffect } from 'react';
import styled from "styled-components";

import axios from "axios";
import { useParams } from "react-router";
import Cookie from "universal-cookie";
import DeleteSearch from "../asset/img/deletesearch.png";
import Dotbogi from "../asset/img/dotbogi.png";
import SelectArrow from "../asset/img/selectarrow.png";
import AuthCheckModal from '../components/AuthCheckModal';
import { BorderDiv, BorderDivR } from '../css/styles';
import Pagination from "./Pagination";
import PointChangeConfirmModal from "./PointChangeConfirmModal";
import PointChargeModal from "./PointChargeModal";
import PointDeduceModal from "./PointDeduceModal";
import PoinManageList from "./PointManageList";
import SearchIndexBox from "./SearchIndexBox";

const cookies = new Cookie();

const PointManageWrapper = styled.div`
  margin-top: 0 !important;
  & > table {
    width: 100%;
    text-align: center;
    & > tbody > tr:first-child {
      line-height: 20px;
      border-bottom: 2px solid #E5E5E5;
    }
    & > tbody > tr {
      line-height: 40px;
      border-bottom: 1px solid #E5E5E5;
      font-weight: 500;
      font-size: 16px;
    }
  }
  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    & > button {
      border: 1px solid #C4C4C4;
      border-radius: 10px;
      background-color: white;
      font-size: 16px;
      line-height: 23px;
      width: 8%;
      padding : 5px 0;
    }
    & > div > button {
      border: 1px solid #C4C4C4;
      border-radius: 10px;
      background-color: white;
      font-size: 16px;
      line-height: 23px;
      padding : 5px;
      margin-right: 10px;
    }
  }
`

const SearchBox = styled.div`
    padding : 2% 0;
    display: flex;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    column-gap: 15px;
    & > div {
      display: flex;
      align-items: center;
      & > img {
        cursor: pointer;
        margin-top: 10px;
        margin-left: 7px;
        width: 20px;
      }
      & > p {
        font-size: 16px;
        margin-top: 9px;
        line-height: 23px;
        color: #C4C4C4;
      }
    } 
`

const ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
`

const SelectBox = styled.select`
    cursor: pointer;
    appearance:none;
    border: 1px solid #C4C4C4;
    border-radius: 10px;
    padding: 7px 15px;
    font-size: 16px;
    line-height: 23px;
    width: 12%;
    background-image: url(${SelectArrow});
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: 87%;
    &:focus{
        outline: none;
    }
`

const SearchInput = styled.input`
  width: 97%;
  border: 1px solid #C4C4C4;
  border-radius: 10px;
  background-image: url(${Dotbogi});
  background-repeat: no-repeat;
  background-size: 17px;
  background-position: 97%;
  padding: 7px 10px;
  font-size: 16px;
  line-height: 23px;
  color: #7E7E7E;
`

const CursorBox = styled.div`
 & > button {
   cursor: pointer;
 }
`

function PointManage(props) {

    const {no , num , page} = useParams();
    const [pointInfo , setPointInfo] = React.useState([]);
    const [poinPagetInfo , setPointPageInfo] = React.useState([]);

    const [amount , setAmount] = React.useState('');
    const [toggleChargePointModal , setToggleChargePointModal] = React.useState(false);
    const [toggleDeducePointModal , setToggleDeducePointModal] = React.useState(false);
    const [toggleChargePointConfirmModal , setToggleChargePointConfirmModal] = React.useState(false);
    const [toggleDeducePointConfirmModal , setToggleDeducePointConfirmModal] = React.useState(false);
    const [togglePointConfirmModal , setTogglePointConfirmModal] = React.useState(false);
    const [searchData , setSearchData] = React.useState(null);
    const [searchType , setSearchType] = React.useState(null);
    const [startDate , setStartDate] = React.useState(null);
    const [endDate , setEndeDate] = React.useState(null);

    const [toggleAuthModal , setToggleAuthModal] = React.useState(false);

    const handleToggleAuthModal = () => {
        setToggleAuthModal(!toggleAuthModal);
    }
    const handleToggleChargePointModal = () => {
        setToggleChargePointModal(!toggleChargePointModal);
    }

    const handleToggleDeducePointModal = () => {
        setToggleDeducePointModal(!toggleDeducePointModal);
    }

    const handleToggleChargePointConfirmModal = () => {
        handleToggleChargePointModal();
        setToggleChargePointConfirmModal(!toggleChargePointConfirmModal);
    }

    const handleToggleDeducePointConfirmModal = () => {
        handleToggleDeducePointModal();
        setToggleDeducePointConfirmModal(!toggleDeducePointConfirmModal);
    }

    const handleTogglePointConfirmModal = () => {
        setTogglePointConfirmModal(!togglePointConfirmModal);
    }


    const handleAmount = (e) => {
        setAmount(e.target.value)
    }

    useEffect(() => {
        axios({
            method : 'get',
            url : `${window.Config.G_API_URL}/admin/user/history/point`,
            params : {
                token : cookies.get('token'),
                us_id : no.replace(':' , ''),
                page_num : num.replace(':' , ''),
                search_type : searchType,
                search_data : searchData,
                start_date : startDate,
                end_date : endDate,
            }
        })
            .then(response => {
                if(response.data.result['code'] === '200'){
                    console.log(response);
                    setPointPageInfo(response.data.data.result)
                    setPointInfo(response.data.data.result.list)
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function(error){
                alert(error);
            })
    },[num, searchType , searchData ,startDate , endDate]);


    const onTypeChange = (e) => {
        setSearchType(e.target.value);
    }

    const onSearchDataChange = (e) => {
        setSearchData(e.target.value);
    }

    const onStartDateChange = (e) => {
        setStartDate(e.target.value);
    }

    const onEndDateChange = (e) => {
        setEndeDate(e.target.value);
    }

    const pointCharge = () => {
        handleToggleChargePointModal();
    }

    const pointDeduce = () => {
        handleToggleDeducePointModal();
    }


    const chargePoint = () => {
        axios({
            method : 'post',
            url : `${window.Config.G_API_URL}/admin/user/point/charge`,
            params : {
                token : cookies.get('token'),
                us_id : no.replace(':' , ''),
                point : amount,
            },
            // no : checkList 파라미터 [] 없애는 옵션
            paramsSerializer : function(paramObj) {
                const params = new URLSearchParams()
                for (const key in paramObj) {
                    params.append(key, paramObj[key])
                }
                return params.toString()
            }
        })
            .then(response => {
                if(response.data.result['code'] === '200'){
                    console.log(response);
                    handleTogglePointConfirmModal()
                } else if(response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function(error){
                alert(error);
            })
    }

    const deducePoint = () => {
        axios({
            method : 'post',
            url : `${window.Config.G_API_URL}/admin/user/point/deduct`,
            params : {
                token : cookies.get('token'),
                us_id : no.replace(':' , ''),
                point : amount,
            },
            // no : checkList 파라미터 [] 없애는 옵션
            paramsSerializer : function(paramObj) {
                const params = new URLSearchParams()
                for (const key in paramObj) {
                    params.append(key, paramObj[key])
                }
                return params.toString()
            }
        })
            .then(response => {
                if(response.data.result['code'] === '200'){
                    console.log(response);
                    handleTogglePointConfirmModal()
                } else if(response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function(error){
                alert(error);
            })
    }

    const handleDate = (e) => {
        var date = new Date();
        var currentDate = date.toISOString().substring(0,10);
        setEndeDate(currentDate);
        switch(e.target.id){
            case 'all':
                setStartDate(null);
                break;
            case 'week':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 7));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate(currentDate);
                break;
            case 'halfMonth':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 14));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate(currentDate);
                break;
            case 'month':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 30));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate(currentDate);
                break;
            case 'threeMonth':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 91));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate(currentDate);
                break;
            case 'halfYear':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 182));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate(currentDate);
                break;
            case 'year':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 365));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate(currentDate);
                break;
        }
    }

    return (
        <PointManageWrapper>
            <SearchBox>
                <SelectBox name="searchselect" id="serachselect" onChange={onTypeChange}>
                    <option value={null} hidden>내용분류</option>
                    <option value="포인트 충전">포인트 충전</option>
                    <option value="포인트 결제">포인트 결제</option>
                    <option value="포인트 차감">포인트 차감</option>
                </SelectBox>
                <ColumnBox>
                    <SearchInput type="text" placeholder={'처리자 입력'} onChange={onSearchDataChange}/>
                    <SearchIndexBox
                        startChange={onStartDateChange}
                        endChange={onEndDateChange}
                handleDate={handleDate}
                startDate={startDate}
                endDate={endDate}
                    />
                </ColumnBox>
                <div>
                    <img src={DeleteSearch} alt=""/>
                    <p>초기화</p>
                </div>
            </SearchBox>
            <table>
                <tbody>
                    <tr>
                        <th>No.</th>
                        <th><BorderDiv>내용</BorderDiv></th>
                        <th>변동 포인트</th>
                        <th><BorderDiv>누적 포인트</BorderDiv></th>
                        <th><BorderDivR>변동 일시</BorderDivR></th>
                        <th>처리자</th>
                    </tr>
                    {
                        pointInfo.map((item , key) => (
                            <PoinManageList
                                key={key}
                                rowNum={item.rownum}
                                status={item.status}
                                amount={item.amount}
                                point={item.us_point}
                                date={item.date_time}
                                user={item.in_user}
                            />
                        ))
                    }
                </tbody>
            </table>
            <CursorBox>
                <CursorBox>
                    <button onClick={pointCharge}>포인트 충전</button>
                    <button onClick={pointDeduce}>포인트 차감</button>
                </CursorBox>
                <Pagination
                    no={num.replace(':' , '')}
                    url={`/admin/manageuser/detail/${no}/${page}/pointmanage/:`}
                    pages={poinPagetInfo['pages']}
                    isFirstPage={poinPagetInfo['isFirstPage']}
                    isLastPage={poinPagetInfo['isLastPage']}
                    prePage={poinPagetInfo['prePage']}
                    nextPage={poinPagetInfo['nextPage']}
                    hasPrePage={poinPagetInfo['hasPreviousPage']}
                    hasNextPage={poinPagetInfo['hasNextPage']}
                />
                <div></div>
                {/* <button>쿠폰 발행</button> */}
            </CursorBox>


            <PointChargeModal
                toggle={toggleChargePointModal}
                toggleFunc={handleToggleChargePointModal}
                toggleNext={chargePoint}
                amount={amount}
                setAmount={handleAmount}
                no={no}
            />

            <PointDeduceModal
                toggle={toggleDeducePointModal}
                toggleFunc={handleToggleDeducePointModal}
                toggleNext={deducePoint}
                amount={amount}
                setAmount={handleAmount}
                no={no}
            />

            <PointChangeConfirmModal
                toggle={togglePointConfirmModal}
                toggleFunc={handleTogglePointConfirmModal}
            />
            <AuthCheckModal
                toggle={toggleAuthModal}
                toggleFunc={handleToggleAuthModal}
            />
        </PointManageWrapper>
    );
}

export default PointManage;