import React from 'react';
import {Description, SearchGroupDetailWrapper, Title} from "../css/styles";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import DaumPostcode from 'react-daum-postcode'
import Cookie from "universal-cookie";
import AdvertisementRegisterTable from "../components/AdvertisementRegisterTable";
import AuthCheckModal from '../components/AuthCheckModal';

import EmptyValueModal from '../components/EmptyValueModal';

const ButtonWrapper = styled.div`
  & > button:first-child{
    padding : 5px 20px !important;
  }
  & > button:last-child{
    padding : 5px 20px !important;
    color: white;
    background-color: #DF1F26;
  }
`

function AdvertisementRegister({props}) {

    const cookies = new Cookie();
    // table 용 state
    const [formInfo , setFormInfo] = React.useState({
        title: null,
        startDate : null,
        EndDate : null,
        type : null,
    })
    const [latitude , setLatitude] = React.useState('')
    const [longitude , setLongitude] = React.useState('')
    const [location , setLocation] = React.useState(null);
    const [toggleAuthModal , setToggleAuthModal] = React.useState(false);

    const handleToggleAuthModal = () => {
        setToggleAuthModal(!toggleAuthModal);
    }

    //파일 전송 state
    const [imgBase64, setImgBase64] = React.useState([])

    const [toggleEmptyModal , setToggleEmptyModal] = React.useState(false);

    const handleToggleEmptyModal = () => {
        setToggleEmptyModal(!toggleEmptyModal);
    }

    const navigate = useNavigate()

    const onRegister = () => {
        var formdata = {};
        formdata.ad_addr = location;
        formdata.ad_exposure_expire_date = formInfo.EndDate;
        formdata.ad_exposure_start_date = formInfo.startDate;
        formdata.ad_image_url = imgBase64;
        formdata.ad_lat = latitude;
        formdata.ad_lng = longitude;
        formdata.ad_name = formInfo.title;
        formdata.ad_type = formInfo.type;
        formdata.token = cookies.get('token');

        axios({
            method : 'post',
            url : `${window.Config.G_API_URL}/admin/advertisement`,
            data :  formdata,
        })
            .then(response => {
                if(response.data.result['code'] === '200'){
                    window.location.href = '/admin/advertisement/:1'
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else if(response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                }  else {
                    alert(response.data.result['message']);
                }
            }).catch(function(error){
            handleToggleEmptyModal();
        })
        console.log(imgBase64)
        console.log(formInfo)
        console.log(latitude)
        console.log(longitude)
        console.log(location)
    }

    const tableInputChange = (e) => {
        let {name , value} = e.target;
        switch (name){
            case 'title' :
                setFormInfo({...formInfo , title : value})
                break;
            case 'startDate' :
                setFormInfo({...formInfo , startDate : value})
                break;
            case 'EndDate' :
                setFormInfo({...formInfo , EndDate : value})
                break;
            case 'type' :
                setFormInfo({...formInfo , type : value})
                break;
        }
    }

    return (
        <SearchGroupDetailWrapper>
            <Title>
                광고 등록
            </Title>
            <Description>
                광고 관리 {'>'} 광고 등록
            </Description>
            <AdvertisementRegisterTable
                onTableInputChange={tableInputChange}
                setLocation={setLocation}
                lat={latitude}
                setLat={setLatitude}
                long={longitude}
                setLong={setLongitude}
                imgBase={imgBase64}
                setImgBase={setImgBase64}
            />
            <ButtonWrapper>
                <button onClick={() => navigate(-1)}>취소</button>
                <button onClick={onRegister}>등록</button>
            </ButtonWrapper>

            <EmptyValueModal
                toggle={toggleEmptyModal}
                toggleFunc={handleToggleEmptyModal}
            />
            <AuthCheckModal
                toggle={toggleAuthModal}
                toggleFunc={handleToggleAuthModal}
            />
        </SearchGroupDetailWrapper>
    );
}

export default AdvertisementRegister;