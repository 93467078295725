import React from 'react';
import CheckBox from "./CheckBox";
import styled from "styled-components";

const Tr = styled.tr`
  cursor: pointer;
  & > td:first-child{
    width: 5%;
  }
  & > td:nth-child(2){
    width: 5%;
  }
  & > td:nth-child(3){
    width: 5%;
  }
`

function StationUsageModalList(props) {

    const goToUserDetail = () => {
        if(props.us_name !== null){
            window.location.href = `/admin/manageuser/detail/:${props.us_id}/:1/pointmanage/:1`
        }
    }

    return (
        <Tr onClick={goToUserDetail}>
            <td>{props.rownum}</td>
            <td>{props.company === null ? '-' : props.company}</td>
            <td>{props.us_name === null ? '-' : props.us_name}</td>
            <td>{props.phone}</td>
            <td>{props.coupon_name}</td>
            <td>{props.coupon_number}</td>
            <td>{props.date_time}</td>
            <td>{props.usage_status ==='Y' ? '사용' : '미사용'}</td>
            <td>{props.station_id === null ? '-' : props.station_id}</td>
            <td>{props.station_name === null ? '-' : props.station_name}</td>
            <td>{props.port === null ? '-' : props.port}</td>
            <td>{props.usage_date_time === null ? '-' : props.usage_date_time }</td>
        </Tr>
    );
}

export default StationUsageModalList;