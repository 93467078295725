import axios from 'axios';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import Cookie from 'universal-cookie';
import AuthCheckModal from '../components/AuthCheckModal';

import { useNavigate } from 'react-router-dom';
import DeleteConfirmModal from '../components/DeleteConfirmModal';
import DeleteModal from '../components/DeleteModal';
import {
    BcArrow,
    Description,
    NoticeDetailButtonWrapper,
    NoticeWriteWrapper,
    Title,
    WriteBoardForm,
} from '../css/styles';

import breadcrumbArrow from '../asset/img/breadcrumbarrow.png';

const cookies = new Cookie();

const Div = styled.div`
    position : relative;
`

const Img = styled.img`
    position : absolute;
    
`

function NoticeDetail() {
    let navigate = useNavigate();
    const { no } = useParams();
    const [deleteModalToggle, setDeleteModalToggle] = React.useState(false);
    const [deleteConfirmModalToggle, setDeleteConfirmModalToggle] =
        React.useState(false);
    const [noticeInfo, setNoticeInfo] = React.useState({title:'',content:''});
    const [toggleDeleteModal, setToggleDeleteModal] = React.useState(false);

    const [toggleAuthModal , setToggleAuthModal] = React.useState(false);

    const handleToggleAuthModal = () => {
        setToggleAuthModal(!toggleAuthModal);
    }
    useEffect(() => {
        axios({
            method: 'get',
            url: `${window.Config.G_API_URL}/admin/notice/`,
            params: {
                token: cookies.get('token'),
                no: no.replace(':', ''),
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    console.log(response);
                    setNoticeInfo(response.data.data);
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    }, [no]);

    const handleUpdate = () => {
        window.location.href = `/admin/notice/update/${no}`;
    };

    const handleDeleteModal = () => {
        setToggleDeleteModal(!toggleDeleteModal);
    };

    const handleConfirmDeletModal = () => {
        handleDeleteModal();
        setDeleteConfirmModalToggle(!deleteConfirmModalToggle);
    };

    const onDelete = () => {
        axios({
            method: 'delete',
            url: `${window.Config.G_API_URL}/admin/notice/`,
            params: {
                token: cookies.get('token'),
                no: no.replace(':', ''),
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    console.log(response);

                    handleConfirmDeletModal();
                } else if(response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    };

    return (
        <NoticeWriteWrapper>
            <Title>공지사항</Title>
            <Description>
                게시판 관리 <BcArrow src={breadcrumbArrow} /> 공지사항{' '}
                <BcArrow src={breadcrumbArrow} /> 글 번호 {noticeInfo.no}
            </Description>
            <WriteBoardForm>
                <Div>
                    <input type="text" value={noticeInfo.title && noticeInfo.title.replace(/\%5B/g , '[').replace(/\%5D/g, ']')} style={{fontFamily : 'notosansbold'}}  readOnly />
                </Div>
                <textarea value={noticeInfo.content && noticeInfo.content.replace(/\%5B/g , '[').replace(/\%5D/g, ']')} style={{fontFamily : 'notosans'}} readOnly />
            </WriteBoardForm>
            <NoticeDetailButtonWrapper>
                <button onClick={() => navigate(-1)}>목록</button>
                <div>
                    <button onClick={handleUpdate}>수정</button>
                    <button onClick={handleDeleteModal}>삭제</button>
                </div>
            </NoticeDetailButtonWrapper>


            <DeleteModal
                content={'공지사항을'}
                toggle={toggleDeleteModal}
                toggleFunc={handleDeleteModal}
                deleteFunc={onDelete}
            />

            <DeleteConfirmModal
                toggle={deleteConfirmModalToggle}
                location={'/admin/notice/:1'}
            />
            <AuthCheckModal
                toggle={toggleAuthModal}
                toggleFunc={handleToggleAuthModal}
            />
        </NoticeWriteWrapper>
    );
}

export default NoticeDetail;
