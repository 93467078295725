import React, { useEffect } from 'react';
import CheckBox from "./CheckBox";
import styled from "styled-components";


const Tr = styled.tr`
  font-family : notosansmedium;
  &:hover{
    background-color: #f6f6f6;
  }
  & > td:nth-child(3) {
    padding : 0 !important;
    text-align : center !important;
  }
`

function SearchGroupDetailInnerTable(info) {

    const goToStationDetail = () => {
        window.location.href = `/`
    }

    useEffect(()=>(
      console.log(info)
    ))

    return (
        <Tr>
            <td>{info.info.rownum}</td>
            <td onClick={goToStationDetail} style={{cursor : 'pointer'}}>{info.info.station_name}</td>
            <td>{info.info.port}</td>
            <td>{info.info.station_id}</td>
            <td>{info.info.start_time}</td>
            <td>{info.info.end_time}</td>
            <td>{info.info.charging}</td>
            <td>{info.info.amount}</td>
            <td>{info.info.kickboard_number}</td>
        </Tr>
    );
}

export default SearchGroupDetailInnerTable;
