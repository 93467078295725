import React, { useEffect } from 'react';
import {
    SearchGroupDetailWrapper,
    Title,
    Description,
    BcArrow,
    BorderDiv,
} from '../css/styles';
import styled from 'styled-components';
import axios from 'axios';
import Cookie from 'universal-cookie';
import { useParams } from 'react-router';
import Pagination from '../components/Pagination';
import { useNavigate } from 'react-router-dom';
import breadcrumbArrow from '../asset/img/breadcrumbarrow.png';
import DeleteInDetailModal from '../components/DeleteInDetailModal';
import CompanyDetailTable from '../components/CompanyDetailTable';
import CompanyDetailInnerTable from '../components/CompanyDetailInnerTable';

const cookies = new Cookie();

const Wrapper = styled(SearchGroupDetailWrapper)`
    & > div:last-child{
        justify-content : center;
    }
`

const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    & > p {
        font-family : notosansbold;
        font-size: 32px;
        line-height: 46px;
    }
    & > a {
        font-size: 16px;
        line-height: 20px;
        border: 1px solid #c4c4c4;
        border-radius: 10px;
        color: #df1f26;
        padding: 7px 10px !important;
    }
`;

const InnerTable = styled.div`
    & > table {
        width: 100%;
        text-align: center;
        & > thead {
            & > tr {
                font-family : notosansbold;
                line-height: 20px;
                border-bottom: 2px solid #e5e5e5;
                & > th:first-child > div > label {
                    cursor: pointer;
                }
                & > th:nth-child(3) {
                    text-align: left;
                    padding-left: 30px;
                }
            }
        }
        & > tbody {
            & > tr {
                line-height: 40px;
                border-bottom: 1px solid #e5e5e5;
                & > td:nth-child(3) {
                    text-align: left;
                    padding-left: 30px;
                }
            }
            & > tr > td:first-child > div > label {
                cursor: pointer;
            }
        }
    }
`;

const ButtonWrapper = styled.div`
    & > a {
        font-size: 16px;
        line-height: 20px;
        border: 1px solid #c4c4c4;
        border-radius: 10px;
        padding: 7px 10px !important;
        margin-left : 20px;
        cursor : pointer;
    }
    & > a:last-child {
    color: #df1f26;
}
`

function SearchGroupDetail(props) {
    const { num , name} = useParams();

    const [companyInfo, setCompanyInfo] = React.useState([]);
    const [toggleManagerModal, setToggleManagerModal] = React.useState(false);
    const [toggleDeleteModal, setToggleDeleteModal] = React.useState(false);
    const [toggleDeleteConfirmModal, setToggleDeleteConfirmModal] = React.useState(false);
    const [toggleDeviceRegModal, setToggleDeviceRegModal] = React.useState(false);
    const [deleteDetailModalToggle, setDeleteDetailModalToggle] = React.useState(false);
    const [stationInfo, setStationInfo] = React.useState([]);
    const [stationListInfo, setStationListInfo] = React.useState([]);
    const [checked, setChecked] = React.useState(false);
    const [checkAll, setCheckAll] = React.useState(false);
    const [checkList, setCheckList] = React.useState([]);

    const [pageInfo , setPageInfo] = React.useState([]);
    const [usageInfo , setUsageInfo] = React.useState([]);

    useEffect(() => {
        setCheckList([]);

        axios({
            method: 'get',
            url: `${window.Config.G_API_URL}/company/detail`,
            params: {
                token: cookies.get('token'),
                company: name.replace(':', ''),
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    setCompanyInfo(response.data.data);
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    }, []);

    useEffect(() => {
        axios({
            method: 'get',
            url: `${window.Config.G_API_URL}/company/detail/usage`,
            params: {
                token: cookies.get('token'),
                company: name.replace(':', ''),
                page_num : num.replace(':', ''),
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    setPageInfo(response.data.data.result);
                    setUsageInfo(response.data.data.result.list)
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    }, [num]);

    useEffect(() => {
        if (checkAll) {
            let arr = [];
            stationInfo.map((item) => {
                arr.push(`${item.station_id}`);
            });
            setCheckList(arr);
        } else if (checkList.length == stationInfo.length) {
            setCheckList([]);
        }
    }, [checkAll]);

    useEffect(() => {
        if (checkList.length == stationInfo.length) {
            setCheckAll(true);
        } else {
            setCheckAll(false);
        }
    }, [checkList, stationInfo]);

    const handleToggleDeleteModal = () => {
        setToggleDeleteModal(!toggleDeleteModal);
    };

    const handleToggleDeleteConfirmModal = () => {
        setToggleDeleteConfirmModal(!toggleDeleteConfirmModal);
    };

    const handleDetailDeleteModal = () => {
        setDeleteDetailModalToggle(!deleteDetailModalToggle);
    };

    const handleCheckEach = (e) => {
        const { id, checked } = e.target;
        let arr = [...checkList];
        if (arr.includes(id)) {
            for (let i = 0; i < arr.length; i++) {
                console.log(arr[i]);
                console.log(id);
                if (arr[i] == id) {
                    arr.splice(i, 1);
                    setChecked(!checked);
                    break;
                }
            }
            setCheckList(arr);
        } else {
            setChecked(!checked);
            setCheckList([...checkList, id]);
        }
    };

    const deleteFromGroup = () => {
        axios({
            method: 'put',
            url: `${window.Config.G_API_URL}/admin/station/group/stations`,
            params: {
                token: cookies.get('token'),
                station_id: checkList,
            },
            // no : checkList 파라미터 [] 없애는 옵션
            paramsSerializer: function (paramObj) {
                const params = new URLSearchParams();
                for (const key in paramObj) {
                    params.append(key, paramObj[key]);
                }

                return params.toString();
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    handleToggleDeleteModal();
                    handleToggleDeleteConfirmModal();
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    };

    const navigate = useNavigate();

    return (
        <Wrapper>
            <Title>업체 상세</Title>
            <Description>
                정산 관리 <BcArrow src={breadcrumbArrow} /> 업체 상세
            </Description>
            <TitleWrapper>
                <p>{companyInfo.company}</p>
                <ButtonWrapper>
                    <a onClick={handleDetailDeleteModal}>업체 삭제</a>
                    <a href={`/`}>정보 수정</a>
                </ButtonWrapper>
            </TitleWrapper>
            <CompanyDetailTable
                date={companyInfo.date_time}
                address={companyInfo.company_address}
                companyNum={companyInfo.company_number}
                phone={companyInfo.company_phone}
                settleDate={companyInfo.settlement_date}
                adminName={companyInfo.company_admin}
                calState={companyInfo.calculate_status}
                color={companyInfo.company_color}
            />
            <div>
                <button onClick={() => navigate(-1)}>목록</button>
            </div>
            <Title style={{paddingTop : '30px'}}>사용 기록</Title>
            <InnerTable>
                <table>
                    <thead>
                        <tr>
                            <th>
                                No.
                            </th>
                            <th>
                                <BorderDiv>스테이션 이름</BorderDiv>
                            </th>
                            <th>포트 번호</th>
                            <th>
                                <BorderDiv>스테이션ID</BorderDiv>
                            </th>
                            <th>사용 시작 일시</th>
                            <th>
                                <BorderDiv>사용 종료 일시</BorderDiv>
                            </th>
                            <th>충전 시간 (분)</th>
                            <th><BorderDiv>요금 (원)</BorderDiv></th>
                            <th>
                                킥보드 ID
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            usageInfo && usageInfo.map((item)=>(
                                <CompanyDetailInnerTable
                                    info={item}
                                />
                            ))
                        }
                    </tbody>
                </table>
            </InnerTable>
            <div>
                <Pagination
                    no={num.replace(':', '')}
                    url={`/admin/company/detail/${name}/:`}
                    pages={pageInfo['pages']}
                    isFirstPage={pageInfo['isFirstPage']}
                    isLastPage={pageInfo['isLastPage']}
                    prePage={pageInfo['prePage']}
                    nextPage={pageInfo['nextPage']}
                    hasPrePage={pageInfo['hasPreviousPage']}
                    hasNextPage={pageInfo['hasNextPage']}
                />
            </div>

        </Wrapper>
    );
}

export default SearchGroupDetail;
