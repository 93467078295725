import axios from "axios";
import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { useParams } from "react-router";
import styled from 'styled-components';
import Cookie from "universal-cookie";
import AuthCheckModal from '../components/AuthCheckModal';
import { BorderDiv, BorderDivR } from "../css/styles";
import VersionModalList from "./VersionModalList";

const cookies = new Cookie();

const Title = styled.h1`
    font-weight: bold;
    font-size: 24px;
    line-height: 35px;
    padding-bottom : 7%;
    color: #221E1F;
`

const ButtonContainer = styled.div`
    display : flex;
    justify-content : flex-end;
  padding-top: 27px;
`

const Button = styled.button`
  background-color: white;
  border-radius: 10px;
  border: 1px solid #C4C4C4;
  width : 11%;
  padding : 10px 0 ;
  font-weight: bold;
  font-size: 14px;
  color: #221E1F;
`

const Table = styled.table`
  width: 100%;
  line-height: 40px;
  text-align: center;
  & > thead > tr{
    border-bottom: 2px solid #e5e5e5;
    line-height: 20px;
    & > th:first-child > div > label {
        cursor: pointer;
    }
    & > th:nth-child(3) {
        padding-left: 10px;
    }
  }
  & > tbody > tr{
    border-bottom: 1px solid #e5e5e5;
    & > td:first-child > div > label {
        cursor: pointer;
    }
    & > td:nth-child(3) {
        padding-left: 10px;
    }
  }
`

const ScrollDiv = styled.div`
  height: 65%;
  overflow-y: scroll;
`

function VersionModal(props) {

    const {no} = useParams();
    const [versionInfo , setVersionInfo] = React.useState([]);

    const [toggleAuthModal , setToggleAuthModal] = React.useState(false);

    const handleToggleAuthModal = () => {
        setToggleAuthModal(!toggleAuthModal);
    }

    useEffect(()=>{
        if(props.type === 'android'){
            axios({
                method : 'get',
                url : `${window.Config.G_API_URL}/admin/app-version/history/aos`,
                params : {
                    token : cookies.get('token'),
                }
            })
            .then(response => {
                if(response.data.result['code'] === '200'){
                    setVersionInfo(response.data.data)
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                }else if(response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function(error){
                alert(error);
            })
        } else {
            axios({
                method : 'get',
                url : `${window.Config.G_API_URL}/admin/app-version/history/ios`,
                params : {
                    token : cookies.get('token'),
                }
            })
            .then(response => {
                if(response.data.result['code'] === '200'){
                    setVersionInfo(response.data.data)
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                }else if(response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function(error){
                alert(error);
            })
        }

    },[])

    return (
        <Modal isOpen={props.toggle} onRequestClose={props.toggleFunc}
               ariaHideApp={false}
               style={{
                   overlay :{
                       backgroundColor : 'rgba(34, 30, 31, 0.5)'
                   },
                   content : {
                       height : '53%',
                       left : '25%',
                       right : '25%',
                       top : '20%',
                       borderRadius : '10px',
                   }
               }}
        >
            <Title>{props.type === 'android' ? '안드로이드' : 'IOS'} 버전 수정 기록</Title>
            <ScrollDiv>
                <Table>
                    <thead>
                    <tr>
                        <th>No.</th>
                        <th><BorderDiv>버전</BorderDiv></th>
                        <th><BorderDivR>일시</BorderDivR></th>
                    </tr>
                    </thead>
                    <tbody>
                        {versionInfo.map((item,key) => (
                            <VersionModalList
                                key={key}
                                rownum={item.rownum}
                                version={item.app_version}
                                date={item.date_time}
                            />
                        ))}
                    </tbody>
                </Table>
            </ScrollDiv>
            <ButtonContainer>
                <Button onClick={props.toggleFunc}>닫기</Button>
            </ButtonContainer>

            <AuthCheckModal
                toggle={toggleAuthModal}
                toggleFunc={handleToggleAuthModal}
            />
        </Modal>
    );
}

export default VersionModal;
