import React from 'react';
import Warning from "../asset/img/warning.png";

function TroubleInfo(props) {
    return (
        <span>
            <img src={Warning} alt=""/>
            <p>{props.content}</p>
        </span>
    );
}

export default TroubleInfo;