import React from 'react';
import {NavLink, useLocation, useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import { useEffect } from 'react';

const StyledLinkWrapper = styled.div`
  display: flex;
  width: 100%;
  & > div {
    width: 100%;
    border-bottom: 2px solid #E5E5E5;
    padding : 10px 0;
  }
  & > .active{
    font-weight: bold;
    background-color: white;
    border-bottom: none;
  }
`

const StyledLink = styled(NavLink)`
  width: 100%;
  text-align: center;
  padding : 10px 0;
  border : 2px solid #E5E5E5;
  margin-right: -2px;
  font-size: 16px;
  line-height: 23px;
  background: #F7F7F7;
`

window.addEventListener('popstate', function(event) {
  window.location.replace(`/admin/manageuser/:1`);
});


function IndexLink(props) {
  // useEffect(() => {
  //   const preventGoBack = () => {
  //     // change start
  //     window.history.pushState(null, '', `/admin/manageuser/:1`);
  //     // change end
  //     console.log('prevent go back!');
  //   };
    
  //   window.history.pushState(null, '', `/admin/manageuser/:1`);
  //   window.addEventListener('popstate', preventGoBack);
    
  //   return () => window.removeEventListener('popstate', preventGoBack);
  // }, []);

  return (
      <StyledLinkWrapper>
          <StyledLink to={`/admin/manageuser/detail/:${props.no}/:${props.page}/pointmanage/:1`}>포인트 관리</StyledLink>
          <StyledLink to={`/admin/manageuser/detail/:${props.no}/:${props.page}/stationusage/:1`}>스테이션 이용 내역</StyledLink>
          <StyledLink to={`/admin/manageuser/detail/:${props.no}/:${props.page}/notificationmanage/:1`}>알림톡 발송 관리</StyledLink>
          <StyledLink to={`/admin/manageuser/detail/:${props.no}/:${props.page}/managemessage/:1`}>문자 발송 관리</StyledLink>
          <StyledLink to={`/admin/manageuser/detail/:${props.no}/:${props.page}/loginlog/:1`}>로그인 내역</StyledLink>
          <StyledLink to={`/admin/manageuser/detail/:${props.no}/:${props.page}/managermemo/:1`}>관리자 메모</StyledLink>
          <StyledLink to={`/admin/manageuser/detail/:${props.no}/:${props.page}/inqury/:1`}>문의 내역</StyledLink>
          <StyledLink to={`/admin/manageuser/detail/:${props.no}/:${props.page}/report/:1`}>신고 내역</StyledLink>
          <StyledLink to={`/admin/manageuser/detail/:${props.no}/:${props.page}/couponusage/:1`}>쿠폰 이용 내역</StyledLink>
      </StyledLinkWrapper>
  );
}

export default IndexLink;