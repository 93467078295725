import React, { useEffect } from 'react';
import axios from 'axios';
import Cookie from 'universal-cookie';
import AuthCheckModal from '../components/AuthCheckModal';

import {
    NoticeWriteWrapper,
    PinButton,
    WriteBoardForm,
    NoticeWriteButtonWrapper,
    Title,
    Description,
    BcArrow,
} from '../css/styles';
import CancelModal from '../components/CancelModal';

import Pin from '../asset/img/pin.png';
import WhitePin from '../asset/img/whitepin.png';
import breadcrumbArrow from '../asset/img/breadcrumbarrow.png';

import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

const cookies = new Cookie();

function NoticeUpdate() {
    let navigate = useNavigate();
    const { no } = useParams();
    const [title, setTitle] = React.useState('');
    const [content, setContent] = React.useState('');
    const [isCheck, setIsCheck] = React.useState(false);
    const [exposure, setExposure] = React.useState('N');
    const [cancelModalToggle, setCancelModalToggle] = React.useState(false);
    const [toggleAuthModal , setToggleAuthModal] = React.useState(false);

    const handleToggleAuthModal = () => {
        setToggleAuthModal(!toggleAuthModal);
    }

    useEffect(() => {
        axios({
            method: 'get',
            url: `${window.Config.G_API_URL}/admin/notice/`,
            params: {
                token: cookies.get('token'),
                no: no.replace(':', ''),
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    console.log(response);
                    setTitle(response.data.data.title);
                    setContent(response.data.data.content);
                    if (response.data.data.exposure === 'Y') {
                        setIsCheck(!isCheck);
                        setExposure('Y');
                    }
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    }, [no]);

    const onSubmit = () => {
        axios({
            method: 'put',
            url: `${window.Config.G_API_URL}/admin/notice`,
            params: {
                token: cookies.get('token'),
                title: title.replace(/\[/g, '%5B').replace(/]/g, '%5D'),
                content: content.replace(/\[/g, '%5B').replace(/]/g, '%5D'),
                no: no.replace(':', ''),
                exposure: exposure,
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    console.log(response);
                    window.location.href = '/admin/notice/:1';
                } else if(response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    };

    const handleCancelModal = () => {
        setCancelModalToggle(!cancelModalToggle);
    };

    const handleTitle = (e) => {
        setTitle(e.target.value);
    };

    const handleContent = (e) => {
        setContent(e.target.value);
    };

    const handleExposure = (e) => {
        setIsCheck(!isCheck);
        if (isCheck) {
            setExposure('N');
        } else {
            setExposure('Y');
        }
    };

    return (
        <NoticeWriteWrapper>
            <Title>공지사항 수정</Title>
            <Description>
                게시판 관리 공지사항 <BcArrow src={breadcrumbArrow} /> 글번호{' '}
                {no.replace(':', '')} 수정
            </Description>
            <WriteBoardForm>
                <div>
                    <input
                        type="text"
                        placeholder="제목을 입력하세요"
                        value={title.replace(/\%5B/g , '[').replace(/\%5D/g, ']')}
                        onChange={handleTitle}
                    />
                </div>
                <textarea value={content.replace(/\%5B/g , '[').replace(/\%5D/g, ']')} onChange={handleContent} />
            </WriteBoardForm>
            <NoticeWriteButtonWrapper>
                <button onClick={handleCancelModal}>취소</button>
                <div>
                    <PinButton state={isCheck} htmlFor="pin">
                        <input
                            id="pin"
                            type="checkbox"
                            checked={isCheck}
                            onChange={handleExposure}
                        />
                        상단 노출
                        <img src={isCheck ? WhitePin : Pin} alt="" />
                    </PinButton>
                    <button onClick={onSubmit}>등록</button>
                </div>
            </NoticeWriteButtonWrapper>

            <CancelModal
                toggle={cancelModalToggle}
                toggleFunc={handleCancelModal}
            />
            <AuthCheckModal
                toggle={toggleAuthModal}
                toggleFunc={handleToggleAuthModal}
            />
        </NoticeWriteWrapper>
    );
}

export default NoticeUpdate;
