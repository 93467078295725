import axios from "axios";
import React, { useEffect } from 'react';
import { useParams } from "react-router";
import styled from "styled-components";
import Cookie from "universal-cookie";
import AuthCheckModal from '../components/AuthCheckModal';
import SelectUserModal from '../components/SelectUserModal';
import { BorderDiv } from '../css/styles';
import CheckBox from "./CheckBox";
import DeleteUserModal from "./DeleteUserModal";
import PaginationAdmin from "./PaginationAdmin";
import SuperAdminTableList from "./SuperAdminTableList";

const SuperAdminContent = styled.div`
    transform : ${props => props.toggle ? 'scaleY(1)' : 'scaleY(0)'};
    transform-origin : 0 0 0;
    max-height : ${props => props.toggle ? `500px` : '0px'};
    transition :  transform .5s, padding .5s , max-height .5s;
    display: flex;
  flex-direction: column;
  cursor: default !important;
`

const cookies = new Cookie();

const Table = styled.table`
      width: 100%;
      line-height: 40px;
      text-align: center;
      & > tbody{
        & > tr:first-child {
          line-height: 20px;
          border-bottom: 2px solid #E5E5E5;
          & > th > div > label {
            cursor: pointer;
          }
        }
        & > tr {
          border-bottom: 1px solid #E5E5E5;
        }
        & > tr > td:first-child {
          width: 5%;
        }
        & > tr > td:nth-child(2) {
          width: 5%;
        }
        & > tr > td:nth-child(3) {
          width: 15%;
        }
        & > tr > td:nth-child(4) {
          width: 20%;
        }
      }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center !important;
  position: relative;
  width: 100% !important;
  padding : 2% 0 1% 0;
  & > button {
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    border-radius: 10px;
    padding : 5px 10px;
    position: absolute;
    left: 0;
  }
`

function CsAdminTable(props) {

    const {superadmin , chief , cs ,cscenter , partner} = useParams();

    const [superAdminList , setSuperAdminList] = React.useState([]);
    const [superAdminResult , setSuperAdminResult] = React.useState([]);
    //station_id : no.replace(':' , '')
    const [toggleDeleteModal , setToggleDeleteModal] = React.useState(false);
    const [toggleEmptyModal, setToggleEmptyModal] = React.useState(false);

    const [checked , setChecked] = React.useState(false);
    const [checkAll , setCheckAll] = React.useState(false);
    const [checkList , setCheckList] = React.useState([]);

    const [toggleAuthModal , setToggleAuthModal] = React.useState(false);

    const handleToggleAuthModal = () => {
        setToggleAuthModal(!toggleAuthModal);
    }

    useEffect(() => {
        setCheckList([]);
        axios({
            method : 'get',
            url : `${window.Config.G_API_URL}/admin`,
            params : {
                token : cookies.get('token'),
                admin_level : '2',
                page_num : cs.replace(':' , ''),
                search_type: props.searchType === '전체' ? null : props.searchType,
                search_data: props.searchData,
                start_date: props.startDate,
                end_date: props.endDate,
            }
        })
            .then(response => {
                if(response.data.result['code'] === '200'){
                    setSuperAdminList(response.data.data.result.list);
                    setSuperAdminResult(response.data.data.result);
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                }else if(response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function(error){
                alert(error);
            })
    },[cs , props.searchType , props.searchData , props.startDate , props.endDate])

    useEffect(()=>{
        if(checkAll){
            let arr = [];
            superAdminList.map((item)=>{
                    arr.push(`${item.admin_id}`);
            });
            setCheckList(arr);
        } else if(checkList.length==superAdminList.length) {
            setCheckList([]);
        }
    },[checkAll]);

    useEffect(()=>{
        if(checkList.length == superAdminList.length){
            setCheckAll(true);
        } else {
            setCheckAll(false);
        }
        console.log(checkList);
    },[checkList,superAdminList]);

    const handleCheckAll = (e) => {
        setCheckAll(!checkAll);
    }

    const handleCheckEach = (e) => {
        const { id , checked} = e.target;
        let arr = [...checkList];
        if (arr.includes(id)) {
            for (let i = 0; i < arr.length; i++) {
                if (arr[i] == id) {
                    arr.splice(i, 1);
                    setChecked(!checked);
                    break;
                }
            }
            setCheckList(arr);
        } else {
            setChecked(!checked);
            setCheckList([...checkList, id]);
        }
    }

    const handleDeleteModal = () => {
        if(checkList.length==0){
            setToggleEmptyModal(!toggleEmptyModal);
            return;
        }
        setToggleDeleteModal(!toggleDeleteModal)
    }

    const handleToggleEmptyModal = () => {
        setToggleEmptyModal(!toggleEmptyModal);
    }

    const onDelete = () => {
        axios({
            method : 'delete',
            url : `${window.Config.G_API_URL}/admin`,
            params : {
                token : cookies.get('token'),
                admin_id : checkList,
            },
            paramsSerializer : function(paramObj) {
                const params = new URLSearchParams()
                for (const key in paramObj) {
                    params.append(key, paramObj[key])
                }

                return params.toString()
            }
        })
            .then(response => {
                if(response.data.result['code'] === '200'){
                    window.location.reload();
                }else if(response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function(error){
                alert(error);
            })
    }

    return (
        <SuperAdminContent toggle={props.toggle}>
            <Table>
                <tbody>
                <tr>
                    <th><CheckBox no="checkAll_cs" checked={checkAll} onChange={handleCheckAll}/></th>
                    <th>No.</th>
                    <th><BorderDiv>이름</BorderDiv></th>
                    <th>관리자 아이디</th>
                    <th><BorderDiv>연락처</BorderDiv></th>
                    <th>등록일시</th>
                </tr>
                {
                    superAdminList.map((item,index) => (
                        <SuperAdminTableList
                            key={index}
                            adminNo={item.admin_no}
                            adminName={item.admin_name}
                            adminId={item.admin_id}
                            adminPhone={item.admin_phone}
                            date={item.date_time}
                            checked={checkList.includes(`${item.admin_id}`)}
                            onChange={handleCheckEach}
                        />
                    ))
                }
                </tbody>
            </Table>
            <ButtonContainer>
                <button onClick={handleDeleteModal}>선택 삭제</button>
                <PaginationAdmin
                    type={'cs'}
                    no={superadmin.replace(':' , '')}
                    url='/admin/manageadminuser/'
                    pages={superAdminResult['pages']}
                    isFirstPage={superAdminResult['isFirstPage']}
                    isLastPage={superAdminResult['isLastPage']}
                    prePage={superAdminResult['prePage']}
                    nextPage={superAdminResult['nextPage']}
                    hasPrePage={superAdminResult['hasPreviousPage']}
                    hasNextPage={superAdminResult['hasNextPage']}
                />
                <DeleteUserModal
                    toggle={toggleDeleteModal}
                    toggleFunc={handleDeleteModal}
                    deleteFunc={onDelete}
                    content={'관리자를 삭제하시겠습니까?'}
                />
            </ButtonContainer>
            <SelectUserModal
                toggle={toggleEmptyModal}
                toggleFunc={handleToggleEmptyModal}
                content={'관리자 회원을'}
            />
            <AuthCheckModal
                toggle={toggleAuthModal}
                toggleFunc={handleToggleAuthModal}
            />
        </SuperAdminContent>
    );
}

export default CsAdminTable;