import React, {useEffect} from 'react';
import styled from "styled-components";
import SelectArrow from "../asset/img/selectarrow.png";
import Cookie from "universal-cookie";
import axios from 'axios';
const cookies = new Cookie();

const Table = styled.table`
  & > tbody > tr > td:first-child{
    width: 17% !important;
  }
  & > tbody > tr:last-child{
    border-bottom: 2px solid #E5E5E5 !important;
  }
`

const BigInput = styled.input`
  border: 1px solid #C4C4C4;
  width: 25%;
  padding: 7px;
  border-radius: 10px;
`

const Select = styled.select`
  cursor: pointer;
  appearance:none;
  background-color: white;
  border: 1px solid #C4C4C4;
  border-radius: 10px;
  padding : 4px;
  width: 15%;
  font-size: 16px;
  line-height: 23px;
  background-image: url(${SelectArrow});
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: 90%;
  &:focus{
    outline: none;
  }
`

const Span = styled.span`
    font-size: 12px;
`

function ManageAdminRegisterPartnerAdminTable(props) {
    const [companyList, setCompanyList] = React.useState([]);

    useEffect(() => {
      console.log(cookies.get('token'));
      axios({
        method: 'get',
        url: `${window.Config.G_API_URL}/admin/company/list/`,
        params: {
          token: cookies.get('token'),
        },
      }).then((response) => {
        console.log(response.data.data);
        setCompanyList(response.data.data);
      }).catch(function (error) {
        alert(error);
      });
    },[]);

    useEffect(() => {
      console.log("companyList" ,companyList)
    },[companyList])

    return (
        <Table>
            <tbody>
            <tr>
                <td>이름</td>
                <td colSpan='2'><BigInput type='text' name='name' onChange={props.onTableInputChange} placeholder="이름을 입력해주세요."/></td>
            </tr>
            <tr>
                <td>아이디</td>
                <td colSpan='2'><BigInput type='text' name='adminId' onChange={props.onTableInputChange} placeholder="아이디를 입력해주세요."/></td>
            </tr>
            <tr>
                <td>연락처</td>
                <td colSpan='2'><BigInput type='text' name='phoneNum' onChange={props.onTableInputChange} placeholder="연락처를 입력해주세요."/></td>
            </tr>
            <tr>
                <td>비밀번호</td>
                <td colSpan='2'><BigInput type='text' name='password' onChange={props.onTableInputChange} placeholder="비밀번호를 입력해주세요."/></td>
            </tr>
            <tr>
                <td>관리자 등급</td>
                <td>
                    업체 관리자
                </td>
            </tr>
            <tr>
                <td>업체명</td>
                <td>
                    <Select name="company" id="company" onChange={props.onTableInputChange}>
                        <option value="all" hidden>선택</option>
                        {companyList.map((item) => (
                          <option value={`${item}`}>{item}</option>
                        ))}
                    </Select>
                </td>
            </tr>
            </tbody>
        </Table>
    )
}

export default ManageAdminRegisterPartnerAdminTable;