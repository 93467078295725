import React, { useEffect } from 'react';
import {
    SearchGroupDetailWrapper,
    Title,
    Description,
    BcArrow,
} from '../css/styles';
import styled from 'styled-components';
import axios from 'axios';
import Cookie from 'universal-cookie';
import { useParams } from 'react-router';
import Warning from '../asset/img/warning.png';
import breadcrumbArrow from '../asset/img/breadcrumbarrow.png';

import ControllDeviceModal from '../components/ControllDeviceModal';
import PortDetailTable from '../components/PortDetailTable';
import TroubleInfo from '../components/TroubleInfo';
import PortControllModal from '../components/PortControllModal';
import { useNavigate } from 'react-router-dom';
import PortNotiModal from '../components/PortNotiModal';
import ChangeStateModal from '../components/ChangeStateModal';

const cookies = new Cookie();

const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    & > div {
        display: flex;
        align-items: center;
        column-gap: 10px;
    }
    & > div:first-child {
        & > h1 {
            font-weight: bold;
            font-size: 28px;
            line-height: 46px;
        }
    }
    & > div:last-child {
        & > button:last-child{
            border : none;
            background-color :${props => props.connect === 'T' ? '#df1f26' : '#79BD42'} ;
            color : white;
        }
    }
`;

const Unuse = styled.span`
    background: #c4c4c4;
    border-radius: 36px;
    color: white;
    padding: 2px 5px 4px 5px;
`;

const Lock = styled.span`
    background-color: ${(props) =>
        props.state === '0' ? '#c4c4c4' : '#DF1F26'};
    color: white;
    border-radius: 86px;
  padding : 1px 10px 2px 10px;
`;

const Port = styled.span`
    background: #df1f26;
  font-size: 15px;
    border-radius: 25px;
    color: white;
  padding : 1px 10px 2px 10px;
`;

const Connect = styled.span`
  background-color: ${props => props.state === 'T' ? '#79BD42' : '#c4c4c4'};
  color: white;
  border-radius: 86px;
  padding : 1px 10px 2px 10px;
  font-size: 16px;
`

const Charging = styled.span`
    background: #df1f26;
    border-radius: 36px;
    color: white;
  padding : 1px 10px 2px 10px;
`;

const Holding = styled.span`
    background: #79bd42;
    border-radius: 36px;
    color: white;
  padding : 1px 10px 2px 10px;
`;

const Reserving = styled.span`
    background: #fbbc05;
    border-radius: 36px;
    color: white;
  padding : 1px 10px 2px 10px;
`;

const StandBy = styled.span`
    background: #c4c4c4;
    border-radius: 36px;
  padding : 1px 10px 2px 10px;
`;

const InfoWrapper = styled.div`
    display: flex;
    justify-content: flex-start !important;
    column-gap: 10px;
  margin-top: 10px !important;
    & > span {
        display: flex;
        align-items: center;
        border: 1px solid #df1f26;
        border-radius: 36px;
        column-gap: 3px;
        padding: 0px 6px 0px 1px;
        & > img {
            width: 16px;
        }
    }
`;

function PortDetail(props) {
    const { no, num } = useParams();
    const [portInfo, setPortInfo] = React.useState([]);
    const [toggleModal, setToggleModal] = React.useState(false);

    const [toggleStationModal , setToggleStationModal] = React.useState(false);
    const [toggleChargingModal , setToggleChargingModal] = React.useState(false);
    const [toggleConfirmModal , setToggleConfirmModal] = React.useState(false);

    const [toggleChargingConfirmModal , setToggleChargingConfirmModal] = React.useState(false);
    const [toggleStationConfirmModal , setToggleStationConfirmModal] = React.useState(false);

    const handleChargingConfirmModal = () => {
        setToggleChargingConfirmModal(!toggleChargingConfirmModal);
    }

    const handleStationConfirmModal = () => {
        setToggleStationConfirmModal(!toggleStationConfirmModal);
    }

    const handleConfirmModal = () => {
        setToggleConfirmModal(!toggleConfirmModal);
    }
    const handleStationModal = () => {
        setToggleStationModal(!toggleStationModal);
    }

    const handleChargingModal = () => {
        setToggleChargingModal(!toggleChargingModal);
    }

    useEffect(() => {
        axios({
            method: 'get',
            url: `${window.Config.G_API_URL}/admin/station/port`,
            params: {
                token: cookies.get('token'),
                station_id: no.replace(':', ''),
                port: num.replace(':', ''),
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    setPortInfo(response.data.data);
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    }, []);

    const handleToggleModal = () => {
        setToggleModal(!toggleModal);
    };

    const usage = (usage) => {
        switch (usage) {
            case '미사용':
                return <Unuse>미사용</Unuse>;
            case '충전 중':
                return <Charging>충전 중</Charging>;
            case '거치 중':
                return <Holding>거치 중</Holding>;
            case '예약 중':
                return <Reserving>예약 중</Reserving>;
            case '서비스 준비 중':
                return <StandBy>서비스 준비 중</StandBy>;
            default:
                return;
        }
    };

    const batteryState = (status) => {
        if (status === '1') {
            return <TroubleInfo content={'배터리 단선'} />;
        } else if (status === '2') {
            return <TroubleInfo content={'배터리 단락'} />;
        } else if (status === '3') {
            return <TroubleInfo content={'배터리 퓨즈 단선 등'} />;
        }
    };

    const highTemp = (status) => {
        if (status === '1') {
            return <TroubleInfo content={'고온'} />;
        }
    };
    const overVoltage = (status) => {
        if (status === '1') {
            return <TroubleInfo content={'과전압'} />;
        }
    };
    const overCurrent = (status) => {
        if (status === '1') {
            return <TroubleInfo content={'과전류'} />;
        }
    };
    const voltageError = (status) => {
        if (status === '1') {
            return <TroubleInfo content={'전압 선택 에러'} />;
        }
    };
    const batteryError = (status) => {
        if (status === '1') {
            return <TroubleInfo content={'비분류 배터리'} />;
        }
    };
    const fanState = (status) => {
        if (status === '1') {
            return <TroubleInfo content={'FAN 상태 확인'} />;
        }
    };

    const navigate = useNavigate();

    const setGeochi = () => {
        handleChargingConfirmModal();
        axios({
            method : 'get',
            url : `${window.Config.G_API_URL}/admin/station/holder`,
            params : {
                token : cookies.get('token'),
                station_id : no.replace(':' , ''),
                port : num.replace(':' , ''),
            }
        })
            .then(response => {
                if(response.data.result['code'] === '200'){
                    handleConfirmModal();
                }else{
                    alert(response.data.result['message']);
                }
            })
            .catch(function(error){
                alert(error);
            })
    }

    const stopPort = () => {
        handleStationConfirmModal();
        axios({
            method : 'put',
            url : `${window.Config.G_API_URL}/admin/station/port/connect`,
            params : {
                token : cookies.get('token'),
                station_id : no.replace(':' , ''),
                port : num.replace(':' , ''),
                status : portInfo.connect === 'T' ? 'disconnect' : 'connect', 
            }
        })
            .then(response => {
                if(response.data.result['code'] === '200'){
                    handleConfirmModal();
                } else if (response.data.result['code'] === '409'){
                    handleStationModal();
                } else if (response.data.result['code'] === '403'){
                    handleChargingModal();
                } else{
                    alert(response.data.result['message']);
                }
            })
            .catch(function(error){
                alert(error);
            })
    }

    return (
        <SearchGroupDetailWrapper>
            <Title>포트 상세</Title>
            <Description>
                스테이션 관리 <BcArrow src={breadcrumbArrow} /> 그룹 검색{' '}
                <BcArrow src={breadcrumbArrow} /> 그룹 상세{' '}
                <BcArrow src={breadcrumbArrow} /> 스테이션 상세{' '}
                <BcArrow src={breadcrumbArrow} /> 포트 상세
            </Description>
            <TitleWrapper connect={portInfo.connect}>
                <div>
                    <h1>{portInfo.station_name}</h1>
                    <Port>#{portInfo.port}</Port>
                    <Connect state={portInfo.connect}>{portInfo.connect === 'T' ? '연결' : '미연결'}</Connect>
                    <Lock state={portInfo.lock_status}>
                        {portInfo.lock_status === '0' ? 'OPEN' : 'LOCK'}
                    </Lock>
                    {usage(portInfo.status)}
                </div>
                <div>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    {
                        portInfo.status === '충전 중' ?
                            <button onClick={handleChargingConfirmModal}>거치로 전환</button>
                        :
                            ""
                    }
                    <button onClick={handleStationConfirmModal}>{portInfo.connect === 'T' ? '이용 중지' : '포트 연결'}</button>
                </div>
            </TitleWrapper>
            <InfoWrapper>
                {batteryState(portInfo.battery_status)}
                {highTemp(portInfo.temperature_error)}
                {overVoltage(portInfo.voltage_error)}
                {overCurrent(portInfo.current_error)}
                {voltageError(portInfo.select_voltage_error)}
                {batteryError(portInfo.battery_error)}
                {fanState(portInfo.fan_error)}
            </InfoWrapper>
            <PortDetailTable />
            <div>
                <button onClick={() => navigate(-1)}>목록</button>
            </div>

            <PortControllModal
                portInfo={portInfo}
                toggle={toggleModal}
                toggleFunc={handleToggleModal}
            />
            <PortNotiModal
                toggle={toggleStationModal}
                toggleFunc={handleStationModal}
                description={'연결된 스테이션이 아닙니다.'}
            />
            <PortNotiModal
                toggle={toggleChargingModal}
                toggleFunc={handleChargingModal}
                description={'충전 / 거치중인 포트입니다.'}
            />
            <PortNotiModal
                toggle={toggleConfirmModal}
                toggleFunc={handleConfirmModal}
                description={'상태 변경이 완료되었습니다.'}
            />

            <ChangeStateModal
                toggle={toggleChargingConfirmModal}
                toggleFunc={handleChargingConfirmModal}
                content={'해당 포트를 거치로 전환하시겠습니까?'}
                text='전환하겠습니다.'
                deleteFunc={setGeochi}
            />            
            <ChangeStateModal
                toggle={toggleStationConfirmModal}
                toggleFunc={handleStationConfirmModal}
                content={'해당 포트의 연결 상태를 전환하시겠습니까?'}
                text='전환하겠습니다.'
                deleteFunc={stopPort}
            />
        </SearchGroupDetailWrapper>
    );
}

export default PortDetail;
