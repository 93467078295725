import React , {useEffect} from 'react';
import styled from 'styled-components';
import {useParams} from "react-router";
import Cookie from "universal-cookie";
import QuestionMark from '../asset/img/questionmark.png';

const cookies = new Cookie();

const Table = styled.table`
  & > tbody{
    & > tr:nth-child(5) {
        &> td:first-child::before{
            content: '업체 관리자';
        }
    }
    & > tr > td:first-child{
      width: 17%;
      font-family : notosansbold;
    }
    & > tr > td:last-child{
      font-family : notosansmedium;
    }
  }
`

const Tr = styled.tr`
  border: none !important;
`

const Td = styled.td`
    display : flex;
    align-items : center;
    width : 180px !important;
    height : 40px;
    position : relative;
    & > img {
        width : 18px;
        margin-left : 12px;
    }
    & > div {
        display : ${props => props.hover ? 'block' : 'none'};
        position : absolute;
        font-size: 14px;
        line-height: 20px;
        top : 47%;
        left : 58%;
        width : 500px;
        border: 1px solid #C4C4C4;
        border-radius: 5px;
        background-color : #ffffff;
        padding : 9px 12px;
    }
`

const ColoredTd = styled.td`
    color : ${props => props.color};
    & > div {
        display : flex;
        align-items : center;
        & > span {
        display : inline-block;
        width : 18px;
        height : 18px;
        margin-top : 2px;
        border-radius : 100%;
        background-color : ${props => props.color};
        }
    }
`

function CompanyDetailTable(props) {
    
    const {no , num} = useParams();

    const [onHover , setOnHover] = React.useState(false);
    const [adminName , setAdminName] = React.useState([]);

    useEffect(()=>{
        props.adminName && setAdminName(props.adminName); 
    },[props.adminName , adminName]);

    const toggleOnHover = () => {
        setOnHover(true);
    }

    const leaveOnHover = () => {
        setOnHover(false);
    }

    return (
        <Table>
            <tbody>
                <tr>
                    <td>업체 등록일</td>
                    <td>{props.date}</td>
                </tr>
                <tr>
                    <td>주소</td>
                    <td>{props.address}</td>
                </tr>
                <tr>
                    <td>사업자 등록번호</td>
                    <td>{props.companyNum}</td>
                </tr>
                <tr>
                    <td>연락처</td>
                    <td>{props.phone}</td>
                </tr>
                    {
                        props.adminName && props.adminName.map((item)=>(
                            <Tr>
                                <Td hover={onHover}>
                                    <img src={QuestionMark} alt="" onMouseEnter={toggleOnHover} onMouseLeave={leaveOnHover}/>    
                                    <div>
                                        업체에 신규 관리자를 추가하려면 관리자 설정 {'>'} 관리자 회원 관리에서 
                                        '업체 관리자 등록' 버튼을 통해 진행하세요.
                                    </div>
                                </Td>
                                    <td>{item}</td>
                            </Tr>
                    ))}
                <tr>
                    <td>정산일</td>
                    <td>매월 {props.settleDate}일</td>
                </tr>
                <tr>
                    <td>미납 여부</td>
                    <td>
                        {props.calState}
                    </td>
                </tr>
                <tr>
                    <td>정산 카드</td>
                    <td></td>
                </tr>
                <tr>
                    <td>색상</td>
                    <ColoredTd color={props.color}><div>{props.color}<span/></div></ColoredTd>
                </tr>
            </tbody>
        </Table>
    );
}

export default CompanyDetailTable;