import React , {useEffect} from 'react'
import styled from 'styled-components';
import MessageDetailModal from './MessageDetailModal';
import Cookie from "universal-cookie";
const cookies = new Cookie();

const Tr = styled.tr`
    position : relative;
        & > td:nth-child(3) {
            width : 10%;
            text-align : center !important;
            padding-left : 0 !important;
        }
    & > td:nth-child(5){
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        max-width: 700px;
        cursor: pointer;
        text-align: left;
        text-overflow:ellipsis;
        overflow:hidden;
        line-height: 30px;
    }
`

const ContentBox = styled.div`
    position : absolute;
    top : 0;
    left : 0;
    width : 300px;
    display : ${props => props.hover ? 'inline-block !important' : 'none'};
`

function NoticeList(props) {
    const [toggleDetailModal , setToggleDetailModal] = React.useState();
    const [levelAuth , setLevelAuth] = React.useState(false);


    const handleToggleDetailModal = () => {
        setToggleDetailModal(!toggleDetailModal);
    }

  const goToDetail = () => {
    window.location.href = `/admin/manageuser/detail/:${props.usId}/:1/pointmanage/:1`
  }

  useEffect(()=>{
    if(cookies.get('level')<=0){
        setLevelAuth(true);
    }
},[])

  const maskingPhone = (originStr) => {
    let maskingStr = '';
    let phoneStr =  originStr.length < 11 ? originStr.match(/\d{10}/gi) : originStr.match(/\d{11}/gi);
        
    if(originStr.length < 11)
    { // 1.1) 0110000000
        return maskingStr = originStr.toString().replace(phoneStr, phoneStr.toString().replace(/(\d{3})(\d{3})(\d{4})/gi,'$1***$3'));
    }
    else
    { // 1.2) 01000000000
        return maskingStr = originStr.toString().replace(phoneStr, phoneStr.toString().replace(/(\d{3})(\d{4})(\d{4})/gi,'$1****$3'));
    }
}

  return (
    <>
        <Tr  onClick={handleToggleDetailModal}>
            <td>{props.no}</td>
            <td>{props.usName}</td>
            <td>{props.alimType}</td>
            <td>{levelAuth ? props.usPhone : props.usPhone === '' ? '' : maskingPhone(props.usPhone)}</td>
            <td>
                {props.content}
            </td>
            <td>{props.adminId}</td>
            <td>{props.notiDate}</td>
        </Tr>
        <MessageDetailModal 
            toggle={toggleDetailModal}
            toggleFunc={handleToggleDetailModal}
            goToDetail={goToDetail}
            no={props.no}
            alimType={props.alimType}
            content={props.content}
            usName={props.usName}
            usPhone={levelAuth ? props.usPhone : props.usPhone === '' ? '' : maskingPhone(props.usPhone)}
            notiDate={props.notiDate}
        />
    </>
  )
}

export default NoticeList