import axios from 'axios';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Cookie from 'universal-cookie';
import breadcrumbArrow from '../asset/img/breadcrumbarrow.png';
import Delete from '../asset/img/deletemanager.png';
import GrayQuestionMark from "../asset/img/grayquestionmark.png";
import AuthCheckModal from '../components/AuthCheckModal';
import SearchGroupDetailUpdateTable from '../components/SearchGroupDetailUpdateTable';
import {
    BcArrow,
    Description,
    SearchGroupDetailWrapper,
    Title,
} from '../css/styles';

import EmptyValueModal from '../components/EmptyValueModal';

const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start !important;
    & > input {
        font-weight: bold;
        font-size: 32px;
        line-height: 46px;
        border: none;
        border-bottom: 2px solid #e5e5e5;
        &:focus {
            outline: none;
        }
    }
    & > img {
        width: 20px;
        cursor: pointer;
    }
`;

const CaptionWrapper = styled.div`
    display: flex;
    justify-content: flex-start !important;
    align-items: center;
    margin-top: 7px !important;
    column-gap: 5px;
    & > img {
        width: 18px;
    }
    & > p {
        font-size: 14px;
        line-height: 160%;
        letter-spacing: -0.035em;
        color: #999999;
    }
`;

const ButtonWrapper = styled.div`
    & > button:first-child {
        padding: 5px 20px !important;
    }
    & > button:last-child {
        padding: 5px 20px !important;
        color: white;
        background-color: #df1f26;
    }
`;

function SearchGroupRegister({ props }) {
    const cookies = new Cookie();
    const [toggleModal, setToggleModal] = React.useState(false);
    const [toggleAddModal, setToggleAddModal] = React.useState(false);

    const [title, setTitle] = React.useState(null);
    // table 용 state
    const [chargePrice, setChargePrice] = React.useState(null);
    const [holderPrice, setHolderChargePrice] = React.useState(null);
    const [weekStart, setWeekStart] = React.useState(null);
    const [weekStartMin, setWeekStartMin] = React.useState(null);
    const [weekEnd, setWeekEnd] = React.useState(null);
    const [weekEndMin, setWeekEndMin] = React.useState(null);
    const [satStart, setSatStart] = React.useState(null);
    const [satStartMin, setSatStartMin] = React.useState(null);
    const [satEnd, setSatEnd] = React.useState(null);
    const [satEndMin, setSatEndMin] = React.useState(null);
    const [holiStart, setHoliStart] = React.useState(null);
    const [holiStartMin, setHoliStartMin] = React.useState(null);
    const [holiEnd, setHoliEnd] = React.useState(null);
    const [holiEndMin, setHoliEndMin] = React.useState(null);
    const [operationState, setOperationState] = React.useState(null);

    const [toggleEmptyModal , setToggleEmptyModal] = React.useState(false);

    const [toggleAuthModal , setToggleAuthModal] = React.useState(false);

    const handleToggleAuthModal = () => {
        setToggleAuthModal(!toggleAuthModal);
    }
    const handleToggleEmptyModal = () => {
        setToggleEmptyModal(!toggleEmptyModal);
    }

    const handleAddModal = () => {
        setToggleAddModal(!toggleAddModal);
    };

    const handleModal = () => {
        setToggleModal(!toggleModal);
    };

    const navigate = useNavigate();

    const onTitleChange = (e) => {
        setTitle(e.target.value);
    };

    const onRegister = () => {
        console.log(weekEnd + ':' + weekEndMin)
        axios({
            method: 'post',
            url: `${window.Config.G_API_URL}/admin/station/group`,
            params: {
                token: cookies.get('token'),
                group_name: title,
                charge_price: chargePrice,
                holder_price: holderPrice,
                operation_status: operationState,
                weekdays_hours_start: weekStart,
                weekdays_hours_end: weekEnd,
                saturday_hours_start: satStart,
                saturday_hours_end: satEnd,
                holiday_hours_start: holiStart,
                holiday_hours_end: holiEnd,
            },
            // no : checkList 파라미터 [] 없애는 옵션
            // paramsSerializer : function(paramObj) {
            //     const params = new URLSearchParams()
            //     for (const key in paramObj) {
            //         params.append(key, paramObj[key])
            //     }
            //
            //     return params.toString()
            // }
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    window.location.href = '/admin/searchgroup/:1';
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                }else if(response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                }  else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                handleToggleEmptyModal();
            });
    };

    const tableInputChange = (e) => {
        let name = e.target.name;
        switch (name) {
            case 'chargePrice':
                setChargePrice(e.target.value);
                break;
            case 'holderPrice':
                setHolderChargePrice(e.target.value);
                break;
            case 'weekStart':
                setWeekStart(e.target.value);
                break;
            case 'weekStartMin':
                setWeekStartMin(e.target.value);
                break;
            case 'weekEnd':
                setWeekEnd(e.target.value);
                break;
            case 'weekEndMin':
                setWeekEndMin(e.target.value);
                break;
            case 'satStart':
                setSatStart(e.target.value);
                break;
            case 'satStartMin':
                setSatStartMin(e.target.value);
                break;
            case 'satEnd':
                setSatEnd(e.target.value);
                break;
            case 'satEndMin':
                setSatEndMin(e.target.value);
                break;
            case 'holiStart':
                setHoliStart(e.target.value);
                break;
            case 'holiStartMin':
                setHoliStartMin(e.target.value);
                break;
            case 'holiEnd':
                setHoliEnd(e.target.value);
                break;
            case 'holiEndMin':
                setHoliEndMin(e.target.value);
                break;
            case 'operationState':
                setOperationState(e.target.value);
                console.log(e.target.value);
                break;
        }
    };

    return (
        <SearchGroupDetailWrapper>
            <Title>그룹 추가</Title>
            <Description>
                스테이션 관리 <BcArrow src={breadcrumbArrow} /> 그룹 검색{' '}
                <BcArrow src={breadcrumbArrow} /> 그룹 추가
            </Description>
            <TitleWrapper>
                <input type="text" value={title||''} onChange={onTitleChange} />
                <img src={Delete} alt="" />
            </TitleWrapper>
            <SearchGroupDetailUpdateTable
                onTableInputChange={tableInputChange}
                toggle={handleModal}
                toggleAdd={handleAddModal}
            />
            <CaptionWrapper>
                <img src={GrayQuestionMark} alt="" />
                <p>관리자 및 장비는 그룹상세에서 수정할 수 있습니다.</p>
            </CaptionWrapper>
            <ButtonWrapper>
                <button onClick={() => navigate(-1)}>취소</button>
                <button onClick={onRegister}>등록</button>
            </ButtonWrapper>

            <EmptyValueModal
                toggle={toggleEmptyModal}
                toggleFunc={handleToggleEmptyModal}
            />
            <AuthCheckModal
                toggle={toggleAuthModal}
                toggleFunc={handleToggleAuthModal}
            />
        </SearchGroupDetailWrapper>
    );
}

export default SearchGroupRegister;
