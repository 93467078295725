import React from 'react';
import styled from "styled-components";
import Cookie from "universal-cookie";

const cookies = new Cookie();

const BigInput = styled.input`
  border: 1px solid #C4C4C4;
  width: 25%;
  padding: 7px;
  border-radius: 10px;
`

function VersionUpdateTable(props) {

    return (
        <table>
            <tbody>
            <tr>
                <td>신규 버전</td>
                <td>
                    <BigInput type='text' value={props.value||''} onChange={props.onChange}/>
                </td>
            </tr>
            <tr>
                <td>현재 버전</td>
                <td>{props.version}</td>
            </tr>
            <tr>
                <td>최근 버전 저장 날짜</td>
                <td>{props.date}</td>
            </tr>
            </tbody>
        </table>
    );
}

export default VersionUpdateTable;