import React, { useEffect } from 'react';
import styled from 'styled-components';

import DeleteSearch from '../asset/img/deletesearch.png';
import SelectArrow from '../asset/img/selectarrow.png';
import Dotbogi from '../asset/img/dotbogi.png';
import SearchIndexBox from './SearchIndexBox';
import axios from 'axios';
import Cookie from 'universal-cookie';
import { useParams } from 'react-router';
import Pagination from './Pagination';
import CheckBox from "./CheckBox";
import { BorderDiv, BorderDivR } from '../css/styles';
import AskList from '../components/AskList';

const cookies = new Cookie();

const PointManageWrapper = styled.div`
    margin-top: 0 !important;
    & > table {
        width: 100%;
        text-align: center;

        & > thead  > tr{
            border-bottom: 1px solid #e5e5e5;
        }
        & > tbody > tr {
            line-height: 40px;
            border-bottom: 1px solid #e5e5e5;
            font-weight: 500;
            font-size: 16px;
        }
    }
    & > div {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }
`;

const SearchBox = styled.div`
    padding: 2% 0;
    display: flex;
    justify-content: flex-start !important;
    align-items: flex-start;
    column-gap: 15px;
    & > div {
        display: flex;
        align-items: center;
        & > img {
            margin-top: 10px;
            margin-left: 7px;
            width: 20px;
        }
        & > p {
            font-size: 16px;
            margin-top: 9px;
            line-height: 23px;
            color: #c4c4c4;
        }
    }
`;

const ColumnBox = styled.div`
    display: flex;
    flex-direction: column;
`;

const SelectBox = styled.select`
    cursor: pointer;
    appearance: none;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    padding: 7px 15px;
    font-size: 16px;
    line-height: 23px;
    width: 10%;
    background-image: url(${SelectArrow});
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: 87%;
    &:focus {
        outline: none;
    }
`;

const SearchInput = styled.input`
    width: 97%;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    background-image: url(${Dotbogi});
    background-repeat: no-repeat;
    background-size: 17px;
    background-position: 97%;
    padding: 7px 10px;
    font-size: 16px;
    line-height: 23px;
    color: #7e7e7e;
`;

const Table = styled.table`
    margin-top : 30px !important;
    & > thead > tr {
        & > th:nth-child(1) {
            width : 7% !important;
        }
        & > th:nth-child(2) {
            width : 10% !important;
        }
        & > th:nth-child(3) {
            width : 10%;
            text-align : center !important;
            padding-left : 0 !important;
        }
        & > th:nth-child(4) {
            width : 40%;
            & > div {
                justify-content : flex-start;
                padding-left: 30px;
            }
        }
        & > th:nth-child(5) {
            width : 12% !important;
        }
    }
`

const ThSelectBox = styled(SelectBox)`
    border : none;
    background-color : white;
    font-weight : 700;
    background-position: 100%;
    width : 70%;
`

function Inquiry(props) {
    const { no, num, page } = useParams();
    const [stationInfo, setStationInfo] = React.useState([]);
    const [stationPageInfo, setStationPageInfo] = React.useState([]);
    const [searchData, setSearchData] = React.useState(null);
    const [searchType, setSearchType] = React.useState('장비 제목');
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndeDate] = React.useState(null);
    const [answerState , setAnserState] = React.useState(null);

    const [checked , setChecked] = React.useState(false);
    const [checkAll , setCheckAll] = React.useState(false);
    const [checkList , setCheckList] = React.useState([]);
    
    const handleAnswerState = (e) => {
        setAnserState(e.target.value);
    }

    useEffect(() => {
        axios({
            method: 'get',
            url: `${window.Config.G_API_URL}/admin/user/history/ask`,
            params: {
                token: cookies.get('token'),
                ask_user_id: parseInt(no.replace(':', '')),
                page_num: num.replace(':', ''),
                search_type: searchType,
                search_data: searchData,
                start_date: startDate,
                end_date: endDate,
                answer_status : answerState,
            },  
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    console.log(response.data.data);
                    setStationPageInfo(response.data.data.result);
                    setStationInfo(response.data.data.result.list);
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    console.log(response.data.result);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    }, [num, searchType, searchData, startDate, endDate , answerState]);

    useEffect(()=>{
        if(checkList.length == stationInfo.length){
            setCheckAll(true);
        } else {
            setCheckAll(false);
        }
        console.log(checkList);
    },[checkList,stationInfo]);

    const onTypeChange = (e) => {
        setSearchType(e.target.value);
    };

    const onSearchDataChange = (e) => {
        setSearchData(e.target.value);
    };

    const onStartDateChange = (e) => {
        setStartDate(e.target.value);
    };

    const onEndDateChange = (e) => {
        setEndeDate(e.target.value);
    };

    const handleCheckAll = (e) => {
        setCheckAll(!checkAll);
    }
    useEffect(() => {
        console.log(stationInfo);
    }, [stationInfo]);

    const handleCheckEach = (e) => {
        const { id , checked} = e.target;
        let arr = [...checkList];
        if (arr.includes(id)) {
            for (let i = 0; i < arr.length; i++) {
                if (arr[i] == id) {
                    arr.splice(i, 1);
                    setChecked(!checked);
                    break;
                }
            }
            setCheckList(arr);
        } else {
            setChecked(!checked);
            setCheckList([...checkList, id]);
        }
    }
    
    const handleDate = (e) => {
        var date = new Date();
        var currentDate = date.toISOString().substring(0,10);
        setEndeDate(currentDate);
        switch(e.target.id){
            case 'all':
                setStartDate(null);
                break;
            case 'week':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 7));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate(currentDate);
                break;
            case 'halfMonth':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 14));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate(currentDate);
                break;
            case 'month':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 30));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate(currentDate);
                break;
            case 'threeMonth':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 91));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate(currentDate);
                break;
            case 'halfYear':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 182));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate(currentDate);
                break;
            case 'year':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 365));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate(currentDate);
                break;
        }
    }
    return (
        <PointManageWrapper>
            <SearchBox>
                <ColumnBox>
                    <SearchInput
                        type="text"
                        placeholder={'검색어 입력'}
                        onChange={onSearchDataChange}
                    />
                    <SearchIndexBox
                        startChange={onStartDateChange}
                        endChange={onEndDateChange}
                        handleDate={handleDate}
                        startDate={startDate}
                        endDate={endDate}
                    />
                </ColumnBox>
                <div>
                    <img src={DeleteSearch} alt="" />
                    <p>초기화</p>
                </div>
            </SearchBox>
            <Table>
                <thead>
                    <tr>
                        <th>
                            글번호
                        </th>
                        <th>
                            <BorderDiv>등록일</BorderDiv>
                        </th>
                        <th>
                            문의 등록자
                        </th>
                        <th>
                            <BorderDiv>제목</BorderDiv>
                        </th>
                        <th>
                            <ThSelectBox name="searchselect" id="serachselect" onChange={handleAnswerState}>
                                <option value=''>답변 여부</option>
                                <option value="검토 중">검토 중</option>
                                <option value="답변 완료">답변 완료</option>
                            </ThSelectBox>
                        </th>
                        <th>
                            <BorderDiv>답변자</BorderDiv>
                        </th>
                        <th>
                            답변일
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {stationInfo.map((item, key, index) => (
                        <AskList
                            key={key}
                            no={item.ask_id}
                            title={item.ask_title}
                            date={item.ask_insert_date}
                            exposure={item.exposure}
                            admin={item.admin_no}
                            user={item.ask_user_name}
                            adminId={item.ask_comment_user_id}
                            complete={item.ask_comment_complete}
                            commentDate={item.insert_date}
                        />
                    ))}
                </tbody>
            </Table>
            <div>
                <Pagination
                    no={num.replace(':', '')}
                    url={`/admin/manageuser/detail/${no}/${page}/inqury/:`}
                    pages={stationPageInfo['pages']}
                    isFirstPage={stationPageInfo['isFirstPage']}
                    isLastPage={stationPageInfo['isLastPage']}
                    prePage={stationPageInfo['prePage']}
                    nextPage={stationPageInfo['nextPage']}
                    hasPrePage={stationPageInfo['hasPreviousPage']}
                    hasNextPage={stationPageInfo['hasNextPage']}
                />
            </div>
        </PointManageWrapper>
    );
}

export default Inquiry;
