import React, { useEffect } from 'react';
import styled from 'styled-components';

import Apple from '../asset/img/circledapple.png';
import Google from '../asset/img/circledgoogle.png';
import Kakao from '../asset/img/circledkakao.png';
import Naver from '../asset/img/circlednaver.png';
import Sum from '../asset/img/circledsum.png';
import Tsid from '../asset/img/circledtsid.png';

const LoginInfoWrapper = styled.div`
  display: ${props => props.state === ''||props.state === null ? 'none' : 'flex'};
  align-items: center;
  column-gap: 10px;
  & > img {
    width: 25px;
  }
`

function LoginInfo(props) {

    useEffect(()=>{
        returnImg();
    })

    const [icon , setIcon] = React.useState('');

    const returnImg = () => {
        switch(props.type){
            case 'kakao':
                return setIcon(Kakao);
            case 'naver':
                return setIcon(Naver);
            case 'apple':
                return setIcon(Apple)
            case 'google':
                return setIcon(Google);
            case 'sum':
                return setIcon(Sum);
            case 'tsid':
                return setIcon(Tsid);
            default :
                break;
        }
    }

    return (
        <LoginInfoWrapper state={props.email}>
            <img src={icon} alt=""/>
            <p>{props.email}</p>
        </LoginInfoWrapper>
    );
}

export default LoginInfo;