import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

const Img = styled.img`
 width : 100%;
`

function ImageModal(props) {

    return (
        <Modal isOpen={props.toggle} onRequestClose={props.toggleFunc}
               style={{
                   overlay :{
                       backgroundColor : 'rgba(34, 30, 31, 0.5)'
                   },
                   content : {
                       height : '80%',
                       left : '30%',
                       right : '30%',
                       top : '10%',
                       padding : 0,
                   }
               }}
        >
            <Img src={`${props.url}${props.img}`} alt='상세 이미지'/>
        </Modal>
    );
}

export default ImageModal;
