import React , {useEffect} from 'react';
import LoginInfo from "./LoginInfo";
import styled from "styled-components";
import Cookie from "universal-cookie";
import VersionModal from './VersionModal';
const cookies = new Cookie();

const Td = styled.td`
    & > div {
        display : flex;
        align-items : center;
        & > button {
            font-size: 12px;
            line-height: 17px;
            color: #DF1F26;
            border: 1px solid #DF1F26;
            border-radius: 8px;
            background-color : white;
            cursor : pointer;
            padding : 3px 2;
        }
    }
`

function VersionManageTable(props) {

    const [ toggleVersionHistory , setToggleVersionHistory ] = React.useState(false);

    const handleToggleVersionModal = () => {
        setToggleVersionHistory(!toggleVersionHistory);
    }

    return (
        <table>
            <tbody>
            <tr>
                <td>현재 버전</td>
                <td>{props.version}</td>
            </tr>
            <tr>
                <td>최근 버전 저장 날짜</td>
                <Td>
                    <div>
                        {props.date}
                        <button onClick={handleToggleVersionModal}>버전 수정 기록</button>
                    </div>
                </Td>
            </tr>
            </tbody>

            <VersionModal
                toggle={toggleVersionHistory}
                toggleFunc={handleToggleVersionModal}
                type={props.type}
            />
        </table>
    );
}

export default VersionManageTable;