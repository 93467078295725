import React, {useEffect} from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import {SearchBox} from "../css/styles";
import DeleteSearch from "../asset/img/deletesearch.png";
import SelectArrow from "../asset/img/selectarrow.png";
import Dotbogi from "../asset/img/dotbogi.png";
import axios from "axios";
import Cookie from "universal-cookie";
import {useParams} from "react-router";
import CouponUsageStateDetailModalList from "./CouponUsageStateDetailModalList";
import PaginationSettlement from "./PaginationSettlement";
import SearchIndexBoxModal from "./SearchIndexBoxModal";

const cookies = new Cookie();

const Title = styled.h1`
    font-weight: bold;
    font-size: 24px;
    line-height: 35px;
    color: #221E1F;
`

const SelectBox = styled.select`
  cursor: pointer;
  appearance:none;
  border: 1px solid #C4C4C4;
  border-radius: 10px;
  padding: 7px 10px;
  width: 10%;
  font-size: 16px;
  line-height: 23px;
  background-color: white;
  background-image: url(${SelectArrow});
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: 85%;
  &:focus{
    outline: none;
  }
`


const SearchInput = styled.input`
  margin-left: 21px;
  width: 60%;
  border: 1px solid #C4C4C4;
  border-radius: 10px;
  background-image: url(${Dotbogi});
  background-repeat: no-repeat;
  background-size: 17px;
  background-position: 97%;
  padding: 7px 10px;
  font-size: 16px;
  line-height: 23px;
  color: #7E7E7E;
`

const ButtonContainer = styled.div`
    display : flex;
    justify-content : space-between;
  padding-top: 40px;
`

const Button = styled.button`
  background-color: white;
  border-radius: 10px;
  border: 1px solid #C4C4C4;
  width : 11%;
  padding : 10px 0 ;
  font-weight: bold;
  font-size: 14px;
  color: #221E1F;
`

const Table = styled.table`
  width: 100%;
  line-height: 40px;
  text-align: center;
  & > thead > tr{
    border-bottom: 2px solid #e5e5e5;
    line-height: 23px;
    & > th {
      vertical-align: middle;
    }
    & > th:nth-child(1) {
        width : 2%;
    }
    & > th:nth-child(2) {
        width : 10%;
    }
    & > th:nth-child(5) {
        width : 13%;
    }
  }
  & > tbody > tr{
    border-bottom: 1px solid #e5e5e5;
  }
`
const TableDiv = styled.div`
  height: 65%;
  padding-top: 15px;
`

const ModalSearchBox = styled(SearchBox)`
  width: 100%;
`

const ThSelectBox = styled(SelectBox)`
    border : none;
    background-color : white;
    background-position: 100%;
    font-family : notosansmedium;
    width : 70%;
`

function StationUsageModal(props) {

    const {no , usage} = useParams();
    const [deviceInfo , setDeviceInfo] = React.useState([]);
    const [deviceListInfo , setDeviceListInfo] = React.useState([]);

    const [searchData , setSearchData] = React.useState(null);
    const [searchType , setSearchType] = React.useState(null);

    const [startDate , setStartDate1] = React.useState(null);
    const [endDate , setEndeDate1] = React.useState(null);

    const [answerState , setAnsetState] = React.useState(null);

    const handleAnswerState = (e) => {
        console.log(e.target.value)
        if(e.target.value === ''){
            setAnsetState(null);
        }else{
            setAnsetState(e.target.value);
        }
    }

    useEffect(()=>{
        axios({
            method : 'get',
            url : `${window.Config.G_API_URL}/admin/coupon/detail/issuance`,
            params : {
                token : cookies.get('token'),
                coupon_id : no.replace(':' , ''),
                usage_status : answerState,
                page_num : usage.replace(':',''),
                search_type : searchType,
                search_data : searchData,
                start_date : startDate,
                end_date : endDate,
            }
        })
            .then(response => {
                if(response.data.result['code'] === '200'){
                    console.log(response.data.data.result);
                    setDeviceInfo(response.data.data.result.list)
                    setDeviceListInfo(response.data.data.result)
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function(error){
                alert(error);
            })
    },[searchType , searchData , usage , startDate , endDate ,answerState  ])


    const onTypeChange = (e) => {
        if(e.target.value === 'null'){
            setSearchType(null)
        } else {
            setSearchType(e.target.value);
        }
    }

    const onSearchDataChange = (e) => {
        setSearchData(e.target.value);
    }

    const onReset = () => {
        setSearchData('');
    }

    const onStartDateChange = (e) => {
        setStartDate1(e.target.value);
    }

    const onEndDateChange = (e) => {
        setEndeDate1(e.target.value);
    }

    const handleDate = (e) => {
        var date = new Date();
        var currentDate = date.toISOString().substring(0,10);
        setEndeDate1(currentDate);
        switch(e.target.id){
            case 'all1':
                setStartDate1(null);
                break;
            case 'week1':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 7));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate1(currentDate);
                break;
            case 'halfMonth1':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 14));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate1(currentDate);
                break;
            case 'month1':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 30));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate1(currentDate);
                break;
            case 'threeMonth1':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 91));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate1(currentDate);
                break;
            case 'halfYear1':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 182));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate1(currentDate);
                break;
            case 'year1':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 365));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate1(currentDate);
                break;
        }
    }


    return (
        <Modal isOpen={props.toggle} onRequestClose={props.toggleFunc}
               ariaHideApp={false}
               style={{
                   overlay :{
                       backgroundColor : 'rgba(34, 30, 31, 0.5)'
                   },
                   content : {
                       height : '85%',
                       left : '15%',
                       right : '7%',
                       top : '5%',
                       borderRadius : '10px',
                   }
               }}
        >
            <Title>쿠폰 이용 현황</Title>
            <ModalSearchBox>
                    <SelectBox name="searchselect" id="serachselect" onChange={onTypeChange}>
                        <option value={'null'}>선택</option>
                        <option value="회원명">회원명</option>
                        <option value="업체명">업체명</option>
                        <option value="쿠폰 이름">쿠폰 이름</option>
                        <option value="쿠폰 번호">쿠폰 번호</option>
                        <option value="스테이션명">스테이션명</option>
                        <option value="스테이션 ID">스테이션 ID</option>
                    </SelectBox>
                    <SearchInput type="text" placeholder={'검색어 입력'} onChange={onSearchDataChange}/>
                    <div onClick={onReset}>
                        <img src={DeleteSearch} alt=""/>
                        <p>초기화</p>
                    </div>
            </ModalSearchBox>
            <SearchIndexBoxModal
                width={'90'}
                startChange={onStartDateChange}
                endChange={onEndDateChange}
                handleDate={handleDate}
                startDate={startDate}
                endDate={endDate}
            />
            <TableDiv>
                <Table>
                    <thead>
                    <tr>
                        <th>No.</th>
                        <th>업체명</th>
                        <th>회원명</th>
                        <th>연락처</th>
                        <th>쿠폰 번호</th>
                        <th>발급일</th>
                        <th> 
                            <ThSelectBox name="searchselect" id="serachselect" onChange={handleAnswerState}>
                                <option value=''>사용 여부</option>
                                <option value="사용">사용</option>
                                <option value="미사용">미사용</option>
                            </ThSelectBox>
                        </th>
                        <th>스테이션 ID</th>
                        <th>스테이션명</th>
                        <th>포트</th>
                        <th>사용 일시</th>
                    </tr>
                    </thead>
                    <tbody>
                    {deviceInfo.map((item) => (
                        <CouponUsageStateDetailModalList
                            rownum={item.rownum}
                            us_id={item.us_id}
                            us_name={item.us_name}
                            phone={item.phone}
                            company={item.company}
                            coupon_id={item.coupon_id}
                            coupon_name={item.coupon_name}
                            coupon_number={item.coupon_number}
                            date_time={item.date_time}
                            usage_status={item.usage_status}
                            station_id={item.station_id}
                            station_name={item.station_name}
                            port={item.port}
                            usage_date_time={item.usage_date_time}
                        />
                    ))}
                    </tbody>
                </Table>
            </TableDiv>
            <ButtonContainer>
                <p/>
                <PaginationSettlement
                    no={usage.replace(':', '')}
                    url={`/admin/couponmanage/detail/${no}/:`}
                    pages={deviceListInfo['pages']}
                    isFirstPage={deviceListInfo['isFirstPage']}
                    isLastPage={deviceListInfo['isLastPage']}
                    prePage={deviceListInfo['prePage']}
                    nextPage={deviceListInfo['nextPage']}
                    hasPrePage={deviceListInfo['hasPreviousPage']}
                    hasNextPage={deviceListInfo['hasNextPage']}
                />
                <Button onClick={props.toggleFunc}>닫기</Button>
            </ButtonContainer>
        </Modal>
    );
}

export default StationUsageModal;
