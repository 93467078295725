import React from "react";
import Modal from "react-modal";
import styled from "styled-components";

const ModalDescription = styled.p`
    text-align: center;
    font-size: 16px;
    margin-top: 16px;
    margin-bottom: 36px;
`

const ModalButton = styled.button`
    display: flex;
    margin: auto;
    font-size: 16px;
    line-height: 23px;
    padding : 7px 20px;
    background-color: white;
    border-radius: 10px; 
    border: 1px solid #C4C4C4;
    color: red;
    font-weight: bold;
`

const EmptyListModal = (props) => {
    return(
        <Modal isOpen={props.toggle} onRequestClose={props.toggleFunc}
            style={{
                overlay :{
                    backgroundColor : 'rgba(34, 30, 31, 0.5)'
                },
                content : {
                    height : '14%',
                    left : '37%',
                    right : '37%',
                    top : '38%',
                    borderRadius : '10px',
                    border: '1px solid #C4C4C4' ,
                }
            }}
        >
            <ModalDescription>누락된 필수 정보가 존재합니다. 다시 확인해 주세요.</ModalDescription>
            <ModalButton onClick={props.toggleFunc}>확인</ModalButton>
        </Modal>
    )
}

export default EmptyListModal;