import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

const Description = styled.p`
    font-size: 16px;
    line-height: 23px;
    text-align: center;
`

const ButtonContainer = styled.div`
  display : flex;
  justify-content : center;
  margin-top : 35px;
  column-gap: 20px;
`


const RedButton = styled.a`
  color: #DF1F26;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #C4C4C4;
  width : 25%;
  padding : 10px 0 ;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
`

function ChargingUserModal(props) {

    return (
        <Modal isOpen={props.toggle} onRequestClose={props.toggleFunc}
               style={{
                   overlay :{
                       backgroundColor : 'rgba(34, 30, 31, 0.5)'
                   },
                   content : {
                       height : '16%',
                       left : '37%',
                       right : '37%',
                       top : '35%',
                       borderRadius : '10px',
                       border: '1px solid #C4C4C4' ,
                   }
               }}
        >
            <Description>
                탈퇴처리한 사용자 중에 스테이션 사용중인 사용자는 삭제되지 않습니다.
            </Description>
            <ButtonContainer>
                <RedButton onClick={() => window.location.reload()}>확인</RedButton>
            </ButtonContainer>
        </Modal>
    );
}

export default ChargingUserModal;
