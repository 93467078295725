import React from 'react';
import { NavLink } from 'react-router-dom';
import { ArrowImg, DoubleArrowImg, PaginationWrapper, RightArrowImg, RightDoubleArrowImg } from '../css/styles';

import DoubleArrow from '../asset/img/doublepaginationarrow.png';
import Arrow from '../asset/img/paginationarrow.png';

function Pagination(props) {

    const goToFirstPage = () => {
        if(!props.isFirstPage) {
            return(
                <NavLink to={`${props.url}1`}>
                    <DoubleArrowImg onClick={goToNextPage} src={DoubleArrow} alt="" />
                </NavLink>
            ) 
        }else {
            return(
                <DoubleArrowImg onClick={goToNextPage} src={DoubleArrow} alt="" />
                )
        }
    }

    const goToLastPage = () => {
        if(!props.isLastPage) {
            return(
                <NavLink to={`${props.url}${props.pages}`}>
                    <RightDoubleArrowImg onClick={goToNextPage} src={DoubleArrow} alt="" />
                </NavLink>
            ) 
        } else{
            return(
                <RightDoubleArrowImg onClick={goToNextPage} src={DoubleArrow} alt="" />
            )
        }
    }

    const goToNextPage = () => {
        if(props.hasNextPage) {
            return(
                <NavLink to={`${props.url}${props.nextPage}`}>
                    <RightArrowImg onClick={goToNextPage} src={Arrow} alt="" />
                </NavLink>
            ) 
        } else{
            return(
                <RightArrowImg onClick={goToNextPage} src={Arrow} alt="" />
            )
        }
    }

    const goToPrePage = () => {
        if(props.hasPrePage) {
            return(
                <NavLink to={`${props.url}${props.prePage}`}>
                    <ArrowImg onClick={goToNextPage} src={Arrow} alt="" />
                </NavLink>
            ) 
        } else{
            return(
                <ArrowImg onClick={goToNextPage} src={Arrow} alt="" />
            )
        }
    }
    const firstPageNum = () => {
        return 1 + (10 * Math.floor( (props.no - 1) / 10))
    }
    const lastPageNum = () => {
        return (props.pages === 0 || props.pages === undefined) ? 1 : (Math.floor( (props.no - 1) / 10) + 1) * 10;
    }
    const navigatePage = () => {
        return Math.floor( (props.no - 1) / 10);
    }

    const pages = () => {
        const result = [];
        for(let i = firstPageNum(); i <= lastPageNum() ; i++){
            result.push(<NavLink key={i} to={`${props.url}${i}`}>{i}</NavLink>);
            if(i === props.pages) break;
        }
        return result;
    }

    return (
        <PaginationWrapper>
            <div>
                {goToFirstPage()}
                {goToPrePage()}
                {pages()}
                {goToNextPage()}
                {goToLastPage()}
            </div>
        </PaginationWrapper>
    )
}

export default Pagination