import React, { useEffect } from 'react';
import styled from 'styled-components';
import GrayQuestionMark from "../asset/img/grayquestionmark.png";
import SelectArrow from "../asset/img/selectarrow.png";
import Postcode from "./Postcode";

const Table = styled.table`
  & > tbody{
    & > tr:nth-child(8){
      & > td:nth-child(2){
        vertical-align: middle !important;
      }
    }
    & > tr:nth-child(9){
      border-top: none;
      border-bottom: none;
      & > td:nth-child(2){
        vertical-align: middle !important;
      }
    }
    & > tr:nth-child(10){
      border-top: none;
      border-bottom: none;
      & > td:nth-child(2){
        vertical-align: middle !important;
      }
    }
    & > tr:nth-child(11){
      border-top: 1px solid #e5e5e5 !important;
      & > td:first-child:after{
        content: ${props => props.len === 0 ? '관리자' : '관리자'};
      }
    }
    & > tr > td:first-child{
      width: 17% !important;
    }
  }
`
const Td = styled.td`
position : relative;
& > div {
  display : flex;
  width : 100%;
  align-items : center;
  column-gap : 10px;
  & > img {
    width : 18px;
    cursor : pointer;
  }
}
`
const ToolTop = styled.div`
  display : ${props => props.hover ? 'block !important' : 'none !important'};
  position : absolute;
  background-color: white;
  border: 1px solid #C4C4C4;
  border-radius: 5px;
  left : 120px;
  top : 20px;
  width : 500px !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding : 3%;
  & > p:last-child{
    margin-top : 15px;
  }
`

const Color = styled.span`
  color : ${props => props.state === 'Y' ? '' : '#df1f26' } ;
`

const BigInput = styled.input`
  border: 1px solid #C4C4C4;
  width: 25%;
  padding: 8px;
  border-radius: 10px;
`

const SmallInput = styled.input`
  border: 1px solid #C4C4C4;
  width: 2%;
  padding: 7px 5px;
  border-radius: 10px;
  text-align: center;
`

const Select = styled.select`
  appearance:none;
  border: 1px solid #C4C4C4;
  border-radius: 10px;
  padding : 4px 10px;
  width: 90px;
  font-size: 16px;
  line-height: 23px;
  background-image: url(${SelectArrow});
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: 85%;
  cursor: pointer;
  &:focus{
    outline: none;
  }
`

const Tr = styled.tr`
  border-top: none !important;
  border-bottom: none !important;
`

const Span = styled.span`
  font-size: 12px;
`

const RowBox = styled.div`
  display : flex;
  align-items : center;
  width : 40%;
  column-gap: 10px; 
  & > input {
    width : 73%;
  }
 & > p {
   color : #DF1F26;
   font-size: 12px;
   border-radius: 10px;
   border: 1px solid #DF1F26;
   padding : 0 7px 1px 7px;
   cursor: pointer;
 }
`

const AddressInput = styled.input`
  border: 1px solid #C4C4C4;
  width: 25%;
  padding: 7px;
  border-radius: 10px;
  &:focus{
    outline: none;
  }
`

const TimeInput = styled.input`
  border: 1px solid #C4C4C4;
  width: 120px;
  padding: 7px 5px;
  border-radius: 10px;
  text-align: center;
  -moz-appearance:textfield;
`

function DeviceDetailTable(props) {
  const { kakao } = window;
  const [onHover , setOnHover] = React.useState(false);
  const [location , setLocation] = React.useState('');
  const [lat , setLat] = React.useState('');
  const [long , setLon] = React.useState('');
  const [toggleAddressModal , setToggleAddressModal] = React.useState(false);

  const handleInOnHover = () => {
    setOnHover(true);
  }
  const handleOutOnHover = () => {
    setOnHover(false);
  }

  useEffect(()=>{
    setLocation(props.adress);
  } , [])

  useEffect(() => {
    if(kakao) {
      const geocoder = new kakao.maps.services.Geocoder();
      geocoder.addressSearch(props.address, function (results, status) {
          // 정상적으로 검색이 완료됐으면
          console.log(results[0].y , results[0].x)
          if(parseFloat(results[0].y).toFixed(7) === 'NaN'){
              setLat('-');
              setLon('-');
          } else {
              console.log(parseFloat(results[0].y).toFixed(7));
              props.setLat(parseFloat(results[0].y).toFixed(7))
              setLat(parseFloat(results[0].y).toFixed(7))
              props.setLon(parseFloat(results[0].x).toFixed(7))
              setLon(parseFloat(results[0].x).toFixed(7))
          }
          console.log(results[0])
          if(results[0].y !== undefined && results[0].x !== undefined){
            props.setLocation(props.address);
          }
      });
    }
    //광고 등록 위치 스테이트
  } , [location,  lat , long , props.address])

  const handleToggleAddressModal = () => {
    setToggleAddressModal(!toggleAddressModal);
}
    return (
        <Table len={props.info['admin'] && props.info['admin'].length}>
            <tbody>
            <tr>
                <td>주소 / 위치</td>
                <td colSpan='2'>
                    <div>
                    <RowBox>
                        <AddressInput type='text' name='address' value={props.address||''} onChange={props.onTableInputChange} readOnly/>
                        <p onClick={handleToggleAddressModal}>주소찾기</p>
                        {
                            toggleAddressModal &&
                                <Postcode toggle={toggleAddressModal} setLocation={props.setLocation||''} toggleFunc={handleToggleAddressModal}/>
                        }
                    </RowBox>
                        {/* <BigInput type='text' name={'address'} value={props.address} onChange={props.onTableInputChange}/> */}
                    </div>
                </td>
            </tr>
            <tr>
              <td>위치</td>
              <td>
                <Select name="outside" id="" value={props.outside||'Y'}  onChange={props.onTableInputChange}>
                    <option value="Y">실외</option>
                    <option value="N">실내</option>
                </Select> 
              </td>
            </tr>
            <tr>
                <td>스테이션 ID</td>
                <td>{props.info.station_id}</td>
            </tr>
            <tr>
                <td>종류</td>
                <td>
                    <Select name="privateStation" id="" value={props.privateStation||'Y'} onChange={props.onTableInputChange}>
                        <option value="Y">민영</option>
                        <option value="N">공영</option>
                    </Select>
                </td>
            </tr>
            <tr>
                <Td>
                  <div>
                    <p>충전기 종류</p>
                    <img src={GrayQuestionMark} alt="" onMouseEnter={handleInOnHover} onMouseLeave={handleOutOnHover}/>
                  </div>
                  {onHover && (
                  <ToolTop hover={onHover}>
                    <p>충전기 종류를 잘못 선택시에 스테이션이 원활하게 작동하지 않을 수 있습니다. 정확한 충전기 종류를 선택해주세요.</p>
                    <p>스테이션 등록이 완료되면 충전기 종류를 수정할 수 없습니다.</p>
                  </ToolTop>
                  )
                  }
                </Td>
                <td>
                    <Select name="chargerStatus" id="" value={props.chargerStatus||undefined} onChange={props.onTableInputChange}>
                        <option value={null}>선택</option>
                        <option value="KR">한국</option>
                        <option value="CN">중국</option>
                    </Select>
                </td>
            </tr>            
            <tr>
                <td>전원</td>
                <td><Color state={props.running}>{props.running === 'Y' ? 'ON' : 'OFF'}</Color></td>
            </tr>
            <tr>
                <td>위도</td>
                <td colSpan='2'>
                  {/* {props.lat === '' ? '-' : props.lat} */}
                  <BigInput type='text' name={'latitude'} value={props.latitude||''} onChange={props.onTableInputChange}/>
                </td>
            </tr>
            <tr>
                <td>경도</td>
                <td colSpan='2'>
                  {/* {props.lon === '' ? '-' : props.lon} */}
                  <BigInput type='text' name={'longitude'} value={props.longitude||''} onChange={props.onTableInputChange}/>
                </td>
            </tr>
            <Tr>
                <td>운영 시간</td>
                <td>평일</td>
                <td>
                    <TimeInput type='time' name='weekStart' value={props.weekStart||''} onChange={props.onTableInputChange}/> ~ <TimeInput type='time' name='weekEnd' value={props.weekEnd||''} onChange={props.onTableInputChange}/> 
                </td>
            </Tr>
            <Tr>
                <td/>
                <td>토요일</td>
                <td>
                  <TimeInput type='time' name='satStart' value={props.satStart||''} onChange={props.onTableInputChange}/> ~ <TimeInput type='time' name='satEnd' value={props.satEnd||''} onChange={props.onTableInputChange}/> 
                </td>
            </Tr>
            <Tr>
                <td/>
                <td>일요일/공휴일</td>
                <td>
                <TimeInput type='time' name='holiStart' value={props.holiStart||''} onChange={props.onTableInputChange}/> ~ <TimeInput type='time' name='holiEnd' value={props.holiEnd||''} onChange={props.onTableInputChange}/> 
                </td>
            </Tr>
            {
                props.info['admin'] && props.info['admin'].length === 0 ? 
                <Tr>
                    <td>관리자</td>
                    <td></td>
                </Tr>
                :
                props.info['admin'] && props.info['admin'].map((item,key) => (
                    <Tr key={key}>
                        <td/>
                        <td>{item.admin_name}</td>
                    </Tr>
                ))
            }   
            <tr>
                <td>충전 요금 / <Span>10분(원)</Span></td>
                <td>{props.info.charge_price}</td>
            </tr>
            <tr>
                <td>거치 요금 / <Span>10분(원)</Span></td>
                <td>{props.info.holder_price}</td>
            </tr>
            </tbody>
        </Table>
    );
}

export default DeviceDetailTable;