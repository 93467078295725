import React, {useEffect} from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import {SearchBox} from "../css/styles";
import DeleteSearch from "../asset/img/deletesearch.png";
import SelectArrow from "../asset/img/selectarrow.png";
import Dotbogi from "../asset/img/dotbogi.png";
import axios from "axios";
import CouponLimitModal from "./CouponLimitModal";
import Cookie from "universal-cookie";
import {useParams} from "react-router";
import CouponUserSendModalList from "./CouponUserSendModalList";
import Pagination from "./Pagination";
import CheckBox from '../components/CheckBox';
import SearchIndexBoxModal from "./SearchIndexBoxModal";

const cookies = new Cookie();

const Title = styled.h1`
    font-weight: bold;
    font-size: 24px;
    line-height: 35px;
    color: #221E1F;
`

const BigInput = styled.input`
    border: 1px solid #c4c4c4;
    width: 12%;
    padding: 8px;
    border-radius: 10px;
    &:focus {
        outline: none;
    }
`;

const SelectBox = styled.select`
  cursor: pointer;
  appearance:none;
  border: 1px solid #C4C4C4;
  border-radius: 10px;
  padding: 7px 10px;
  width: 10%;
  font-size: 16px;
  line-height: 23px;
  background-color: white;
  background-image: url(${SelectArrow});
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: 85%;
  &:focus{
    outline: none;
  }
`


const SearchInput = styled.input`
  margin-left: 21px;
  width: 60%;
  border: 1px solid #C4C4C4;
  border-radius: 10px;
  background-image: url(${Dotbogi});
  background-repeat: no-repeat;
  background-size: 17px;
  background-position: 97%;
  padding: 7px 10px;
  font-size: 16px;
  line-height: 23px;
  color: #7E7E7E;
`

const ButtonContainer = styled.div`
    display : flex;
    justify-content : space-between;
    padding-top : 2%;
  & > button:first-child{
    color : #df1f26;
  }
`

const Button = styled.button`
  background-color: white;
  border-radius: 10px;
  border: 1px solid #C4C4C4;
  width : 11%;
  padding : 10px 0 ;
  font-weight: bold;
  font-size: 14px;
  color: #221E1F;
`

const Table = styled.table`
  width: 100%;
  line-height: 40px;
  text-align: center;
  & > thead > tr{
    border-bottom: 2px solid #e5e5e5;
    line-height: 23px;
    & > th {
      vertical-align: middle;
    }
    & > th:nth-child(1) {
        width : 5%;
    }
    & > th:nth-child(2) {
        width : 10%;
    }
    & > th:nth-child(5) {
        width : 13%;
    }
  }
  & > tbody > tr{
    border-bottom: 1px solid #e5e5e5;
  }
`
const TableDiv = styled.div`
  height: 65%;
  padding-top: 15px;
  overflow-y : scroll;
`

const ModalSearchBox = styled(SearchBox)`
  width: 100%;
`

const ThSelectBox = styled(SelectBox)`
    border : none;
    background-color : white;
    background-position: 100%;
    font-family : notosansmedium;
    width : 70%;
`

const SearchAmountContainer = styled.div`
    display : flex;
    align-items : center;
    column-gap: 13px; 
    font-family : notosansbold;
    padding : 20px 0 ;
`

function StationUsageModal(props) {

    const {no , usage} = useParams();
    const [deviceInfo , setDeviceInfo] = React.useState([]);
    const [deviceListInfo , setDeviceListInfo] = React.useState([]);

    const [searchData , setSearchData] = React.useState(null);
    const [searchType , setSearchType] = React.useState(null);

    const [checked, setChecked] = React.useState(false);
    const [checkAll, setCheckAll] = React.useState(false);
    const [checkList, setCheckList] = React.useState([]);

    const [startAmount , setStartAmount] = React.useState(null);
    const [endAmount , setEndAmount] = React.useState(null);

    const [answerState , setAnsetState] = React.useState(null);

    const [toggleCouponLimitModal , setToggleCouponLimitModal] = React.useState(false);

    const handleToggleCouponLimitModal = () => {
        setToggleCouponLimitModal(!toggleCouponLimitModal);
    }

    const handleAnswerState = (e) => {
        console.log(e.target.value)
        if(e.target.value === ''){
            setAnsetState(null);
        }else{
            setAnsetState(e.target.value);
        }
    }

    const handleStartAmount = (e) => {
        if(e.target.value === ''){
            setStartAmount(null);
        } else {
            setStartAmount(e.target.value);
        }
    }

    const handleEndAmount = (e) => {
        if(e.target.value === ''){
            setEndAmount(null);
        } else {
            setEndAmount(e.target.value);
        }
    }

    useEffect(()=>{
        axios({
            method : 'get',
            url : `${window.Config.G_API_URL}/admin/coupon/detail/users`,
            params : {
                token : cookies.get('token'),
                search_type : searchType,
                search_data : searchData,
                start_amount : startAmount,
                end_amount : endAmount,
            }
        })
            .then(response => {
                if(response.data.result['code'] === '200'){
                    setDeviceListInfo(response.data.data)
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function(error){
                alert(error);
            })
    },[searchType , searchData , startAmount , endAmount   ])

    useEffect(()=>{
        if(checkAll){
            let arr = [];
            deviceListInfo.map((item)=>{
                    arr.push(`${item.us_id}`);
            });
            setCheckList(arr);
        } else if(checkList.length==deviceListInfo.length) {
            setCheckList([]);
        }
    },[checkAll]);

    const onTypeChange = (e) => {
        if(e.target.value === 'null'){
            setSearchType(null)
        } else {
            setSearchType(e.target.value);
        }
    }

    const onSearchDataChange = (e) => {
        setSearchData(e.target.value);
    }

    const onReset = () => {
        setSearchData('');
    }

    const handleCheckAll = (e) => {
        setCheckAll(!checkAll);
    };

    const handleCheckEach = (e) => {
        const { id, checked } = e.target;
        let arr = [...checkList];

        if (arr.includes(id)) {
            for (let i = 0; i < arr.length; i++) {
                if (arr[i] == id) {
                    arr.splice(i, 1);
                    setChecked(!checked);
                    break;
                }
            }
            setCheckList(arr);
        } else {
            setChecked(!checked);
            setCheckList([...checkList, id]);
        }
    };

    const sendCoupon = () => {
        axios({
            method : 'post',
            url : `${window.Config.G_API_URL}/admin/coupon/user`,
            params : {
                token : cookies.get('token'),
                coupon_id : no.replace(':' , ''),
                us_id : checkList
            },
            // no : checkList 파라미터 [] 없애는 옵션
            paramsSerializer : function(paramObj) {
                const params = new URLSearchParams()
                for (const key in paramObj) {
                    params.append(key, paramObj[key])
                }
                return params.toString()
            }
        })
            .then(response => {
                if(response.data.result['code'] === '200'){
                    alert('발송이 완료되었습니다.')
                    window.location.reload();
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                }   else if (response.data.result['code'] === '403'){
                    handleToggleCouponLimitModal();
                } else {
                    alert(response.data.result['message']);
                }
            }).catch(function(error){
            alert(error);
        })
    }

    return (
        <Modal isOpen={props.toggle} onRequestClose={props.toggleFunc}
               ariaHideApp={false}
               style={{
                   overlay :{
                       backgroundColor : 'rgba(34, 30, 31, 0.5)'
                   },
                   content : {
                       height : '85%',
                       left : '15%',
                       right : '7%',
                       top : '5%',
                       borderRadius : '10px',
                   }
               }}
        >
            <Title>쿠폰 회원 발송</Title>
            <ModalSearchBox>
                    <SelectBox name="searchselect" id="serachselect" onChange={onTypeChange}>
                        <option value={'null'}>선택</option>
                        <option value="회원 고유번호">회원 고유번호</option>
                        <option value="이름">이름</option>
                        <option value="연락처">연락처</option>
                    </SelectBox>
                    <SearchInput type="text" placeholder={'검색어 입력'} onChange={onSearchDataChange}/>
                    <div onClick={onReset}>
                        <img src={DeleteSearch} alt=""/>
                        <p>초기화</p>
                    </div>
            </ModalSearchBox>
            <SearchAmountContainer>
                누적 이용금액
                <BigInput
                    type="text"
                    placeholder="입력"
                    value={startAmount}
                    onChange={handleStartAmount}
                />
                ~
                <BigInput
                    type="text"
                    placeholder="입력"
                    value={endAmount}
                    onChange={handleEndAmount}
                />
            </SearchAmountContainer>
            <TableDiv>
                <Table>
                    <thead>
                    <tr>                        
                        <th>
                            <CheckBox
                                no="checkAll"
                                checked={checkAll}
                                onChange={handleCheckAll}
                            />
                        </th>
                        <th>No.</th>
                        <th>회원 고유번호</th>
                        <th>이름</th>
                        <th>연락처</th>
                        <th>생년월일</th>
                        <th>누적 이용금액</th>
                    </tr>
                    </thead>
                    <tbody>
                        {deviceListInfo.map((item) => (
                            <CouponUserSendModalList
                                rownum={item.rownum}
                                us_id={item.us_id}
                                us_name={item.us_name}
                                us_phone={item.us_phone}
                                us_birth={item.us_birth}
                                amount={item.amount}
                                checked={checkList.includes(`${item.us_id}`)}
                                onChange={handleCheckEach}
                            />
                        ))}
                    </tbody>
                </Table>
            </TableDiv>
            <ButtonContainer>
                <Button onClick={sendCoupon}>쿠폰 발송</Button>
                <Button onClick={props.toggleFunc}>닫기</Button>
            </ButtonContainer>
            <CouponLimitModal
                toggle={toggleCouponLimitModal}
                toggleFunc={handleToggleCouponLimitModal}
            />
        </Modal>
    );
}

export default StationUsageModal;
