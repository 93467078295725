import React , {useEffect} from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

const Description = styled.p`
    font-size: 16px;
    line-height: 23px;
    text-align: left;
    & > p:first-child{
        font-family : notosansbold
    }
    & > p:nth-child(2){

    }
    & > p:last-child{
        font-family : notosansbold;
        color : #df1f26;
    }
`

const ButtonContainer = styled.div`
    display : flex;
    justify-content : space-between;
    margin-top : 35px;
    padding : 0 20%;
`

const Button = styled.button`
    font-size: 16px;
    line-height: 23px;
    padding : 7px 20px;
    background-color: white;
    border-radius: 10px; 
    border: 1px solid #C4C4C4;
`

const RedButton = styled(Button)`
    color: white;
    background-color : ${props => props.active ? '#df1f26' : '#E5E5E5'};
    border : none;
`

const BigInput = styled.input`
  border: 1px solid #C4C4C4;
  width: 90%;
  padding: 10px;
  border-radius: 10px;
  margin-top : 7%;
  &::-webkit-calendar-picker-indicator{
    cursor: pointer;
  }
`

function DeleteFromGroupModal(props) {

    const [active , setActive] = React.useState(false);
    const [value , setValue] = React.useState('');

    const onValueChange = (e) => {
        setValue(e.target.value);
    }

    useEffect(()=>{
        if(value === '삭제하겠습니다.'){
            setActive(true);
        } else {
            setActive(false);
        }
    } , [value])

    return (
        <Modal isOpen={props.toggle} onRequestClose={props.toggleFunc}
            style={{
                overlay :{
                    backgroundColor : 'rgba(34, 30, 31, 0.5)'
                },
                content : {
                    height : '220px',
                    left : '37%',
                    right : '37%',
                    top : '35%',
                    borderRadius : '10px',
                    border: '1px solid #C4C4C4' ,
                }
            }}
        >
            <Description>
                <p>선택한 {props.content} 삭제하시겠습니까?</p> <br/>
                <p>진행하시려면 다음 문구를 따라 입력해주세요.</p>
                <p>삭제하겠습니다.</p>
            </Description>
            <BigInput type='text' name='title' value={value} onChange={onValueChange} placeholder={'삭제하겠습니다.'}/>
            <ButtonContainer>
                <Button onClick={props.toggleFunc}>취소</Button>
                <RedButton active={active} disabled={!active}  onClick={props.deleteFunc} readOnly>확인</RedButton>
            </ButtonContainer>
        </Modal>
    );
}

export default DeleteFromGroupModal;
