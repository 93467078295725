import React, { useEffect }  from 'react'
import styled from 'styled-components';
import CheckBox from "./CheckBox";
import Cookie from 'universal-cookie';
const cookies = new Cookie();

const Color = styled.td`
  color : ${props => props.state === '이용 정지' ? '#DF1F26' : ''};
`

const Td = styled.td`
    width : 7%;
`

const TdRight = styled.td`
    text-align : right;
`

function ManageUserList(props) {

    const [levelAuth , setLevelAuth] = React.useState(false);

    const goToDetail = () => {
        window.location.href = `/admin/breakoutuser/detail/:${props.no}/:${props.num}/pointmanage/:1`
    }

    const returnType = (type) => {
        switch (type) {
            case 'sum' :
                return 'SU:MSTATION'
            case 'kakao' :
                return '카카오'
            case 'naver' :
                return '네이버'
            case 'apple' :
                return '애플'
            case 'google' :
                return '구글'
            default :
                return '알 수 없음'
        }
    }

    useEffect(()=>{
        if(cookies.get('level')<=0){
            setLevelAuth(true);
        }
    },[])

    const maskingPhone = (originStr) => {
        let maskingStr = '';
        let phoneStr = originStr.length < 11 ? originStr.match(/\d{10}/gi) : originStr.match(/\d{11}/gi);
			
			if(originStr.length < 11)
			{ // 1.1) 0110000000
				return maskingStr = originStr.toString().replace(phoneStr, phoneStr.toString().replace(/(\d{3})(\d{3})(\d{4})/gi,'$1***$3'));
			}
			else
			{ // 1.2) 01000000000
				return maskingStr = originStr.toString().replace(phoneStr, phoneStr.toString().replace(/(\d{3})(\d{4})(\d{4})/gi,'$1****$3'));
			}
		}    
        const numberWithCommas = (x) => {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        };
    
        
    return (
        <tr>
            <td><CheckBox no={props.no} onChange={props.onChange} checked={props.checked}/></td>
            <td onClick={goToDetail}>{props.no} </td>
            <Td onClick={goToDetail}>{props.name}</Td>
            <td onClick={goToDetail}>{props.email}</td>
            <td onClick={goToDetail}>{returnType(props.type)}</td>
            <td onClick={goToDetail}>{levelAuth ? props.phone : maskingPhone(props.phone)}</td>
            <TdRight onClick={goToDetail}>{props.point === undefined ? props.point : numberWithCommas(props.point)}</TdRight>
            <td onClick={goToDetail}>{props.count}</td>
            <td onClick={goToDetail}>{props.date}</td>
            <td onClick={goToDetail}>{props.breakoutDate}</td>
        </tr>
    )
}

export default ManageUserList