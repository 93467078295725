import React, {useEffect} from 'react';
import {BcArrow, Description, SearchGroupDetailWrapper, Title} from "../css/styles";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useParams } from 'react-router';
import Cookie from "universal-cookie";
import AdvertisementUpdateTable from "../components/AvertisementUpdateTable";
import breadcrumbArrow from "../asset/img/breadcrumbarrow.png";
import AuthCheckModal from '../components/AuthCheckModal';

const ButtonWrapper = styled.div`
  & > button:first-child{
    padding : 5px 20px !important;
  }
  & > button:last-child{
    padding : 5px 20px !important;
    color: white;
    background-color: #DF1F26;
  }
`

function AdvertisementUpdate({props}) {

    const cookies = new Cookie();
    const { no, num } = useParams();
    const [adInfo, setAdInfo] = React.useState([]);
    // table 용 state
    const [formInfo , setFormInfo] = React.useState({
        title: adInfo.ad_name,
        startDate : '',
        type : adInfo.ad_type,
    })
    const [toggleAuthModal , setToggleAuthModal] = React.useState(false);

    const handleToggleAuthModal = () => {
        setToggleAuthModal(!toggleAuthModal);
    }


    const [imgModify , setImgModify] = React.useState(false);

    //파일 전송 state
    const [imgBase64, setImgBase64] = React.useState(null)

    const navigate = useNavigate()

    const onRegister = () => {
        var formdata = {};
        formdata.ad_exposure_expire_date = adInfo.ad_exposure_expire_date;
        formdata.ad_id = adInfo.ad_id
        formdata.ad_image_url = imgBase64;
        formdata.ad_name = adInfo.ad_name;
        formdata.ad_type = adInfo.ad_type;
        formdata.ad_image_modify = imgModify;
        formdata.ad_image_previous = adInfo.ad_image_url;
        formdata.token = cookies.get('token');

        axios({
            method : 'put',
            url : `${window.Config.G_API_URL}/admin/advertisement`,
            data :  formdata,
        })
            .then(response => {
                if(response.data.result['code'] === '200'){
                    window.location.href = '/admin/advertisement/:1'
                } else if(response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            }).catch(function(error){
            alert(error);
        })
    }

    useEffect(() => {
        axios({
            method: 'get',
            url: `${window.Config.G_API_URL}/admin/advertisement/detail`,
            params: {
                token: cookies.get('token'),
                no: no.replace(':', ''),
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    setAdInfo(response.data.data);
                    console.log(response.data.data)
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    }, [no]);

    const tableInputChange = (e) => {
        let {name , value} = e.target;
        switch (name){
            case 'title' :
                setAdInfo({...adInfo , ad_name : value})
                break;
// 
            // case 'EndDate' :
            //     setFormInfo({...formInfo , EndDate : value})
            //     break;
            case 'type' :
                setAdInfo({...adInfo , ad_type : value})
                break;
            case 'EndDate' :
                setAdInfo({...adInfo , ad_exposure_expire_date : value})
                break;
        }
    }

    return (
        <SearchGroupDetailWrapper>
            <Title>
                광고 수정
            </Title>
            <Description>
                광고 관리 <BcArrow src={breadcrumbArrow} /> No.{adInfo.ad_id} <BcArrow src={breadcrumbArrow} /> 수정
            </Description>
            <AdvertisementUpdateTable
                onTableInputChange={tableInputChange}
                imgBase={imgBase64}
                setImgBase={setImgBase64}
                info={adInfo} 
                title={formInfo.title}
                setImgModify={setImgModify}
            />
            <ButtonWrapper>
                <button onClick={() => navigate(-1)}>취소</button>
                <button onClick={onRegister}>등록</button>
            </ButtonWrapper>
            <AuthCheckModal
                toggle={toggleAuthModal}
                toggleFunc={handleToggleAuthModal}
            />
        </SearchGroupDetailWrapper>
    );
}

export default AdvertisementUpdate;