import React, { useEffect } from 'react';
import {
    Description,
    SearchGroupDetailWrapper,
    Title,
    BcArrow,
} from '../css/styles';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import SearchManagerModal from '../components/SearchManagerModal';
import Delete from '../asset/img/deletemanager.png';
import DeviceDetailUpdateTable from '../components/DeviceDetailUpdateTable';
import GrayQuestionMark from '../asset/img/grayquestionmark.png';
import axios from 'axios';
import { useParams } from 'react-router';
import Cookie from 'universal-cookie';
import AuthCheckModal from '../components/AuthCheckModal';
import EmptyValueModal from '../components/EmptyValueModal';

import breadcrumbArrow from '../asset/img/breadcrumbarrow.png';

const cookies = new Cookie();

const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start !important;
    & > input {
        font-weight: bold;
        font-size: 32px;
        line-height: 46px;
        border: none;
        border-bottom: 2px solid #e5e5e5;
        &:focus {
            outline: none;
        }
    }
    & > img {
        width: 20px;
        cursor: pointer;
    }
`;

const ButtonWrapper = styled.div`
    & > button:first-child {
        padding: 5px 20px !important;
    }
    & > button:last-child {
        padding: 5px 10px !important;
        color: white;
        background-color: #df1f26;
    }
`;

const CaptionWrapper = styled.div`
    display: flex;
    justify-content: flex-start !important;
    align-items: center;
    margin-top: 7px !important;
    column-gap: 5px;
    & > img {
        width: 18px;
    }
    & > p {
        font-size: 14px;
        line-height: 160%;
        letter-spacing: -0.035em;
        color: #999999;
    }
`;

function DeviceDetailUpdate({ props }) {
    const { no } = useParams();
    const [stationInfo, setStationInfo] = React.useState([]);
    const [toggleModal, setToggleModal] = React.useState(false);

    const [title, setTitle] = React.useState('');
    const [address, setAddress] = React.useState('');
    const [weekStart, setWeekStart] = React.useState('');
    const [weekStartMin, setWeekStartMin] = React.useState('');
    const [weekEnd, setWeekEnd] = React.useState('');
    const [weekEndMin, setWeekEndMin] = React.useState('');
    const [satStart, setSatStart] = React.useState('');
    const [satStartMin, setSatStartMin] = React.useState('');
    const [satEnd, setSatEnd] = React.useState('');
    const [satEndMin, setSatEndMin] = React.useState('');
    const [holiStart, setHoliStart] = React.useState('');
    const [holiStartMin, setHoliStartMin] = React.useState('');
    const [holiEnd, setHoliEnd] = React.useState('');
    const [holiEndMin, setHoliEndMin] = React.useState('');
    const [outside, setOutside] = React.useState('');
    const [privateStation, setPrivateStation] = React.useState('');
    const [chargerStatus, setChargerStatus] = React.useState('');
    const [latitude , setLatitude] = React.useState('');
    const [longitude , setLongitude] = React.useState('');
    const [toggleAuthModal , setToggleAuthModal] = React.useState(false);
    const [toggleEmptyModal , setToggleEmptyModal] = React.useState(false);

    const handleToggleAuthModal = () => {
        setToggleAuthModal(!toggleAuthModal);
    }
    const handleToggleEmptyModal = () => {
        setToggleEmptyModal(!toggleEmptyModal);
    }
    useEffect(() => {
        axios({
            method: 'get',
            url: `${window.Config.G_API_URL}/admin/station/detail`,
            params: {
                token: cookies.get('token'),
                station_id: no.replace(':', ''),
            },
        })
            .then((response) => {
                console.log(response.data.data['address'])
                if (response.data.result['code'] === '200') {
                    setStationInfo(response.data.data);
                    setTitle(response.data.data['station_name']);
                    setAddress(response.data.data['address']);
                    setWeekStart(response.data.data['weekdays_hours_start']+ ':' +response.data.data['weekdays_hours_start_min']);
                    setWeekEnd(response.data.data['weekdays_hours_end'] + ':' + response.data.data['weekdays_hours_end_min']);
                    setSatStart(response.data.data['saturday_hours_start'] + ':' + response.data.data['saturday_hours_start_min']);
                    setSatEnd(response.data.data['saturday_hours_end'] + ':' + response.data.data['saturday_hours_end_min']);
                    setHoliStart(response.data.data['holiday_hours_start'] + ':' + response.data.data['holiday_hours_start_min']);
                    setHoliEnd(response.data.data['holiday_hours_end'] + ':' + response.data.data['holiday_hours_end_min']);
                    setOutside(response.data.data['outside']);
                    setPrivateStation(response.data.data['private_station']);
                    setChargerStatus(response.data.data['charger_status']);
                    setLatitude(response.data.data['latitude']);
                    setLongitude(response.data.data['longitude']);
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    }, []);

    const handleModal = () => {
        setToggleModal(!toggleModal);
    };

    const navigate = useNavigate();

    const onUpdate = () => {
        // console.log(address)
        const param = {
            token: cookies.get('token'),
            station_id: no.replace(':', ''),
            station_name: title,
            private_station: privateStation,
            charger_status: chargerStatus,
            outside: outside,
            address: address,
            weekdays_hours_start: weekStart === '' ? null : weekStart,
            weekdays_hours_end: weekEnd === '' ? null : weekEnd,
            saturday_hours_start: satStart === '' ? null : satStart,
            saturday_hours_end: satEnd === '' ? null : satEnd,
            holiday_hours_start: holiStart === '' ? null : holiStart,
            holiday_hours_end: holiEnd === '' ? null : holiEnd,
            latitude : latitude,
            longitude : longitude,
        };

        axios({
            method: 'put',
            url: `${window.Config.G_API_URL}/admin/station/detail`,
            params: param,
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    window.location.href = `/admin/devicedetail/${no}/:1/:1`
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else if(response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                handleToggleEmptyModal();
            });
    };

    const tableInputChange = (e) => {
        let name = e.target.name;
        switch (name) {
            case 'title':
                setTitle(e.target.value);
                break;
            case 'address':
                setAddress(e.target.value);
                break;
            case 'weekStart':
                setWeekStart(e.target.value);
                break;
            case 'weekStartMin':
                setWeekStartMin(e.target.value);
                break;
            case 'weekEnd':
                setWeekEnd(e.target.value);
                break;
            case 'weekEndMin':
                setWeekEndMin(e.target.value);
                break;
            case 'satStart':
                setSatStart(e.target.value);
                break;
            case 'satStartMin':
                setSatStartMin(e.target.value);
                break;
            case 'satEnd':
                setSatEnd(e.target.value);
                break;
            case 'satEndMin':
                setSatEndMin(e.target.value);
                break;
            case 'holiStart':
                setHoliStart(e.target.value);
                break;
            case 'holiStartMin':
                setHoliStartMin(e.target.value);
                break;
            case 'holiEnd':
                setHoliEnd(e.target.value);
                break;
            case 'holiEndMin':
                setHoliEndMin(e.target.value);
                break;
            case 'outside':
                setOutside(e.target.value);
                break;
            case 'privateStation':
                setPrivateStation(e.target.value);
                break;
            case 'chargerStatus':
                setChargerStatus(e.target.value);
                break;
            case 'latitude':
                if(e.target.value === ''){
                    setLatitude(null);
                } else {
                    setLatitude(e.target.value);
                }
                break;
            case 'longitude':
                if(e.target.value === ''){
                    setLongitude(null);
                } else {
                    setLongitude(e.target.value);
                }
                break;
        }
    };

    return (
        <SearchGroupDetailWrapper>
            <Title>스테이션 정보 수정</Title>
            <Description>
                스테이션 관리 <BcArrow src={breadcrumbArrow} /> 그룹 검색{' '}
                <BcArrow src={breadcrumbArrow} /> 그룹 상세{' '}
                <BcArrow src={breadcrumbArrow} /> 스테이션 상세{' '}
                <BcArrow src={breadcrumbArrow} /> 스테이션 수정
            </Description>
            <TitleWrapper>
                <input
                    name={'title'}
                    type="text"
                    value={title}
                    onChange={tableInputChange}
                />
                <img src={Delete} alt="" />
            </TitleWrapper>
            <DeviceDetailUpdateTable
                setLat={setLatitude}
                setLon={setLongitude}
                info={stationInfo}
                outside={outside}
                privateStation={privateStation}
                chargerStatus={chargerStatus}
                address={address}
                weekStart={weekStart}
                weekStartMin={weekStartMin}
                weekEnd={weekEnd}
                weekEndMin={weekEndMin}
                satStart={satStart}
                satStartMin={satStartMin}
                satEnd={satEnd}
                satEndMin={satEndMin}
                holiStart={holiStart}
                holiStartMin={holiStartMin}
                holiEnd={holiEnd}
                holiEndMin={holiEndMin}
                latitude={latitude}
                longitude={longitude}
                onTableInputChange={tableInputChange}
                toggle={handleModal}
                lat={latitude}
                lon={longitude}
                setLocation={setAddress}
            />
            <CaptionWrapper>
                <img src={GrayQuestionMark} alt="" />
                <p>관리자 및 요금 정보는 그룹에서 수정할 수 있습니다.</p>
            </CaptionWrapper>
            <ButtonWrapper>
                <button onClick={() => navigate(-1)}>취소</button>
                <button onClick={onUpdate}>수정 완료</button>
            </ButtonWrapper>
            <AuthCheckModal
                toggle={toggleAuthModal}
                toggleFunc={handleToggleAuthModal}
            />
            <EmptyValueModal
                toggle={toggleEmptyModal}
                toggleFunc={handleToggleEmptyModal}
            />
        </SearchGroupDetailWrapper>
    );
}

export default DeviceDetailUpdate;
