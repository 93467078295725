import React from 'react';
import styled from 'styled-components';

const Tr = styled.tr`
  font-size: 16px;
  line-height: 23px;
`

function LoginLogList(props) {
    return (
        <Tr>
            <td>{props.rowNum}</td>
            <td>{props.date}</td>
        </Tr>
    );
}

export default LoginLogList;