import React, { useEffect } from 'react';
import styled from "styled-components";

import axios from "axios";
import { useParams } from "react-router";
import Cookie from "universal-cookie";
import { BorderDivR } from '../css/styles';
import LoginLogList from "./LoginLogList";
import Pagination from "./Pagination";
import SearchIndexBox from "./SearchIndexBox";

const cookies = new Cookie();

const PointManageWrapper = styled.div`
  margin-top: 0 !important;
  & > table {
    width: 100%;
    text-align: center;
    & > tbody > tr {
      line-height: 40px;
      border-bottom: 1px solid #E5E5E5;
      font-weight: 500;
      font-size: 16px;
    }
    & > tbody > tr:first-child {
      line-height: 20px;
      border-bottom: 2px solid #E5E5E5;
      & > th:first-child{
        padding-bottom: 10px;
      }
    }
    & > tbody > tr > td:first-child{
      width: 10%;
    }
    & > tbody > tr > th:last-child{
      text-align: left;
      padding-left: 10%;
    }
    & > tbody > tr > td:last-child{
      text-align: left;
      padding-left: 7%;
    }
  }
  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    & > button {
      border: 1px solid #C4C4C4;
      border-radius: 10px;
      background-color: white;
      font-size: 16px;
      line-height: 23px;
      width: 8%;
      padding : 5px 0;
    }
    & > div > button {
      border: 1px solid #C4C4C4;
      border-radius: 10px;
      background-color: white;
      font-size: 16px;
      line-height: 23px;
      padding : 5px;
      margin-right: 10px;
    }
  }
`

const SearchBox = styled.div`
    padding : 2% 0;
    display: flex;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    column-gap: 15px;
    & > div {
      display: flex;
      align-items: center;
      & > img {
        margin-top: 10px;
        margin-left: 7px;
        width: 20px;
      }
      & > p {
        font-size: 16px;
        margin-top: 9px;
        line-height: 23px;
        color: #C4C4C4;
      }
    } 
`

const ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
`

const CursorBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: center !important;
`

function PointManage(props) {

    const {no , num , page} = useParams();
    const [loginInfo , setLoginInfo] = React.useState([]);
    const [loginPageInfo , setLoginPageInfo] = React.useState([]);
    const [startDate , setStartDate] = React.useState(null);
    const [endDate , setEndeDate] = React.useState(null);

    useEffect(() => {
        axios({
            method : 'get',
            url : `${window.Config.G_API_URL}/admin/user/history/login`,
            params : {
                token : cookies.get('token'),
                us_id : no.replace(':' , ''),
                page_num : num.replace(':' , ''),
                start_date : startDate,
                end_date : endDate,
            }
        })
            .then(response => {
                if(response.data.result['code'] === '200'){
                    console.log(response);
                    setLoginPageInfo(response.data.data.result)
                    setLoginInfo(response.data.data.result.list)
                  } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function(error){
                alert(error);
            })
    },[num , startDate , endDate]);

    const onStartDateChange = (e) => {
        setStartDate(e.target.value);
    }

    const onEndDateChange = (e) => {
        setEndeDate(e.target.value);
    }
  
    const handleDate = (e) => {
      var date = new Date();
      var currentDate = date.toISOString().substring(0,10);
      setEndeDate(currentDate);
      switch(e.target.id){
          case 'all':
              setStartDate(null);
              break;
          case 'week':
              var date = new Date();
              var newDate = new Date(date.setDate(date.getDate() - 7));
              var currentDate = newDate.toISOString().substring(0,10);
              setStartDate(currentDate);
              break;
          case 'halfMonth':
              var date = new Date();
              var newDate = new Date(date.setDate(date.getDate() - 14));
              var currentDate = newDate.toISOString().substring(0,10);
              setStartDate(currentDate);
              break;
          case 'month':
              var date = new Date();
              var newDate = new Date(date.setDate(date.getDate() - 30));
              var currentDate = newDate.toISOString().substring(0,10);
              setStartDate(currentDate);
              break;
          case 'threeMonth':
              var date = new Date();
              var newDate = new Date(date.setDate(date.getDate() - 91));
              var currentDate = newDate.toISOString().substring(0,10);
              setStartDate(currentDate);
              break;
          case 'halfYear':
              var date = new Date();
              var newDate = new Date(date.setDate(date.getDate() - 182));
              var currentDate = newDate.toISOString().substring(0,10);
              setStartDate(currentDate);
              break;
          case 'year':
              var date = new Date();
              var newDate = new Date(date.setDate(date.getDate() - 365));
              var currentDate = newDate.toISOString().substring(0,10);
              setStartDate(currentDate);
              break;
      }
  }

    return (
        <PointManageWrapper>
            <SearchBox>
                <ColumnBox>
                    <SearchIndexBox
                        startChange={onStartDateChange}
                        endChange={onEndDateChange}
                        handleDate={handleDate}
                        startDate={startDate}
                        endDate={endDate}
                    />
                </ColumnBox>
            </SearchBox>
            <table>
                <tbody>
                <tr>
                    <th><BorderDivR>No.</BorderDivR></th>
                    <th>로그인 일시</th>
                </tr>
                {
                    loginInfo.map((item , key) => (
                        <LoginLogList
                            key={key}
                            date={item.date_time}
                            rowNum={item.rownum}
                        />
                    ))
                }
                </tbody>
            </table>
            <CursorBox>
                <Pagination
                    no={num.replace(':' , '')}
                    url={`/admin/manageuser/detail/${no}/${page}/loginlog/:`}
                    pages={loginPageInfo['pages']}
                    isFirstPage={loginPageInfo['isFirstPage']}
                    isLastPage={loginPageInfo['isLastPage']}
                    prePage={loginPageInfo['prePage']}
                    nextPage={loginPageInfo['nextPage']}
                    hasPrePage={loginPageInfo['hasPreviousPage']}
                    hasNextPage={loginPageInfo['hasNextPage']}
                />
            </CursorBox>
        </PointManageWrapper>
    );
}

export default PointManage;