import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

const Description = styled.p`
    font-size: 16px;
    line-height: 23px;
    text-align: center;
`


const ModalButton = styled.button`
    display: flex;
    margin: auto;
    font-size: 16px;
    line-height: 23px;
    padding : 7px 50px;
    background-color: white;
    border-radius: 10px; 
    border: 1px solid #C4C4C4;
    color: red;
    margin-top : 13%;
    font-weight: bold;
`


function NoticeDeleteModal(props) {

    return (
        <Modal isOpen={props.toggle} onRequestClose={props.toggleFunc}
               style={{
                   overlay :{
                       backgroundColor : 'rgba(34, 30, 31, 0.5)'
                   },
                   content : {
                       height : '13%',
                       left : '38%',
                       right : '38%',
                       top : '35%',
                       borderRadius : '10px',
                       border: '1px solid #C4C4C4' ,
                   }
               }}
        >
            <Description>
                총 발행량을 초과하여 발행할 수 없습니다.
            </Description>
                <ModalButton onClick={props.toggleFunc}>확인</ModalButton>

        </Modal>
    );
}

export default NoticeDeleteModal;
