import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import SelectArrow from "../asset/img/selectarrow.png";

const Title = styled.h1`
  font-weight: bold;
  font-size: 24px;
  line-height: 35px;
  color: #221E1F;
`

const Container = styled.div`
    display : flex;
    justify-content : space-between;
  align-items: center;
`

const ButtonContainer = styled.div`
    display : flex;
    justify-content : flex-end;
  column-gap: 10px;
  margin-top: 30px;
`

const Select = styled.select`
  appearance:none;
  border: 1px solid #C4C4C4;
  border-radius: 10px;
  padding : 4px 17px;
  width: 30%;
  font-size: 16px;
  line-height: 23px;
  background-image: url(${SelectArrow});
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: 85%;
  margin-top: 5px;
  cursor: pointer;
  &:focus{
    outline: none;
  }
`

const RedButton = styled.a`
  background: #DF1F26;
  border-radius: 10px;
  border : none;
  color : white;
  padding : 10px 0 ;
  margin-top : 17px;
  font-weight: bold;
  font-size: 14px;
  width : 25%;
  text-align : center;
`

const Button = styled.button`
    background-color: white;
    border-radius: 10px;
    border: 1px solid #C4C4C4;
   width : 25%;
    padding : 10px 0 ;
    font-weight: bold;
    font-size: 14px;
    margin-top : 17px;
    color: #221E1F;
`
function ControllDeviceModal(props) {

    return (
        <Modal isOpen={props.toggle} onRequestClose={props.toggleFunc}
               style={{
                   overlay :{
                       backgroundColor : 'rgba(34, 30, 31, 0.5)'
                   },
                   content : {
                       height : '13%',
                       left : '40%',
                       right : '40%',
                       top : '35%',
                       borderRadius : '10px',
                       border: '1px solid #C4C4C4' ,
                   }
               }}
        >
            <Container>
                <Title>
                    스테이션 제어
                </Title>
                <Select name="" id="">
                    <option value="" hidden>선택</option>
                    <option value="">재부팅</option>
                    <option value="">중지</option>
                </Select>
            </Container>
            <ButtonContainer>
                <Button onClick={props.toggleFunc}>취소</Button>
                <RedButton>확인</RedButton>
            </ButtonContainer>
        </Modal>
    );
}

export default ControllDeviceModal;
