import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import axios from 'axios';
import Cookie from 'universal-cookie';

import RightArrow from '../asset/img/rightarrow.png';

const cookies = new Cookie();

const Title = styled.p`
    font-family : notosansbold;
    font-size : 32px;
`

const Description = styled.p`
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    & > div{
        display : flex;
        align-items : center;
        column-gap: 13px; 
        padding : 7% 0 ;
        & > p {
            font-family : notosansbold;
            font-size : 16px;
        }
        & > input {
            width: 70%;
            border: 1px solid #c4c4c4;
            border-radius: 10px;
            background-repeat: no-repeat;
            background-size: 17px;
            background-position: 97%;
            padding: 5px 10px;
            font-size: 16px;
            line-height: 23px;
            &::placeholder{
                color: #7E7E7E;
            }
        }
        & > img {
            width : 11px;
        }
    }
`

const ButtonContainer = styled.div`
    display : flex;
    justify-content : space-between;
`

const Button = styled.a`
    font-size: 16px;
    line-height: 23px;
    padding : 7px 25px;
    background-color: white;
    border-radius: 10px; 
    border: 1px solid #C4C4C4;
`

const RedButton = styled(Button)`
    background-color : #DF1F26;
    border : none;
    color : white;
`

function CategoryUpdateModal(props) {

    const [name , setName] = React.useState('');

    const onNameChange = (e) => {
        setName(e.target.value);
    }

    const onSubmit = () => {
        axios({
            method: 'put',
            url: `${window.Config.G_API_URL}/admin/faq/category`,
            params: {
                token: cookies.get('token'),
                faq_category: props.name,
                new_faq_category : name,
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    window.location.reload();
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    }
    
  return (
    <Modal isOpen={props.toggle} onRequestClose={props.toggleFunc}
        style={{
            overlay :{
                backgroundColor : 'rgba(34, 30, 31, 0.5)'
            },
            content : {
                height : '21%',
                left : '35%',
                right : '35%',
                top : '35%',
                borderRadius : '10px',
                border: '1px solid #C4C4C4' ,
            }
        }}
    >
        <Title>
            카테고리 수정
        </Title>
        <Description>
            <div>
                <p>{props.name}</p>
                <img src={RightArrow} alt="" />
                <input placeholder='이름을 입력해주세요.' value={name} onChange={onNameChange}/>
            </div>
        </Description>
        <ButtonContainer>
            <Button onClick={props.toggleFunc}>취소</Button>
            <RedButton onClick={onSubmit}>등록</RedButton>
        </ButtonContainer>
    </Modal>
    );  
}

export default CategoryUpdateModal;
