import React from 'react'

import Pin from '../asset/img/pin.png';
import CheckBox from "./CheckBox";
import styled from "styled-components";

import NoGroupModal from './NoGroupModal';


const Color = styled.span`
  color : ${props => props.state === 'Y' ? '' : '#df1f26' } ;
`

const CursorTd = styled.td`
  cursor: pointer;
`

function SearchGroupList(props) {

    const [noGroupModal , setNoGroupModal] = React.useState(false);

    const handleToggleNoGroupModal = () => {
        setNoGroupModal(!noGroupModal);
    }

    const goToDetail = () => {
        if(props.groupId == ''){
            handleToggleNoGroupModal();
        }else{
            window.location.href = `/admin/devicedetail/:${props.stationID}/:1/:1`
        }
    }

    const goToGroupDetail = () => {
        if(props.groupId == ''){

        } else {
            window.location.href = `/admin/searchgroup/detail/:${props.groupId}/:1`
        }
    }

    return (
        <>
            <tr>
                <td><CheckBox no={props.stationID} onChange={props.onChange} checked={props.checked}/></td>
                <td>{props.rowNum}</td>
                <CursorTd onClick={goToDetail}>
                    {props.stationName}
                </CursorTd>
                <CursorTd onClick={goToGroupDetail}>{props.groupName}</CursorTd>
                <td>{props.charger_status === 'KR' ? '한국' : '중국'}</td>
                <td>{props.stationID}</td>
                <td>{props.date}</td>
                <td><Color state={props.running}>{props.running === 'Y' ? 'ON' : 'OFF'}</Color></td>
                <td>{props.usage} / {props.max}</td>
            </tr>
            <NoGroupModal 
                toggle={noGroupModal} 
                toggleFunc={handleToggleNoGroupModal}
            />
        </>
    )
}

export default SearchGroupList