import React , { useEffect } from 'react';
import axios from 'axios';
import Cookie from 'universal-cookie';
import styled from 'styled-components';
import { useParams } from 'react-router';

import {
    NoticeWriteWrapper,
    PinButton,
    WriteBoardForm,
    NoticeWriteButtonWrapper,
    Title,
    Description,
    BcArrow,
} from '../css/styles';
import CancelModal from '../components/CancelModal';

import Pin from '../asset/img/pin.png';
import WhitePin from '../asset/img/whitepin.png';
import breadcrumbArrow from '../asset/img/breadcrumbarrow.png';
import SelectArrow from '../asset/img/selectarrow.png';
import Bar from '../asset/img/bar.png';
import AuthCheckModal from '../components/AuthCheckModal';


const cookies = new Cookie();

const SelectBox = styled.select`
    cursor: pointer;
    appearance: none;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    padding: 7px 13px;
    width: 20%;
    font-size: 16px;
    line-height: 23px;
    background-image: url(${SelectArrow});
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: 85%;
    &:focus {
        outline: none;
    }
`;

const TitleBox = styled.div`
    display : flex;
    align-items : center;
    column-gap: 35px; 
    padding-left : 35px;
    & > img {
        height : 30px;
    }
    & > input {
        padding-left : 0 !important;
    }
`

function FaqRegister() {
    const { no } = useParams();

    const [title, setTitle] = React.useState('');
    const [content, setContent] = React.useState('');
    const [isCheck, setIsCheck] = React.useState(false);
    const [exposure, setExposure] = React.useState('N');
    const [searchType, setSearchType] = React.useState(null);
    const [cancelModalToggle, setCancelModalToggle] = React.useState(false);
    const [toggleAuthModal , setToggleAuthModal] = React.useState(false);
    const [categoryList , setCategoryList] = React.useState([]);

    const handleToggleAuthModal = () => {
        setToggleAuthModal(!toggleAuthModal);
    }

    useEffect(() => {
        console.log(cookies.get('token'));

        axios({
            method: 'get',
            url: `${window.Config.G_API_URL}/admin/faq/detail`,
            params: {
                no: no.replace(':', ''),
                token: cookies.get('token'),
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    console.log(response.data.data);
                    setTitle(response.data.data.title);
                    setContent(response.data.data.content);
                    setIsCheck(response.data.data.popular==='Y');
                    setSearchType(response.data.data.faq_category);
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    }, [no]);

    useEffect(() => {
        axios({
            method: 'get',
            url: `${window.Config.G_API_URL}/admin/faq/category`,
            params: {
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    console.log(response.data.data)
                    setCategoryList(response.data.data);
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    }, []);
    
    const onSubmit = () => {
        if(title === '' || content === ''){
            alert('제목 , 내용 , 카테고리를 입력해주세요!');
            return;
        }

        axios({
            method: 'put',
            url: `${window.Config.G_API_URL}/admin/faq`,
            params: {
                no : no.replace(':',''),
                token: cookies.get('token'),
                title: title.replace(/\[/g, '%5B').replace(/]/g, '%5D'),
                content: content.replace(/(?:\r\n|\r|\n)/g , '\r\n').replace(/\[/g, '%5B').replace(/]/g, '%5D'),
                faq_category : searchType,
                popular: isCheck ? 'Y' : 'N',
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    console.log(response);
                    window.location.href = '/admin/faq/:1';
                } else if(response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    };

    const handleCancelModal = () => {
        setCancelModalToggle(!cancelModalToggle);
    };

    const handleTitle = (e) => {
        setTitle(e.target.value);
    };

    const handleContent = (e) => {
        setContent(e.target.value);
    };

    const handleExposure = (e) => {
        setIsCheck(!isCheck);
        if (isCheck) {
            setExposure('N');
        } else {
            setExposure('Y');
        }
    };

    const onTypeChange = (e) => {
        if (e.target.value === '전원') {
            setSearchType(null);
        } else {
            setSearchType(e.target.value);
        }
    };

    return (
        <NoticeWriteWrapper>
            <Title>Faq 수정</Title>
            <Description>
                게시판 관리 <BcArrow src={breadcrumbArrow} /> FAQ{' '}
                <BcArrow src={breadcrumbArrow} /> FAQ 수정
            </Description>
            <WriteBoardForm>
                <TitleBox>
                    <SelectBox
                        name="searchselect1"
                        id="serachselect1"
                        onChange={onTypeChange}
                        value={searchType}
                    >
                        <option value="전원">전체</option>
                        {
                            categoryList.map((item)=>{
                                return <option value={item}>{item}</option>
                            })
                        }
                    </SelectBox>    
                    <img src={Bar} alt="" />
                    <input
                        type="text"
                        placeholder="제목을 입력하세요"
                        value={title.replace(/\%5B/g , '[').replace(/\%5D/g, ']')}
                        onChange={handleTitle}
                    />
                </TitleBox>
                <textarea placeholder='내용을 입력해주세요.' value={content.replace(/\%5B/g , '[').replace(/\%5D/g, ']')} onChange={handleContent}/>
            </WriteBoardForm>
            <NoticeWriteButtonWrapper>
                <button onClick={handleCancelModal}>취소</button>
                <div>
                    <PinButton state={isCheck} htmlFor="pin">
                        <input
                            id="pin"
                            type="checkbox"
                            checked={isCheck}
                            onChange={handleExposure}
                        />
                        자주 묻는 질문
                        <img src={isCheck ? WhitePin : Pin} alt="" />
                    </PinButton>
                    <button onClick={onSubmit}>등록</button>
                </div>
            </NoticeWriteButtonWrapper>

            <CancelModal
                toggle={cancelModalToggle}
                toggleFunc={handleCancelModal}
            />
            <AuthCheckModal
                toggle={toggleAuthModal}
                toggleFunc={handleToggleAuthModal}
            />
        </NoticeWriteWrapper>
    );
}

export default FaqRegister;
