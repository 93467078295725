import React from 'react';
import {Description, SearchGroupDetailWrapper, Title} from "../css/styles";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import DaumPostcode from 'react-daum-postcode'
import Cookie from "universal-cookie";
import CouponManageRegisterTable from "../components/CouponManageRegisterTable";
import AuthCheckModal from '../components/AuthCheckModal';

import EmptyValueModal from '../components/EmptyValueModal';

const ButtonWrapper = styled.div`
  & > button:first-child{
    padding : 5px 20px !important;
  }
  & > button:last-child{
    padding : 5px 20px !important;
    color: white;
    background-color: #DF1F26;
  }
`

const Title1 = styled(Title)`
    font-size :28px;
    padding-top : 30px;
`

function AdvertisementRegister({props}) {

    const cookies = new Cookie();
    // table 용 state
    const [formInfo , setFormInfo] = React.useState({
        title: null,
        content : null,
        amount : null,
        startDate : null,
        EndDate : null,
        issAmount : null,
        company : null,
    })
    const [latitude , setLatitude] = React.useState('')
    const [longitude , setLongitude] = React.useState('')
    const [location , setLocation] = React.useState(null);
    const [toggleAuthModal , setToggleAuthModal] = React.useState(false);

    const handleToggleAuthModal = () => {
        setToggleAuthModal(!toggleAuthModal);
    }

    //파일 전송 state
    const [imgBase64, setImgBase64] = React.useState([])

    const [toggleEmptyModal , setToggleEmptyModal] = React.useState(false);

    const handleToggleEmptyModal = () => {
        setToggleEmptyModal(!toggleEmptyModal);
    }

    const navigate = useNavigate()

    const onRegister = () => {
        var formdata = {};
        formdata.coupon_start_date = formInfo.startDate;
        formdata.coupon_end_date = formInfo.EndDate;
        formdata.coupon_image_path = imgBase64;
        formdata.coupon_issuance_count = formInfo.issAmount;
        formdata.coupon_context = formInfo.content;
        formdata.coupon_amount = formInfo.amount;
        formdata.coupon_company = formInfo.company;
        formdata.coupon_name = formInfo.title;
        formdata.token = cookies.get('token');

        axios({
            method : 'post',
            url : `${window.Config.G_API_URL}/admin/coupon`,
            data :  formdata,
        })
            .then(response => {
                if(response.data.result['code'] === '200'){
                    window.location.href = '/admin/couponmanage/:1/:1'
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else if(response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                }  else {
                    alert(response.data.result['message']);
                }
            }).catch(function(error){
            handleToggleEmptyModal();
        })
    }

    const tableInputChange = (e) => {
        let {name , value} = e.target;
        switch (name){
            case 'title' :
                setFormInfo({...formInfo , title : value})
                break;
            case 'content' :
                setFormInfo({...formInfo , content : value})
                break;
            case 'amount' :
                setFormInfo({...formInfo , amount : value})
                break;
            case 'issAmount' :
                setFormInfo({...formInfo , issAmount : value})
                break;
            case 'company' :
                if(value === '전원'){
                    setFormInfo({...formInfo , company : null})
                } else {
                    setFormInfo({...formInfo , company : value})
                }
                break;        
            case 'startDate' :
                setFormInfo({...formInfo , startDate : value})
                break;
            case 'EndDate' :
                setFormInfo({...formInfo , EndDate : value})
                break;
        }
    }

    return (
        <SearchGroupDetailWrapper>
            <Title>
                쿠폰 발행
            </Title>
            <Description>
                쿠폰 관리 {'>'} 쿠폰 발행
            </Description>
            <Title1>
                쿠폰 발행
            </Title1>
            <CouponManageRegisterTable
                onTableInputChange={tableInputChange}
                imgBase={imgBase64}
                setImgBase={setImgBase64}
            />
            <ButtonWrapper>
                <button onClick={() => navigate(-1)}>취소</button>
                <button onClick={onRegister}>등록</button>
            </ButtonWrapper>

            <EmptyValueModal
                toggle={toggleEmptyModal}
                toggleFunc={handleToggleEmptyModal}
            />
            <AuthCheckModal
                toggle={toggleAuthModal}
                toggleFunc={handleToggleAuthModal}
            />
        </SearchGroupDetailWrapper>
    );
}

export default AdvertisementRegister;