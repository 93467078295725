import axios from 'axios';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import Cookie from 'universal-cookie';

import {
    BcArrow,
    BorderDiv,
    Description,
    SearchBox,
    SearchGroupWrapper,
    Title,
} from '../css/styles';

import styled from 'styled-components';
import DeleteSearch from '../asset/img/deletesearch.png';
import Dotbogi from '../asset/img/dotbogi.png';
import SelectArrow from '../asset/img/selectarrow.png';
import ChargingStationModal from '../components/ChargingStationModal';
import CheckBox from '../components/CheckBox';
import DeleteConfirmModal from '../components/DeleteConfirmModal';
import DeleteModal from '../components/DeleteModal';
import Pagination from '../components/Pagination';
import SearchGroupList from '../components/SearchGroupList';

import breadcrumbArrow from '../asset/img/breadcrumbarrow.png';
import AuthCheckModal from '../components/AuthCheckModal';
import SelectUserModal from '../components/SelectUserModal';

const cookies = new Cookie();

const SelectBox = styled.select`
    appearance: none;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    padding: 7px 13px;
    width: 9%;
    font-size: 16px;
    line-height: 23px;
    background-image: url(${SelectArrow});
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: 85%;
    cursor: pointer;
    &:focus {
        outline: none;
    }
`;

const SearchInput = styled.input`
    margin-left: 21px;
    width: 35%;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    background-image: url(${Dotbogi});
    background-repeat: no-repeat;
    background-size: 17px;
    background-position: 97%;
    padding: 7px 10px;
    font-size: 16px;
    line-height: 23px;
    color: #7e7e7e;
`;

function SearchGroup() {
    const { no } = useParams();
    const [groupInfo, setGroupInfo] = React.useState([]);
    const [groupListInfo, setGroupListInfo] = React.useState([]);
    const [deleteModalToggle, setDeleteModalToggle] = React.useState(false);
    const [deleteConfirmModalToggle, setDeleteConfirmModalToggle] =
        React.useState(false);
    const [toggleEmptyModal, setToggleEmptyModal] = React.useState(false);

    const [checked, setChecked] = React.useState(false);
    const [checkAll, setCheckAll] = React.useState(false);
    const [checkList, setCheckList] = React.useState([]);
    const [searchData, setSearchData] = React.useState(null);
    const [searchType, setSearchType] = React.useState(null);
    const [toggleChargingModal , setToggleChargingModal] = React.useState(false);

    const [toggleAuthModal , setToggleAuthModal] = React.useState(false);

    const handleToggleAuthModal = () => {
        setToggleAuthModal(!toggleAuthModal);
    }

    const handleToggleChargingModal = () => {
        setToggleChargingModal(!toggleChargingModal);
    }

    useEffect(() => {
        console.log(cookies.get('token'));
        setCheckList([]);

        axios({
            method: 'get',
            url: `${window.Config.G_API_URL}/admin/station/groups`,
            params: {
                page_num: no.replace(':', ''),
                token: cookies.get('token'),
                search_type: searchType,
                search_data: searchData,
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    console.log(response.data.data.result);
                    setGroupListInfo(response.data.data.result);
                    setGroupInfo(response.data.data.result.list);
                }else if(response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    }, [no, searchData, searchType]);

    useEffect(()=>{
        if(checkAll){
            let arr = [];
            groupInfo.map((item)=>{
                    arr.push(`${item.group_id}`);
            });
            setCheckList(arr);
        } else if(checkList.length==groupInfo.length) {
            setCheckList([]);
        }
    },[checkAll]);

    useEffect(()=>{
        if(checkList.length == groupInfo.length){
            setCheckAll(true);
        } else {
            setCheckAll(false);
        }
        // console.log(checkList);
    },[checkList,groupInfo]);

    const handleDeleteModal = () => {
        if(checkList.length==0){
            setToggleEmptyModal(!toggleEmptyModal);
            return;
        }
        setDeleteModalToggle(!deleteModalToggle);
    };

    const handleConfirmDeleteModal = () => {
        handleDeleteModal();
        setDeleteConfirmModalToggle(!deleteConfirmModalToggle);
    };

    const onDelete = () => {
        axios({
            method: 'delete',
            url: `${window.Config.G_API_URL}/admin/station/group`,
            params: {
                token: cookies.get('token'),
                group_id: checkList,
            },
            // no : checkList 파라미터 [] 없애는 옵션
            paramsSerializer: function (paramObj) {
                const params = new URLSearchParams();
                for (const key in paramObj) {
                    params.append(key, paramObj[key]);
                }

                return params.toString();
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    window.location.reload();
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                }  else if (response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                }  else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    };

    const handleCheckAll = (e) => {
        setCheckAll(!checkAll);
    };

    const handleCheckEach = (e) => {
        const { id, checked } = e.target;
        let arr = [...checkList];
        if (arr.includes(id)) {
            for (let i = 0; i < arr.length; i++) {
                console.log(arr[i]);
                console.log(id);
                if (arr[i] == id) {
                    arr.splice(i, 1);
                    setChecked(!checked);
                    break;
                }
            }
            setCheckList(arr);
        } else {
            setChecked(!checked);
            setCheckList([...checkList, id]);
        }
    };

    const goToWrite = () => {
        window.location.href = '/admin/searchgroup/register';
    };

    const onTypeChange = (e) => {
        if (e.target.value === '운영 여부') {
            setSearchType(null);
        } else {
            setSearchType(e.target.value);
        }
    };

    const onSearchDataChange = (e) => {
        setSearchData(e.target.value);
    };

    const handleToggleEmptyModal = () => {
        setToggleEmptyModal(!toggleEmptyModal);
    }

    const onReset = () => {
        setSearchData('');
    }
    
    return (
        <SearchGroupWrapper>
            <Title>그룹 검색</Title>
            <Description>
                스테이션 관리 <BcArrow src={breadcrumbArrow} /> 그룹 검색{' '}
            </Description>
            <SearchBox>
                <SelectBox
                    name="searchselect"
                    id="serachselect"
                    onChange={onTypeChange}
                >
                    <option value="운영 여부">운영 여부</option>
                    <option value="운영 중">운영 중</option>
                    <option value="미운영">미운영</option>
                </SelectBox>
                <SearchInput
                    type="text"
                    placeholder={'검색어 입력'}
                    onChange={onSearchDataChange}
                    value={searchData||''}      
                />
                <div onClick={onReset}>
                    <img src={DeleteSearch} alt="" />
                    <p>초기화</p>
                </div>
            </SearchBox>
            <table>
                <thead>
                    <tr>
                        <th>
                            <CheckBox
                                no="checkAll"
                                checked={checkAll}
                                onChange={handleCheckAll}
                            />
                        </th>
                        <th>
                            <BorderDiv>No.</BorderDiv>
                        </th>
                        <th>그룹명</th>
                        <th>
                            <BorderDiv>충전요금 / 10분(원)</BorderDiv>
                        </th>
                        <th>거치요금 / 10분(원)</th>
                        <th>
                            <BorderDiv>스테이션 수량</BorderDiv>
                        </th>
                        <th>운영 여부</th>
                    </tr>
                </thead>
                <tbody>
                    {groupInfo.map((item, key) => (
                        <SearchGroupList
                            key={key}
                            no={item.group_id}
                            title={item.group_name}
                            chargePrice={item.charge_price}
                            holderPrice={item.holder_price}
                            stationCount={item.station_count}
                            status={item.operation_status}
                            checked={checkList.includes(`${item.group_id}`)}
                            onChange={handleCheckEach}
                        />
                    ))}
                </tbody>
            </table>
            <div>
                <button onClick={handleDeleteModal}>선택삭제</button>
                <Pagination
                    no={no.replace(':', '')}
                    url="/admin/searchgroup/:"
                    pages={groupListInfo['pages']}
                    isFirstPage={groupListInfo['isFirstPage']}
                    isLastPage={groupListInfo['isLastPage']}
                    prePage={groupListInfo['prePage']}
                    nextPage={groupListInfo['nextPage']}
                    hasPrePage={groupListInfo['hasPreviousPage']}
                    hasNextPage={groupListInfo['hasNextPage']}
                />
                <button onClick={goToWrite}>그룹 추가</button>
            </div>

            <DeleteModal
                content={'그룹을'}
                toggle={deleteModalToggle}
                toggleFunc={handleDeleteModal}
                deleteFunc={onDelete}
            />
            <DeleteConfirmModal toggle={deleteConfirmModalToggle} />

            <SelectUserModal
                toggle={toggleEmptyModal}
                toggleFunc={handleToggleEmptyModal}
                content={'그룹을'}
            />

            <ChargingStationModal
                toggle={toggleChargingModal}
                toggleFunc={handleToggleChargingModal}
            />
            <AuthCheckModal
                toggle={toggleAuthModal}
                toggleFunc={handleToggleAuthModal}
            />
        </SearchGroupWrapper>
    );
}

export default SearchGroup;
