import React from 'react'
import styled from 'styled-components';
import axios from 'axios';
import Cookie from 'universal-cookie';

import MainLogo from '../asset/img/adminmainlogo.png';
import QuestionMark from '../asset/img/questionmark.png';

const cookies = new Cookie();

const Wrapper = styled.div`
    display : flex;
    justify-content : center;
    height : 100%;
    & > div {
        display : flex;
        max-width : 30%;
        height : 100%;
        flex-direction : column;
        justify-content : center;
        align-items : center;
        & > img {
            width : 50%;
            padding-top : 40%;
            padding-bottom : 55px;
        }
        & > button {
            width : 100%;
            margin: 35px 0 30px 0;
            background-color: #221E1F;
            color : white;
            padding : 17px 0 ;
            text-align : center;
            border : none;
            font-weight: bold;
            font-size: 16px;
            border-radius : 10px;
            &:hover {
                transform : scale(1.01);
                transition : transform 0.2s
            }
        }
        & > div {
            display : flex;
            justify-content : center;
            align-items : center;
            column-gap: 18px;
            & > img {
                width : 18px;
            }
            & > p {
                font-size: 14px;
                line-height: 160%;
                letter-spacing: -0.035em;
                color: #999999;
            }
        }
    }
`

const InputBox = styled.input`
    width : 100%;
    margin-top: 10px;
    padding : 17px 13px;
    border : 1px solid ${props => props.active ? '#EEBCBC' : '#E5E5E5'} ;
    border-radius : 10px;
    box-sizing : border-box;
`

function Login() {

    const [email , setEmail] = React.useState('');
    const [pwd , setPwd] = React.useState('');

    const handleEmail = (e) => {
        const text = e.target.value;
        setEmail(text);
    }

    const handlePwd = (e) => {
        const text = e.target.value;
        setPwd(text);
    }

    const handleLogin = () => {
        var params = new URLSearchParams();
        params.append('admin_id', email)
        params.append('admin_password', pwd)
        axios.post(`${window.Config.G_API_URL}/admin/login`,params)
        .then(function(response){
            const accessToken = response.data.data;
            console.log(accessToken);
            cookies.set('id' , email);
            cookies.set('password' , pwd);
            cookies.set('token' , accessToken);
            if(response.data.result['code'] === '200'){
                window.location.href = '/auth'
            } else if(response.data.result['code'] === '400') {
                alert('로그인 실패!')
            }
        })
        .catch(function(error){
            alert(error);
        })
    }

    const onKeyPress = (e) => {
        if(e.key === 'Enter'){
            handleLogin();
        }
    }

    return (
        <Wrapper>
            <div>
                <img src={MainLogo} alt='mainlogo'/>
                <InputBox type='text' value={email} onChange={handleEmail} placeholder='관리자 아이디'/>
                <InputBox type='password' value={pwd} onChange={handlePwd} placeholder='비밀번호' onKeyPress={onKeyPress}/>
                <button onClick={handleLogin}>로그인</button>
                <div>
                    <img src={QuestionMark} alt='QuestionMark'/>
                    <p>
                        숨스테이션 관리자는 회원 전용입니다.<br/>
                        부여받은 아이디와 비밀번호를 입력해주세요.
                    </p>
                </div>
            </div>
        </Wrapper>
    )
}

export default Login