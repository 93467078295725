import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import SelectArrow from "../asset/img/selectarrow.png";
import axios from "axios";
import {useParams} from "react-router";
import Cookie from "universal-cookie";

const cookies = new Cookie();

const Title = styled.h1`
  font-weight: bold;
  font-size: 24px;
  line-height: 35px;
  color: #221E1F;
`

const Table = styled.table`
  width: 100%;
  line-height: 40px;
  margin-top: 80px;
  & > tbody {
    & > tr {
      border-top: 1px solid #e5e5e5;
      & > td:first-child {
        font-weight: bold;
      }
      & > td:last-child {
        text-align: right;
        padding-bottom: 3px;
        & > button {
          padding : 7px 6px;
          width: 75%;
          font-size: 16px;
          line-height: 23px;
          color: #DF1F26;
          border: 1px solid #e5e5e5;
          border-radius: 10px;
          background-color: white;
        }
      }
    }
  }
  
`

const ButtonContainer = styled.div`
    display : flex;
    justify-content : space-between;
  margin-top: 20px;
`

const Select = styled.select`
  appearance:none;
  border: 1px solid #C4C4C4;
  border-radius: 10px;
  padding : 7px 17px;
  width: 75%;
  font-size: 16px;
  line-height: 23px;
  background-image: url(${SelectArrow});
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: 85%;
  margin-top: 5px;
  &:focus{
    outline: none;
  }
`

const RedButton = styled.a`
  background: #DF1F26;
  border-radius: 10px;
  border : none;
  color : white;
  padding : 10px 0 ;
  margin-top : 17px;
  font-weight: bold;
  font-size: 14px;
  width : 25%;
  text-align : center;
`

const Button = styled.button`
    background-color: white;
    border-radius: 10px;
    border: 1px solid #C4C4C4;
   width : 25%;
    padding : 10px 0 ;
    font-weight: bold;
    font-size: 14px;
    margin-top : 17px;
    color: #221E1F;
`
function PortControllModal(props) {

    const {no , num} = useParams();

    const [deviceState , setDeviceState] = React.useState('');

    const changeState = () => {
        axios({
            method : 'get',
            url : `${window.Config.G_API_URL}/admin/station/holder`,
            params : {
                token : cookies.get('token'),
                station_id : no.replace(':' , ''),
                port : num.replace(':' , ''),
            }
        })
            .then(response => {
                if(response.data.result['code'] === '200'){
                    window.location.reload();
                }else{
                    alert(response.data.result['message']);
                }
            })
            .catch(function(error){
                alert(error);
            })
    }

    return (
        <Modal isOpen={props.toggle} onRequestClose={props.toggleFunc}
               style={{
                   overlay :{
                       backgroundColor : 'rgba(34, 30, 31, 0.5)'
                   },
                   content : {
                       height : '40%',
                       left : '40%',
                       right : '40%',
                       top : '25%',
                       borderRadius : '10px',
                       border: '1px solid #C4C4C4' ,
                   }
               }}
        >
            <Title>기기 제어</Title>
            <Table>
                <tbody>
                    <tr>
                        <td>포트 잠금</td>
                        <td>
                            <Select name="" id="">
                                <option value="">LOCK</option>
                                <option value="">OPEN</option>
                            </Select>
                        </td>
                    </tr>
                    <tr>
                        <td>기기 상태</td>
                        <td>
                            <Select name="" id="">
                                <option value="">미사용</option>
                                <option value="">충전 중</option>
                                <option value="">거치 중</option>
                                <option value="">예약 중</option>
                                <option value="">서비스 준비 중</option>
                            </Select>
                        </td>
                    </tr>
                    <tr>
                        <td/>
                        <td>
                            <button>계산 및 OPEN</button>
                        </td>
                    </tr>
                </tbody>
            </Table>
            <ButtonContainer>
                <Button onClick={props.toggleFunc}>취소</Button>
                <RedButton onClick={changeState}>확인</RedButton>
            </ButtonContainer>
        </Modal>
    );
}

export default PortControllModal;
