import React from 'react';
import {NavLink} from 'react-router-dom';
import {PaginationWrapper  , DoubleArrowImg , ArrowImg , RightDoubleArrowImg , RightArrowImg} from '../css/styles';

import DoubleArrow from '../asset/img/doublepaginationarrow.png';
import Arrow from '../asset/img/paginationarrow.png';

const pageBlockSize = 10;

function Pagination(props) {

    const goToFirstPage = () => {
        if(!props.isFirstPage) {
            window.location.href = `${props.url}1`
        }
    }

    const goToLastPage = () => {
        if(!props.isLastPage) {
            window.location.href = `${props.url}${props.pages}`
        }
    }

    const goToNextPage = () => {
        if(props.hasNextPage) {
            window.location.href = `${props.url}${props.nextPage}`
        }
    }

    const goToPrePage = () => {
        if(props.hasPrePage) {
            window.location.href = `${props.url}${props.prePage}`
        }
    }

    const navigatePage = () => {
        return Math.floor( (props.no - 1) / pageBlockSize);
    }

    const pageAlgo = (totalPageSize, bottomSize, cursor ) => {
    
        let firstBottomNumber = 1 + (10 * navigatePage());  //하단 최초 숫자
        let lastBottomNumber = (10 * navigatePage()) + bottomSize;  //하단 마지막 숫자
    
        if(lastBottomNumber > totalPageSize) lastBottomNumber = totalPageSize  //총 갯수보다 큰 경우 방지
        if(lastBottomNumber == 0) lastBottomNumber = 1;
    
        return {
            firstBottomNumber,
            lastBottomNumber,
            totalPageSize,
            bottomSize,
            cursor
        }
    }

    const pageInfo = pageAlgo(props.pages, pageBlockSize, props.no);

    
    const pages = () => {
        const result = [];
        for(let i = pageInfo.firstBottomNumber ; i <= pageInfo.lastBottomNumber ; i++){
            result.push(<NavLink to={`${props.url}${i}`} key={i}>{i}</NavLink>);
            if(i === props.pages) break;
        }
        return result;
    }

    return (
      <PaginationWrapper>
        <div>
          <DoubleArrowImg onClick={goToFirstPage} src={DoubleArrow} alt=""/>
          <ArrowImg onClick={goToPrePage} src={Arrow} alt="" />
          {pages()}
          <RightArrowImg onClick={goToNextPage} src={Arrow} alt="" />
          <RightDoubleArrowImg onClick={goToLastPage} src={DoubleArrow} alt="" />
        </div>
      </PaginationWrapper>
    )
}

export default Pagination