import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import axios from 'axios';
import Cookie from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import AuthCheckModal from '../components/AuthCheckModal';

import {
    FaqWrapper,
    Title,
    Description,
    SearchBox,
    BcArrow,
    BorderDivL,
} from '../css/styles';

import CategoryList from '../components/CategoryList';
import CheckBox from '../components/CheckBox';
import DeleteModal from '../components/DeleteModal';
import DeleteConfirmModal from '../components/DeleteConfirmModal';
import styled from 'styled-components';
import breadcrumbArrow from '../asset/img/breadcrumbarrow.png';
import SelectUserModal from '../components/SelectUserModal';
import CategoryRegisterModal from '../components/CategoryRegisterModal';

const cookies = new Cookie();

const FootterWrapper = styled.div`
    & > div {
        display: flex;
        column-gap: 10px;
        & > button {
            border: 1px solid #C4C4C4;
            border-radius : 10px;
            background-color : white;
            padding : 5px 10px;
            font-size: 16px;        
        }
    }
    & > button {
        padding : 5px 10px !important;
    }
`;

const Table = styled.table`
 & >thead {
     & > tr {
         & > th:last-child{
            width : 90% !important;
            text-align : left;
         }
     }
 }
`

function Faq() {
    let navigate = useNavigate();

    const [noticeInfo, setNoticeInfo] = React.useState([]);
    const [noticeListInfo, setNoticeListInfo] = React.useState([]);
    const [deleteModalToggle, setDeleteModalToggle] = React.useState(false);
    const [deleteConfirmModalToggle, setDeleteConfirmModalToggle] = React.useState(false);

    const [checked, setChecked] = React.useState(false);
    const [checkAll, setCheckAll] = React.useState(false);
    const [checkList, setCheckList] = React.useState([]);
    const [searchData, setSearchData] = React.useState(null);
    const [searchType, setSearchType] = React.useState(null);
    const [groupType, setGroupType] = React.useState(null);
    const [reload , setReload] = React.useState(false);
    const [toggleSelectUserModal , setToggleSelectUserModal] = React.useState(false);

    const [toggleRegisterModal , setToggleRegisterModal] = React.useState(false);

    const [toggleAuthModal , setToggleAuthModal] = React.useState(false);

    const toggleReload = () => {
        setReload(!reload);
    }

    const handleToggleAuthModal = () => {
        setToggleAuthModal(!toggleAuthModal);
    }

    const handleToggleRegisterModal = () => {
        setToggleRegisterModal(!toggleRegisterModal);
    }

    const handleToggleSelectUsetModal = () => {
        setToggleSelectUserModal(!toggleSelectUserModal);
    }

    useEffect(() => {
        axios({
            method: 'get',
            url: `${window.Config.G_API_URL}/admin/faq/category`,
            params: {
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    setNoticeInfo(response.data.data);
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    }, [reload]);

    useEffect(()=>{
        if(checkAll){
            let arr = [];
            noticeInfo.map((item)=>{
                    arr.push(`${item}`);
            });
            setCheckList(arr);
        } else if(checkList.length==noticeInfo.length) {
            setCheckList([]);
        }
    },[checkAll]);

    useEffect(()=>{
        if(checkList.length == noticeInfo.length){
            setCheckAll(true);
        } else {
            setCheckAll(false);
        }
        // console.log(checkList);
    },[checkList, noticeInfo]);

    const handleDeleteModal = () => {
        setDeleteModalToggle(!deleteModalToggle);
    };

    const handleConfirmDeleteModal = () => {
        handleDeleteModal();
        setDeleteConfirmModalToggle(!deleteConfirmModalToggle);
    };

    const onDelete = () => {
        axios({
            method: 'delete',
            url: `${window.Config.G_API_URL}/admin/faq/category`,
            params: {
                token: cookies.get('token'),
                faq_category: checkList,
            },
            // no : checkList 파라미터 [] 없애는 옵션
            paramsSerializer: function (paramObj) {
                const params = new URLSearchParams();
                for (const key in paramObj) {
                    params.append(key, paramObj[key]);
                }
                return params.toString();
            },
        })
        .then((response) => {
            if (response.data.result['code'] === '200') {
                console.log(response);
                handleConfirmDeleteModal();
            } else if(response.data.result['code'] === '402'){
                handleToggleAuthModal();
            } else if (response.data.result['code'] === '401'){
                alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                window.location.href = '/'
            } else {
                alert(response.data.result['message']);
            }
            })
        .catch(function (error) {
            alert(error);
        });
    };

    const handleCheckAll = (e) => {
        setCheckAll(!checkAll);
    };

    const handleCheckEach = (e) => {
        const { id, checked } = e.target;
        let arr = [...checkList];
        if (arr.includes(id)) {
            for (let i = 0; i < arr.length; i++) {
                if (arr[i] == id) {
                    arr.splice(i, 1);
                    setChecked(!checked);
                    break;
                }
            }
            setCheckList(arr);
        } else {
            setChecked(!checked);
            setCheckList([...checkList, id]);
        }
    };

    const reorder = (arr) => {
        axios({
            method: 'put',
            url: `${window.Config.G_API_URL}/admin/faq/category/index`,
            params: {
                token: cookies.get('token'),
                faq_category : arr,
            },
            paramsSerializer: function (paramObj) {
                const params = new URLSearchParams();
                for (const key in paramObj) {
                    params.append(key, paramObj[key]);
                }
                return params.toString();
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    toggleReload();
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else if(response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    }

    const shiftUp = (index , value) => {
        console.log(value)
        if(noticeInfo !== undefined){
            let temp = noticeInfo;
            temp.splice(index - 1 , 0 , value);
            temp.splice(index + 1 , 1);
            reorder(temp)
        }
    }

    const shiftDown = (index , value) => {
        if(noticeInfo !== undefined){
            let temp = noticeInfo;
            temp.splice(index ,0 , value);
            temp.splice(index + 2 , 1);
            reorder(temp)
        }
    }

    return (
        <FaqWrapper>
            <Title>FAQ 카테고리 관리</Title>
            <Description>
                게시판 관리 <BcArrow src={breadcrumbArrow} /> FAQ{' '} <BcArrow src={breadcrumbArrow} /> 카테고리 관리
            </Description>
            <Table>
                <thead>
                    <tr>
                        <th>
                            <CheckBox
                                no="checkAll"
                                checked={checkAll}
                                onChange={handleCheckAll}
                            />
                        </th>
                        <th>
                            <BorderDivL>카테고리</BorderDivL>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {noticeInfo.map((item, index) => (
                        <CategoryList
                            index={index}
                            name={item}
                            info={noticeInfo}
                            length={noticeInfo.length}
                            checked={checkList.includes(item)}
                            onChange={handleCheckEach}
                            shiftUp={shiftUp}
                            shiftDown={shiftDown}
                        />
                            
                    ))}
                </tbody>
            </Table>
            <FootterWrapper>
                <div>
                    <button onClick={() => navigate(-1)}>
                        목록
                    </button>
                    <button onClick={handleDeleteModal}>
                    선택삭제
                    </button>
                </div>
                <button onClick={handleToggleRegisterModal}>카테고리 추가</button>
            </FootterWrapper>

            <DeleteModal
                content={'카테고리를'}
                toggle={deleteModalToggle}
                toggleFunc={handleDeleteModal}
                deleteFunc={onDelete}
            />

            <DeleteConfirmModal toggle={deleteConfirmModalToggle} location={'/admin/faq/categorymanage'}/>

            <SelectUserModal
                toggle={toggleSelectUserModal}
                toggleFunc={handleToggleSelectUsetModal}
                content={'카테고리를'}
            />
            
            <CategoryRegisterModal
                toggle={toggleRegisterModal}
                toggleFunc={handleToggleRegisterModal}
            />
            <AuthCheckModal
                toggle={toggleAuthModal}
                toggleFunc={handleToggleAuthModal}
            />
        </FaqWrapper>
    );
}

export default Faq;
