import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import {useNavigate} from "react-router-dom";

const Description = styled.p`
    font-size: 16px;
    line-height: 23px;
    text-align: center;
`

const ButtonContainer = styled.div`
    display : flex;
    justify-content : center;
    margin-top : 35px;
`


const RedButton = styled.a`
    color: #DF1F26;
    font-size: 16px;
    line-height: 23px;
    padding : 7px 20px;
    background-color: white;
    border-radius: 10px; 
    border: 1px solid #C4C4C4;
`

function DeleteConfirmModal(props) {

    const navigate = useNavigate()

  return (
    <Modal isOpen={props.toggle} onRequestClose={props.toggleFunc}
        style={{
            overlay :{
                backgroundColor : 'rgba(34, 30, 31, 0.5)'
            },
            content : {
                height : '14%',
                left : '40%',
                right : '40%',
                top : '35%',
                borderRadius : '10px',
                border: '1px solid #C4C4C4' ,
            }
        }}
    >
        <Description>
            QR 코드 발송 실패. <br/>번호를 확인해주세요
        </Description>
        <ButtonContainer>
            <RedButton onClick={props.toggleFunc}>확인</RedButton>
        </ButtonContainer>
    </Modal>
    );  
}

export default DeleteConfirmModal;
