import axios from "axios";
import React from 'react';
import Modal from 'react-modal';
import { useParams } from "react-router";
import styled from 'styled-components';
import Cookie from "universal-cookie";
import AuthCheckModal from '../components/AuthCheckModal';

const cookies = new Cookie();

const Description = styled.div`
    & > h1 {
      font-family: notosansbold;
      font-size: 20px;
      letter-spacing: -1px;
      padding-top: 10px;
      padding-bottom: 17px;
    }
  & > p {
    letter-spacing: -0.3px;
    font-weight: bold;
    padding-bottom: 27px;
  }
  & > textarea {
    padding : 16px 10px;
    resize: none;
    width: 96%;
    height: 370px;
    overflow-y: scroll;
    border-radius: 10px;
    border: 1px solid #e5e5e5;
    &:focus{
      outline: none;
    }
  }
`

const ButtonContainer = styled.div`
    display : flex;
    justify-content : space-between;
    margin-top : 20px;
`

const RedButton = styled.a`
  background: #DF1F26;
  color: white;
  font-size: 16px;
  line-height: 23px;
  padding : 7px 20px;
  border-radius: 10px;
  border: 1px solid #C4C4C4;
  margin-left : 20px;
`
const Button = styled.a`
    font-size: 16px;
    line-height: 23px;
    padding : 7px 20px;
    background-color: white;
    border-radius: 10px; 
    border: 1px solid #C4C4C4;
`

function ManagerMemoRegModal(props) {

    const {no , num} = useParams();
    const [content , setContent] = React.useState('');

    const [toggleAuthModal , setToggleAuthModal] = React.useState(false);

    const handleToggleAuthModal = () => {
        setToggleAuthModal(!toggleAuthModal);
    }
    const onContentChange = (e) => {
        setContent(e.target.value)
    }

    const regMemo = () => {
        axios({
            method : 'post',
            url : `${window.Config.G_API_URL}/admin/user/history/memo`,
            params : {
                token : cookies.get('token'),
                us_id : no.replace(':' , ''),
                admin_memo : content,
            }
        })
            .then(response => {
                if(response.data.result['code'] === '200'){
                    window.location.reload();
                } else if(response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                }else{
                    alert(response.data.result['message']);
                }
            })
            .catch(function(error){
                alert(error);
            })
    }

    return (
        <Modal isOpen={props.toggle} onRequestClose={props.toggleFunc}
               style={{
                   overlay :{
                       backgroundColor : 'rgba(34, 30, 31, 0.5)'
                   },
                   content : {
                       height : '60%',
                       left : '32%',
                       right : '32%',
                       top : '15%',
                       borderRadius : '10px',
                       border: '1px solid #C4C4C4' ,
                   }
               }}
        >
            <Description>
                <h1>관리자 메모</h1>
                <textarea value={content||''} onChange={onContentChange}/>
            </Description>
            <ButtonContainer>
                <Button onClick={props.toggleFunc}>취소</Button>
                <RedButton onClick={regMemo}>작성</RedButton>
            </ButtonContainer>
            <AuthCheckModal
                toggle={toggleAuthModal}
                toggleFunc={handleToggleAuthModal}
            />
        </Modal>
    );
}

export default ManagerMemoRegModal;
