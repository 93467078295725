import React, { useEffect } from 'react';
import {
    ManageUserDetailWrapper,
    Title,
    Description,
    BcArrow,
} from '../css/styles';
import {useNavigate} from "react-router-dom";
import axios from 'axios';
import Cookie from 'universal-cookie';
import { useParams } from 'react-router';

import breadcrumbArrow from '../asset/img/breadcrumbarrow.png';
import CouponManageDetailTable from "../components/CouponManageDetailTable";
import CouponDeleteModal from '../components/CouponDeleteModal';
import styled from "styled-components";
import AuthCheckModal from '../components/AuthCheckModal';
import CouponCompanySendModal from '../components/CouponCompanySendModal';
import CouponUserSendModal from '../components/CouponUserSendModal';
import CouponUsageStateDetailModal from '../components/CouponUsageStateDetailModal';

const AdTitle = styled.h2`
    font-size: 28px;
    font-family: notosansbold;
`

const Img = styled.img`
  margin-top: 20px;
  width: 40%;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  & > div {
    & > button {
      font-weight: normal;
      font-size: 16px;
      line-height: 23px;
      border: 1px solid #c4c4c4;
      border-radius: 10px;
      background-color: white;
      padding: 7px 20px;
      margin-left: 18px;
      cursor: pointer;
    }
    & >button:first-child{
      color : #DF1F26;
      border: 1px solid #DF1F26;
    }
  }
`

const Button1 = styled.button`
    font-size: 16px;
    line-height: 23px;
    color: black;
    padding: 7px 20px;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #c4c4c4;
    cursor : pointer;
    margin-left : 10px;
`;

const Container = styled.div`
    display : flex;
    align-items : center;
    justify-content : space-between;
`

const cookies = new Cookie();

function AdvertisementDetail(props) {
    const { no, num } = useParams();
    const [adInfo, setAdInfo] = React.useState([]);
    const [toggleDeleteModal, setToggleDeleteModal] = React.useState(false);
    const [number , setNum] = React.useState('');
    const [toggleAuthModal , setToggleAuthModal] = React.useState(false);

    const [toggleCompanySendModal , setToggleCompanySendModal] = React.useState(false);
    const [toggleUserSendModal , setToggleUserSendModal] = React.useState(false);
    const [toggleCouponUsageModal , setToggleCouponUsageModal] = React.useState(false);

    const handleToggleUserSendModal = () => {
        setToggleUserSendModal(!toggleUserSendModal);
    }

    const handleToggleCouponUsageModal = () => {
        setToggleCouponUsageModal(!toggleCouponUsageModal);
    }

    const handleToggleAuthModal = () => {
        setToggleAuthModal(!toggleAuthModal);
    }

    const handleCompanySendModal = () => {
        setToggleCompanySendModal(!toggleCompanySendModal);
    }

    const navigate = useNavigate()

    useEffect(() => {
        axios({
            method: 'get',
            url: `${window.Config.G_API_URL}/admin/coupon/detail`,
            params: {
                token: cookies.get('token'),
                coupon_id: no.replace(':', ''),
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    console.log(response.data)
                    setAdInfo(response.data.data);
                    setNum(response.data.data.ad_id)
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    }, [no]);


    const onDelete = () => {
        axios({
            method: 'delete',
            url: `${window.Config.G_API_URL}/admin/coupon`,
            params: {
                token: cookies.get('token'),
                delete_items: no.replace(':', ''),
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    window.location.href = '/admin/couponmanage/:1/:1';
                } else if(response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    };

    const handleDeleteModal = () => {
        setToggleDeleteModal(!toggleDeleteModal);
    };

    return (
        <ManageUserDetailWrapper>
            <Title>쿠폰 상세</Title>
            <Description>
                쿠폰 관리 <BcArrow src={breadcrumbArrow} /> 쿠폰 상세
            </Description>
            <Container>
                <AdTitle>{adInfo.coupon_name}</AdTitle>
                <div>
                    <Button1 onClick={handleToggleCouponUsageModal}>이용 현황</Button1>
                    <Button1 onClick={handleCompanySendModal}>업체 발송</Button1>
                    <Button1 onClick={handleToggleUserSendModal}>회원 발송</Button1>
                </div>
            </Container>
            <CouponManageDetailTable
                context={adInfo.coupon_context}
                amount={adInfo.coupon_amount}
                startDate={adInfo.coupon_start_date}
                endDate={adInfo.coupon_end_date}
                issAmount={adInfo.coupon_issuance_count}
                company={adInfo.coupon_company}
                usage={adInfo.usage_count}
                date={adInfo.date_time}
            />
            <Img src={`${window.Config.G_API_URL}/images/coupon/${adInfo.coupon_image_path}`} alt=""/>
            <ButtonWrapper>
                <button onClick={() => navigate(-1)}>목록</button>
                <div>
                    <button onClick={() => window.location.href = `/admin/couponmanage/update/${no}`}>수정</button>
                    <button onClick={handleDeleteModal}>삭제</button>
                </div>
            </ButtonWrapper>

            <CouponDeleteModal
                content={'쿠폰을'}
                toggle={toggleDeleteModal}
                toggleFunc={handleDeleteModal}
                deleteFunc={onDelete}
            />
            <AuthCheckModal
                toggle={toggleAuthModal}
                toggleFunc={handleToggleAuthModal}
            />
            <CouponCompanySendModal
                toggle={toggleCompanySendModal}
                toggleFunc={handleCompanySendModal}
            />
            <CouponUserSendModal
                toggle={toggleUserSendModal}
                toggleFunc={handleToggleUserSendModal}
            />
            <CouponUsageStateDetailModal
                toggle={toggleCouponUsageModal}
                toggleFunc={handleToggleCouponUsageModal}
            />
        </ManageUserDetailWrapper>
    );
}

export default AdvertisementDetail;
