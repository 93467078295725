import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import {useNavigate} from "react-router-dom";

const Description = styled.p`
    font-size: 16px;
    line-height: 23px;
    text-align: center;
`

const ButtonContainer = styled.div`
    display : flex;
    justify-content : space-between;
    margin-top : 35px;
    padding : 0 20%;
`

const Button = styled.a`
    font-size: 16px;
    line-height: 23px;
    padding : 7px 20px;
    background-color: white;
    border-radius: 10px; 
    border: 1px solid #C4C4C4;
    margin-left : 20px;
`

const RedButton = styled(Button)`
    color: #DF1F26;
`

function CancelModal(props) {

    const navigate = useNavigate()

  return (
    <Modal isOpen={props.toggle} onRequestClose={props.toggleFunc}
        style={{
            overlay :{
                backgroundColor : 'rgba(34, 30, 31, 0.5)'
            },
            content : {
                height : '14%',
                left : '38%',
                right : '38%',
                top : '35%',
                borderRadius : '10px',
                border: '1px solid #C4C4C4' ,
            }
        }}
    >
        <Description>
            화면 이동 시 작성 중인 내용은 사라집니다. <br/>
            이동하시겠습니까?
        </Description>
        <ButtonContainer>
            <Button onClick={props.toggleFunc}>취소</Button>
            <RedButton onClick={() => navigate(-1)}>확인</RedButton>
        </ButtonContainer>
    </Modal>
    );  
}

export default CancelModal;
