import React, { useEffect } from 'react';
import { useParams } from "react-router";
import styled from "styled-components";
import Cookie from "universal-cookie";
import SelectArrow from "../asset/img/selectarrow.png";

const cookies = new Cookie();

const Cursor = styled.button`
  cursor: pointer;
`

const ChangeButton = styled.button`
  background-color: #df1f26 !important;
  color: white !important;
`

const SelectBox = styled.select`
  cursor: pointer;
  appearance:none;
  border: 1px solid #C4C4C4;
  border-radius: 10px;
  padding: 7px 30px 7px 10px;
  font-size: 16px;
  line-height: 23px;
  background-image: url(${SelectArrow});
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: 85%;
  &:focus{
    outline: none;
  }
`

const Table = styled.table`
    margin-top : 20px !important;
`
function ManageUserDetailTable(props) {

    const {adminid} = useParams();

    const [changeState , setChangeState] = React.useState(false);
    const [levelAuth , setLevelAuth] = React.useState(false);
    const [managerLevel , setManagerLevel] = React.useState(props.managerInfo.admin_level);

    const stateChange = () => {
        handleChangeState();
    }

    const handleChangeState = () => {
        setChangeState(!changeState);
    }


    const returnLevel = () => {
        switch (props.managerInfo.admin_level){
            case '0' : return '슈퍼관리자'
            case '1' : return '최고관리자'
            case '2' : return '고객센터 관리자'
            case '3' : return '고객센터'
            case '4' : return '업체관리자'
        }
    }

    useEffect(()=>{
        if(cookies.get('level')<=0){
            setLevelAuth(true);
        }
    },[])
    
      const maskingPhone = (originStr) => {
        let maskingStr = '';
        let phoneStr =  originStr.length < 11 ? originStr.match(/\d{10}/gi) : originStr.match(/\d{11}/gi);
            
        if(originStr.length < 11)
        { // 1.1) 0110000000
            return maskingStr = originStr.toString().replace(phoneStr, phoneStr.toString().replace(/(\d{3})(\d{3})(\d{4})/gi,'$1***$3'));
        }
        else
        { // 1.2) 01000000000
            return maskingStr = originStr.toString().replace(phoneStr, phoneStr.toString().replace(/(\d{3})(\d{4})(\d{4})/gi,'$1****$3'));
        }
    }

    return (
        <Table>
            <tbody>
            <tr>
                <td>이름</td>
                <td>{props.managerInfo.admin_name}</td>
            </tr>
            <tr>
                <td>아이디</td>
                <td>{props.managerInfo.admin_id}</td>
            </tr>
            <tr>
                <td>연락처</td>
                <td>
                    {levelAuth ? props.managerInfo.admin_phone : props.managerInfo.admin_phone === undefined ? '' : maskingPhone(props.managerInfo.admin_phone)}
                </td>
            </tr>
            <tr>
                <td>등록일</td>
                <td>{props.managerInfo.date_time}</td>
            </tr>
            <tr>
                <td>관리자 등급</td>
                <td>
                    {
                        returnLevel()
                    }
                </td>
            </tr>
            {
                returnLevel() === '업체관리자' ? 
                <tr>
                    <td>업체명</td>
                    <td>{props.managerInfo.company}</td>
                </tr>
                : 
                null
            }
            </tbody>
        </Table>
    );
}

export default ManageUserDetailTable;