import React, {useEffect} from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import {SearchBox} from "../css/styles";
import DeleteSearch from "../asset/img/deletesearch.png";
import SelectArrow from "../asset/img/selectarrow.png";
import Dotbogi from "../asset/img/dotbogi.png";
import axios from "axios";
import CouponLimitModal from "./CouponLimitModal";
import Cookie from "universal-cookie";
import {useParams} from "react-router";
import CouponUserSendModalList from "./CouponUserSendModalList";
import Pagination from "./Pagination";
import CheckBox from '../components/CheckBox';
import MessageSendModal from "../components/MessageSendModal";
import MessageGroupSendModalList from "./MessageGroupSendModalList";
import SelectUserModal from '../components/SelectUserModal';

const cookies = new Cookie();

const Title = styled.h1`
    font-weight: bold;
    font-size: 24px;
    line-height: 35px;
    color: #221E1F;
`

const BigInput = styled.input`
    border: 1px solid #c4c4c4;
    width: 12%;
    padding: 8px;
    border-radius: 10px;
    &:focus {
        outline: none;
    }
`;

const SelectBox = styled.select`
  cursor: pointer;
  appearance:none;
  border: 1px solid #C4C4C4;
  border-radius: 10px;
  padding: 7px 10px;
  width: 10%;
  font-size: 16px;
  line-height: 23px;
  background-color: white;
  background-image: url(${SelectArrow});
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: 85%;
  &:focus{
    outline: none;
  }
`


const SearchInput = styled.input`
  margin-left: 21px;
  width: 60%;
  border: 1px solid #C4C4C4;
  border-radius: 10px;
  background-image: url(${Dotbogi});
  background-repeat: no-repeat;
  background-size: 17px;
  background-position: 97%;
  padding: 7px 10px;
  font-size: 16px;
  line-height: 23px;
  color: #7E7E7E;
`

const ButtonContainer = styled.div`
    display : flex;
    justify-content : space-between;
    padding-top : 2%;
  & > button:first-child{
    color : #df1f26;
  }
`

const Button = styled.button`
  background-color: white;
  border-radius: 10px;
  border: 1px solid #C4C4C4;
  width : 11%;
  padding : 10px 0 ;
  font-weight: bold;
  font-size: 14px;
  color: #221E1F;
`

const Table = styled.table`
  width: 100%;
  line-height: 40px;
  text-align: center;
  & > thead > tr{
    border-bottom: 2px solid #e5e5e5;
    line-height: 23px;
    & > th {
      vertical-align: middle;
    }
    & > th:nth-child(1) {
        width : 5%;
    }
    & > th:nth-child(2) {
        width : 10%;
    }
    & > th:nth-child(5) {
        width : 13%;
    }
  }
  & > tbody > tr{
    border-bottom: 1px solid #e5e5e5;
  }
`
const TableDiv = styled.div`
  height: 70%;
  padding-top: 15px;
  overflow-y : scroll;
`

const ModalSearchBox = styled(SearchBox)`
  width: 100%;
`

function StationUsageModal(props) {

    const {no , usage} = useParams();
    const [deviceListInfo , setDeviceListInfo] = React.useState([]);

    const [searchData , setSearchData] = React.useState(null);
    const [searchType , setSearchType] = React.useState(null);

    const [checked1, setChecked1] = React.useState(false);
    const [checkAll1, setCheckAll1] = React.useState(false);
    const [checkList1, setCheckList1] = React.useState([]);

    const [startAmount , setStartAmount] = React.useState(null);
    const [endAmount , setEndAmount] = React.useState(null);
    const [textMsg , setTextMsg] = React.useState('');

    const [toggleEmptyModal, setToggleEmptyModal] = React.useState(false);
    const [toggleMsgModal , setToggleMsgModal] = React.useState(false);
    const [toggleCouponLimitModal , setToggleCouponLimitModal] = React.useState(false);

    const handleToggleCouponLimitModal = () => {
        setToggleCouponLimitModal(!toggleCouponLimitModal);
    }

    const handleTextMsg = (e) => {
        setTextMsg(e.target.value)
    }

    const handleCheck1 = (e) => {
        handleCheck1Each1(e)
    }

    useEffect(()=>{
        if(checkAll1){
            let arr = [];
            deviceListInfo.map((item)=>{
                    arr.push(`${item.us_id}`);
            });
            setCheckList1(arr);
        } else if(checkList1.length==deviceListInfo.length) {
            setCheckList1([]);
        }
    },[checkAll1]);

    useEffect(()=>{
        axios({
            method : 'get',
            url : `${window.Config.G_API_URL}/admin/users/noPaging`,
            params : {
                token : cookies.get('token'),
                search_type : searchType,
                search_data : searchData,
            }
        })
            .then(response => {
                if(response.data.result['code'] === '200'){
                    setDeviceListInfo(response.data.data)
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function(error){
                alert(error);
            })
    },[searchType , searchData  ])

    useEffect(()=>{
        if(checkAll1){
            let arr = [];
            deviceListInfo.map((item)=>{
                    arr.push(`${item.us_id}`);
            });
            setCheckList1(arr);
        } else if(checkList1.length==deviceListInfo.length) {
            setCheckList1([]);
        }
    },[checkAll1]);

    const onTypeChange = (e) => {
        if(e.target.value === 'null'){
            setSearchType(null)
        } else {
            setSearchType(e.target.value);
        }
    }

    const onSearchDataChange = (e) => {
        setSearchData(e.target.value);
    }

    const onReset = () => {
        setSearchData('');
    }

    const handleCheck1All1 = (e) => {
        setCheckAll1(!checkAll1);
    };

    const handleToggleEmptyModal = () => {
        setToggleEmptyModal(!toggleEmptyModal);
    }

    const handleCheck1Each1 = (e) => {
        const { id, checked } = e.target;
        let arr1 = [...checkList1];

        if (arr1.includes(id)) {
            for (let i = 0; i < arr1.length; i++) {
                if (arr1[i] == id) {
                    arr1.splice(i, 1);
                    setChecked1(!checked);
                    break;
                }
            }
            setCheckList1(arr1);
        } else {
            setChecked1(!checked);
            setCheckList1([...checkList1, id]);
        }
    };

    const handdleToggleMsgModal = () => {
        if(checkList1.length === 0){
            handleToggleEmptyModal();
            return;
        }
        setToggleMsgModal(!toggleMsgModal);
    }
    //문자 발송 함수
    const sendMsg = () => {
        axios({
            method: 'post',
            url: `${window.Config.G_API_URL}/admin/aligo/sms`,
            params: {
                token: cookies.get('token'),
                message : textMsg,
                user_ids: checkList1,
            },
            // no : checkList1 파라미터 [] 없애는 옵션
            paramsSerializer: function (paramObj) {
                const params = new URLSearchParams();
                for (const key in paramObj) {
                    params.append(key, paramObj[key]);
                }
                return params.toString();
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    window.location.reload();
                } else if(response.data.result['code'] === '402'){
                    //handleToggleAuthModal();
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    };

    return (
        <Modal isOpen={props.toggle} onRequestClose={props.toggleFunc}
               ariaHideApp={false}
               style={{
                   overlay :{
                       backgroundColor : 'rgba(34, 30, 31, 0.5)'
                   },
                   content : {
                       height : '80%',
                       left : '12%',
                       right : '7%',
                       top : '5%',
                       borderRadius : '10px',
                   }
               }}
        >
            <Title>문자 발송</Title>
            <ModalSearchBox>
                    <SelectBox name="searchselect" id="serachselect" onChange={onTypeChange}>
                        <option value={'null'}>선택</option>
                        <option value="회원 고유번호">회원 고유번호</option>
                        <option value="이름">이름</option>
                        <option value="연락처">연락처</option>
                    </SelectBox>
                    <SearchInput type="text" placeholder={'검색어 입력'} onChange={onSearchDataChange}/>
                    <div onClick={onReset}>
                        <img src={DeleteSearch} alt=""/>
                        <p>초기화</p>
                    </div>
            </ModalSearchBox>
            <TableDiv>
                <Table>
                    <thead>
                    <tr>                        
                        <th>
                            <CheckBox
                                no="checkAll1"
                                checked={checkAll1}
                                onChange={handleCheck1All1}
                            />
                        </th>
                        <th>회원 번호</th>
                        <th>이름</th>
                        <th>아이디(메일)</th>
                        <th>가입경로</th>
                        <th>연락처</th>
                        <th>보유 포인트</th>
                        <th>이용 내역(건)</th>
                        <th>가입일</th>
                        <th>회원 상태</th>
                    </tr>
                    </thead>
                    <tbody>
                        {deviceListInfo.map((item) => (
                        <MessageGroupSendModalList
                            key={item.us_id}
                            no={item.us_id}
                            num={no.replace(':','')}
                            title={item.title}
                            date={item.date_time.substr(0, 10)}
                            status={item.status}
                            type={item.type}
                            birth={item.us_birth}
                            count={item.use_count}
                            gender={item.us_gender}
                            name={item.us_name}
                            phone={item.us_phone}
                            point={item.us_point}
                            email={item.us_email}
                            checked={checkList1.includes(`${item.us_id}`)}
                            onChange={handleCheck1}
                        />
                        ))}
                    </tbody>
                </Table>
            </TableDiv>
            <ButtonContainer>
                <Button onClick={handdleToggleMsgModal}>문자 발송</Button>
                <Button onClick={props.toggleFunc}>닫기</Button>
            </ButtonContainer>
            <MessageSendModal
                toggle={toggleMsgModal}
                toggleFunc={handdleToggleMsgModal}
                confirm={sendMsg}
                setTextMsg={handleTextMsg}
            />

            <SelectUserModal
                toggle={toggleEmptyModal}
                toggleFunc={handleToggleEmptyModal}
                content={'회원을'}
            />
        </Modal>
    );
}

export default StationUsageModal;
