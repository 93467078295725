import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import axios from 'axios';
import Cookie from 'universal-cookie';
import AuthCheckModal from '../components/AuthCheckModal';

import {
    NoticeWrapper,
    Title,
    Description,
    BcArrow,
    BorderDiv,
} from '../css/styles';

import NoticeList from '../components/NoticeList';
import Pagination from '../components/Pagination';
import CheckBox from '../components/CheckBox';
import DeleteModal from '../components/DeleteModal';
import DeleteConfirmModal from '../components/DeleteConfirmModal';
import SelectUserModal from '../components/SelectUserModal';

import breadcrumbArrow from '../asset/img/breadcrumbarrow.png';

const cookies = new Cookie();

function Notice() {
    const { no } = useParams();
    const [noticeInfo, setNoticeInfo] = React.useState([]);
    const [noticeListInfo, setNoticeListInfo] = React.useState([]);
    const [deleteModalToggle, setDeleteModalToggle] = React.useState(false);
    const [deleteConfirmModalToggle, setDeleteConfirmModalToggle] =
        React.useState(false);

    const [checked, setChecked] = React.useState(false);
    const [checkAll, setCheckAll] = React.useState(false);
    const [checkList, setCheckList] = React.useState([]);
    const [pinCount, setPinCount] = React.useState(0);

    const [toggleSelectUserModal , setToggleSelectUserModal] = React.useState(false);
    const [toggleAuthModal , setToggleAuthModal] = React.useState(false);

    const handleToggleAuthModal = () => {
        setToggleAuthModal(!toggleAuthModal);
    }

    const handleToggleSelectUsetModal = () => {
        setToggleSelectUserModal(!toggleSelectUserModal);
    }

    useEffect(() => {
        setCheckList([]);
        setPinCount(0);
        console.log(cookies.get('token'));

        axios({
            method: 'get',
            url: `${window.Config.G_API_URL}/admin/notices/`,
            params: {
                page_num: no.replace(':', ''),
                token: cookies.get('token'),
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    console.log(response.data.data.result);
                    response.data.data.result.list.map((item) => {
                        if(item.exposure==="Y"){
                            setPinCount(pinCount => pinCount+1);
                        }
                    })
                    setNoticeInfo(response.data.data.result);
                    setNoticeListInfo(response.data.data.result.list);
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    }, [no]);

    useEffect(()=>{
        if(checkAll){
            let arr = [];
            noticeListInfo.map((item)=>{
                    arr.push(`${item.no}`);
            });
            setCheckList(arr);
        } else if(checkList.length==noticeListInfo.length) {
            setCheckList([]);
        }
    },[checkAll]);

    useEffect(()=>{
        if(checkList.length == noticeListInfo.length){
            setCheckAll(true);
        } else {
            setCheckAll(false);
        }
        // console.log(checkList);
    },[checkList,noticeListInfo]);

    const handleDeleteModal = () => {
        setDeleteModalToggle(!deleteModalToggle);
    };

    const toggleModal = () => {
        if(checkList.length == 0){
            console.log('true');
            handleToggleSelectUsetModal();
        } else {
            console.log('false');
            handleDeleteModal();
        }
    }

    const handleConfirmDeleteModal = () => {
        handleDeleteModal();
        setDeleteConfirmModalToggle(!deleteConfirmModalToggle);
    };

    const onDelete = () => {
        axios({
            method: 'delete',
            url: `${window.Config.G_API_URL}/admin/notice/`,
            params: {
                token: cookies.get('token'),
                no: checkList,
            },
            // no : checkList 파라미터 [] 없애는 옵션
            paramsSerializer: function (paramObj) {
                const params = new URLSearchParams();
                for (const key in paramObj) {
                    params.append(key, paramObj[key]);
                }

                return params.toString();
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    console.log(response);

                    handleConfirmDeleteModal();
                } else if(response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                }  else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    };

    const handleCheckAll = (e) => {
        setCheckAll(!checkAll);
    };

    const handleCheckEach = (e) => {
        const { id, checked } = e.target;
        let arr = [...checkList];

        if (arr.includes(id)) {
            for (let i = 0; i < arr.length; i++) {
                if (arr[i] == id) {
                    arr.splice(i, 1);
                    setChecked(!checked);
                    break;
                }
            }
            setCheckList(arr);
        } else {
            setChecked(!checked);
            setCheckList([...checkList, id]);
        }
    };

    const goToWrite = () => {
        window.location.href = '/admin/notice/write';
    };

    return (
        <NoticeWrapper pinCount={pinCount}>
            <Title>공지사항</Title>
            <Description>
                게시판 관리 <BcArrow src={breadcrumbArrow} /> 공지사항
            </Description>
            <table>
                <thead>
                    <tr>
                        <th>
                            <CheckBox
                                no="checkAll"
                                checked={checkAll}
                                onChange={handleCheckAll}
                            />
                        </th>
                        <th>
                            <BorderDiv>글번호</BorderDiv>
                        </th>
                        <th>
                            <div>제목</div>
                        </th>
                        <th>
                            <BorderDiv>등록일</BorderDiv>
                        </th>
                        <th>등록자</th>
                    </tr>
                </thead>
                <tbody>
                    {noticeListInfo.map((item, key, index) => (
                        <NoticeList
                            key={key}
                            no={item.no}
                            title={item.title}
                            date={item.date_time}
                            exposure={item.exposure}
                            admin={item.admin_no}
                            name={item.admin_name}
                            checked={checkList.includes(`${item.no}`)}
                            onChange={handleCheckEach}
                        />
                    ))}
                </tbody>
            </table>
            <div>
                <button onClick={toggleModal}>선택삭제</button>
                <Pagination
                    no={no.replace(':', '')}
                    url="/admin/notice/:"
                    pages={noticeInfo['pages']}
                    isFirstPage={noticeInfo['isFirstPage']}
                    isLastPage={noticeInfo['isLastPage']}
                    prePage={noticeInfo['prePage']}
                    nextPage={noticeInfo['nextPage']}
                    hasPrePage={noticeInfo['hasPreviousPage']}
                    hasNextPage={noticeInfo['hasNextPage']}
                />
                <button onClick={goToWrite}>글쓰기</button>
            </div>

            <DeleteModal
                toggle={deleteModalToggle}
                toggleFunc={handleDeleteModal}
                deleteFunc={onDelete}
                content={'게시글을'}
            />

            <DeleteConfirmModal
                toggle={deleteConfirmModalToggle}
                location={'/admin/notice/:1'}
            />

            <SelectUserModal
                toggle={toggleSelectUserModal}
                toggleFunc={handleToggleSelectUsetModal}
                content={'게시글을'}
            />
            <AuthCheckModal
                toggle={toggleAuthModal}
                toggleFunc={handleToggleAuthModal}
            />
        </NoticeWrapper>
    );
}

export default Notice;
