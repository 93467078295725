import React from 'react';

import { useParams } from "react-router";
import styled from "styled-components";
import Warning from '../asset/img/warning.png';

const Tr = styled.tr`
  & > td:nth-child(1){
    width: 10%;
    position: relative;
  }
  & > td:nth-child(2){
    width: 10%;
  }
  & > td:nth-child(3){
    text-align: center !important;
    width: 15% !important;
  }
`

const Lock = styled.span`
  background-color: ${props => props.state === '0' ? '#c4c4c4' : '#DF1F26'};
  color: white;
  border-radius: 86px;
  padding : 2px 10px 3px;
`

const Connect = styled.span`
  background-color: ${props => props.state === 'T' ? '#79BD42' : '#c4c4c4'};
  color: white;
  border-radius: 86px;
  padding : ${props => props.state === 'T' ? ' 2px 16px 3px' : '2px 10px 3px'};
  font-size: 16px;
`

const Unuse = styled.span`
  background: #C4C4C4;
  border-radius: 36px;
  color: white;
  padding : 2px 10px 3px;
`

const Charging = styled.span`
  background: #DF1F26;
  border-radius: 36px;
  color: white;
  padding : 2px 10px 3px;
`

const Holding = styled.span`
  background: #79BD42;
  border-radius: 36px;
  color: white;
  padding : 2px 10px 3px;
`

const Reserving = styled.span`
  background: #FBBC05;
  border-radius: 36px;
  color: white;
  padding : 2px 10px 3px;
`

const StandBy = styled.span`
  background: #C4C4C4;
  border-radius: 36px;
  padding : 2px 10px 3px;
`

const WarningImg = styled.img`
  position: absolute;
  top: 33%;
  left: 60%;
  width: 16px;
`

const Button = styled.button`
  background-color: white;
  border-radius: 10px;
  border: 1px solid #C4C4C4;
  width : 100px;
  padding : 10px 0 ;
  font-weight: bold;
  font-size: 12px;
  color: #221E1F;
`

function DeviceDetailList(props) {

    const {no} = useParams();

    const goToPortDetail = () => {
        window.location.href = `/admin/devicedetail/${no}/portdetail/:${props.port}`
    }

    const usage = () => {
        switch (props.usage){
            case '미사용' :
                return <Unuse>미사용</Unuse>;
            case '충전 중' :
                return <Charging>충전 중</Charging>;
            case '거치 중' :
                return <Holding>거치 중</Holding>;
            case '예약 중' :
                return <Reserving>예약 중</Reserving>;
            case '서비스 준비 중' :
                return <StandBy>서비스 준비 중</StandBy>;
            default :
                return;
        }
    }
    const handleQrImg = () => {
      props.qrImageFunc(props.qrData)
    }
    return (
        <Tr>
            <td style={{cursor : 'pointer'}} onClick={goToPortDetail}>{props.port}{props.state === '0' ? '' : <WarningImg src={Warning} alt=""/>}</td>
            <td><Connect state={props.connect}>{props.connect === 'T' ? '연결' : '미연결'}</Connect></td>
            <td><Lock state={props.lock}>{props.lock === '0' ? 'OPEN' : 'LOCK'}</Lock></td>
            <td>
                {usage()}
            </td>
            <td>{props.number === null ? '-' : props.number}</td>
            <td>{props.company === null ? '-' : props.company}</td>
            <td><Button onClick={handleQrImg}>QR Image</Button></td>
        </Tr>
    );
}

export default DeviceDetailList;