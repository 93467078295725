import React from 'react';
import styled from "styled-components";
import SelectArrow from "../asset/img/selectarrow.png";

const SelectBox = styled.select`
  cursor: pointer;
  appearance:none;
  border: 1px solid #C4C4C4;
  border-radius: 10px;
  padding: 7px 20px 7px 10px;
  font-size: 16px;
  line-height: 23px;
  background-image: url(${SelectArrow});
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: 85%;
  &:focus{
    outline: none;
  }
`

const BigInput = styled.input`
  border: ${props => props.pwCheck===false ? '1px solid #df1f26' : '1px solid #C4C4C4'};
  width: 25%;
  padding: 8px;
  border-radius: 10px;
    background-color : ${props => props.pwCheck===false ? '#FCE0E0' : 'white'};
`

function ManageUserDetailTable(props) {



    return (
        <table>
            <tbody>
                <tr>
                    <td>아이디</td>
                    <td>{props.managerInfo.admin_id}</td>
                </tr>
                <tr>
                    <td>현재 비밀번호</td>
                    <td><BigInput type='password' name={'admin_passwd'} value={props.managerInfo.admin_passwd||''} onChange={props.onChange}/></td>
                </tr>
                <tr>
                    <td>새 비밀번호</td>
                    <td><BigInput type='password' name={'admin_new_passwd'} value={props.managerInfo.admin_new_passwd||''} onChange={props.onChange}/></td>
                </tr>
                <tr>
                    <td>새 비밀번호 확인</td>
                    <td><BigInput pwCheck={props.pwCheck} type='password' name={'admin_passwd_check'} value={props.managerInfo.admin_passwd_check||''} onChange={props.onChange}/></td>
                </tr>
            </tbody>
        </table>
    );
}

export default ManageUserDetailTable;