import React, {useEffect} from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import {BorderDiv, BorderDivR, SearchBox} from "../css/styles";
import DeleteSearch from "../asset/img/deletesearch.png";
import SelectArrow from "../asset/img/selectarrow.png";
import Dotbogi from "../asset/img/dotbogi.png";
import CheckBox from "./CheckBox";
import DeviceRegisterList from "./DeviceRegisterList";
import axios from "axios";
import Cookie from "universal-cookie";
import {useParams} from "react-router";
import AuthCheckModal from '../components/AuthCheckModal';


const cookies = new Cookie();

const Title = styled.h1`
    font-weight: bold;
    font-size: 24px;
    line-height: 35px;
    color: #221E1F;
`

const SelectBox = styled.select`
  cursor: pointer;
  appearance:none;
  border: 1px solid #C4C4C4;
  border-radius: 10px;
  padding: 7px 10px;
  width: 20%;
  font-size: 16px;
  line-height: 23px;
  background-image: url(${SelectArrow});
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: 85%;
  &:focus{
    outline: none;
  }
`


const SearchInput = styled.input`
  margin-left: 21px;
  width: 60%;
  border: 1px solid #C4C4C4;
  border-radius: 10px;
  background-image: url(${Dotbogi});
  background-repeat: no-repeat;
  background-size: 17px;
  background-position: 97%;
  padding: 7px 10px;
  font-size: 16px;
  line-height: 23px;
  color: #7E7E7E;
`

const ButtonContainer = styled.div`
    display : flex;
    justify-content : space-between;
  padding-top: 27px;
`

const RedButton = styled.a`
  background: #DF1F26;
  border-radius: 10px;
  border : none;
  color : white;
  padding : 10px 0 ;
  font-weight: bold;
  font-size: 14px;
  width : 11%;
  text-align : center;
`

const Button = styled.button`
  background-color: white;
  border-radius: 10px;
  border: 1px solid #C4C4C4;
  width : 11%;
  padding : 10px 0 ;
  font-weight: bold;
  font-size: 14px;
  color: #221E1F;
`

const Table = styled.table`
  width: 100%;
  line-height: 40px;
  text-align: center;
  & > thead > tr{
    border-bottom: 2px solid #e5e5e5;
    line-height: 20px;
    & > th:first-child > div > label {
        cursor: pointer;
    }
    & > th:nth-child(3) {
        padding-left: 10px;
    }
  }
  & > tbody > tr{
    border-bottom: 1px solid #e5e5e5;
    & > td:first-child > div > label {
        cursor: pointer;
    }
    & > td:nth-child(3) {
        padding-left: 10px;
    }
  }
`

const ScrollDiv = styled.div`
  height: 65%;
  overflow-y: scroll;
`

function DeviceRegisterModal(props) {

    const {no} = useParams();
    const [deviceInfo , setDeviceInfo] = React.useState([]);

    const [deviceChecked, setDeviceChecked] = React.useState(false);
    const [deviceCheckAll , setDeviceCheckAll] = React.useState(false);
    const [deviceCheckList , setDeviceCheckList] = React.useState([]);
    const [searchData , setSearchData] = React.useState(null);
    const [searchType , setSearchType] = React.useState('이름');

    const [toggleAuthModal , setToggleAuthModal] = React.useState(false);

    const handleToggleAuthModal = () => {
        setToggleAuthModal(!toggleAuthModal);
    }

    useEffect(()=>{
        setDeviceCheckList([]);
        axios({
            method : 'get',
            url : `${window.Config.G_API_URL}/admin/station/unregistered`,
            params : {
                token : cookies.get('token'),
                search_type : searchType,
                search_data : searchData,
            }
        })
            .then(response => {
                if(response.data.result['code'] === '200'){
                    setDeviceInfo(response.data.data)
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function(error){
                alert(error);
            })
    },[searchType , searchData])

    useEffect(()=>{
        if(deviceCheckAll){
            let arr = [];
            deviceInfo.map((item)=>{
                    arr.push(`${item.station_id}`);
            });
            setDeviceCheckList(arr);
        } else if(deviceCheckList.length==deviceInfo.length) {
            setDeviceCheckList([]);
        }
    },[deviceCheckAll]);

    useEffect(()=>{
        if(deviceCheckList.length == deviceInfo.length){
            setDeviceCheckAll(true);
        } else {
            setDeviceCheckAll(false);
        }
        console.log(deviceCheckList);
    },[deviceCheckList,deviceInfo]);

    const register = () => {
        axios({
            method : 'post',
            url : `${window.Config.G_API_URL}/admin/station/group/stations`,
            params : {
                token : cookies.get('token'),
                group_id : props.no.replace(':' , ''),
                station_id : deviceCheckList,
            },
            paramsSerializer : function(paramObj) {
                const params = new URLSearchParams()
                for (const key in paramObj) {
                    params.append(key, paramObj[key])
                }

                return params.toString()
            }
        })
            .then(response => {
                if(response.data.result['code'] === '200'){
                    window.location.reload();
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else if(response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function(error){
                alert(error);
            })
    }


    const handleCheckEach = (e) => {
        const { id , checked} = e.target;
        let arr = [...deviceCheckList];
        if (arr.includes(id)) {
            for (let i = 0; i < arr.length; i++) {
                if (arr[i] == id) {
                    arr.splice(i, 1);
                    setDeviceChecked(!checked);
                    break;
                }
            }
            setDeviceCheckList(arr);
        } else {
            setDeviceChecked(!checked);
            setDeviceCheckList([...deviceCheckList, id]);
        }
    }

    const handlemanagerCheckAll = (e) => {
        setDeviceCheckAll(!deviceCheckAll);
    };

    const onTypeChange = (e) => {
        setSearchType(e.target.value);
    }

    const onSearchDataChange = (e) => {
        setSearchData(e.target.value);
    }

    return (
        <Modal isOpen={props.toggle} onRequestClose={props.toggleFunc}
               ariaHideApp={false}
               style={{
                   overlay :{
                       backgroundColor : 'rgba(34, 30, 31, 0.5)'
                   },
                   content : {
                       height : '53%',
                       left : '25%',
                       right : '25%',
                       top : '20%',
                       borderRadius : '10px',
                   }
               }}
        >
            <Title>그룹에 미등록 스테이션 추가</Title>
            <SearchBox>
                <SelectBox name="searchselect" id="serachselect" onChange={onTypeChange}>
                    <option value="장비명">스테이션 명</option>
                    <option value="장비 정보">스테이션 ID</option>
                </SelectBox>
                <SearchInput type="text" placeholder={'검색어 입력'} onChange={onSearchDataChange}/>
                <div>
                    <img src={DeleteSearch} alt=""/>
                    <p>초기화</p>
                </div>
            </SearchBox>
            <ScrollDiv>
                <Table>
                    <thead>
                    <tr>
                        <th><CheckBox no='checkAll3' checked={deviceCheckAll} onChange={handlemanagerCheckAll}/></th>
                        <th>No.</th>
                        <th><BorderDiv>스테이션명</BorderDiv></th>
                        <th><BorderDivR>스테이션 ID</BorderDivR></th>
                        <th>설치일</th>
                    </tr>
                    </thead>
                    <tbody>
                        {deviceInfo.map((item) => (
                            <DeviceRegisterList
                                name={item.station_name}
                                id={item.station_id}
                                row={item.rownum}
                                running={item.running}
                                max={item.maxinum_port}
                                use={item.uses_port}
                                date={item.date_time}
                                checked={deviceCheckList.includes(`${item.station_id}`)}
                                onChange={handleCheckEach}
                            />
                        ))}
                    </tbody>
                </Table>
            </ScrollDiv>
            <ButtonContainer>
                <Button onClick={props.toggleFunc}>취소</Button>
                <RedButton onClick={register}>확인</RedButton>
            </ButtonContainer>
            <AuthCheckModal
                toggle={toggleAuthModal}
                toggleFunc={handleToggleAuthModal}
            />
        </Modal>
    );
}

export default DeviceRegisterModal;
