import React from 'react'
import styled from 'styled-components';

const Tr = styled.tr`
cursor : pointer;
    & > td:nth-child(3){
        text-align : center !important;
        padding-left : 0 !important;
    }
    & > td:nth-child(4){
        text-align : left !important;
        padding-left: 30px;
    }
`

const Complete = styled.p`
    display : inline;
    font-size: 11px;
    color: white;
    background: #DF1F26;
    border-radius: 37px;
    padding : 2px 7px 4px 7px;
`

const NotYet = styled.p`
    display : inline;
    font-size: 11px;
    color: #221E1F;
    background: #E5E5E5;
    border-radius: 37px;
    padding : 1px 7px 2px 7px;
`

function AskList(props) {

 const returnComplete = () => {
    switch(props.complete){
        case '0' :
            return <NotYet>검토중</NotYet>
        case '1' :   
            return <Complete>답변 완료</Complete>
    }
 }

    const goToDetail = () => {
        window.location.href = `/admin/trouble/detail/:${props.no}`;
    }

  return (
    <Tr onClick={goToDetail}>
        <td>{props.no}</td>
        <td>{props.date}</td>
        <td>{props.user}</td>
        <td onClick={goToDetail}>{props.title}</td>
        <td>{returnComplete()}</td>
        <td>{props.adminId === '' ? '-' : props.adminId}</td>
        <td>{props.commentDate === null ? '-' : props.commentDate}</td>
    </Tr>
  )
}

export default AskList