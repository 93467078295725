import React from 'react'

import {SideMenuListWrapper , DrawerWrapper} from '../css/styles';

import Arrow from '../asset/img/arrow.png';
import ActiveArrow from '../asset/img/activearrow.png';

function SideMenuList(props) {

    const [showDrawer , setShowDrawer] = React.useState(false);
    const [hover , setHover] = React.useState(false);
        
    const handleShowDrawer = () => {
        setShowDrawer(!showDrawer);
    }

    return (
        <div>
            <SideMenuListWrapper arrow={props.arrow}
                                 state={showDrawer}
                                 onClick={handleShowDrawer}
                                 onMouseOver={() => setHover(true)}
                                 onMouseOut={() => setHover(false)}>
                <div>
                    <img src={hover ? props.activeImg : props.img} alt=""/>
                    <p>{props.text}</p>
                </div>
                <img src={hover ? ActiveArrow : Arrow} alt=""/>
            </SideMenuListWrapper>
            {
                props.children && <DrawerWrapper children={props.children*41} state={showDrawer}>{props.children}</DrawerWrapper>
            }
        </div>
    )
}

SideMenuList.defaultProps = {
    arrow : true,
}

export default SideMenuList