import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

const Description = styled.div`
    & > h1 {
      font-family: notosansbold;
      font-size: 20px;
      letter-spacing: -1px;
      padding-top: 10px;
      padding-bottom: 17px;
    }
  & > p {
    letter-spacing: -0.3px;
    font-weight: bold;
    padding-bottom: 27px;
  }
  & > div {
    padding : 16px 10px;
    height: 310px;
    overflow-y: scroll;
    border-radius: 10px;
    border: 1px solid #e5e5e5;
  }
`

const ButtonContainer = styled.div`
    display : flex;
    justify-content : center;
    margin-top : 35px;
    padding : 0 27%;
`

const Button = styled.a`
    font-size: 16px;
    line-height: 23px;
    padding : 7px 20px;
    background-color: white;
    border-radius: 10px; 
    border: 1px solid #C4C4C4;
    margin-left : 20px;
`

function ManagerMemoDetailModal(props) {

    return (
        <Modal isOpen={props.toggle} onRequestClose={props.toggleFunc}
               style={{
                   overlay :{
                       backgroundColor : 'rgba(34, 30, 31, 0.5)'
                   },
                   content : {
                       height : '60%',
                       left : '32%',
                       right : '32%',
                       top : '15%',
                       borderRadius : '10px',
                       border: '1px solid #C4C4C4' ,
                   }
               }}
        >
            <Description>
                <h1>관리자 메모</h1>
                <p>No. {props.no} {props.date}</p>
                <div>
                    {props.content}
                </div>
            </Description>
            <ButtonContainer>
                <Button onClick={props.toggleFunc}>닫기</Button>
            </ButtonContainer>
        </Modal>
    );
}

export default ManagerMemoDetailModal;
