import React, { useEffect } from 'react';
import styled from "styled-components";
import Cookie from "universal-cookie";
import QuestionMark from '../asset/img/questionmark.png';
import CheckBox from "./CheckBox";

const cookies = new Cookie();

const Table = styled.table`
 & > thead{
   & > tr {
     line-height: 40px;
   }
   & > tr > th:first-child{
     text-align: left;
     padding-left: 10px;
     vertical-align: middle;
     width: 70%;
     & > div {
       display: flex;
       align-items: center;
       & > img {
         width: 20px;
         height: 20px;
         margin-left: 20px;
       }
       & > span {
         font-size: 14px;
         line-height: 160%;
         letter-spacing: -0.035em;
         color: #999999;
         margin-left: 10px;
       }
     }
   }
 }
  & > tbody {
    & > tr > td{
      vertical-align: middle !important;
      & > div {
        display: flex;
        justify-content: center !important;
        & > label {
          cursor: pointer;
        }
      }
    }
  }
`

function ManageUserDetailTable(props) {

    useEffect(()=>{
        console.log(props.checkedList)
    } , [props.checkedList])

    const ReadChecked = (e) => {
    }

    const WriteChecked = (e) => {

    }

    return (
        <Table>
            <thead>
                <tr>
                    <th>
                        <div>
                            <p>권한</p>
                            <img src={QuestionMark} alt=""/>
                            <span>쓰기 권한 활성화 시 자동으로 읽기 권한이 활성화됩니다.</span>
                        </div>
                    </th>
                    <th>
                        읽기
                    </th>
                    <th>
                        쓰기
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>스테이션 관리</td>
                    <td><CheckBox no={'station_read'} onChange={props.onChange} checked={props.checkedList.includes('station_read')} left={'47%'} top={'33%'} relTop={'0'}/></td>
                    <td><CheckBox no={'station_write'} onChange={props.onChange} checked={props.checkedList.includes('station_write')} left={'47%'} top={'33%'} relTop={'0'}/></td>
                </tr>
                <tr>
                    <td>정산 관리</td>
                    <td><CheckBox no={'calculate_read'} onChange={props.onChange} checked={props.checkedList.includes('calculate_read')} left={'47%'} top={'33%'} relTop={'0'}/></td>
                    <td><CheckBox no={'calculate_write'} onChange={props.onChange} checked={props.checkedList.includes('calculate_write')} left={'47%'} top={'33%'} relTop={'0'}/></td>
                </tr>
                <tr>
                    <td>회원 관리</td>
                    <td><CheckBox no={'user_read'} onChange={props.onChange} checked={props.checkedList.includes('user_read')} left={'47%'} top={'33%'} relTop={'0'}/></td>
                    <td><CheckBox no={'user_write'} onChange={props.onChange} checked={props.checkedList.includes('user_write')} left={'47%'} top={'33%'} relTop={'0'}/></td>
                </tr>
                <tr>
                    <td>포인트 관리</td>
                    <td><CheckBox no={'coupon_read'} onChange={props.onChange} checked={props.checkedList.includes('coupon_read')} left={'47%'} top={'33%'} relTop={'0'}/></td>
                    <td><CheckBox no={'coupon_write'} onChange={props.onChange} checked={props.checkedList.includes('coupon_write')} left={'47%'} top={'33%'} relTop={'0'}/></td>
                </tr>
                <tr>
                    <td>광고 관리</td>
                    <td><CheckBox no={'banner_read'} onChange={props.onChange} checked={props.checkedList.includes('banner_read')} left={'47%'} top={'33%'} relTop={'0'}/></td>
                    <td><CheckBox no={'banner_write'} onChange={props.onChange} checked={props.checkedList.includes('banner_write')} left={'47%'} top={'33%'} relTop={'0'}/></td>
                </tr>
                <tr>
                    <td>발송 이력 관리</td>
                    <td><CheckBox no={'shipment_history_read'} onChange={props.onChange} checked={props.checkedList.includes('shipment_history_read')} left={'47%'} top={'33%'} relTop={'0'}/></td>
                    <td><CheckBox no={'shipment_history_write'} onChange={props.onChange} checked={props.checkedList.includes('shipment_history_write')} left={'47%'} top={'33%'} relTop={'0'}/></td>
                </tr>
                <tr>
                    <td>공지사항 , FAQ 관리</td>
                    <td><CheckBox no={'board_read'}  checked={true} left={'47%'} top={'33%'} relTop={'0'} readOnly/></td>
                    <td><CheckBox no={'board_write'} onChange={props.onChange} checked={props.checkedList.includes('board_write')} left={'47%'} top={'33%'} relTop={'0'}/></td>
                </tr>
                <tr>
                    <td>문의 관리</td>
                    <td><CheckBox no={'ask_read'} onChange={props.onChange} checked={props.checkedList.includes('ask_read')} left={'47%'} top={'33%'} relTop={'0'}/></td>
                    <td><CheckBox no={'ask_write'} onChange={props.onChange} checked={props.checkedList.includes('ask_write')} left={'47%'} top={'33%'} relTop={'0'}/></td>
                </tr>
                <tr>
                    <td>신고 관리</td>
                    <td><CheckBox no={'trouble_read'} onChange={props.onChange} checked={props.checkedList.includes('trouble_read')} left={'47%'} top={'33%'} relTop={'0'}/></td>
                    <td><CheckBox no={'trouble_write'} onChange={props.onChange} checked={props.checkedList.includes('trouble_write')} left={'47%'} top={'33%'} relTop={'0'}/></td>
                </tr>
                <tr>
                    <td>관리자 설정</td>
                    <td><CheckBox no={'admin_read'} onChange={props.onChange} checked={props.checkedList.includes('admin_read')} left={'47%'} top={'33%'} relTop={'0'}/></td>
                    <td><CheckBox no={'admin_write'} onChange={props.onChange} checked={props.checkedList.includes('admin_write')} left={'47%'} top={'33%'} relTop={'0'}/></td>
                </tr>
            </tbody>
        </Table>
    );
}

export default ManageUserDetailTable;