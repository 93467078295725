import React from 'react';
import CheckBox from "./CheckBox";
import styled from "styled-components";

const Tr = styled.tr`
  & > td:first-child{
    width: 10%;
  }
`

function SearchManagerList(props) {
    return (
        <Tr>
            <td>{props.rownum}</td>
            <td>{props.version}</td>
            <td>{props.date}</td>
        </Tr>
    );
}

export default SearchManagerList;