import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import axios from 'axios';
import Cookie from 'universal-cookie';

import { NoticeWrapper, Title, Description, BcArrow } from '../css/styles';
import styled from 'styled-components';
import CheckBox from '../components/CheckBox';
import DeleteModal from '../components/DeleteModal';
import { useNavigate } from 'react-router-dom';
import AuthCheckModal from '../components/AuthCheckModal';

import breadcrumbArrow from '../asset/img/breadcrumbarrow.png';

const cookies = new Cookie();

const TableTitle = styled.h1`
    font-weight: bold;
    font-size: 32px;
    line-height: 46px;
    padding-top: 40px;
`;

const Table = styled.table`
    & > tbody {
        & > tr {
            line-height: 80px !important;
            & > td:last-child {
                text-align: right;
                & > button {
                    padding: 5px 10px;
                    font-size: 16px;
                    line-height: 23px;
                    color: #df1f26;
                    background-color: white;
                    border: 1px solid #df1f26;
                    border-radius: 10px;
                    margin-right: 30px;
                    cursor: pointer;
                }
            }
        }
        & > tr:first-child {
            line-height: 40px !important;
            border-top: none;
            border-bottom: 2px solid #e5e5e5;
            font-family: notosansbold;
            font-weight: bold;
            font-size: 16px;
        }
        & > tr > td:first-child {
            font-family: notosansbold;
            font-size: 28px;
            color: #df1f26;
            width: 17%;
            & > span {
                font-family: notosans;
                font-size: 22px;
                margin-left: 3px;
            }
        }
        & > tr > td:nth-child(2) {
            width: 17%;
            text-align: center !important;
        }
        & > tr > td:nth-child(3) {
            width: 17%;
            text-align: center !important;
        }
    }
`;

const ButtonContainer = styled.div`
    justify-content: flex-end !important;
    column-gap: 20px;
    & > button:first-child {
        color: black !important;
        cursor: pointer;
    }
    & > button:last-child {
        color: white !important;
        background-color: #df1f26 !important;
        cursor: pointer;
    }
`;

const ColorInput = styled.input`
    font-family: notosansbold;
    border: 1px solid #c4c4c4;
    width: 50%;
    padding: 2px 8px;
    border-radius: 10px;
    font-size: 24px;
    line-height: 35px;
    color: #df1f26;
    &:focus {
        outline: none;
    }
`;

const BigInput = styled.input`
    border: 1px solid #c4c4c4;
    width: 40%;
    padding: 8px;
    border-radius: 10px;
    &:focus {
        outline: none;
    }
`;

function CouponUpdate() {
    const { no, price, bonus } = useParams();

    const [amount, setAmount] = React.useState(price.replace(':', ''));
    const [free, setFree] = React.useState(bonus.replace(':', ''));
    const [toggleAuthModal , setToggleAuthModal] = React.useState(false);

    const handleToggleAuthModal = () => {
        setToggleAuthModal(!toggleAuthModal);
    }

    const onPriceChange = (e) => {
        setAmount(e.target.value);
    };

    const onBonusChange = (e) => {
        setFree(e.target.value);
    };

    const onUpdate = () => {
        axios({
            method: 'put',
            url: `${window.Config.G_API_URL}/admin/point-coupon/`,
            params: {
                token: cookies.get('token'),
                coupon_id: Number(no.replace(':', '')),
                price: Number(amount),
                free_point: Number(free),
            },
            // no : checkList 파라미터 [] 없애는 옵션
            paramsSerializer: function (paramObj) {
                const params = new URLSearchParams();
                for (const key in paramObj) {
                    params.append(key, paramObj[key]);
                }

                return params.toString();
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    window.location.href = '/admin/coupon';
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else if(response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    };

    const navigate = useNavigate();

    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    return (
        <NoticeWrapper>
            <Title>포인트 관리</Title>
            <Description>
                포인트 관리 <BcArrow src={breadcrumbArrow} /> 포인트 금액 수정
            </Description>
            <TableTitle>포인트 금액 수정</TableTitle>
            <Table>
                <tbody>
                    <tr>
                        <th>금액</th>
                        <th>충전 포인트</th>
                        <th>보너스 포인트</th>
                    </tr>
                    <tr>
                        <td>
                            <ColorInput
                                value={amount}
                                onChange={onPriceChange}
                            />
                            <span>원</span>
                        </td>
                        <td>{numberWithCommas(amount)}P</td>
                        <td>
                            <BigInput value={free} onChange={onBonusChange} /> P
                        </td>
                        <td />
                    </tr>
                </tbody>
            </Table>
            <ButtonContainer>
                <button onClick={() => navigate(-1)}>취소</button>
                <button onClick={onUpdate}>수정 완료</button>
            </ButtonContainer>
            <AuthCheckModal
                toggle={toggleAuthModal}
                toggleFunc={handleToggleAuthModal}
            />
        </NoticeWrapper>
    );
}

export default CouponUpdate;
