import axios from 'axios';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import Cookie from 'universal-cookie';

import { BcArrow, BorderDiv, BorderDivR, Description, NoticeWrapper, Title } from '../css/styles';

import styled from 'styled-components';
import breadcrumbArrow from '../asset/img/breadcrumbarrow.png';
import DeleteSearch from '../asset/img/deletesearch.png';
import SelectArrow from '../asset/img/selectarrow.png';
import Pagination from '../components/Pagination';
import SearchIndexBox from '../components/SearchIndexBox';

import Dotbogi from '../asset/img/dotbogi.png';
import AuthCheckModal from '../components/AuthCheckModal';
import KaKaoList from '../components/KaKaoList';
import MessageDetailModal from '../components/MessageDetailModal';

const cookies = new Cookie();

const Cursor = styled.button`
  cursor: pointer;
`
const HighLight = styled.span`
    color : #df1f26;
    font-family : notosansbold;
    margin-left : 10px;
`
    
const ChangeButton = styled.button`
  background-color: white !important;
  color: #df1f26 !important;
    border : 1px solid #df1f26 !important;
    border-radius : 10px;
    padding : 3px 10px;
    margin-left : 10px;
`

const ColumnBox = styled.div`
    display: flex;
    flex-direction: column;
    width : 80%;
`;

const SearchBox = styled.div`
    padding: 2% 0;
    display: flex;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    column-gap: 15px;
    position: relative;
    & > div {
        display: flex;
        align-items: center;
        & > img {
            margin-top: 10px;
            margin-left: 7px;
            width: 20px;
        }
        & > p {
            font-size: 16px;
            margin-top: 9px;
            line-height: 23px;
            color: #c4c4c4;
        }
    }
`;
const RedButton = styled.button`
    border : 1px solid #df1f26 !important;
`

const SelectBox = styled.select`
    cursor: pointer;
    appearance: none;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    padding: 7px 15px;
    font-size: 16px;
    line-height: 23px;
    background-image: url(${SelectArrow});
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: 87%;
    &:focus {
        outline: none;
    }
`;

const SelectBoxMin = styled(SelectBox)`
    margin-left : 10px;
    padding: 7px 20px 7px 10px;
    background-position: 90%;
`

const SearchInput = styled.input`
    width: 70%;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    background-image: url(${Dotbogi});
    background-repeat: no-repeat;
    background-size: 17px;
    background-position: 97%;
    padding: 7px 10px;
    font-size: 16px;
    line-height: 23px;
    color: #7e7e7e;
`;

const SearchInputMin = styled(SearchInput)`
    width: 20%;
    margin-left : 10px;
    background-image: none;
`

const FlexBox = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    column-gap: 10px;
    & > div {
        display: flex;
        align-items: center;
        width: 100px;
        & > img {
            cursor: pointer;
        }
    }
`;

const PaginationWrapper = styled.div`
    display : flex;
    justify-content : space-between !important;
    & > div {
        display : flex;
        align-items : center !important;
        font-size : 14px;
    }
`

const Table = styled.table`
    margin-top : 0px !important;
    & > thead > tr {
        & > th:nth-child(1) {
            width : 5% !important;
        }
        & > th:nth-child(2) {
            width : 7% !important;
        }
        & > th:nth-child(3) {
            width : 12%;
            text-align : center !important;
            padding-left : 0 !important;
        }
        & > th:nth-child(4) {
            width : 10%;
        }
        & > th:nth-child(5) {
            width : 30% !important;
        }
    }
`

const ContentBox = styled.div`
    position : absolute;
    top : 5%;
    left : 5%;
    width : 300px;
    display : ${props => props.hover ? 'block !important' : 'none'};
`

function ManageAdminUser() {
    const { no } = useParams();
    const [onHover , setOnHover] = React.useState(false);

    const [deleteModalToggle, setDeleteModalToggle] = React.useState(false);
    
    const [changeState , setChangeState] = React.useState(false);

    const [noticeInfo, setNoticeInfo] = React.useState([]);
    const [noticeListInfo, setNoticeListInfo] = React.useState([]);
    const [talkMin , setTalkMin] = React.useState('');
    const [searchInfo , setSearchInfo] = React.useState({
        searchData : null,
        searchType : null,
        startDate : null ,
        endDate : null,
    });
    const [searchType2, setSearchType2] = React.useState(null);

    const [toggleAuthModal , setToggleAuthModal] = React.useState(false);

    const [toggleDetailModal , setToggleDetailModal] = React.useState(false);
    const [levelAuth , setLevelAuth] = React.useState(false);
    const [toggleData, setToggleData] = React.useState({});

    const handleToggleDetailModal = (props) => {
        setToggleData(props);
        setToggleDetailModal(!toggleDetailModal);
    }

    const handleTalkMin = (e) => {
        setTalkMin(e.target.value)
    }

    const handleToggleAuthModal = () => {
        setToggleAuthModal(!toggleAuthModal);
    }
    const handleDeleteModal = () => {
        setDeleteModalToggle(!deleteModalToggle);
    };

    const handleChangeState = () => {
        setChangeState(!changeState);
    }

  const setOnHoverTrue = () => {
    setOnHover(true);
  }

  const setOnHoverFalse = () => {
    setOnHover(false);
  }
    useEffect(()=>{
        if(cookies.get('level')<=0){
            setLevelAuth(true);
        }
    },[])
    useEffect(() => {
        axios({
            method: 'get',
            url: `${window.Config.G_API_URL}/admin/sending_history/alim_talk`,
            params: {
                page_num: no.replace(':', ''),
                token: cookies.get('token'),
                search_data : searchInfo.searchData,
                search_type : searchInfo.searchType,
                start_date : searchInfo.startDate,
                end_date : searchInfo.endDate,
                alim_talk_type : searchType2 === '전체' ? null : searchType2,
            },
        })
        .then((response) => {
            if (response.data.result['code'] === '200') {
                setNoticeInfo(response.data.data.result);
                setNoticeListInfo(response.data.data.result.list);
                setTalkMin(response.data.data.scheduler_time);
            } else if (response.data.result['code'] === '401'){
                alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                window.location.href = '/'
            }else if(response.data.result['code'] === '402'){
                handleToggleAuthModal();
            }  else {
                alert(response.data.result['message']);
            }
        })
        .catch(function (error) {
            alert(error);
        });
    }, [no , searchInfo.searchData , searchInfo.searchType , searchInfo.answerState , searchInfo.startDate , searchInfo.endDate ,searchType2]);

    const onTypeChange = (e) => {
        setSearchInfo({...searchInfo , searchType : e.target.value});
    };

    const onSearchDataChange = (e) => {
        setSearchInfo({...searchInfo , searchData : e.target.value});
    };

    const onStartDateChange = (e) => {
        setSearchInfo({...searchInfo , startDate : e.target.value});
        console.log(e.target.value)
    }

    const onEndDateChange = (e) => {
        setSearchInfo({...searchInfo , endDate : e.target.value});
    }

    const onTypeChange2 = (e) => {
        setSearchType2(e.target.value);
    };

    const onReset = () => {
        setSearchInfo({...searchInfo , searchData : ''});
    }

    const handleDate = (e) => {
        var date = new Date();
        var currentDate1 = date.toISOString().substring(0,10);
        switch(e.target.id){
            case 'all':
                setSearchInfo({...searchInfo , startDate : null ,endDate : currentDate1});
                break;
            case 'week':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 7));
                var currentDate = newDate.toISOString().substring(0,10);
                setSearchInfo({...searchInfo , startDate : currentDate,endDate : currentDate1});
                break;
            case 'halfMonth':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 14));
                var currentDate = newDate.toISOString().substring(0,10);
                setSearchInfo({...searchInfo , startDate : currentDate,endDate : currentDate1});
                break;
            case 'month':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 30));
                var currentDate = newDate.toISOString().substring(0,10);
                setSearchInfo({...searchInfo , startDate : currentDate,endDate : currentDate1});
                break;
            case 'threeMonth':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 91));
                var currentDate = newDate.toISOString().substring(0,10);
                setSearchInfo({...searchInfo , startDate : currentDate,endDate : currentDate1});
                break;
            case 'halfYear':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 182));
                var currentDate = newDate.toISOString().substring(0,10);
                setSearchInfo({...searchInfo , startDate : currentDate,endDate : currentDate1});
                break;
            case 'year':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 365));
                var currentDate = newDate.toISOString().substring(0,10);
                setSearchInfo({...searchInfo , startDate : currentDate,endDate : currentDate1});
                break;
        }
    }

    const handleChangeUserState = () => {
        
        axios({
            method: 'put',
            url: `${window.Config.G_API_URL}/admin/holder/scheduler`,
            params: {
                token: cookies.get('token'),
                hour : parseInt(talkMin),
            },
        })
        .then((response) => {
            if (response.data.result['code'] === '200') {
                window.location.reload();
            } else if (response.data.result['code'] === '401'){
                alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                window.location.href = '/'
            }else if(response.data.result['code'] === '402'){
                handleToggleAuthModal();
            }  else {
                alert(response.data.result['message']);
            }
        })
        .catch(function (error) {
            console.log(error)
            alert(error);
        });
    }

    const goToDetail = () => {
        window.location.href = `/admin/manageuser/detail/:${toggleData.usId}/:1/pointmanage/:1`
    }
    const maskingPhone = (originStr) => {
        let maskingStr = '';
        let phoneStr =  originStr.length < 11 ? originStr.match(/\d{10}/gi) : originStr.match(/\d{11}/gi);
            
        if(originStr.length < 11)
        { // 1.1) 0110000000
            return maskingStr = originStr.toString().replace(phoneStr, phoneStr.toString().replace(/(\d{3})(\d{3})(\d{4})/gi,'$1***$3'));
        }
        else
        { // 1.2) 01000000000
            return maskingStr = originStr.toString().replace(phoneStr, phoneStr.toString().replace(/(\d{3})(\d{4})(\d{4})/gi,'$1****$3'));
        }
    }

    return (
        <NoticeWrapper>
            <Title>카카오 알림톡</Title>
            <Description>
                발송 이력 관리 <BcArrow src={breadcrumbArrow} /> 카카오 알림톡
            </Description>
            <SearchBox>
                <ColumnBox>
                    <FlexBox>
                        <SelectBox name="searchselect" id="serachselect" onChange={onTypeChange}>
                            <option value="all">전체</option>
                            <option value="이름">이름</option>
                            <option value="휴대폰 번호">휴대폰 번호</option>
                            <option value="내용">내용</option>
                        </SelectBox>
                        <SelectBox
                        name="searchselect2"
                        id="serachselect2"
                        onChange={onTypeChange2}
                        value={searchInfo.searchData||undefined}  
                        >
                            <option value={null}>전체</option>
                        <option value="회원가입">회원가입</option>
                        <option value="예약 완료">예약 완료</option>
                        <option value="예약 시간 만료">예약 시간 만료</option>
                        <option value="충전 완료">충전 완료</option>
                        <option value="예약 취소">예약 취소</option>
                        <option value="충전 실패">충전 실패</option>
                        <option value="충전 중지">충전 중지</option>
                        <option value="충전 종료">충전 종료</option>
                        <option value="거치 상태 알림">거치 상태 알림</option>
                        <option value="문의 답변 완료">문의 답변 완료</option>
                        <option value="고장 신고 답변 완료">고장 신고 답변 완료</option>
                        </SelectBox>
                        <SearchInput type="text" value={searchInfo.searchData||''} placeholder={'검색어 입력'} onChange={onSearchDataChange}/>
                        <div onClick={onReset}>
                            <img src={DeleteSearch} alt="" />
                            <p>초기화</p>
                        </div>
                    </FlexBox>
                    <SearchIndexBox  startChange={onStartDateChange} endChange={onEndDateChange}
                        handleDate={handleDate}
                        startDate={searchInfo.startDate}
                        endDate={searchInfo.endDate}/>
                </ColumnBox>
            </SearchBox>
            <Table>
                <thead>
                    <tr>
                        <th>
                            No.
                        </th>
                        <th>
                            <BorderDiv>이름</BorderDiv>
                        </th>
                        <th>
                            발송 종류
                        </th>
                        <th><BorderDiv>연락처</BorderDiv></th>
                        <th>내용</th>
                        <th><BorderDiv>스테이션 명</BorderDiv></th>
                        <th><BorderDivR>포트</BorderDivR></th>
                        <th>발송 일시</th>
                    </tr>
                </thead>
                <tbody>
                    {noticeListInfo.map((item, key) => (
                        <KaKaoList
                            key={key}
                            no={item.index_no}
                            usId={item.us_id}
                            usName={item.us_name}
                            alimType={item.alim_talk_type}
                            usPhone={item.us_phone}
                            content={item.contents}
                            stationName={item.station_name}
                            port={item.port}
                            hover={onHover}
                            notiDate={item.notify_date}
                            setOnHoverTrue={setOnHoverTrue}
                            setOnHoverFalse={setOnHoverFalse}
                            handleToggleDetailModal={handleToggleDetailModal}
                        />
                    ))}
                </tbody>
            </Table>
            {
            toggleDetailModal?
            <MessageDetailModal 
                toggle={toggleDetailModal}
                toggleFunc={handleToggleDetailModal}
                goToDetail={goToDetail}
                no={toggleData.no}
                alimType={toggleData.alimType}
                content={toggleData.content}
                usName={toggleData.usName}
                usPhone={levelAuth ? toggleData.usPhone : toggleData.usPhone === '' ? '' : maskingPhone(toggleData.usPhone)}
                notiDate={toggleData.notiDate}
            />
            :
            ''
            }
            <PaginationWrapper>
                <div>
                    {
                        changeState ?
                            <div>
                                거치 유저 알림톡 발송 주기
                                <SelectBoxMin type="number" value={talkMin} placeholder={'주기 입력'} onChange={handleTalkMin}>
                                    <option value={1}>1시간</option>
                                    <option value={2}>2시간</option>
                                    <option value={3}>3시간</option>
                                    <option value={4}>4시간</option>
                                    <option value={6}>6시간</option>
                                    <option value={12}>12시간</option>
                                </SelectBoxMin>
                                <ChangeButton onClick={handleChangeUserState}>변경 완료</ChangeButton>
                            </div>
                            :
                            <div>거치 유저 알림톡 발송 주기 <HighLight>{talkMin} 시간</HighLight><ChangeButton onClick={handleChangeState}>주기 변경</ChangeButton></div>
                    }
                </div>
                <Pagination
                    no={no.replace(':', '')}
                    url="/admin/kakao/:"
                    pages={noticeInfo['pages']}
                    isFirstPage={noticeInfo['isFirstPage']}
                    isLastPage={noticeInfo['isLastPage']}
                    prePage={noticeInfo['prePage']}
                    nextPage={noticeInfo['nextPage']}
                    hasPrePage={noticeInfo['hasPreviousPage']}
                    hasNextPage={noticeInfo['hasNextPage']}
                />
                <p/>
            </PaginationWrapper>
            <AuthCheckModal
                toggle={toggleAuthModal}
                toggleFunc={handleToggleAuthModal}
            />
        </NoticeWrapper>
    );
}

export default ManageAdminUser;
