import React from 'react';
import CheckBox from "./CheckBox";
import styled from "styled-components";


const Tr = styled.tr`
  &:hover{
    background-color: #f6f6f6;
  }
  & > td:nth-child(6){
    color : ${props => props.running === 'N' ? '#df1f26' : ''}
  }
`

function SearchGroupDetailInnerTable(props) {

    const goToStationDetail = () => {
        window.location.href = `/admin/devicedetail/:${props.stationId}/:1/:1`
    }

    return (
        <Tr running={props.running}>
            <td><CheckBox no={props.stationId} onChange={props.onChecked} checked={props.checked}/></td>
            <td>{props.rowNum}</td>
            <td onClick={goToStationDetail} style={{cursor : 'pointer'}}>{props.stationName}</td>
            <td>{props.stationId}</td>
            <td>{props.dateTime}</td>
            <td>{props.running === 'Y' ? 'ON' : 'OFF'}</td>
            <td>{props.usesPort} / {props.maxPort}</td>
        </Tr>
    );
}

export default SearchGroupDetailInnerTable;