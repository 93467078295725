import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import {
    ManageUserDetailWrapper,
    Title,
    Description,
    BcArrow,
} from '../css/styles';
import axios from 'axios';
import Cookie from 'universal-cookie';
import { useParams } from 'react-router';
import VersionUpdateTable from '../components/VersionUpdateTable';
import styled from 'styled-components';
import AuthCheckModal from '../components/AuthCheckModal';

import breadcrumbArrow from '../asset/img/breadcrumbarrow.png';

import EmptyValueModal from '../components/EmptyValueModal';
import AppVersionCheckModal from '../components/AppVersionCheckModal';

const cookies = new Cookie();

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    & > button:first-child {
        border : 1px solid #c4c4c4 !important;
        border-radius : 10px;
        color : black;
        cursor: pointer;
    }
    & > button:last-child {
        border : 1px solid #df1f26 !important;
        border-radius : 10px;
        background-color : #df1f26;
        color : white;
        cursor: pointer;
    }
`;

const TableTitle = styled.p`
    padding-top : 2%;
    font-size : 24px;
    font-family : notosansbold;
`

function VersionManage(props) {
    const [versionInfo, setVersionInfo] = React.useState([]);
    const [toggleCheckModal, setToggleCheckModal] = React.useState(false);

    const [version , setVersion] = React.useState(null);

    const [toggleEmptyModal , setToggleEmptyModal] = React.useState(false);

    const [toggleAuthModal , setToggleAuthModal] = React.useState(false);

    const handleToggleCheckModal = () => {
        setToggleCheckModal(!toggleCheckModal);
    }

    const handleToggleAuthModal = () => {
        setToggleAuthModal(!toggleAuthModal);
    }
    const handleToggleEmptyModal = () => {
        setToggleEmptyModal(!toggleEmptyModal);
    }
    
    const onVersionChange = (e) => {
        setVersion(e.target.value);
    }

    const navigate = useNavigate();
    const { os } = useParams();

        useEffect(() => {
            axios({
                method: 'get',
                url: `${window.Config.G_API_URL}/admin/app-version/`,
                params: {
                    token: cookies.get('token'),
                },
            })
                .then((response) => {
                    if (response.data.result['code'] === '200') {
                        setVersionInfo(response.data.data);
                    } else if (response.data.result['code'] === '401'){
                        alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                        window.location.href = '/'
                    } else {
                        alert(response.data.result['message']);
                    }
                })
                .catch(function (error) {
                    alert(error);
                });
        }, []);

    const onSubmit = () => {
        console.log('124')
        axios({
            method: 'post',
            url: `${window.Config.G_API_URL}/admin/app-version/`,
            params: {
                token: cookies.get('token'),
                appVersion : version ,
                os_type : os.replace(':' , ''),
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    window.location.href = '/admin/versionmanage';
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else if(response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                handleToggleEmptyModal();
            });
    }

    const checkVersion = () => {
        console.log('123')
        axios({
            method: 'get',
            url: `${window.Config.G_API_URL}/admin/app-version/check`,
            params: {
                token: cookies.get('token'),
                appVersion : version ,
                os_type : os.replace(':' , ''),
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    onSubmit();
                }   else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                }  else if(response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                } else if (response.data.result['code'] === '403'){
                    handleToggleCheckModal();
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                handleToggleEmptyModal();
            });
    }

    return (
        <ManageUserDetailWrapper>
            <Title>앱 버전 수정</Title>
            <Description>
                관리자 설정 <BcArrow src={breadcrumbArrow} /> 앱 버전 관리 <BcArrow src={breadcrumbArrow} /> 앱 버전 수정
            </Description>
            <TableTitle>{os === ':AOS' ? '안드로이드' : 'IOS'} 버전 수정</TableTitle>
            <VersionUpdateTable 
                version={os === ':AOS' ? versionInfo.AOS_appVersion : versionInfo.IOS_appVersion}
                date={os === ':AOS' ? versionInfo.AOS_date_time : versionInfo.IOS_date_time}
                onChange={onVersionChange}
                value={version}
            />
            <ButtonWrapper>
                <button onClick={() => navigate(-1)}>목록</button>
                <button onClick={checkVersion}>저장</button>
            </ButtonWrapper>

            <EmptyValueModal
                toggle={toggleEmptyModal}
                toggleFunc={handleToggleEmptyModal}
            />
            <AuthCheckModal
                toggle={toggleAuthModal}
                toggleFunc={handleToggleAuthModal}
            />
            <AppVersionCheckModal
                toggle={toggleCheckModal}
                toggleFunc={handleToggleCheckModal}
                resultFunc={onSubmit}
            />
        </ManageUserDetailWrapper>
    );
}

export default VersionManage;
