import React from 'react';

function StationUsageList(props) {    
    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };
    return (
        <tr>
            <td>{props.rowNum}</td>
            <td>{props.port}</td>
            <td>{props.stationName}</td>
            <td>{props.stationId}</td>
            <td>{props.start}</td>
            <td>{props.end}</td>
            <td>{props.charge}</td>
            <td>{props.hold}</td>
            <td>{props.couponAmount === null ? '-' : numberWithCommas(props.couponAmount)}</td>
            <td>{props.amount === null ? props.amount : numberWithCommas(props.amount)}</td>
        </tr>
    );
}

export default StationUsageList;
