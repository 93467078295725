import axios from 'axios';
import React, { useEffect } from 'react';
import Cookie from 'universal-cookie';

import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import CancelModal from '../components/CancelModal';
import {
    BcArrow,
    Description,
    NoticeWriteButtonWrapper,
    NoticeWriteWrapper,
    Title,
    WriteBoardForm
} from '../css/styles';

import Bar from '../asset/img/bar.png';
import breadcrumbArrow from '../asset/img/breadcrumbarrow.png';
import Qr from '../asset/img/qr.png';
import ImageModal from '../components/ImageModal';

const cookies = new Cookie();

const ContentWrapper = styled(WriteBoardForm)`
    height : auto;
    & > div:first-child{
        padding : 1% 2%;
        font-size: 32px;
        line-height: 46px;
    }
    & > div:nth-child(2){
        display : flex;
        align-items : center;
        column-gap : 20px;
        padding : 0.5% 2%;
        & > img {
            height : 26px;
        }
        & > div{
            display : flex !important;
            column-gap : 16px;
            align-items : center;
            & > p:first-child{
                font-family : notosansbold;
            }
            & > p:last-child{
                color : #df1f26;
                font-size: 12px;
                border: 1px solid #DF1F26;
                border-radius: 8px;
                padding : 1px 5px 2px 5px;
                cursor : pointer;
            }
        }
        & > p:last-child {
            display : flex;
            align-items : center;
            column-gap: 10px;
            & > img {
                width : 18px;
            }
        }
    }
    & > div:last-child{
        padding : 2% 2%;
        border-bottom : none;
        line-height: 160%;
    }
`

const Button = styled.button`
    border: 1px solid #C4C4C4 !important;
    border-radius: 10px;
    background-color: white !important;
    color : #df1f26 !important;
`

const AskImg = styled.div`
    display : flex;
    flex-direction : row;
    padding-bottom : 20px; 
    column-gap: 10px;
    & > img {
        width : 60px;
        height : 60px;
        border-radius: 10px;
        cursor : pointer;
    }
`

const P = styled.p`
    color : ${props => props.name === null ? '#df1f26' : ''};
`

function AskDetail() {
    const { no } = useParams();
    const [title, setTitle] = React.useState('');
    const [content, setContent] = React.useState('');
    const [isCheck, setIsCheck] = React.useState(false);
    const [exposure, setExposure] = React.useState('N');
    const [cancelModalToggle, setCancelModalToggle] = React.useState(false);
    const [askDetailInfo , setAskDetailInfo] = React.useState([]);

    const [toggleModal1 , setToggleModal1] = React.useState(false);
    const [toggleModal2 , setToggleModal2] = React.useState(false);
    const [toggleModal3 , setToggleModal3] = React.useState(false);
      
    const handleToggleModal1 = () => {
        setToggleModal1(!toggleModal1);
    }
     
    const handleToggleModal2 = () => {
        setToggleModal2(!toggleModal2);
    }   

    const handleToggleModal3 = () => {
        setToggleModal3(!toggleModal3);
    }

    useEffect(()=>{
        axios({
            method: 'get',
            url: `${window.Config.G_API_URL}/admin/ask/detail`,
            params: {
                token: cookies.get('token'),
                ask_id: no.replace(':', ''),
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    setAskDetailInfo(response.data.data);
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    }, []);

    const goToComment = () => {
        window.location.href = `/admin/ask/comment/${no}`
    };

    const goToCommentUpdate = () => {
        window.location.href = `/admin/ask/comment/update/${no}`
    };
    const handleCancelModal = () => {
        setCancelModalToggle(!cancelModalToggle);
    };

    const handleExposure = (e) => {
        setIsCheck(!isCheck);
        if (isCheck) {
            setExposure('N');
        } else {
            setExposure('Y');
        }
    };

    const goToUserDetail = () => {
        window.location.href = `/admin/manageuser/detail/:${askDetailInfo.ask_user_id}/:1/pointmanage/:1`
    }

    const navigate = useNavigate();

    return (
        <NoticeWriteWrapper>
            <Title>문의 상세</Title>
            <Description>
                게시판 관리 <BcArrow src={breadcrumbArrow} /> 문의{' '}
                <BcArrow src={breadcrumbArrow} /> 문의 상세
            </Description>
            <ContentWrapper>
                <div>
                    Q. {askDetailInfo.ask_title && askDetailInfo.ask_title.replace(/\%5B/g , '[').replace(/\%5D/g, ']')}
                </div>
                <div>
                    <P name={askDetailInfo.us_name}>{askDetailInfo.us_name === null ? '탈퇴한 사용자 입니다.' : askDetailInfo.us_name}</P>
                    <img src={Bar} alt="" />
                    <div><p>회원 고유 번호</p> <span>{askDetailInfo.ask_user_id}</span>{askDetailInfo.us_name === null ? '' : <p onClick={goToUserDetail}>회원 상세</p>}</div>
                    <img src={Bar} alt="" />
                    <p>{askDetailInfo.ask_insert_date}</p>
                    {
                        askDetailInfo.ask_station_id === 'name' ? 
                        ''
                        :
                        <>
                            <img src={Bar} alt="" />
                            <p><img src={Qr}/>{askDetailInfo.ask_station_id} 스테이션 {askDetailInfo.ask_station_port}번 포트</p>
                        </>
                    }
                </div>
                <div>
                    <AskImg>
                        {
                            askDetailInfo.ask_image_path_1 === '' ?
                            ''
                            :
                            <>
                                <img src={`${window.Config.G_API_URL}/images/ask/${askDetailInfo.ask_image_path_1}`} alt="" onClick={handleToggleModal1}/>
                                <ImageModal
                                    toggle={toggleModal1}
                                    toggleFunc={handleToggleModal1}
                                    url={`${window.Config.G_API_URL}/images/ask/`}
                                    img={askDetailInfo.ask_image_path_1}
                                />
                            </>
                        }
                        {
                            askDetailInfo.ask_image_path_2 === '' ?
                            ''
                            :
                            <>
                            <img src={`${window.Config.G_API_URL}/images/ask/${askDetailInfo.ask_image_path_2}`} alt="" onClick={handleToggleModal2} />
                                <ImageModal
                                    toggle={toggleModal2}
                                    toggleFunc={handleToggleModal2}
                                    url={`${window.Config.G_API_URL}/images/ask/`}
                                    img={askDetailInfo.ask_image_path_2}
                                />
                            </>
                        }
                        {
                            askDetailInfo.ask_image_path_3 === '' ?
                            ''
                            :
                            <>
                            <img src={`${window.Config.G_API_URL}/images/ask/${askDetailInfo.ask_image_path_3}`} alt="" onClick={handleToggleModal3} />
                                <ImageModal
                                    toggle={toggleModal1}
                                    toggleFunc={handleToggleModal3}
                                    url={`${window.Config.G_API_URL}/images/ask/`}
                                    img={askDetailInfo.ask_image_path_3}
                                />
                            </>
                        }
                    </AskImg>
                    {askDetailInfo.ask_contents && askDetailInfo.ask_contents.split("\n").map((line,key) => { //this.props.data.content: 내용
                        return (
                        <span key={key}>
                            {line.replace(/\%5B/g , '[').replace(/\%5D/g, ']')}
                            <br />
                        </span>
                        );
                    })}
                </div>
            </ContentWrapper>
            {
                askDetailInfo.ask_comment_complete === '0' ? ''
                :
                <ContentWrapper>
                <div>
                    A. {askDetailInfo.title && askDetailInfo.title.replace(/\%5B/g , '[').replace(/\%5D/g, ']')}
                </div>
                <div>
                    <p>{askDetailInfo.admin_name}</p>
                    <img src={Bar} alt="" />
                    <p>{askDetailInfo.insert_date}</p>
                </div>
                <div>
                    {askDetailInfo.contents && askDetailInfo.contents.split("\n").map((line,key) => { //this.props.data.content: 내용
                        return (
                        <span key={key}>
                            {line.replace(/\%5B/g , '[').replace(/\%5D/g, ']')}
                            <br />
                        </span>
                        );
                    })}
                </div>
            </ContentWrapper>
            }
            <NoticeWriteButtonWrapper>
            <button onClick={() => navigate(-1)}>목록</button>
                <div>
                    {
                        askDetailInfo.ask_comment_complete === '0' ? 
                        askDetailInfo.us_name === null ? '' : <Button onClick={goToComment}>답변 등록</Button>
                        :
                        <Button onClick={goToCommentUpdate}>답변 수정</Button>
                    }
                </div>
            </NoticeWriteButtonWrapper>

            <CancelModal
                toggle={cancelModalToggle}
                toggleFunc={handleCancelModal}
            />
        </NoticeWriteWrapper>
    );
}

export default AskDetail;
