import React, { useEffect } from 'react';
import {
    ManageUserDetailWrapper,
    Title,
    Description,
    BcArrow,
} from '../css/styles';
import {useNavigate} from "react-router-dom";
import axios from 'axios';
import Cookie from 'universal-cookie';
import { useParams } from 'react-router';

import breadcrumbArrow from '../asset/img/breadcrumbarrow.png';
import AdvertisementDetailTable from "../components/AdvertisementDetailTable";
import DeleteModal from '../components/DeleteModal';
import styled from "styled-components";
import AuthCheckModal from '../components/AuthCheckModal';

const AdTitle = styled.h2`
    font-size: 28px;
    font-family: notosansbold;
    padding-top: 40px;
`

const Img = styled.img`
  margin-top: 20px;
  width: 40%;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  & > div {
    & > button {
      font-weight: normal;
      font-size: 16px;
      line-height: 23px;
      border: 1px solid #c4c4c4;
      border-radius: 10px;
      background-color: white;
      padding: 7px 20px;
      margin-left: 18px;
      cursor: pointer;
    }
    & >button:first-child{
      color : #DF1F26;
      border: 1px solid #DF1F26;
    }
  }
`

const cookies = new Cookie();

function AdvertisementDetail(props) {
    const { no, num } = useParams();
    const [adInfo, setAdInfo] = React.useState([]);
    const [toggleDeleteModal, setToggleDeleteModal] = React.useState(false);
    const [number , setNum] = React.useState('');
    const [toggleAuthModal , setToggleAuthModal] = React.useState(false);

    const handleToggleAuthModal = () => {
        setToggleAuthModal(!toggleAuthModal);
    }


    const navigate = useNavigate()

    useEffect(() => {
        axios({
            method: 'get',
            url: `${window.Config.G_API_URL}/admin/advertisement/detail`,
            params: {
                token: cookies.get('token'),
                no: no.replace(':', ''),
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    console.log(response.data)
                    setAdInfo(response.data.data);
                    setNum(response.data.data.ad_id)
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    }, [no]);


    const onDelete = () => {
        axios({
            method: 'delete',
            url: `${window.Config.G_API_URL}/admin/advertisement/`,
            params: {
                token: cookies.get('token'),
                delete_items: no.replace(':', ''),
            },
            // no : checkList 파라미터 [] 없애는 옵션
            paramsSerializer: function (paramObj) {
                const params = new URLSearchParams();
                for (const key in paramObj) {
                    params.append(key, paramObj[key]);
                }

                return params.toString();
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    console.log(response);
                    window.location.href = '/admin/advertisement/:1';
                } else if(response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    };

    const handleDeleteModal = () => {
        setToggleDeleteModal(!toggleDeleteModal);
    };

    return (
        <ManageUserDetailWrapper>
            <Title>광고 상세</Title>
            <Description>
                배너관리 <BcArrow src={breadcrumbArrow} /> No.{number}
            </Description>
            <AdTitle>{adInfo.ad_name}</AdTitle>
            <AdvertisementDetailTable
                adStart={adInfo.ad_exposure_start_date}
                adEnd={adInfo.ad_exposure_expire_date}
                adLocation={adInfo.ad_address}
                adLat={adInfo.ad_loc_latitude}
                adLong={adInfo.ad_loc_longitude}
                adType={adInfo.ad_type}
            />
            <Img src={`/images/advertisement/${adInfo.ad_image_url}`} alt=""/>
            <ButtonWrapper>
                <button onClick={() => navigate(-1)}>목록</button>
                <div>
                    <button onClick={() => window.location.href = `/admin/advertisement/update/:${adInfo.ad_id}`}>수정</button>
                    <button onClick={handleDeleteModal}>삭제</button>
                </div>
            </ButtonWrapper>

            <DeleteModal
                content={'광고를'}
                toggle={toggleDeleteModal}
                toggleFunc={handleDeleteModal}
                deleteFunc={onDelete}
            />
            <AuthCheckModal
                toggle={toggleAuthModal}
                toggleFunc={handleToggleAuthModal}
            />
        </ManageUserDetailWrapper>
    );
}

export default AdvertisementDetail;
