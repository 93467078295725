import React from 'react';
import styled from "styled-components";

import { useParams } from "react-router";
import Cookie from "universal-cookie";
import { BorderDivR } from '../css/styles';
import LoginLogList from "./LoginLogList";
import Pagination from "./Pagination";
import SearchIndexBox from "./SearchIndexBox";

const cookies = new Cookie();

const PointManageWrapper = styled.div`
  margin-top: 0 !important;
  & > table {
    width: 100%;
    text-align: center;
    & > tbody > tr {
      line-height: 40px;
      border-bottom: 1px solid #E5E5E5;
      font-weight: 500;
      font-size: 16px;
    }
    & > tbody > tr:first-child {
      line-height: 20px;
      border-bottom: 2px solid #E5E5E5;
      & > th:first-child{
        padding-bottom: 10px;
      }
    }
    & > tbody > tr > td:first-child{
      width: 10%;
    }
    & > tbody > tr > th:last-child{
      text-align: left;
      padding-left: 10%;
    }
    & > tbody > tr > td:last-child{
      text-align: left;
      padding-left: 7%;
    }
  }
  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    & > button {
      border: 1px solid #C4C4C4;
      border-radius: 10px;
      background-color: white;
      font-size: 16px;
      line-height: 23px;
      width: 8%;
      padding : 5px 0;
    }
    & > div > button {
      border: 1px solid #C4C4C4;
      border-radius: 10px;
      background-color: white;
      font-size: 16px;
      line-height: 23px;
      padding : 5px;
      margin-right: 10px;
    }
  }
`

const SearchBox = styled.div`
    padding : 2% 0;
    display: flex;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    column-gap: 15px;
    & > div {
      display: flex;
      align-items: center;
      & > img {
        margin-top: 10px;
        margin-left: 7px;
        width: 20px;
      }
      & > p {
        font-size: 16px;
        margin-top: 9px;
        line-height: 23px;
        color: #C4C4C4;
      }
    } 
`

const ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
`

const CursorBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: center !important;
`

function PointManage(props) {

    const {no} = useParams();

    return (
        <PointManageWrapper>
            <SearchBox>
                <ColumnBox>
                    <SearchIndexBox
                        handleDate={props.handleDate}
                        startChange={props.onStartDateChange}
                        endChange={props.onEndDateChange}
                        startDate={props.startDate}
                        endDate={props.endDate}
                    />
                </ColumnBox>
            </SearchBox>
            <table>
                <tbody>
                <tr>
                    <th><BorderDivR>No.</BorderDivR></th>
                    <th>로그인 일시</th>
                </tr>
                {
                    props.loginInfo.map((item , key) => (
                        <LoginLogList
                            key={key}
                            date={item.date_time}
                            rowNum={item.rownum}
                        />
                    ))
                }
                </tbody>
            </table>
            <CursorBox>
                <Pagination
                    no={no.replace(':' , '')}
                    url={`/admin/manageadminuser/detail/:admin/:`}
                    pages={props.pageInfo['pages']}
                    isFirstPage={props.pageInfo['isFirstPage']}
                    isLastPage={props.pageInfo['isLastPage']}
                    prePage={props.pageInfo['prePage']}
                    nextPage={props.pageInfo['nextPage']}
                    hasPrePage={props.pageInfo['hasPreviousPage']}
                    hasNextPage={props.pageInfo['hasNextPage']}
                />
            </CursorBox>
        </PointManageWrapper>
    );
}

export default PointManage;