import React from 'react';
import Modal from 'react-modal';
import { useParams } from "react-router";
import styled from 'styled-components';
import Cookie from "universal-cookie";

const cookies = new Cookie();

const Description = styled.div`
    & > h1 {
      font-family: notosansbold;
      font-size: 20px;
      letter-spacing: -1px;
      padding-top: 10px;
      padding-bottom: 17px;
    }
  & > p {
    letter-spacing: -0.3px;
    font-weight: bold;
    padding-bottom: 27px;
  }
  & > textarea {
    padding : 16px 10px;
    resize: none;
    width: 96%;
    height: 370px;
    overflow-y: scroll;
    
    border-radius: 10px;
    border: 1px solid #e5e5e5;
    &:focus{
      outline: none;
    }
  }
`

const ButtonContainer = styled.div`
    display : flex;
    justify-content : space-between;
    margin-top : 20px;
`

const RedButton = styled.a`
  background: #DF1F26;
  color: white;
  font-size: 16px;
  line-height: 23px;
  padding : 7px 20px;
  border-radius: 10px;
  border: 1px solid #C4C4C4;
  margin-left : 20px;
`
const Button = styled.a`
    font-size: 16px;
    line-height: 23px;
    padding : 7px 20px;
    background-color: white;
    border-radius: 10px; 
    border: 1px solid #C4C4C4;
`

function ManagerMemoRegModal(props) {

    const {no , num} = useParams();
    const [count , setCount] = React.useState('0자 / 90자');

    const onKey = (value, e) => {
        const obj = document.querySelector('#content');
        const body = obj.value;

        const finalBody = body.length + '자 / 90자';
        setCount(finalBody);
    }
    
    return (
        <Modal isOpen={props.toggle} onRequestClose={props.toggleFunc}
               style={{
                   overlay :{
                       backgroundColor : 'rgba(34, 30, 31, 0.5)'
                   },
                   content : {
                       height : '67%',
                       left : '32%',
                       right : '32%',
                       top : '15%',
                       borderRadius : '10px',
                       border: '1px solid #C4C4C4' ,
                   }
               }}
        >
            <Description>
                <h1>문자 발송</h1>
                <p className="textCount">{count}</p>
                <textarea id="content" maxLength={90} onKeyUp={e => onKey(props.setTextMsg, e)} onChange={props.setTextMsg}/>
            </Description>
            <ButtonContainer>
                <Button onClick={props.toggleFunc}>취소</Button>
                <RedButton onClick={props.confirm}>전송</RedButton>
            </ButtonContainer>
        </Modal>
    );
}

export default ManagerMemoRegModal;
