import React, { useEffect } from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';
import {
    ManageUserDetailWrapper,
    Title,
    Description,
    BcArrow,
} from '../css/styles';
import ManageUserDetailTable from '../components/ManageUserDetailTable';
import axios from 'axios';
import Cookie from 'universal-cookie';
import { useParams } from 'react-router';
import IndexLink from '../components/IndexLink';
import PointManage from '../components/PointManage';
import StationUsage from '../components/StationUsage';
import NotificationManage from '../components/NotificationManage';
import LoginLog from '../components/LoginLog';
import ManagerMemo from '../components/ManagerMemo';
import Report from '../components/Report';
import ManageMessage from '../components/ManageMessage';

import breadcrumbArrow from '../asset/img/breadcrumbarrow.png';
import Inquiry from '../components/Inquiry';
import CouponUsage from '../components/CouponUsage';

const cookies = new Cookie();

function ManageUserDetail(props) {
    const { no, page } = useParams();
    const [userInfo, setUserInfo] = React.useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        axios({
            method: 'get',
            url: `${window.Config.G_API_URL}/admin/user/`,
            params: {
                token: cookies.get('token'),
                us_id: no.replace(':', ''),
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    setUserInfo(response.data.data);
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    }, [no]);

    return (
        <ManageUserDetailWrapper>
            <Title>회원 관리</Title>
            <Description>
                회원 관리 <BcArrow src={breadcrumbArrow} /> 회원 관리{' '}
                <BcArrow src={breadcrumbArrow} /> 회원 상세
            </Description>
            <ManageUserDetailTable userInfo={userInfo}/>
            <div>
                <button onClick={() => window.location.href = `/admin/manageuser/${page}`}>목록</button>
            </div>
            <IndexLink no={no.replace(':', '')} page={page.replace(':','')}/>
            <Routes>
                <Route path="/pointmanage/:num" element={<PointManage />} />
                <Route path="/stationusage/:num" element={<StationUsage />} />
                <Route
                    path="/notificationmanage/:num"
                    element={<NotificationManage />}
                />
                <Route
                    path="/managemessage/:num"
                    element={<ManageMessage />}
                />
                <Route path="/loginlog/:num" element={<LoginLog />} />
                <Route path="/managermemo/:num" element={<ManagerMemo />} />
                <Route path="/inqury/:num" element={<Inquiry />} />
                <Route path="/report/:num" element={<Report />} />
                <Route path="/couponusage/:num" element={<CouponUsage />} />
            </Routes>
        </ManageUserDetailWrapper>
    );
}

export default ManageUserDetail;
