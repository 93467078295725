import React from 'react'

import Ham from '../asset/img/hamburger.png';
import CheckBox from "./CheckBox";
import styled from "styled-components";
import CategoryUpdateModal from './CategoryUpdateModal';
import ActiveArrow from '../asset/img/activearrow.png';

const Tr = styled.tr`
line-height : 50px !important;
    & > td:nth-child(2){
        text-align : left;
        padding-left : 2%;
        padding-right : 1%;
        & > div {
            display : flex;
            justify-content : space-between;
            & > div {
                display : flex;
                justify-content : space-between;
                align-items : center;
                column-gap: 24px;
                width : 17%;
                & > button {
                    border: 1px solid #C4C4C4;
                    border-radius: 8px;
                    background-color : white;
                    padding : 2px 7px;
                }
                & > img {
                    width : 7px;
                    cursor: pointer;
                    transform : rotate(270deg) !important;
                    padding : 5%;
                }
                & > img:last-child{
                    transform : rotate(90deg) !important;
                }
            }
        }
    }
`

function CategoryList(props) {

    const [updateModal , setUpdateModal] = React.useState(false);

    const handleUpdateModal = () => {
        setUpdateModal(!updateModal);
    }

    return (
        <Tr>
            <td><CheckBox no={props.name} onChange={props.onChange} checked={props.checked}/></td>
            <td>
                <div>
                    {props.name} 
                    <div>
                        <button onClick={handleUpdateModal}>이름 수정</button>
                        {props.index === 0 ?
                            ''
                            :
                            <img src={ActiveArrow} alt="" onClick={() => props.shiftUp(props.index , props.info[props.index])}/>
                        }
                        {
                            props.index === props.length-1?
                            <img/>
                            :
                            <img src={ActiveArrow} alt="" onClick={() => props.shiftDown(props.index  , props.info[props.index + 1])}/>
                        }
                    </div>
                </div>
            </td>
            <CategoryUpdateModal
                toggle={updateModal}
                toggleFunc={handleUpdateModal}
                name={props.name}
            />
        </Tr>
    )
}

export default CategoryList;