import React from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';

import { SideMenuWrapper } from '../css/styles';

import ActiveMessage from '../asset/img/activeMessage.png';
import ActiveBanner from '../asset/img/activebanner.png';
import ActiveCoupon from '../asset/img/activecoupon.png';
import ActiveManagerSetting from '../asset/img/activemanageadmin.png';
import ActiveManageboard from '../asset/img/activemanageboard.png';
import ActiveMoney from '../asset/img/activemoney.png';
import ActiveProfile from '../asset/img/activeprofile.png';
import ActiveStationManage from '../asset/img/activestation.png';
import Banner from '../asset/img/banner.png';
import Coupon from '../asset/img/coupon.png';
import Manageboard from '../asset/img/manageboard.png';
import ManagerSetting from '../asset/img/managersetting.png';
import Message from '../asset/img/message.png';
import Money from '../asset/img/money.png';
import Profile from '../asset/img/profile.png';
import Logo from '../asset/img/sidemenulogo.png';
import StationManage from '../asset/img/stationmanageicon.png';
import SideMenuList from './SideMenuList';


function SideMenu() {
    let today = new Date();

    const goHome = () => {
        
    }
    const location = useLocation();
    function LinkComp(props) {
        const currentPath = location.pathname.split(':')[0];
        const checkPath = props.to.split(':')[0];
        
        const checkActive = () => {
            return currentPath === checkPath
        }
        
        return (
            <NavLink className={()=>checkActive() ? 'active-menu' : ''}  to={props.to}>{props.name}</NavLink>
        )
    }

  return (
    <SideMenuWrapper>
        {/* <img src={Logo} alt="logo" /> */}
        <Link to='/admin/notice/:1'>
            <img src={Logo} alt="logo" />
        </Link>
        <div>
            <SideMenuList img={StationManage} activeImg={ActiveStationManage} text='스테이션 관리'>
                <div>
                    <LinkComp to='/admin/searchgroup/:1' name={'그룹 검색'} />
                    <LinkComp to='/admin/searchstation/:1' name={'스테이션 검색'} />
                    <LinkComp to='/admin/error/:1' name={'에러 내역'} />
                </div>
            </SideMenuList>
            <SideMenuList activeImg={ActiveMoney} img={Money} text='정산 관리'>
                <div>
                    <LinkComp to='/admin/company/:1' name={'업체 관리'} />
                    <LinkComp to='/admin/groupstation/:1/:1' name={'그룹 및 스테이션 별 관리'} />
                </div>
            </SideMenuList>
            <SideMenuList activeImg={ActiveProfile} img={Profile} text='회원 관리'>
                <div>
                    <LinkComp to='/admin/manageuser/:1' name={'회원 관리'} />
                    <LinkComp to='/admin/breakoutuser/:1' name={'탈퇴 회원'} />
                </div>
            </SideMenuList>
            <SideMenuList activeImg={ActiveCoupon} img={Coupon} text='적립 관리'>
                <div>
                    <LinkComp to='/admin/coupon' name={'포인트 관리'} />
                    <LinkComp to='/admin/couponmanage/:1/:1' name={'쿠폰 관리'} />
                </div>
            </SideMenuList>
            <NavLink to={'/admin/advertisement/:1'}>
                <SideMenuList activeImg={ActiveBanner} img={Banner} text='광고 관리' arrow={false}/>
            </NavLink>
            <SideMenuList img={Message} activeImg={ActiveMessage} text='발송 이력 관리'>
                <div>
                    <LinkComp to='/admin/kakao/:1' name={'카카오 알림톡'} />
                    <LinkComp to='/admin/message/:1' name={'문자'} />
                </div>
            </SideMenuList>
            <SideMenuList activeImg={ActiveManageboard} img={Manageboard} text='게시판 관리' children='3'>
                <div>
                    <LinkComp to='/admin/notice/:1' name={'공지사항'} />
                    <LinkComp to='/admin/faq/:1' name={'FAQ'} />
                    <LinkComp to='/admin/ask/:1' name={'문의'} />
                    <LinkComp to='/admin/trouble/:1' name={'신고'} />
                </div>
            </SideMenuList>
            <SideMenuList img={ManagerSetting} activeImg={ActiveManagerSetting} text='관리자 설정'>
                <div>
                    <LinkComp to='/admin/manageadminuser/:1/:1/:1/:1/:1' name={'관리자 회원 관리'} />
                    <LinkComp to='/admin/versionmanage' name={'앱 버전 관리'} />
                </div>
            </SideMenuList>
        </div>
        <p><span>Today</span>{today.getFullYear()}-{(today.getMonth() + 1).toString().padStart(2,'0')}-{today.getDate().toString().padStart(2,'0')}</p>
    </SideMenuWrapper>
  )
}

export default SideMenu