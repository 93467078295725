import React, { useEffect } from 'react';
import styled from 'styled-components';
import Cookie from "universal-cookie";
const cookies = new Cookie();


const Tr = styled.tr`
    position : relative;
        & > td:nth-child(3) {
            width : 10%;
            text-align : center !important;
            padding-left : 0 !important;
        }
    & > td:nth-child(5){
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        max-width: 700px;
        cursor: pointer;
        text-align: left;
        overflow:hidden;
        line-height: 30px;
    }
    & > td:nth-child(6){
        
    }
`

function NoticeList(props) {
    const [levelAuth , setLevelAuth] = React.useState(false);

    useEffect(()=>{
        if(cookies.get('level')<=0){
            setLevelAuth(true);
        }
    },[])

  const maskingPhone = (originStr) => {
    let maskingStr = '';
    let phoneStr =  originStr.length < 11 ? originStr.match(/\d{10}/gi) : originStr.match(/\d{11}/gi);
        
    if(originStr.length < 11)
    { // 1.1) 0110000000
        return maskingStr = originStr.toString().replace(phoneStr, phoneStr.toString().replace(/(\d{3})(\d{3})(\d{4})/gi,'$1***$3'));
    }
    else
    { // 1.2) 01000000000
        return maskingStr = originStr.toString().replace(phoneStr, phoneStr.toString().replace(/(\d{3})(\d{4})(\d{4})/gi,'$1****$3'));
    }
}
  return (
        <Tr onClick={()=>props.handleToggleDetailModal(props)}>
            <td>{props.no}</td>
            <td>{props.usName}</td>
            <td>{props.alimType}</td>
            <td>{levelAuth ? props.usPhone : props.usPhone === '' ? '' : maskingPhone(props.usPhone)}</td>
            <td>
                {props.content}
            </td>
            <td>{props.stationName}</td>
            <td>{props.port}</td>
            <td>{props.notiDate}</td>
        </Tr>
  )
}

export default NoticeList