import React from 'react';
import CheckBox from "./CheckBox";
import styled from "styled-components";


const Tr = styled.tr`
  &:hover{
    background-color: #f6f6f6;
  }
`

const SmallText =styled.span`
    font-size : 12px;
`

function AdvertisementDetailTable(props) {
    
    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };
    
    return (
        <table>
            <tbody>
                <Tr>
                    <td>쿠폰 내용</td>
                    <td>{props.context}</td>
                </Tr>
                <Tr>
                    <td>금액 (원)</td>
                    <td>{props.amount === undefined ? props.amount : numberWithCommas(props.amount)}</td>
                </Tr>
                <Tr>
                    <td>발행일</td>
                    <td>{props.date && props.date.substring(0,10)}</td>
                </Tr>
                <Tr>
                    <td>유효기간 시작일</td>
                    <td>{props.startDate}</td>
                </Tr>
                <Tr>
                    <td>유효기간 종료일</td>
                    <td>{props.endDate}</td>
                </Tr>
                <Tr>
                    <td>발행사</td>
                    <td>{props.company}</td>
                </Tr>
                <Tr>
                    <td><SmallText>이용 갯수</SmallText>/ 발행 갯수</td>
                    <td>{props.usage} / {props.issAmount}</td>
                </Tr>
            </tbody>
        </table>
    );
}

export default AdvertisementDetailTable;