import axios from 'axios';
import React, { useEffect } from 'react';
import Cookie from 'universal-cookie';

import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import AuthCheckModal from '../components/AuthCheckModal';
import { Description, NoticeWrapper, Title } from '../css/styles';

import EmptyValueModal from '../components/EmptyValueModal';
const cookies = new Cookie();

const TableTitle = styled.h1`
  font-weight: bold;
  font-size: 32px;
  line-height: 46px;
  padding-top: 40px;
`

const Table = styled.table`
 &> tbody{
   & > tr{
     line-height: 80px !important;
   }
   & > tr:first-child{
     line-height: 60px !important;
     border-top: none;
     border-bottom: 2px solid #e5e5e5;
     font-family: notosansbold;
     font-weight: bold;
     font-size: 16px;
   }
   & > tr > td:first-child{
     font-family: notosansbold;
     font-size: 28px;
     color: #DF1F26;
     width: 17%;
     & > span{
       font-family: notosans;
       font-size: 22px;
       margin-left: 3px;
     }
     & > div {
       display: flex;
       justify-content: center;
       align-items: center;
     }
     & > div > span{
       font-family: notosans;
       font-size: 22px;
       margin-left: 3px;
     }
   }
   & > tr > td:nth-child(2){
     width: 17%;
   }
   & > tr > td:nth-child(3){
     width: 17%;
     text-align: center !important;
   }
 }
`

const ButtonContainer = styled.div`
 justify-content: flex-end !important;
 column-gap: 20px;
  & > button:first-child{
    color : black !important;
    cursor: pointer;
  }
  & > button:last-child{
    color : white !important;
    background-color: #DF1F26 !important;
    cursor: pointer;
  }
`

const ColorInput = styled.input`
  font-family: notosansbold;
  border: 1px solid #C4C4C4;
  width: 50%;
  padding: 2px 8px;
  border-radius: 10px;
  font-size: 24px;
  line-height: 35px;
  color: #DF1F26;
`

const BigInput = styled.input`
  border: 1px solid #C4C4C4;
  width: 40%;
  padding: 8px;
  border-radius: 10px;
`

function CouponRegister() {

    const [coupon , setCoupon] = React.useState([]);

    const [price , setPrice] = React.useState(null);
    const [bonus , setBonus] = React.useState(null);

    const [toggleEmptyModal , setToggleEmptyModal] = React.useState(false);
    const [toggleAuthModal , setToggleAuthModal] = React.useState(false);

    const handleToggleAuthModal = () => {
        setToggleAuthModal(!toggleAuthModal);
    }

    const handleToggleEmptyModal = () => {
        setToggleEmptyModal(!toggleEmptyModal);
    }

    useEffect(()=>{
        axios({
            method : 'get',
            url : `${window.Config.G_API_URL}/admin/point-coupon`,
            params : {
                token : cookies.get('token')
            }
        })
        .then(response => {
            if(response.data.result['code'] === '200'){
                setCoupon(response.data.data);
                console.log(response.data.data);
              } else if (response.data.result['code'] === '401'){
                alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.');
                window.location.href = '/';
            } else {
                alert(response.data.result['message']);
            }
        })
        .catch(function(error){
            alert(error);
        })
    },[])

    const onRegister = () => {
        axios({
            method : 'post',
            url : `${window.Config.G_API_URL}/admin/point-coupon`,
            params : {
                token : cookies.get('token'),
                price : price ,
                free_point : bonus
            }
        })
            .then(response => {
                if(response.data.result['code'] === '200'){
                    window.location.href = '/admin/coupon'
                  } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else if(response.data.result['code'] === '402'){
                  handleToggleAuthModal();
              } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function(error){
              handleToggleEmptyModal();
            })
    }

    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    const navigate = useNavigate()

    const onPriceChange = (e) => {
        setPrice(e.target.value)
    }

    const onBonusChange = (e) => {
        setBonus(e.target.value)
    }

    return (
        <NoticeWrapper>
            <Title>포인트 관리</Title>
            <Description>포인트 관리</Description>
            <TableTitle>등록된 포인트 리스트</TableTitle>
            <Table>
                <tbody>
                <tr>
                    <th>금액</th>
                    <th>충전 포인트</th>
                    <th>보너스 포인트</th>
                </tr>
                {
                    coupon.map((item,key) => (
                        <tr key={key}>
                            <td>{numberWithCommas(item.price)}<span>원</span></td>
                            <td>{numberWithCommas(item.price)}P</td>
                            <td>{numberWithCommas(item.free_point)}P</td>
                            <td/>
                        </tr>
                    ))
                }
                <tr>
                    <td><div><ColorInput value={price||''} placeholder='금액입력' onChange={onPriceChange}/><span>원</span></div></td>
                    <td>{numberWithCommas(Number(price))}P</td>
                    <td><BigInput value={bonus||''} placeholder='포인트입력' onChange={onBonusChange}/> P</td>
                </tr>
                </tbody>
            </Table>
            <ButtonContainer>
                <button onClick={()=>navigate(-1)}>취소</button>
                <button onClick={onRegister}>등록완료</button>
            </ButtonContainer>

            <EmptyValueModal
                toggle={toggleEmptyModal}
                toggleFunc={handleToggleEmptyModal}
            />
            <AuthCheckModal
                toggle={toggleAuthModal}
                toggleFunc={handleToggleAuthModal}
            />
        </NoticeWrapper>
    )
}

export default CouponRegister;