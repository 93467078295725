import React , {useEffect} from 'react';
import LoginInfo from "./LoginInfo";
import styled from "styled-components";
import SelectArrow from "../asset/img/selectarrow.png";
import {useParams} from "react-router";
import axios from "axios";
import Cookie from "universal-cookie";
import DeleteUserModal from "./DeleteUserModal";
import AuthCheckModal from '../components/AuthCheckModal';

const cookies = new Cookie();

const Cursor = styled.button`
  cursor: pointer;
`

const ChangeButton = styled.button`
  background-color: #df1f26 !important;
  color: white !important;
`

const SelectBox = styled.select`
  cursor: pointer;
  appearance:none;
  border: 1px solid #C4C4C4;
  border-radius: 10px;
  padding: 7px 30px 7px 10px;
  font-size: 16px;
  line-height: 23px;
  background-image: url(${SelectArrow});
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: 85%;
  &:focus{
    outline: none;
  }
`
function BreakOutUserDetailTable(props) {

    const {no} = useParams();

    const [changeState , setChangeState] = React.useState(false);
    const [toggleDeleteModal , setToggleDeleteModal] = React.useState(false);
    const [userState , setUserState] = React.useState('정상');
    const [toggleAuthModal , setToggleAuthModal] = React.useState(false);
    const [levelAuth , setLevelAuth] = React.useState(false);

    const handleToggleAuthModal = () => {
        setToggleAuthModal(!toggleAuthModal);
    }

    const stateChange = () => {
        handleChangeState();
    }

    const handleChangeState = () => {
        setChangeState(!changeState);
    }

    const handleToggleDeleteModal = () => {
        setToggleDeleteModal(!toggleDeleteModal);
    }

    const deleteUser = () => {
        axios({
            method : 'delete',
            url : `${window.Config.G_API_URL}/admin/user`,
            params : {
                token : cookies.get('token'),
                us_id : no.replace(':' , ''),
            },
        })
            .then(response => {
                if(response.data.result['code'] === '200'){
                    console.log(response);
                    window.location.href = `/admin/manageuser/:1`;

                } else if(response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function(error){
                alert(error);
            })
    }

    const handleChangeUserState = () => {
        axios({
            method : 'put',
            url : `${window.Config.G_API_URL}/admin/user/status`,
            params : {
                token : cookies.get('token'),
                us_id : no.replace(':' , ''),
                status : userState,
            }
        })
            .then(response => {
                if(response.data.result['code'] === '200'){
                    alert('변경이 완료되었습니다')
                    window.location.reload();
                } else if(response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function(error){
                alert(error);
            })
    }

    const handleUserState = (e) => {
        switch (e.target.value){
            case '정상' :
            case '이용 정지' :
                setUserState(e.target.value);
                break;
            case '탈퇴' :
                handleToggleDeleteModal();
                break;
            default :
                break;
        }
    }

    useEffect(()=>{
        if(cookies.get('level')<=0){
            setLevelAuth(true);
        }
    },[])

    const maskingPhone = (originStr) => {
        let maskingStr = '';
        let phoneStr =  originStr.length < 11 ? originStr.match(/\d{10}/gi) : originStr.match(/\d{11}/gi);
			
		if(originStr.length < 11)
		{ // 1.1) 0110000000
			return maskingStr = originStr.toString().replace(phoneStr, phoneStr.toString().replace(/(\d{3})(\d{3})(\d{4})/gi,'$1***$3'));
		}
		else
		{ // 1.2) 01000000000
			return maskingStr = originStr.toString().replace(phoneStr, phoneStr.toString().replace(/(\d{3})(\d{4})(\d{4})/gi,'$1****$3'));
		}
	}
    
    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    return (
        <table>
            <tbody>
                <tr>
                    <td>이름</td>
                    <td>{props.userInfo.us_name}</td>
                </tr>
                <tr>
                    <td>생년월일</td>
                    <td>{props.userInfo.us_birth}</td>
                </tr>
                <tr>
                    <td>아이디(이메일)</td>
                    <td>
                        <div>
                            <LoginInfo
                                type="sum"
                                email={props.userInfo.us_email}
                            />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td/>
                    <td>
                        <div>
                            <LoginInfo type="apple" email={props.userInfo.apple_email}/>
                            <LoginInfo type="google" email={props.userInfo.google_email}/>
                            <LoginInfo type="naver" email={props.userInfo.naver_email}/>
                            <LoginInfo type="kakao" email={props.userInfo.kakao_email}/>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>연락처</td>
                    <td>{levelAuth ? props.userInfo.us_phone : props.userInfo.us_phone === undefined ? '' : maskingPhone(props.userInfo.us_phone)}</td>
                </tr>
                <tr>
                    <td>보유 포인트</td>
                    <td>{props.userInfo.us_point === undefined ? props.userInfo.us_point : numberWithCommas(props.userInfo.us_point)} P</td>
                </tr>
                <tr>
                    <td>이용 내역 (건)</td>
                    <td>{props.userInfo.use_count}</td>
                </tr>
                <tr>
                    <td>가입일</td>
                    <td>{props.userInfo.date_time}</td>
                </tr>
                <tr>
                    <td>탈퇴일</td>
                    <td>{props.userInfo.withdrawal_date_time}</td>
                </tr>
                <tr>
                    <td>회원 상태</td>
                    <td>
                        {
                            changeState ?
                                <div>
                                    <SelectBox name="state" id="state" value={userState} onChange={handleUserState}>
                                        <option value="정상">정상</option>
                                        <option value="이용 정지">이용 정지</option>
                                        <option value="탈퇴">탈퇴</option>
                                    </SelectBox>
                                    <ChangeButton onClick={handleChangeUserState}>변경 완료</ChangeButton>
                                </div>
                                :
                                <div>{props.userInfo.status}<Cursor onClick={stateChange}>상태 변경</Cursor></div>
                        }
                    </td>
                </tr>
            </tbody>
            <DeleteUserModal
                toggle={toggleDeleteModal}
                toggleFunc={handleToggleDeleteModal}
                deleteFunc={deleteUser}
            />
            <AuthCheckModal
                toggle={toggleAuthModal}
                toggleFunc={handleToggleAuthModal}
            />
        </table>
    );
}

export default BreakOutUserDetailTable;