import React from 'react'
import styled from 'styled-components';

const Tr = styled.tr`
    cursor: pointer;
    position : relative;
        & > td:nth-child(3) {
            width : 7%;
            text-align : center !important;
            padding-left : 0 !important;
        }
    & > td:nth-child(5){
    
    }
    & > td:nth-child(6){
        
    }
`

function NoticeList(props) {

  const goToDetail = () => {
    window.location.href = `/admin/devicedetail/:${props.stationID}/:1/:1`
  }

  return (
    <>
        <Tr onClick={goToDetail}>
            <td>{props.no}</td>
            <td>{props.stationName}</td>
            <td>{props.port}</td>
            <td>
                {props.stationID}
            </td>
            <td>{props.errorName}</td>
            <td>{props.date}</td>
        </Tr>
    </>
  )
}

export default NoticeList