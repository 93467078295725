import React from 'react';
import CheckBox from "./CheckBox";
import styled from "styled-components";

const Tr = styled.tr`
  & > td:first-child{
    width: 5%;
  }
  & > td:nth-child(2){
    width: 4%;
  }
  & > td:nth-child(3){
    width: 13%;
  }
`

const TdRight = styled.td`
    text-align : right;
`
function StationUsageModalList(props) {
  const numberWithCommas = (x) => {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };
  
    return (
        <Tr>
            <td>{props.rowNum}</td>
            <td>{props.port}</td>
            <td>{props.number}</td>
            <td>{props.company}</td>
            <td>{props.startTime}</td>
            <td>{props.endTime}</td>
            <td>{props.charging}</td>
            <td>{props.holding}</td>
            <TdRight>{props.coupon_amount === null ? props.coupon_amount : numberWithCommas(props.coupon_amount)}</TdRight>
            <TdRight>{props.amount === null ? props.amount : numberWithCommas(props.amount)}</TdRight>
        </Tr>
    );
}

export default StationUsageModalList;