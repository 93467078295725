import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import axios from 'axios';
import Cookie from 'universal-cookie';

import { NoticeWrapper, Title, Description, BorderDiv } from '../css/styles';

import ManageCalcList from '../components/ManageCalcList';
import Pagination from '../components/Pagination';
import SearchIndexBox from '../components/SearchIndexBox';
import DeleteSearch from '../asset/img/deletesearch.png';
import styled from 'styled-components';
import SelectArrow from '../asset/img/selectarrow.png';

import Dotbogi from '../asset/img/dotbogi.png';
const cookies = new Cookie();

const ColumnBox = styled.div`
    display: flex;
    flex-direction: column;
`;

const SearchBox = styled.div`
    padding: 2% 0;
    display: flex;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    column-gap: 15px;
    & > div {
        display: flex;
        align-items: center;
        & > img {
            margin-top: 10px;
            margin-left: 7px;
            width: 20px;
        }
        & > p {
            font-size: 16px;
            margin-top: 9px;
            line-height: 23px;
            color: #c4c4c4;
        }
    }
`;

const SelectBox = styled.select`
    appearance: none;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    padding: 7px 15px;
    font-size: 16px;
    line-height: 23px;
    background-image: url(${SelectArrow});
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: 87%;
    &:focus {
        outline: none;
    }
`;

const SearchInput = styled.input`
    width: 50%;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    background-image: url(${Dotbogi});
    background-repeat: no-repeat;
    background-size: 17px;
    background-position: 97%;
    padding: 7px 10px;
    font-size: 16px;
    line-height: 23px;
    color: #7e7e7e;
`;

const RowBox = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between !important;
    column-gap: 21px;
`;

const Center = styled.div`
    display: flex;
    justify-content: center !important;
`;

const UpperTable = styled.div`
    display: block !important;
    border-top: 2px solid #e5e5e5;
    padding-top: 20px;
    & > h1 {
        font-weight: 700;
        font-size: 28px;
        line-height: 46px;
    }
    & > table {
        margin-top: 20px;
        width: 100%;
        text-align: center;
        & > tbody {
            & > tr:first-child {
                border-bottom: 2px solid #e5e5e5;
            }
            & > tr:last-child {
                border-bottom: 2px solid #e5e5e5;
            }
            & > tr {
                border-bottom: 1px solid #e5e5e5;
                line-height: 40px;
            }
        }
    }
`;

function ManageCalc() {
    const { no } = useParams();
    const [manageCalcInfo, setManageCalcInfo] = React.useState([]);
    const [manageCalcList, setManageCalcList] = React.useState([]);
    const [companySummary, setCompanySummary] = React.useState([]);

    const [searchData, setSearchData] = React.useState(null);
    const [searchType, setSearchType] = React.useState('station_name');
    const [searchCompany, setSearchCompany] = React.useState('');
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndeDate] = React.useState(null);

    useEffect(() => {
        console.log(cookies.get('token'));

        axios({
            method: 'get',
            url: `${window.Config.G_API_URL}/admin/calculate/`,
            params: {
                page_num: no.replace(':', ''),
                token: cookies.get('token'),
                search_type: searchType,
                search_data: searchData,
                search_company: searchCompany,
                start_time: startDate,
                end_time: endDate,
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    setCompanySummary(response.data.data.summary);
                    setManageCalcInfo(response.data.data.result);
                    setManageCalcList(response.data.data.result.list);
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    }, [no, searchType, searchData, searchCompany, startDate, endDate]);

    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    const onTypeChange = (e) => {
        setSearchType(e.target.value);
    };

    const onSearchDataChange = (e) => {
        setSearchData(e.target.value);
    };

    const onSearchCompanyChange = (e) => {
        setSearchCompany(e.target.value);
    };

    const onStartDateChange = (e) => {
        setStartDate(e.target.value);
    };

    const onEndDateChange = (e) => {
        setEndeDate(e.target.value);
    };

    return (
        <NoticeWrapper>
            <Title>정산관리</Title>
            <Description>정산관리</Description>
            <SearchBox>
                <SelectBox
                    name="searchselect"
                    id="serachselect"
                    onChange={onTypeChange}
                >
                    <option value="station_name">스테이션 이름</option>
                    <option value="port">포트</option>
                </SelectBox>
                <ColumnBox>
                    <RowBox>
                        <SearchInput
                            type="text"
                            placeholder={'검색어 입력'}
                            value={searchData}
                            onChange={onSearchDataChange}
                        />
                        <SearchInput
                            type="text"
                            placeholder={'업체명 입력'}
                            value={searchCompany}
                            onChange={onSearchCompanyChange}
                        />
                    </RowBox>
                    <SearchIndexBox
                        start={startDate}
                        startChange={onStartDateChange}
                        end={endDate}
                        endChange={onEndDateChange}
                    />
                </ColumnBox>
                <div>
                    <img src={DeleteSearch} alt="" />
                    <p>초기화</p>
                </div>
            </SearchBox>
            <UpperTable>
                <h1>업체 리스트</h1>
                <table>
                    <tbody>
                        <tr>
                            <th>No.</th>
                            <th>
                                <BorderDiv>업체명</BorderDiv>
                            </th>
                            <th>충전 시간 (분)</th>
                            <th>
                                <BorderDiv>거치 시간 (분)</BorderDiv>
                            </th>
                            <th>요금 (원)</th>
                        </tr>
                        {companySummary.map((item) => (
                            <tr>
                                <td>10</td>
                                <td>{item.company}</td>
                                <td>{item.charging}</td>
                                <td>{item.holding}</td>
                                <td>{numberWithCommas(item.amount)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </UpperTable>
            <table>
                <thead>
                    <tr>
                        <th>No.</th>
                        <th>
                            <BorderDiv style={{ margin: '10px 0px' }}>
                                장비 제목
                            </BorderDiv>
                        </th>
                        <th>
                            <div>포트 번호</div>
                        </th>
                        <th>
                            <BorderDiv>사용 시작 일시</BorderDiv>
                        </th>
                        <th>
                            <div>사용 종료 일시</div>
                        </th>
                        <th>
                            <BorderDiv>충전 시간 (분)</BorderDiv>
                        </th>
                        <th>
                            <div>거치 시간 (분)</div>
                        </th>
                        <th>
                            <BorderDiv>요금 (원)</BorderDiv>
                        </th>
                        <th>업체명</th>
                    </tr>
                </thead>
                <tbody>
                    {manageCalcList.map((item, key) => (
                        <ManageCalcList
                            stationName={item.station_name}
                            port={item.port}
                            charge={item.charging}
                            holding={item.holding}
                            amount={item.amount}
                            startTime={item.start_time}
                            endTime={item.end_time}
                            company={item.company}
                        />
                    ))}
                </tbody>
            </table>
            <Center>
                <Pagination
                    no={no.replace(':', '')}
                    url="/admin/managecalc/:"
                    pages={manageCalcInfo['pages']}
                    isFirstPage={manageCalcInfo['isFirstPage']}
                    isLastPage={manageCalcInfo['isLastPage']}
                    prePage={manageCalcInfo['prePage']}
                    nextPage={manageCalcInfo['nextPage']}
                    hasPrePage={manageCalcInfo['hasPreviousPage']}
                    hasNextPage={manageCalcInfo['hasNextPage']}
                />
            </Center>
        </NoticeWrapper>
    );
}

export default ManageCalc;
