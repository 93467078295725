import React from 'react'

import Pin from '../asset/img/pin.png';
import CheckBox from "./CheckBox";
import styled from 'styled-components';

const Td = styled.td`
    text-align : center !important;
    padding-left : 0 !important;
`;

const TdRight = styled.td`
    text-align : right;
`

function SearchGroupList(props) {

    const goToDetail = () => {
        window.location.href = `/admin/searchgroup/detail/:${props.no}/:1`
    }
    
    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    return (
        <tr>
            <td><CheckBox no={props.no} id={props.no} onChange={props.onChange} checked={props.checked}/></td>
            <td>{props.no}</td>
            <Td>{props.group_name}</Td>
            <td>{props.charging === undefined ? props.charging : numberWithCommas(props.charging)}</td>
            <TdRight>{props.charging_price === undefined ? props.charging_price : numberWithCommas(props.charging_price)}</TdRight>
            <td>{props.holding === undefined ? props.holding : numberWithCommas(props.holding)}</td>
            <TdRight>{props.holding_price === undefined ? props.holding_price : numberWithCommas(props.holding_price)}</TdRight>
            <TdRight>{props.coupon_amount === undefined ? props.coupon_amount : numberWithCommas(props.coupon_amount)}</TdRight>
            <TdRight>{props.amount === undefined ? props.amount : numberWithCommas(props.amount)}</TdRight>
        </tr>
    )
}

export default SearchGroupList