import React from 'react';
import Cookie from 'universal-cookie';

import { BcArrow, Description, NoticeWrapper, Title } from '../css/styles';

import styled from 'styled-components';
import ActiveArrow from '../asset/img/activearrow.png';
import breadcrumbArrow from '../asset/img/breadcrumbarrow.png';
import DeleteSearch from '../asset/img/deletesearch.png';
import SelectArrow from '../asset/img/selectarrow.png';
import SearchIndexBox from '../components/SearchIndexBox';
import SuperAdminTable from '../components/SuperAdminTable';

import Dotbogi from '../asset/img/dotbogi.png';
import ChiefAdminTable from '../components/ChiefAdminTable';
import CsAdminTable from '../components/CsAdminTable';
import CsCenterAdminTable from '../components/CsCenterAdminTable';
import PartnerAdminTable from '../components/PartnerAdminTable';

const cookies = new Cookie();

const ColumnBox = styled.div`
    display: flex;
    flex-direction: column;
`;

const SearchBox = styled.div`
    padding: 2% 0;
    display: flex;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    column-gap: 15px;
    border-bottom: 2px solid #e5e5e5;
    position: relative;
    & > div {
        display: flex;
        align-items: center;
        & > img {
            margin-top: 10px;
            margin-left: 7px;
            width: 20px;
        }
        & > p {
            font-size: 16px;
            margin-top: 9px;
            line-height: 23px;
            color: #c4c4c4;
        }
    }
`;

const SelectBox = styled.select`
    cursor: pointer;
    appearance: none;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    padding: 7px 15px;
    font-size: 16px;
    line-height: 23px;
    background-image: url(${SelectArrow});
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: 87%;
    &:focus {
        outline: none;
    }
`;

const SearchInput = styled.input`
    width: 97%;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    background-image: url(${Dotbogi});
    background-repeat: no-repeat;
    background-size: 17px;
    background-position: 97%;
    padding: 7px 10px;
    font-size: 16px;
    line-height: 23px;
    color: #7e7e7e;
`;

const ButtonContainer = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    padding-bottom: 2%;
    & > button {
        font-size: 16px;
        line-height: 23px;
        color: #df1f26;
        background-color: white;
        border: 1px solid #c4c4c4;
        box-sizing: border-box;
        border-radius: 10px;
        padding: 5px;
        cursor: pointer;
    }
    & > button:first-child {
        margin-right: 10px;
    }
`;

const DrawerMenu = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1% 0;
    border-bottom: 2px solid #e5e5e5;
    & > div {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between !important;
        & > button {
            font-size: 16px;
            line-height: 23px;
            background-color: white;
            border: 1px solid #c4c4c4;
            box-sizing: border-box;
            border-radius: 10px;
            padding: 5px 10px;
            cursor: pointer;
        }
        & > div {
            display: flex;
            cursor: pointer;
            justify-content: flex-start;
            align-items: center;
            width: 80%;
            & > h1 {
                font-size: 28px;
                line-height: 46px;
            }
            & > img {
                width: 7px;
                margin-left: 30px;
                transform: ${(props) => (props.toggle ? 'rotate(90deg)' : '')};
                transition: transform 0.5s;
            }
        }
    }
`;

const FlexBox = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    column-gap: 10px;
    & > div {
        display: flex;
        align-items: center;
        width: 100px;
        & > img {
            cursor: pointer;
        }
    }
`;

function ManageAdminUser() {
    const [deleteModalToggle, setDeleteModalToggle] = React.useState(false);
    const [deleteConfirmModalToggle, setDeleteConfirmModalToggle] =
        React.useState(false);

    const [toggleSuperAdmin, setToggleSuperAdmin] = React.useState(false);
    const [toggleChiefAdmin, setToggleChiefAdmin] = React.useState(false);
    const [toggleCsAdmin, setToggleCsAdmin] = React.useState(false);
    const [toggleCsCenterAdmin, setToggleCsCenterAdmin] = React.useState(false);
    const [togglePartnerAdmin, setTogglePartnerAdmin] = React.useState(false);

    const [checked, setChecked] = React.useState(false);
    const [checkAll, setCheckAll] = React.useState(false);
    const [checkList, setCheckList] = React.useState([]);

    const [searchData, setSearchData] = React.useState('');
    const [searchType, setSearchType] = React.useState(null);
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndeDate] = React.useState(null);

    const handleDeleteModal = () => {
        setDeleteModalToggle(!deleteModalToggle);
    };

    const handleConfirmDeleteModal = () => {
        handleDeleteModal();
        setDeleteConfirmModalToggle(!deleteConfirmModalToggle);
    };

    const handleCheckEach = (e) => {
        const { id, checked } = e.target;
        if (checkList.includes(id)) {
            for (let i = 0; i < checkList.length; i++) {
                if (checkList[i] == id) {
                    checkList.splice(i, 1);
                    setChecked(!checked);
                    i--;
                }
            }
        } else {
            setChecked(!checked);
            setCheckList([...checkList, id]);
        }
        console.log(checkList.includes(id));
        console.log(checkList);
    };

    const handleToggleSuperAdmin = () => {
        setToggleSuperAdmin(!toggleSuperAdmin);
    };
    const handleToggleChiefAdmin = () => {
        setToggleChiefAdmin(!toggleChiefAdmin);
    };

    const handleToggleCsAdmin = () => {
        setToggleCsAdmin(!toggleCsAdmin);
    };
    const handleToggleCsCenterAdmin = () => {
        setToggleCsCenterAdmin(!toggleCsCenterAdmin);
    };
    const handleTogglePartnerAdmin = () => {
        setTogglePartnerAdmin(!togglePartnerAdmin);
    };

    const onTypeChange = (e) => {
        setSearchType(e.target.value);
    };

    const onSearchDataChange = (e) => {
        setSearchData(e.target.value);
    };

    const onStartDateChange = (e) => {
        setStartDate(e.target.value);
    };

    const onEndDateChange = (e) => {
        setEndeDate(e.target.value);
    };
    
    const handleDate = (e) => {
        var date = new Date();
        var currentDate = date.toISOString().substring(0,10);
        setEndeDate(currentDate);
        switch(e.target.id){
            case 'all':
                setStartDate(null);
                break;
            case 'week':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 7));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate(currentDate);
                break;
            case 'halfMonth':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 14));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate(currentDate);
                break;
            case 'month':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 30));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate(currentDate);
                break;
            case 'threeMonth':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 91));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate(currentDate);
                break;
            case 'halfYear':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 182));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate(currentDate);
                break;
            case 'year':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 365));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate(currentDate);
                break;
        }
    }
    const goToUpdateAuth = (level) => {
        console.log('1111');
        //window.location.href = `/admin/manageadminuser/updateauth/:${level}`
    };

    const onReset = () => {
        setSearchData('');
    }

    return (
        <NoticeWrapper>
            <Title>관리자 회원 관리</Title>
            <Description>
                관리자 설정 <BcArrow src={breadcrumbArrow} /> 관리자 회원 관리
            </Description>
            <SearchBox>
                <ColumnBox>
                    <FlexBox>
                        <SelectBox
                            name="searchselect"
                            id="serachselect"
                            onChange={onTypeChange}
                        >
                            <option value={null}>전체</option>
                            <option value="이름">이름</option>
                            <option value="관리자 아이디">관리자 아이디</option>
                            <option value="연락처">연락처</option>
                        </SelectBox>
                        <SearchInput
                        type="text"
                        placeholder={'검색어 입력'}
                        onChange={onSearchDataChange}
                        value={searchData}  
                    />
                        <div onClick={onReset}>
                            <img src={DeleteSearch} alt="" />
                            <p>초기화</p>
                        </div>
                    </FlexBox>
                    <SearchIndexBox
                        startChange={onStartDateChange}
                        endChange={onEndDateChange}
                        handleDate={handleDate}
                        startDate={startDate}
                        endDate={endDate}
                    />
                </ColumnBox>
                <ButtonContainer>
                    <button
                        onClick={() =>
                            (window.location.href =
                                '/admin/manageadminuser/partnerregister')
                        }
                    >
                        업체 관리자 등록
                    </button>
                    <button
                        onClick={() =>
                            (window.location.href =
                                '/admin/manageadminuser/register')
                        }
                    >
                        관리자 등록
                    </button>
                </ButtonContainer>
            </SearchBox>

            <DrawerMenu toggle={toggleSuperAdmin}>
                <div>
                    <div onClick={handleToggleSuperAdmin}>
                        <h1>슈퍼관리자</h1>
                        <img src={ActiveArrow} alt="" />
                    </div>
                    <button
                        onClick={() =>
                            (window.location.href = `/admin/manageadminuser/updateauth/:0`)
                        }
                    >
                        권한 편집
                    </button>
                </div>
                <SuperAdminTable toggle={toggleSuperAdmin} startDate={startDate} endDate={endDate} searchData={searchData} searchType={searchType}/>
            </DrawerMenu>

            <DrawerMenu toggle={toggleChiefAdmin}>
                <div>
                    <div onClick={handleToggleChiefAdmin}>
                        <h1>최고관리자</h1>
                        <img src={ActiveArrow} alt="" />
                    </div>
                    <button
                        onClick={() =>
                            (window.location.href = `/admin/manageadminuser/updateauth/:1`)
                        }
                    >
                        권한 편집
                    </button>
                </div>
                <ChiefAdminTable toggle={toggleChiefAdmin} startDate={startDate} endDate={endDate} searchData={searchData} searchType={searchType} />
            </DrawerMenu>

            <DrawerMenu toggle={toggleCsAdmin}>
                <div>
                    <div onClick={handleToggleCsAdmin}>
                        <h1>고객센터 관리자</h1>
                        <img src={ActiveArrow} alt="" />
                    </div>
                    <button
                        onClick={() =>
                            (window.location.href = `/admin/manageadminuser/updateauth/:2`)
                        }
                    >
                        권한 편집
                    </button>
                </div>
                <CsAdminTable toggle={toggleCsAdmin} startDate={startDate} endDate={endDate} searchData={searchData} searchType={searchType} />
            </DrawerMenu>

            <DrawerMenu toggle={toggleCsCenterAdmin}>
                <div>
                    <div onClick={handleToggleCsCenterAdmin}>
                        <h1>고객센터</h1>
                        <img src={ActiveArrow} alt="" />
                    </div>
                    <button
                        onClick={() =>
                            (window.location.href = `/admin/manageadminuser/updateauth/:3`)
                        }
                    >
                        권한 편집
                    </button>
                </div>
                <CsCenterAdminTable toggle={toggleCsCenterAdmin} startDate={startDate} endDate={endDate} searchData={searchData} searchType={searchType} />
            </DrawerMenu>

            <DrawerMenu toggle={togglePartnerAdmin}>
                <div>
                    <div onClick={handleTogglePartnerAdmin}>
                        <h1>업체 관리자</h1>
                        <img src={ActiveArrow} alt="" />
                    </div>
                    <button
                        onClick={() =>
                            (window.location.href = `/admin/manageadminuser/updateauth/:4`)
                        }
                    >
                        권한 편집
                    </button>
                </div>
                <PartnerAdminTable toggle={togglePartnerAdmin} startDate={startDate} endDate={endDate} searchData={searchData} searchType={searchType} />
            </DrawerMenu>
        </NoticeWrapper>
    );
}

export default ManageAdminUser;
