import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import SelectArrow from "../asset/img/selectarrow.png";
import axios from "axios";
import Cookie from "universal-cookie";

const Container = styled.div`
    display : flex;
    justify-content : center;
  align-items: center;
`

const ButtonContainer = styled.div`
    display : flex;
    justify-content : center;
  column-gap: 10px;
  margin-top: 20px;
`

const RedButton = styled.a`
  border-radius: 10px;
  border: 1px solid #C4C4C4;
  color : #DF1F26;
  padding : 10px 0 ;
  margin-top : 17px;
  font-weight: bold;
  font-size: 14px;
  width : 25%;
  text-align : center;
`

function PointDeduceModal(props) {

    return (
        <Modal isOpen={props.toggle} onRequestClose={props.toggleFunc}
               style={{
                   overlay :{
                       backgroundColor : 'rgba(34, 30, 31, 0.5)'
                   },
                   content : {
                       height : '13%',
                       left : '40%',
                       right : '37%',
                       top : '40%',
                       borderRadius : '10px',
                       border: '1px solid #C4C4C4' ,
                   }
               }}
        >
            <Container>
                <div>
                    작업을 수행할 {props.content} 선택해주세요.
                </div>
            </Container>
            <ButtonContainer>
                <RedButton onClick={props.toggleFunc}>확인</RedButton>
            </ButtonContainer>
        </Modal>
    );
}

export default PointDeduceModal;
