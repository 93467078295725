import React from 'react'
import styled from 'styled-components';
import axios from 'axios';
import Cookie from 'universal-cookie';

import MainLogo from '../asset/img/adminmainlogo.png';
import QuestionMark from '../asset/img/questionmark.png';

const cookies = new Cookie();

const Wrapper = styled.div`
    display : flex;
    justify-content : center;
    height : 100%;
    & > div {
        display : flex;
        max-width : 25%;
        height : 100%;
        flex-direction : column;
        justify-content : center;
        align-items : center;
        & > img {
            width : 50%;
            padding-top : 40%;
            padding-bottom : 55px;
        }
        & > button {
            width : 100%;
            margin: 35px 0 30px 0;
            background-color: #221E1F;
            color : white;
            padding : 17px 0 ;
            text-align : center;
            border : none;
            font-weight: bold;
            font-size: 16px;
            border-radius : 10px;
            &:hover {
                transform : scale(1.01);
                transition : transform 0.2s
            }
        }
        & > div {
            display : flex;
            justify-content : center;
            align-items : center;
            column-gap: 18px;
            & > img {
                width : 18px;
            }
            & > p {
                font-size: 14px;
                line-height: 160%;
                letter-spacing: -0.035em;
                color: #999999;
            }
        }
        & > p {
            font-family : notosansmedium;
            font-size : 14px;
            margin-top : 30px;
        }
    }
`

const InputBox = styled.input`
    ::-webkit-inner-spin-button{
        -webkit-appearance: none !important; 
        -moz-appearance:textfield !important;
        margin: 0 !important; 
    }
    ::-webkit-outer-spin-button{
        -webkit-appearance: none !important; 
        -moz-appearance:textfield !important;
        margin: 0 !important; 
    }    
    width : 8%;
    margin-top: 10px;
    padding : 17px 10px;
    border : 1px solid ${props => props.active ? '#EEBCBC' : '#E5E5E5'} ;
    border-radius : 10px;
    box-sizing : border-box;
`

function Login() {

    const [email , setEmail] = React.useState('');
    const [pwd , setPwd] = React.useState('');

    const [auth1 , setAuth1] = React.useState('');
    const [auth2 , setAuth2] = React.useState('');
    const [auth3 , setAuth3] = React.useState('');
    const [auth4 , setAuth4] = React.useState('');
    const [auth5 , setAuth5] = React.useState('');
    const [auth6 , setAuth6] = React.useState('');

    const handleEmail = (e) => {
        const text = e.target.value;
        setEmail(text);
    }

    const handlePwd = (e) => {
        const text = e.target.value;
        setPwd(text);
    }

    const authNum1 = React.useRef(null);
    const authNum2 = React.useRef(null);
    const authNum3 = React.useRef(null);
    const authNum4 = React.useRef(null);
    const authNum5 = React.useRef(null);
    const authNum6 = React.useRef(null);

    const handleLogin = () => {
        var params = new URLSearchParams();
        console.log(auth1 + auth2 + auth3 + auth4 + auth5 + auth6)
        params.append('admin_id', cookies.get('id'))
        params.append('admin_password', cookies.get('password'))
        params.append('auth_code' , auth1 + auth2 + auth3 + auth4 + auth5 + auth6 )
        axios.post(`${window.Config.G_API_URL}/admin/auth/login`,params)
        .then(function(response){
            const accessToken = response.data.data.token;
            console.log(accessToken);
            cookies.set('level' , response.data.data.admin_level);
            cookies.set('token' , accessToken);
            if(response.data.result['code'] === '200'){
                window.location.href = '/admin/notice/:1'
            } else if(response.data.result['code'] === '400') {
                alert('로그인 실패!')
            }
        })
        .catch(function(error){
            alert(error);
        })
    }

    const onKeyPress = (e) => {
        if(e.key === 'Enter'){
            handleLogin();
        }
    }

    const handleFirst = (e) => {
        if(e.target.value.length === 2) return false;
        setAuth1(e.target.value)
        if(e.target.value.length === 1){
            authNum2.current.focus();
        }
    }

    const handleSecond = (e) => {
        if(e.target.value.length === 2) return false;
        setAuth2(e.target.value)
        if(e.target.value.length === 1){
            authNum3.current.focus();
        }
    }

    const handleThird = (e) => {
        if(e.target.value.length === 2) return false;
        setAuth3(e.target.value)
        if(e.target.value.length === 1){
            authNum4.current.focus();
        }
    }

    const handleFourth = (e) => {
        if(e.target.value.length === 2) return false;
        setAuth4(e.target.value)
        if(e.target.value.length === 1){
            authNum5.current.focus();
        }
    }

    const handleFifth = (e) => {
        if(e.target.value.length === 2) return false;
        setAuth5(e.target.value)
        if(e.target.value.length === 1){
            authNum6.current.focus();
        }
    }

    const handleSixth = (e) => {
        if(e.target.value.length === 2) return false;
        setAuth6(e.target.value)
        if(e.target.value.length === 1){
            return
        }
    }

    return (
        <Wrapper>
            <div>
                <img src={MainLogo} alt='mainlogo'/>
                <div>
                    <InputBox type='number'pattern="[0-9]*" value={auth1} ref={authNum1} onChange={handleFirst}/>
                    <InputBox type='number'pattern="[0-9]*" value={auth2} ref={authNum2}  onChange={handleSecond}/>
                    <InputBox type='number'pattern="[0-9]*" value={auth3} ref={authNum3}  onChange={handleThird}/>
                    <InputBox type='number'pattern="[0-9]*" value={auth4} ref={authNum4}  onChange={handleFourth}/>
                    <InputBox type='number'pattern="[0-9]*" value={auth5} ref={authNum5}  onChange={handleFifth}/>
                    <InputBox type='number'pattern="[0-9]*" value={auth6} ref={authNum6}  onChange={handleSixth} onKeyPress={onKeyPress}/>
                </div>
                <p>2차 인증 OTP를 입력해주세요.</p>
                <button onClick={handleLogin}>인증</button>
            </div>
        </Wrapper>
    )
}

export default Login