import axios from 'axios';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Cookie from 'universal-cookie';
import AuthCheckModal from '../components/AuthCheckModal';

import DeleteModal from '../components/DeleteModal';
import {
    BcArrow,
    Description,
    NoticeWriteButtonWrapper,
    NoticeWriteWrapper,
    Title,
    WriteBoardForm
} from '../css/styles';

import Bar from '../asset/img/bar.png';
import breadcrumbArrow from '../asset/img/breadcrumbarrow.png';
import Pin from '../asset/img/pin.png';
import SelectArrow from '../asset/img/selectarrow.png';

const cookies = new Cookie();

const SelectBox = styled.select`
    cursor: pointer;
    appearance: none;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    padding: 7px 13px;
    width: 9%;
    font-size: 16px;
    line-height: 23px;
    background-image: url(${SelectArrow});
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: 85%;
    &:focus {
        outline: none;
    }
`;

const TitleBox = styled.div`
    display : flex;
    align-items : center;
    column-gap: 35px; 
    padding-left : 35px;
    padding : 10px 35px;
    & > img {
        height : 30px;
    }
    & > p:first-child{
        font-size : 16px;
        font-family : notosansbold;
    }
    & > p:last-child{
        font-size : 20px;
        font-family : notosansbold;
        color: #221E1F;
    }
`

const Content = styled.p`
    padding : 20px;
`

const ButtonWrapper = styled.div`
    & > button:first-child{
        background-color : white;
        color : #df1f26;
        border : 1px solid #df1f26;
    }
    & > button:last-child{
        background-color : white;
        color : #000000;
        border : 1px solid #C4C4C4;
    }
`

function FaqDetail() {
    const { no } = useParams();

    const [faqDetailInfo , setFaqDetailInfo] = React.useState([]);
    const [deleteModalToggle, setDeleteModalToggle] = React.useState(false);
    const [toggleAuthModal , setToggleAuthModal] = React.useState(false);

    const handleToggleAuthModal = () => {
        setToggleAuthModal(!toggleAuthModal);
    }

    let navigate = useNavigate();

    const goToUpdate = () => {
        window.location.href = `/admin/faq/update/${no}`
    }

    useEffect(() => {
        console.log(cookies.get('token'));

        axios({
            method: 'get',
            url: `${window.Config.G_API_URL}/admin/faq/detail`,
            params: {
                no: no.replace(':', ''),
                token: cookies.get('token'),
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    console.log(response.data.data);
                    setFaqDetailInfo(response.data.data);
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    }, [no]);

    const onDelete = () => {

        axios({
            method: 'delete',
            url: `${window.Config.G_API_URL}/admin/faq`,
            params: {
                token: cookies.get('token'),
                no : no.replace(':' , ''),
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    console.log(response);
                    window.location.href = '/admin/faq/:1';
                } else if(response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    };

    const handleDeleteModal = () => {
        setDeleteModalToggle(!deleteModalToggle);
    };

    return (
        <NoticeWriteWrapper>
            <Title>Faq 상세</Title>
            <Description>
                게시판 관리 <BcArrow src={breadcrumbArrow} /> FAQ{' '}
                <BcArrow src={breadcrumbArrow} /> FAQ 상세
            </Description>
            <WriteBoardForm>
                <TitleBox>
                    <p>{faqDetailInfo.faq_category}</p> 
                    <img src={Bar} alt="" />
                    <p>{faqDetailInfo.title && faqDetailInfo.title.replace(/\%5B/g , '[').replace(/\%5D/g, ']')}</p>
                    {
                        faqDetailInfo.popular === 'Y' ? <img src={Pin} alt='' style={{width : '16px' , height : 'auto'}}/> : ''
                    }
                </TitleBox>
                <Content>
                    {faqDetailInfo.content && faqDetailInfo.content.split("\n").map((line, index) => { //this.props.data.content: 내용
                        return (
                        <span key={index}>
                            {line.replace(/\%5B/g , '[').replace(/\%5D/g, ']')}
                            <br />
                        </span>
                        );
                    })}
                </Content>
            </WriteBoardForm>
            <NoticeWriteButtonWrapper>
                <button onClick={() => navigate(-1)}>목록</button>
                <ButtonWrapper>
                    <button onClick={goToUpdate}>수정</button>
                    <button onClick={handleDeleteModal}>삭제</button>
                </ButtonWrapper>
            </NoticeWriteButtonWrapper>

            <DeleteModal
                content={'FAQ를'}
                toggle={deleteModalToggle}
                toggleFunc={handleDeleteModal}
                deleteFunc={onDelete}
            />
            <AuthCheckModal
                toggle={toggleAuthModal}
                toggleFunc={handleToggleAuthModal}
            />
        </NoticeWriteWrapper>
    );
}

export default FaqDetail;
