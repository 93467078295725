import React from 'react'
import styled from "styled-components";
import CheckBox from "./CheckBox";
import Pin from "../asset/img/pin.png";

const Tr = styled.tr`
  & > td:nth-child(3){
    padding-left: 20px;
  }
`

function AdvertisementList(props) {

    const goToDetail = () => {
        window.location.href = `/admin/advertisement/detail/:${props.no}`
    }

    return (
        <Tr>
            <td><CheckBox no={props.no} onChange={props.onChange} checked={props.checked}/></td>
            <td onClick={goToDetail}>{props.adId}</td>
            <td onClick={goToDetail}>{props.adName}</td>
            <td onClick={goToDetail}>{props.adType === 'popup' ? '전면' : '배너'}</td>
            <td onClick={goToDetail}>{props.adStart}</td>
            <td onClick={goToDetail}>{props.adEnd}</td>
        </Tr>
    )
}

export default AdvertisementList