import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 13px;
  width: ${props => props.width}%;
  & > div:last-child{
    display: flex;
    align-items: center;
    column-gap: 10px;
    &  > input {
      display: none;
      &:checked + label {
        color : black;
        background-color: #E5E5E5;
      }
    }
    &  > label {
      cursor: pointer;
      display: inline-block;
      width: 60px;
      padding : 6px 0;
      font-size: 16px;
      line-height: 23px;
      color: #7E7E7E;
      border: 1px solid #C4C4C4;
      border-radius: 10px;
      text-align: center;
    }
  }

`

const DateInput = styled.input`
  display: inline-block !important;
  width: 38%;
  padding : 6px 10px;
  font-size: 14px;
  line-height: 23px;
  color: #7E7E7E;
  border: 1px solid #C4C4C4;
  border-radius: 10px;
  content : 'wd';
  &::-webkit-calendar-picker-indicator{
    cursor: pointer;
  }
`

function SearchIndexBox(props) {

    const [start, setStart] = React.useState(new Date());

    return (
        <Wrapper width={props.width}>
            <div>
                <DateInput type="date" onChange={props.startChange} value={props.startDate||''}/> ~ <DateInput type="date" onChange={props.endChange} value={props.endDate||''}/>
            </div>
            <div>
                <input id='all' name='dateRange'  type="radio" onChange={props.handleDate}/><label htmlFor='all'>전체</label>
                <input id='week' name='dateRange' type="radio" onChange={props.handleDate}/><label htmlFor='week'>7일</label>
                <input id='halfMonth' name='dateRange' type="radio" onChange={props.handleDate}/><label htmlFor='halfMonth'>15일</label>
                <input id='month' name='dateRange' type="radio" onChange={props.handleDate}/><label htmlFor='month'>1개월</label>
                <input id='threeMonth' name='dateRange' type="radio" onChange={props.handleDate}/><label htmlFor='threeMonth'>3개월</label>
                <input id='halfYear' name='dateRange' type="radio" onChange={props.handleDate}/><label htmlFor='halfYear'>6개월</label>
                <input id='year' name='dateRange' type="radio" onChange={props.handleDate}/><label htmlFor='year'>1년</label>
            </div>
        </Wrapper>
    );
}

SearchIndexBox.defaultProps = {
    width : '100'
}

export default SearchIndexBox;