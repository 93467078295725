import React, {useEffect} from 'react'
import CheckBox from "./CheckBox";
import styled from "styled-components";
import Cookie from "universal-cookie";
const cookies = new Cookie();

const Td = styled.td`
  cursor: pointer;
`

const Tr = styled.tr`
  & > td > div > label {
    cursor: pointer;
  }
  &:hover{
    background-color: #f6f6f6;
  }
`



function SuperAdminTableList(props) {
  const [levelAuth , setLevelAuth] = React.useState(false);

    const goToDetail = () => {
        window.location.href = `/admin/manageadminuser/detail/:${props.adminId}/:1`
    }

    useEffect(()=>{
      if(cookies.get('level')<=0){
          setLevelAuth(true);
      }
    },[])
    
    const maskingPhone = (originStr) => {
      let maskingStr = '';
      let phoneStr =  originStr.length < 11 ? originStr.match(/\d{10}/gi) : originStr.match(/\d{11}/gi);
          
      if(originStr.length < 11)
      { // 1.1) 0110000000
          return maskingStr = originStr.toString().replace(phoneStr, phoneStr.toString().replace(/(\d{3})(\d{3})(\d{4})/gi,'$1***$3'));
      }
      else
      { // 1.2) 01000000000
          return maskingStr = originStr.toString().replace(phoneStr, phoneStr.toString().replace(/(\d{3})(\d{4})(\d{4})/gi,'$1****$3'));
      }
    }

    return (
        <Tr>
            <td><CheckBox no={props.adminId} onChange={props.onChange} checked={props.checked}/></td>
            <td>{props.adminNo}</td>
            <Td onClick={goToDetail}>{props.adminName}</Td>
            <Td onClick={goToDetail}>{props.adminId}</Td>
            <Td onClick={goToDetail}>{levelAuth ? props.adminPhone : props.adminPhone === undefined ? '' : maskingPhone(props.adminPhone)}</Td>
            <Td onClick={goToDetail}>{props.date}</Td>
        </Tr>
    );
}

export default SuperAdminTableList;