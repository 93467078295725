import React from 'react';
import styled from 'styled-components';

const StyledTd1 = styled.td`
  color: ${props => props.status === '충전' ? '#DF1F26' : ''};
`

const StyledTd2 = styled.td`
  color: ${props => props.status === '회원 본인' ? '#C4C4C4' : '#DF1F26'};
`

function PointManageList(props) {    
  
  const numberWithCommas = (x) => {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

    return (
        <tr>
            <td>{props.rowNum}</td>
            <StyledTd1 status={props.status}>포인트 {props.status}</StyledTd1>
            <td>{props.amount === undefined ? props.amount : numberWithCommas(props.amount)} P</td>
            <td>{props.point === undefined ? props.point : numberWithCommas(props.point)} P</td>
            <td>{props.date}</td>
            <StyledTd2 status={props.user}>{props.user}</StyledTd2>
        </tr>
    );
}

export default PointManageList;