import React from 'react'

import Pin from '../asset/img/pin.png';
import CheckBox from "./CheckBox";

function NoticeList(props) {

  const goToDetail = () => {
    window.location.href = `/admin/notice/detail/:${props.no}`
  }

  

  return (
    <tr>
        <td><CheckBox no={props.no} onChange={props.onChange} checked={props.checked}/></td>
        <td>{props.no}</td>
        <td onClick={goToDetail}>
          {props.title.replace(/\%5B/g , '[').replace(/\%5D/g, ']')}     
          {
            props.exposure === 'Y' ? <img src={Pin} alt='' style={{width : '11px' , marginLeft : '6px'}}/>  : '' 
          }
        </td>
        <td>{props.date}</td>
        <td>{props.name}</td>
    </tr>
  )
}

export default NoticeList