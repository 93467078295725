import React from 'react';
import {Route ,Routes , BrowserRouter} from 'react-router-dom';

import './css/index.css';
import './css/reset.css';

import Login from './page/Login';
import Routing from './page/Routing';
import SecondLogin from './page/SecondLogin';

function App() {
  return (
    <div className="App">

      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Login/>}/>
          <Route path='/auth' element={<SecondLogin/>}/>
          <Route path='/admin/*' element={<Routing/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
