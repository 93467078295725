import React , {useEffect} from 'react';
import styled from 'styled-components';
import axios from "axios";
import Cookie from "universal-cookie";
import {useParams} from "react-router";

const cookies = new Cookie();

const Table = styled.table`
  margin-top: 13px !important;
  & > tbody{
    & > tr > td:first-child{
      width: 17% !important;
    }
  }
`

function DeviceDetailTable(props) {
    const { no, num } = useParams();
    const [portInfo, setPortInfo] = React.useState([]);

    useEffect(() => {
        axios({
            method: 'get',
            url: `${window.Config.G_API_URL}/admin/station/port`,
            params: {
                token: cookies.get('token'),
                station_id: no.replace(':', ''),
                port: num.replace(':', ''),
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    console.log(response.data.data)
                    setPortInfo(response.data.data);
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    }, []);

    return (
        <Table>
            <tbody>
            <tr>
                <td>IoT ID</td>
                <td>{portInfo.station_id}</td>
            </tr>
            <tr>
                <td>충전기 종류</td>
                <td>{portInfo.charger_status === 'KR' ? '한국' : '중국'}</td>
            </tr>
            <tr>
                <td>출력 전압 (DC)</td>
                <td>{portInfo.voltage}V</td>
            </tr>
            <tr>
                <td>출력 전류 (DC)</td>
                <td>{portInfo.current}mA</td>
            </tr>
            <tr>
                <td>DC 출력 온도 (AC)</td>
                <td>{portInfo.temperature}°C</td>
            </tr>
            <tr>
                <td>충전 시간</td>
                <td>{portInfo.charging}분</td>
            </tr>
            <tr>
                <td>대기 시간</td>
                <td>{portInfo.holding}분</td>
            </tr>
            {/* <tr>
                <td>배터리 잔량</td>
                <td>{portInfo.battery}%</td>
            </tr> */}
            </tbody>
        </Table>
    );
}

export default DeviceDetailTable;