import React from 'react'
import styled from 'styled-components';

import CheckBox from "./CheckBox";
import ManagerMemoDetailModal from "./ManagerMemoDetailModal";
import ManagerMemoRegModal from "./ManagerMemoRegModal";

const Tr = styled.tr`
  & > td:first-child{
      width: 5%;
    }
  & > td:nth-child(2){
    width: 7%;
  }
  & > td {
    vertical-align: middle;
  }
`

const Td = styled.td`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  max-width: 700px;
  cursor: pointer;
  text-align: left;
  text-overflow:ellipsis;
  overflow:hidden;
  line-height: 30px;
`

function ManagerMemoList(props) {

    const [toggleMemoModal , setToggleMemoModal] = React.useState(false);

    const handleToggleMemoModal = () => {
        setToggleMemoModal(!toggleMemoModal);
    }

    return (
        <Tr>
            <td><CheckBox no={props.no} onChange={props.onChange} checked={props.checked}/></td>
            <td>{props.rowNum}</td>
            <Td onClick={handleToggleMemoModal}>{props.content}</Td>
            <td>{props.date}</td>
            <td>{props.admin}</td>

            <ManagerMemoDetailModal
                toggle={toggleMemoModal}
                toggleFunc={handleToggleMemoModal}
                no={props.no}
                date={props.date}
                content={props.content}
            />


        </Tr>
    )
}

export default ManagerMemoList