import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import {
    ManageUserDetailWrapper,
    Title,
    Description,
    BcArrow,
} from '../css/styles';
import axios from 'axios';
import Cookie from 'universal-cookie';
import styled from 'styled-components';

import breadcrumbArrow from '../asset/img/breadcrumbarrow.png';
import VersionManageTable from '../components/VersionManageTable';
import AuthCheckModal from '../components/AuthCheckModal';

const cookies = new Cookie();

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    & > button {
        border : 1px solid #df1f26 !important;
        border-radius : 10px;
        color : #df1f26;
        cursor: pointer;
    }
`;

const TableTitle = styled.p`
    padding-top : 2%;
    font-size : 24px;
    font-family : notosansbold;
`

function VersionManage(props) {
    const [versionInfo, setVersionInfo] = React.useState([]);
    const [toggleDeleteModal, setToggleDeleteModal] = React.useState(false);

    const [toggleAuthModal , setToggleAuthModal] = React.useState(false);

    const handleToggleAuthModal = () => {
        setToggleAuthModal(!toggleAuthModal);
    }

    const navigate = useNavigate();

        useEffect(() => {
            axios({
                method: 'get',
                url: `${window.Config.G_API_URL}/admin/app-version/`,
                params: {
                    token: cookies.get('token'),
                },
            })
                .then((response) => {
                    if (response.data.result['code'] === '200') {
                        setVersionInfo(response.data.data);
                    }else if(response.data.result['code'] === '402'){
                        handleToggleAuthModal();
                    } else if (response.data.result['code'] === '401'){
                        alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                        window.location.href = '/'
                    } else {
                        alert(response.data.result['message']);
                    }
                })
                .catch(function (error) {
                    alert(error);
                });
        }, []);

    const handleToggleDeleteModal = () => {
        setToggleDeleteModal(!toggleDeleteModal);
    };

    const goToAOSUpdate = () => {
        window.location.href = '/admin/versionmanage/update/:AOS'
    }

    const goToIOSUpdate = () => {
        window.location.href = '/admin/versionmanage/update/:IOS'
    }

    return (
        <ManageUserDetailWrapper>
            <Title>앱 버전 관리</Title>
            <Description>
                관리자 설정 <BcArrow src={breadcrumbArrow} /> 앱 버전 관리
            </Description>
            <TableTitle>안드로이드 버전</TableTitle>
            <VersionManageTable 
                version={versionInfo.AOS_appVersion}
                date={versionInfo.AOS_date_time}
                type={'android'}
            />
            <ButtonWrapper>
                <button onClick={goToAOSUpdate}>수정</button>
            </ButtonWrapper>
            <TableTitle>IOS 버전</TableTitle>
            <VersionManageTable 
                version={versionInfo.IOS_appVersion}
                date={versionInfo.IOS_date_time}
                type={'ios'}
            />
            <ButtonWrapper>
                <button onClick={goToIOSUpdate}>수정</button>
            </ButtonWrapper>

            <AuthCheckModal
                toggle={toggleAuthModal}
                toggleFunc={handleToggleAuthModal}
            />
        </ManageUserDetailWrapper>
    );
}

export default VersionManage;
