import axios from 'axios';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import Cookie from 'universal-cookie';

import {
    BorderDiv,
    BorderDivR,
    Description,
    NoticeWrapper,
    Title,
} from '../css/styles';

import styled from 'styled-components';
import DeleteSearch from '../asset/img/deletesearch.png';
import Dotbogi from '../asset/img/dotbogi.png';
import SelectArrow from '../asset/img/selectarrow.png';
import AdvertisementList from '../components/AdvertisementList';
import AuthCheckModal from '../components/AuthCheckModal';
import CheckBox from '../components/CheckBox';
import DeleteConfirmModal from '../components/DeleteConfirmModal';
import DeleteModal from '../components/DeleteModal';
import Pagination from '../components/Pagination';
import SearchIndexBox from '../components/SearchIndexBox';
import SelectUserModal from '../components/SelectUserModal';

const cookies = new Cookie();

const SearchBox = styled.div`
    padding: 2% 0;
    display: flex;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    column-gap: 15px;
    & > div {
        display: flex;
        align-items: center;
        & > img {
            cursor: pointer;
            margin-top: 10px;
            margin-left: 7px;
            width: 20px;
        }
        & > p {
            font-size: 16px;
            margin-top: 9px;
            line-height: 23px;
            color: #c4c4c4;
        }
    }
`;

const ColumnBox = styled.div`
    display: flex;
    flex-direction: column;
`;

const SelectBox = styled.select`
    cursor: pointer;
    appearance: none;
    text-align: center;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    padding: 7px 15px;
    font-size: 16px;
    line-height: 23px;
    width: 7%;
    background-image: url(${SelectArrow});
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: 87%;
    &:focus {
        outline: none;
    }
`;

const SearchInput = styled.input`
    width: 97%;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    background-image: url(${Dotbogi});
    background-repeat: no-repeat;
    background-size: 17px;
    background-position: 97%;
    padding: 7px 10px;
    font-size: 16px;
    line-height: 23px;
    color: #7e7e7e;
`;

const Table = styled.table`
    margin-top: 20px !important;
    & > thead > tr {
        & > th:nth-child(2) {
            width: 7% !important;
        }
        & > th:nth-child(3) {
            width: 40%;
            padding-left: 20px;
        }
        & > th:nth-child(4) {
            width: 5% !important;
        }
        & > th:nth-child(5) {
            width: 12%;
        }
        & > th:nth-child(6) {
            width: 12%;
        }
    }
`;

const Button = styled.button`
    cursor: pointer;
`;

function Advertisement() {
    const { no } = useParams();
    const [noticeInfo, setNoticeInfo] = React.useState([]);
    const [noticeListInfo, setNoticeListInfo] = React.useState([]);
    const [deleteModalToggle, setDeleteModalToggle] = React.useState(false);
    const [deleteConfirmModalToggle, setDeleteConfirmModalToggle] =
        React.useState(false);

    const [checked, setChecked] = React.useState(false);
    const [checkAll, setCheckAll] = React.useState(false);
    const [checkList, setCheckList] = React.useState([]);

    const [searchData, setSearchData] = React.useState(null);
    const [searchType, setSearchType] = React.useState(null);
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndeDate] = React.useState(null);

    const [toggleSelectUserModal , setToggleSelectUserModal] = React.useState(false);

    const [toggleAuthModal , setToggleAuthModal] = React.useState(false);

    const handleToggleAuthModal = () => {
        setToggleAuthModal(!toggleAuthModal);
    }
    const handleToggleSelectUsetModal = () => {
        setToggleSelectUserModal(!toggleSelectUserModal);
    }

    const onTypeChange = (e) => {
        setSearchType(e.target.value);
    };

    const onSearchDataChange = (e) => {
        setSearchData(e.target.value);
    };

    const onStartDateChange = (e) => {
        setStartDate(e.target.value);
    };

    const onEndDateChange = (e) => {
        setEndeDate(e.target.value);
    };

    useEffect(() => {
        setCheckList([]);

        axios({
            method: 'get',
            url: `${window.Config.G_API_URL}/admin/advertisement`,
            params: {
                page_num: no.replace(':', ''),
                token: cookies.get('token'),
                search_type: searchType === '전체' ? null : searchType,
                search_data: searchData,
                start_date: startDate,
                end_date: endDate,
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    setNoticeInfo(response.data.data.result);
                    setNoticeListInfo(response.data.data.result.list);
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                }else if(response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    }, [no , searchType , searchData , startDate , endDate]);

    useEffect(()=>{
        if(checkAll){
            let arr = [];
            noticeListInfo.map((item)=>{
                    arr.push(`${item.ad_id}`);
            });
            setCheckList(arr);
        } else if(checkList.length==noticeListInfo.length) {
            setCheckList([]);
        }
    },[checkAll]);

    useEffect(()=>{
        if(checkList.length == noticeListInfo.length){
            setCheckAll(true);
        } else {
            setCheckAll(false);
        }
        // console.log(checkList);
    },[checkList,noticeListInfo]);

    const handleDeleteModal = () => {
        setDeleteModalToggle(!deleteModalToggle);
    };

    const handleConfirmDeleteModal = () => {
        handleDeleteModal();
        setDeleteConfirmModalToggle(!deleteConfirmModalToggle);
    };

    const onDelete = () => {
        axios({
            method: 'delete',
            url: `${window.Config.G_API_URL}/admin/advertisement/`,
            params: {
                token: cookies.get('token'),
                delete_items: checkList,
            },
            // no : checkList 파라미터 [] 없애는 옵션
            paramsSerializer: function (paramObj) {
                const params = new URLSearchParams();
                for (const key in paramObj) {
                    params.append(key, paramObj[key]);
                }

                return params.toString();
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    console.log(response);

                    handleConfirmDeleteModal();
                } else if(response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                }  else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    };

    const handleCheckAll = (e) => {
        setCheckAll(!checkAll);
    };

    const handleCheckEach = (e) => {
        const { id, checked } = e.target;
        let arr = [...checkList];
        if (arr.includes(id)) {
            for (let i = 0; i < arr.length; i++) {
                console.log(arr[i]);
                console.log(id);
                if (arr[i] == id) {
                    arr.splice(i, 1);
                    setChecked(!checked);
                    break;
                }
            }
            setCheckList(arr);
        } else {
            setChecked(!checked);
            setCheckList([...checkList, id]);
        }
    };

    const goToWrite = () => {
        window.location.href = '/admin/advertisement/register';
    };

    const toggleModal = () => {
        if(checkList.length == 0){
            handleToggleSelectUsetModal();
        } else {
            handleDeleteModal();
        }
    }

    const onReset = () => {
        setSearchData('');
    }
    
    const handleDate = (e) => {
        var date = new Date();
        var currentDate = date.toISOString().substring(0,10);
        setEndeDate(currentDate);
        switch(e.target.id){
            case 'all':
                setStartDate(null);
                break;
            case 'week':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 7));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate(currentDate);
                break;
            case 'halfMonth':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 14));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate(currentDate);
                break;
            case 'month':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 30));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate(currentDate);
                break;
            case 'threeMonth':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 91));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate(currentDate);
                break;
            case 'halfYear':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 182));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate(currentDate);
                break;
            case 'year':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 365));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate(currentDate);
                break;
        }
    }

    return (
        <NoticeWrapper>
            <Title>광고 관리</Title>
            <Description>광고 관리</Description>
            <SearchBox>
                <SelectBox
                    name="searchselect"
                    id="serachselect"
                    onChange={onTypeChange}
                >
                    <option value={null}>전체</option>
                    <option value="배너">배너</option>
                    <option value="전면">전면</option>
                </SelectBox>
                <ColumnBox>
                    <SearchInput
                        type="text"
                        placeholder={'광고명 입력'}
                        onChange={onSearchDataChange}
                        value={searchData||''}  
                    />
                    <SearchIndexBox
                        startChange={onStartDateChange}
                        endChange={onEndDateChange}
                        handleDate={handleDate}
                        startDate={startDate}
                        endDate={endDate}
                    />
                </ColumnBox>
                <div onClick={onReset}>
                    <img src={DeleteSearch} alt="" />
                    <p>초기화</p>
                </div>
            </SearchBox>
            <Table>
                <thead>
                    <tr>
                        <th>
                            <CheckBox
                                no="checkAll"
                                checked={checkAll}
                                onChange={handleCheckAll}
                            />
                        </th>
                        <th>
                            <BorderDiv>No.</BorderDiv>
                        </th>
                        <th>광고명</th>
                        <th>
                            <BorderDiv>타입</BorderDiv>
                        </th>
                        <th>
                            <BorderDivR>광고 시작일</BorderDivR>
                        </th>
                        <th>광고 종료일</th>
                    </tr>
                </thead>
                <tbody>
                    {noticeListInfo.map((item, key) => (
                        <AdvertisementList
                            no={item.ad_id}
                            key={key}
                            adId={item.index_no}
                            adName={item.ad_name}
                            adType={item.ad_type}
                            adStart={item.ad_exposure_start_date}
                            adEnd={item.ad_exposure_expire_date}
                            checked={checkList.includes(`${item.ad_id}`)}
                            onChange={handleCheckEach}
                        />
                    ))}
                </tbody>
            </Table>
            <div>
                <Button onClick={toggleModal}>선택삭제</Button>
                <Pagination
                    no={no.replace(':', '')}
                    url="/admin/advertisement/:"
                    pages={noticeInfo['pages']}
                    isFirstPage={noticeInfo['isFirstPage']}
                    isLastPage={noticeInfo['isLastPage']}
                    prePage={noticeInfo['prePage']}
                    nextPage={noticeInfo['nextPage']}
                    hasPrePage={noticeInfo['hasPreviousPage']}
                    hasNextPage={noticeInfo['hasNextPage']}
                />
                <Button onClick={goToWrite}>광고 등록</Button>
            </div>

            <DeleteModal
                toggle={deleteModalToggle}
                toggleFunc={handleDeleteModal}
                deleteFunc={onDelete}
                content={'광고를'}
            />
            <DeleteConfirmModal
                toggle={deleteConfirmModalToggle}
                location={'/admin/advertisement/:1'}
            />

            <SelectUserModal
                toggle={toggleSelectUserModal}
                toggleFunc={handleToggleSelectUsetModal}
                content={'게시글을'}
            />
            <AuthCheckModal
                toggle={toggleAuthModal}
                toggleFunc={handleToggleAuthModal}
            />
        </NoticeWrapper>
    );
}

export default Advertisement;
