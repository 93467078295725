import axios from 'axios';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Cookie from 'universal-cookie';
import ControllDeviceModal from '../components/ControllDeviceModal';
import DeviceDetailList from '../components/DeviceDetailList';
import DeviceDetailTable from '../components/DeviceDetailTable';
import {
    BcArrow,
    BorderDiv,
    BorderDivL,
    BorderDivR,
    Description,
    SearchGroupDetailWrapper,
    Title,
} from '../css/styles';

import breadcrumbArrow from '../asset/img/breadcrumbarrow.png';
import AuthCheckModal from '../components/AuthCheckModal';
import ChargingStationModal from '../components/ChargingStationModal';
import DeleteInDetailModal from '../components/DeleteInDetailModal';
import QrImgModal from '../components/QrImgModal';
import StationErrorModal from "../components/StationErrorModal";
import StationUsageModal from "../components/StationUsageModal";

const cookies = new Cookie();

const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    & > div:nth-child(2) > a {
        cursor: pointer;
    }
    & > div {
        display: flex;
        align-items: center;
        column-gap: 10px;
    }
    & > div:first-child {
        & > h1 {
            font-weight: bold;
            font-size: 28px;
            line-height: 46px;
        }
        & > img {
            width: 25px;
        }
    }
    & > div:last-child {
          
          & > a:first-child {
            font-size: 16px;
            line-height: 20px;
            border-radius: 10px;
            border: 1px solid #c4c4c4;
            padding: 7px 10px !important;
          }
          & > a:nth-child(2) {
            font-size: 16px;
            line-height: 20px;
            border-radius: 10px;
            border: 1px solid #DF1F26;
            padding: 7px 10px !important;
          }
          & > a:nth-child(3) {
            font-size: 16px;
            line-height: 20px;
            border-radius: 10px;
            border: 1px solid #c4c4c4;
            padding: 7px 10px !important;
          }
        & > a:nth-child(4) {
            font-size: 16px;
            line-height: 20px;
            border: 1px solid #c4c4c4;
            border-radius: 10px;
            color: #df1f26;
            padding: 7px 10px !important;
        }
        & > a:last-child {
        }
    }
`;

const InnerTable = styled.div`
    & > table {
        width: 100%;
        text-align: center;
        & > thead {
            & > tr {
                line-height: 20px;
                border-bottom: 2px solid #e5e5e5;
                & > th:nth-child(3) {
                    text-align: center;
                    padding-left: 10px;
                }
              & > th:nth-child(1) {
                width: 100px;
              }
              & > th:nth-child(2) {
                width: 100px;
              }
              & > th:nth-child(3) {
                width: 100px;
              }
              & > th:nth-child(4) {
                width: 150px;
              }
              & > th:nth-child(5) {
                width: 150px;
              }
              & > th:nth-child(6) {
                width: 100px !important;
              }
            }
        }
        & > tbody {
            & > tr {
                line-height: 40px;
                border-bottom: 1px solid #e5e5e5;
                & > td:nth-child(3) {
                    text-align: center;
                    padding-left: 10px;
                }
            }
        }
    }
`;

const PortInfo = styled.span`
    background: #79bd42;
    border-radius: 36px;
    color: white;
    padding: 2px 10px 4px 10px;
`;

function DeviceDetail(props) {
    const { no } = useParams();
    const [stationInfo, setStationInfo] = React.useState([]);
    const [toggleModal, setToggleModal] = React.useState(false);
    const [toggleErrorModal , setToggleErrorModal] = React.useState(false);

    const [deleteModalToggle, setDeleteModalToggle] = React.useState(false);

    const [toggleUsageModal ,setToggleUsageModal] = React.useState(false);
    const [toggleChargingModal , setToggleChargingModal] = React.useState(false);

    const [toggleAuthModal , setToggleAuthModal] = React.useState(false);

    const [toggleQrImgModal , setToggleQrImgModal] = React.useState(false);

    const [qrData , setQrData] = React.useState({});

    useEffect(() => {
        setToggleUsageModal(window.sessionStorage.getItem("toggleUsageModal")===true?true:false);
      }, []);
    
      useEffect(() => {
        window.sessionStorage.setItem("toggleUsageModal", toggleUsageModal);
      }, [toggleUsageModal]);

      useEffect(() => {
        setToggleErrorModal(window.sessionStorage.getItem("toggleErrorModal")===true?true:false);
      }, []);
    
      useEffect(() => {
        window.sessionStorage.setItem("toggleErrorModal", toggleErrorModal);
      }, [toggleErrorModal]);

    const handleToggleAuthModal = () => {
        setToggleAuthModal(!toggleAuthModal);
    }
    const handleToggleChargingModal = () => {
        setToggleChargingModal(!toggleChargingModal);
    }

    const handleToggleErrorModal = () => {
        setToggleErrorModal(!toggleErrorModal);
    }

    const handleDetailDeleteModal = () => {
        setDeleteModalToggle(!deleteModalToggle);
    };

    const handleToggleQrImgModal = () => {
        setToggleQrImgModal(!toggleQrImgModal);
    }

    useEffect(() => {
        axios({
            method: 'get',
            url: `${window.Config.G_API_URL}/admin/station/detail`,
            params: {
                token: cookies.get('token'),
                station_id: no.replace(':', ''),
            },
        })
            .then((response) => {
                console.log(response.data.data)
                if (response.data.result['code'] === '200') {
                    response.data.data.port.sort((a,b) => (a.port - b.port));
                    setStationInfo(response.data.data);
                    console.log(response.data.data);
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    }, [no]);

    useEffect(() => {
        console.log(stationInfo);
    },[stationInfo]);

    const navigate = useNavigate();

    const handleToggleModal = () => {
        setToggleModal(!toggleModal);
    };

    const handleToggleUsageModal = () => {
        setToggleUsageModal(!toggleUsageModal);
    }

    const handleQrImage = (props) => {
        setQrData(props);
        handleToggleQrImgModal();
    }

    const onDelete = () => {
        axios({
            method: 'delete',
            url: `${window.Config.G_API_URL}/admin/station/`,
            params: {
                token: cookies.get('token'),
                station_id: no.replace(':', ''),
            },

        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    window.location.href = '/admin/searchstation/:1'
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else if(response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                }  else if (response.data.result['code'] === '403'){
                    handleToggleChargingModal();
                }  else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    };
    return (
        <SearchGroupDetailWrapper>
            <Title>스테이션 상세</Title>
            <Description>
                스테이션 관리 <BcArrow src={breadcrumbArrow} /> 그룹 검색{' '}
                <BcArrow src={breadcrumbArrow} /> 그룹 상세{' '}
                <BcArrow src={breadcrumbArrow} /> 스테이션 상세
            </Description>
            <TitleWrapper>
                <div>
                    <h1>{stationInfo.station_name}</h1>
                    <PortInfo>
                        {stationInfo.uses_port}/{stationInfo.maxinum_port}
                    </PortInfo>
                    {/*<img src={Warning} alt="" />*/}
                </div>
                <div>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a onClick={handleDetailDeleteModal}>스테이션 삭제</a>
                    <a onClick={handleToggleErrorModal}>에러 내역</a>
                    <a onClick={handleToggleUsageModal}>이용 내역</a>
                    {/* <a onClick={handleToggleModal}>스테이션 제어</a> */}
                    <a href={`/admin/devicedetail/${no}/update`}>정보 수정</a>
                </div>
            </TitleWrapper>
            <DeviceDetailTable
                address={stationInfo.address}
                outside={stationInfo.outside}
                stationId={stationInfo.station_id}
                groupId={stationInfo.group_id}
                private={stationInfo.private_station}
                running={stationInfo.running}
                lat={stationInfo.latitude}
                lon={stationInfo.longitude}
                weekStart={stationInfo.weekdays_hours_start}
                weekStartMin={stationInfo.weekdays_hours_start_min}
                satStart={stationInfo.saturday_hours_start}
                satStartMin={stationInfo.saturday_hours_start_min}
                holStart={stationInfo.holiday_hours_start}
                holStartMin={stationInfo.holiday_hours_start_min}
                weekEnd={stationInfo.weekdays_hours_end}
                weekEndMin={stationInfo.weekdays_hours_end_min}
                satEnd={stationInfo.saturday_hours_end}
                satEndMin={stationInfo.saturday_hours_end_min}
                holEnd={stationInfo.holiday_hours_end}
                holEndMin={stationInfo.holiday_hours_end_min}
                groupName={stationInfo.group_name}
                chargePrice={stationInfo.charge_price}
                holderPrice={stationInfo.holder_price}
                charger_status={stationInfo.charger_status}
                admin={stationInfo.admin}
            />
            <div>
                <button onClick={() => window.location.href = '/admin/searchstation/:1'}>목록</button>
            </div>
            <InnerTable>
                <table>
                    <thead>
                        <tr>
                            <th><BorderDivR>포트번호</BorderDivR></th>
                            <th>연결상태</th>
                            <th><BorderDiv>잠금상태</BorderDiv></th>
                            <th>이용현황</th>
                            <th><BorderDiv>회원/킥보드 고유번호</BorderDiv></th>
                            <th>업체명</th>
                            <th><BorderDivL>QR CODE</BorderDivL></th>
                        </tr>
                    </thead>
                    <tbody>
                        {stationInfo.port &&
                            stationInfo.port.map((item, index) => (
                                <DeviceDetailList key={index}
                                    port={item.port}
                                    state={item.error_status}
                                    lock={item.lock_status}
                                    usage={item.status}
                                    connect={item.connect}
                                    number={item.number}
                                    company={item.company}
                                    qrData={item}
                                    qrImageFunc = {handleQrImage}
                                />
                            ))}
                    </tbody>
                </table>
            </InnerTable>

            <ControllDeviceModal
                toggle={toggleModal}
                toggleFunc={handleToggleModal}
            />

            <StationUsageModal
                toggle={toggleUsageModal}
                toggleFunc={handleToggleUsageModal}
                name={stationInfo.station_name}
            />

            <StationErrorModal
                toggle={toggleErrorModal}
                toggleFunc={handleToggleErrorModal}
                name={stationInfo.station_name}
            />

            <DeleteInDetailModal
                toggle={deleteModalToggle}
                toggleFunc={handleDetailDeleteModal}
                deleteFunc={onDelete}
                content={'스테이션을'}
            />            

            <ChargingStationModal
                toggle={toggleChargingModal}
                toggleFunc={handleToggleChargingModal}
            />
            <AuthCheckModal
                toggle={toggleAuthModal}
                toggleFunc={handleToggleAuthModal}
            />

            <QrImgModal 
                toggle={toggleQrImgModal} 
                toggleFunc={handleToggleQrImgModal} 
                qrData={qrData}
            />
        </SearchGroupDetailWrapper>
    );
}

export default DeviceDetail;
