import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

const Description = styled.p`
    font-size: 16px;
    line-height: 23px;
    text-align: center;
`

const ButtonContainer = styled.div`
    display : flex;
    justify-content : center;
    margin-top : 35px;
    column-gap: 20px;
`


const RedButton = styled.a`
    color: #DF1F26;
      background-color: white;
      border-radius: 10px;
      border: 1px solid #C4C4C4;
      width : 25%;
      padding : 10px 0 ;
      font-weight: bold;
      font-size: 14px;
      text-align: center;
      margin-top : 17px;  
`

const Button = styled.button`
    background-color: white;
    border-radius: 10px;
    border: 1px solid #C4C4C4;
    width : 25%;
    padding : 10px 0 ;
    font-weight: bold;
    font-size: 14px;
    margin-top : 17px;
    color: #221E1F;
`
function PointDeduceConfirmModal(props) {

    return (
        <Modal isOpen={props.toggle} onRequestClose={props.toggleFunc}
               style={{
                   overlay :{
                       backgroundColor : 'rgba(34, 30, 31, 0.5)'
                   },
                   content : {
                       height : '14%',
                       left : '37%',
                       right : '37%',
                       top : '35%',
                       borderRadius : '10px',
                       border: '1px solid #C4C4C4' ,
                   }
               }}
        >
            <Description>
                총 {props.length} 명에게 포인트를 차감하시겠습니까?
            </Description>
            <ButtonContainer>
                <Button onClick={props.toggleFunc}>취소</Button>
                <RedButton onClick={props.confirm}>확인</RedButton>
            </ButtonContainer>
        </Modal>
    );
}

export default PointDeduceConfirmModal;
