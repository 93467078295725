import React from 'react';
import CheckBox from "./CheckBox";
import styled from "styled-components";

const Tr = styled.tr`
  & > td:first-child{
    width: 10%;
  }
  & > td:nth-child(2){
    width: 10%;
  }
  & > td:nth-child(3){
    width: 40%;
  }
`

function StationErrorModalList(props) {
    return (
        <Tr>
            <td>{props.rowNum}</td>
            <td>{props.port}</td>
            <td>{props.error}</td>
            <td>{props.dateTime}</td>
        </Tr>
    );
}

export default StationErrorModalList;