import React from 'react';
import DaumPostcode from 'react-daum-postcode';
import Modal from "react-modal";
import styled from "styled-components";

const Postcode = (props) => {

    const postCodeStyle = {
        display: "block",
        width: "500px",
        height: "500px",
        zIndex: 100,
    };

    const ModalDescription = styled.div`
        text-align: center;
        font-size: 16px;
        padding-left: 13px;
        margin-top: 16px;
        margin-bottom: 0px;
    `
    const onComplete = (data) => {
        props.setLocation(data.address)
        props.toggleFunc();
        console.log(data);
    }

    return (
        <Modal isOpen={props.toggle} onRequestClose={props.toggleFunc}
            style={{
                overlay :{
                    backgroundColor : 'rgba(34, 30, 31, 0.5)'
                },
                content : {
                    height : '520px',
                    left : '35%',
                    right : '35%',
                    top : '10%',
                    borderRadius : '10px',
                    border: '1px solid #C4C4C4' ,
                }
            }}
        >
            <ModalDescription>
                <DaumPostcode
                    style={postCodeStyle}
                    onComplete={onComplete}
                />
            </ModalDescription>
        </Modal>
    );
}

export default Postcode;