import React from 'react'

import Pin from '../asset/img/pin.png';
import CheckBox from "./CheckBox";
import styled from "styled-components";


const Color = styled.span`
  color : ${props => props.state === 'Y' ? '' : '#df1f26' } ;
`

const CursorTd = styled.td`
  cursor: pointer;
  width : 13%;
  text-align : center !important;
  padding-left : 0 !important;
`
const Tr = styled.tr`
    & > td:nth-child(4){
        text-align : left;
        padding-left : 3%;
    }
    & > td:nth-child(5){
        width : 10%;
    }
`

const Td = styled.td`
    cursor: pointer;
`

function FaqList(props) {

    const goToDetail = () => {
        window.location.href = `/admin/faq/detail/:${props.no}`
    }

    return (
        <Tr>
            <td><CheckBox no={props.no} onChange={props.onChange} checked={props.checked}/></td>
            <td>{props.no}</td>
            <CursorTd onClick={goToDetail}>
                {props.category}
            </CursorTd>
            <Td onClick={goToDetail}>
                {props.title.replace(/\%5B/g , '[').replace(/\%5D/g, ']')}
                {
                    props.popular === 'Y' ? <img src={Pin} alt='' style={{width : '11px' , marginLeft : '6px'}}/>  : '' 
                }
            </Td>
            <td>{props.dateTime}</td>
            <td>{props.adminName}</td>
        </Tr>
    )
}

export default FaqList;