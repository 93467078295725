import axios from 'axios';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import Cookie from 'universal-cookie';

import {
    BcArrow,
    BorderDiv,
    BorderDivR,
    Description,
    SearchBox,
    SearchGroupWrapper,
    Title,
} from '../css/styles';

import styled from 'styled-components';
import breadcrumbArrow from '../asset/img/breadcrumbarrow.png';
import DeleteSearch from '../asset/img/deletesearch.png';
import Dotbogi from '../asset/img/dotbogi.png';
import SelectArrow from '../asset/img/selectarrow.png';
import AuthCheckModal from '../components/AuthCheckModal';
import ChargingStationModal from '../components/ChargingStationModal';
import CheckBox from '../components/CheckBox';
import DeleteConfirmModal from '../components/DeleteConfirmModal';
import DeleteModal from '../components/DeleteModal';
import Pagination from '../components/Pagination';
import SearchStationList from '../components/SearchStationList';
import SelectUserModal from '../components/SelectUserModal';

const cookies = new Cookie();

const SelectBox = styled.select`
    cursor: pointer;
    appearance: none;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    padding: 7px 13px;
    width: 9%;
    font-size: 16px;
    line-height: 23px;
    background-image: url(${SelectArrow});
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: 85%;
    &:focus {
        outline: none;
    }
`;

const SearchInput = styled.input`
    margin-left: 21px;
    width: 35%;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    background-image: url(${Dotbogi});
    background-repeat: no-repeat;
    background-size: 17px;
    background-position: 97%;
    padding: 7px 10px;
    font-size: 16px;
    line-height: 23px;
    color: #7e7e7e;
`;

const FootterWrapper = styled.div`
    & > div:last-child {
        display: flex;
        column-gap: 10px;
        & > button {
            background-color: white;
            border: 1px solid #e5e5e5;
            border-radius: 10px;
            padding: 10px;
            cursor: pointer;
        }
        & > button:last-child {
            color: #df1f26;
        }
    }
`;

function SearchGroup() {
    const { no } = useParams();
    const [noticeInfo, setNoticeInfo] = React.useState([]);
    const [noticeListInfo, setNoticeListInfo] = React.useState([]);
    const [deleteModalToggle, setDeleteModalToggle] = React.useState(false);
    const [deleteConfirmModalToggle, setDeleteConfirmModalToggle] =
        React.useState(false);

    const [checked, setChecked] = React.useState(false);
    const [checkAll, setCheckAll] = React.useState(false);
    const [checkList, setCheckList] = React.useState([]);
    const [searchData, setSearchData] = React.useState(null);
    const [searchType, setSearchType] = React.useState(null);
    const [groupType, setGroupType] = React.useState(null);
    const [toggleSelectUserModal , setToggleSelectUserModal] = React.useState(false);
    const [toggleChargingModal , setToggleChargingModal] = React.useState(false);

    const [toggleAuthModal , setToggleAuthModal] = React.useState(false);

    const handleToggleAuthModal = () => {
        setToggleAuthModal(!toggleAuthModal);
    }
    const handleToggleChargingModal = () => {
        setToggleChargingModal(!toggleChargingModal);
    }

    const handleToggleSelectUsetModal = () => {
        setToggleSelectUserModal(!toggleSelectUserModal);
    }

    useEffect(() => {
        console.log(cookies.get('token'));
        setCheckList([]);

        axios({
            method: 'get',
            url: `${window.Config.G_API_URL}/admin/station/`,
            params: {
                page_num: no.replace(':', ''),
                token: cookies.get('token'),
                search_type: searchType,
                search_data: searchData,
                group_type: groupType,
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    console.log(response.data.data.result);
                    setNoticeInfo(response.data.data.result);
                    setNoticeListInfo(response.data.data.result.list);
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                }else if(response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    }, [no, searchData, searchType, groupType]);

    useEffect(()=>{
        if(checkAll){
            let arr = [];
            noticeListInfo.map((item)=>{
                    arr.push(`${item.station_id}`);
            });
            setCheckList(arr);
        } else if(checkList.length==noticeListInfo.length) {
            setCheckList([]);
        }
    },[checkAll]);

    useEffect(()=>{
        if(checkList.length == noticeListInfo.length){
            setCheckAll(true);
        } else {
            setCheckAll(false);
        }
        // console.log(checkList);
    },[checkList, noticeListInfo]);

    const handleDeleteModal = () => {
        setDeleteModalToggle(!deleteModalToggle);
    };

    const handleConfirmDeleteModal = () => {
        handleDeleteModal();
        setDeleteConfirmModalToggle(!deleteConfirmModalToggle);
    };

    const onDelete = () => {
        axios({
            method: 'delete',
            url: `${window.Config.G_API_URL}/admin/station/`,
            params: {
                token: cookies.get('token'),
                station_id: checkList,
            },
            // no : checkList 파라미터 [] 없애는 옵션
            paramsSerializer: function (paramObj) {
                const params = new URLSearchParams();
                for (const key in paramObj) {
                    params.append(key, paramObj[key]);
                }

                return params.toString();
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    console.log(response);

                    handleConfirmDeleteModal();
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else if(response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                } else if (response.data.result['code'] === '403'){
                    handleToggleChargingModal();
                }  else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    };

    const handleCheckAll = (e) => {
        setCheckAll(!checkAll);
    };

    const handleCheckEach = (e) => {
        const { id, checked } = e.target;
        let arr = [...checkList];
        if (arr.includes(id)) {
            for (let i = 0; i < arr.length; i++) {
                if (arr[i] == id) {
                    arr.splice(i, 1);
                    setChecked(!checked);
                    break;
                }
            }
            setCheckList(arr);
        } else {
            setChecked(!checked);
            setCheckList([...checkList, id]);
        }
    };

    const onTypeChange = (e) => {
        if (e.target.value === '전원') {
            setSearchType(null);
        } else {
            setSearchType(e.target.value);
        }
    };

    const onSearchDataChange = (e) => {
        setSearchData(e.target.value);
    };
    const onGroupTypeChange = (e) => {
        if (e.target.value === '그룹 전체') {
            setGroupType(null);
        } else {
            setGroupType(e.target.value);
        }
    };

    const toggleModal = () => {
        if(checkList.length == 0){
            console.log('true');
            handleToggleSelectUsetModal();
        } else {
            console.log('false');
            handleDeleteModal();
        }
    }

    const onReset = () => {
        setSearchData('');
    }
    return (
        <SearchGroupWrapper>
            <Title>스테이션 검색</Title>
            <Description>
                스테이션 관리 <BcArrow src={breadcrumbArrow} /> 스테이션 검색{' '}
            </Description>
            <SearchBox>
                <SelectBox
                    name="searchselect1"
                    id="serachselect1"
                    onChange={onTypeChange}
                >
                    <option value="전원">전원</option>
                    <option value="ON">ON</option>
                    <option value="OFF">OFF</option>
                </SelectBox>
                <SearchInput
                    type="text"
                    placeholder={'검색어 입력'}
                    onChange={onSearchDataChange}
                    value={searchData||''}                />
                <div onClick={onReset}>
                    <img src={DeleteSearch} alt="" />
                    <p>초기화</p>
                </div>
            </SearchBox>
            <SelectBox
                name="searchselect2"
                id="serachselect2"
                onChange={onGroupTypeChange}
            >
                <option value="그룹 전체">그룹 전체</option>
                <option value="그룹 지정됨">그룹 지정됨</option>
                <option value="그룹 미지정">그룹 미지정</option>
            </SelectBox>
            <table>
                <thead>
                    <tr>
                        <th>
                            <CheckBox
                                no="checkAll"
                                checked={checkAll}
                                onChange={handleCheckAll}
                            />
                        </th>
                        <th>
                            <BorderDiv>No.</BorderDiv>
                        </th>
                        <th>스테이션명</th>
                        <th>
                            <BorderDiv>그룹명</BorderDiv>
                        </th>
                        <th><BorderDivR>충전기 종류</BorderDivR></th>
                        <th>스테이션 ID</th>
                        <th>
                            <BorderDiv>설치일</BorderDiv>
                        </th>
                        <th>
                            <BorderDivR>전원</BorderDivR>
                        </th>
                        <th>이용 현황</th>
                    </tr>
                </thead>
                <tbody>
                    {noticeListInfo.map((item, key) => (
                        <SearchStationList
                            key={key}
                            rowNum={item.rownum}
                            groupName={item.group_name}
                            groupId={item.group_id}
                            stationID={item.station_id}
                            stationName={item.station_name}
                            charger_status={item.charger_status}
                            date={item.date_time}
                            running={item.running}
                            usage={item.uses_port}
                            max={item.maxinum_port}
                            checked={checkList.includes(`${item.station_id}`)}
                            onChange={handleCheckEach}
                        />
                    ))}
                </tbody>
            </table>
            <FootterWrapper>
                <button onClick={toggleModal}>선택삭제</button>
                <Pagination
                    no={no.replace(':', '')}
                    url="/admin/searchstation/:"
                    pages={noticeInfo['pages']}
                    isFirstPage={noticeInfo['isFirstPage']}
                    isLastPage={noticeInfo['isLastPage']}
                    prePage={noticeInfo['prePage']}
                    nextPage={noticeInfo['nextPage']}
                    hasPrePage={noticeInfo['hasPreviousPage']}
                    hasNextPage={noticeInfo['hasNextPage']}
                />
                <div>
                    <button>엑셀 입력</button>
                    <button>엑셀 출력</button>
                    <button
                        onClick={() =>
                            (window.location.href =
                                '/admin/searchstation/register')
                        }
                    >
                        스테이션 등록
                    </button>
                </div>
            </FootterWrapper>
            <DeleteModal
                content={'스테이션을'}
                toggle={deleteModalToggle}
                toggleFunc={handleDeleteModal}
                deleteFunc={onDelete}
            />
            <DeleteConfirmModal 
                toggle={deleteConfirmModalToggle} 
                location={'/admin/searchstation/:1'}
            />

            <SelectUserModal
                toggle={toggleSelectUserModal}
                toggleFunc={handleToggleSelectUsetModal}
                content={'스테이션을'}
            />

            <ChargingStationModal
                toggle={toggleChargingModal}
                toggleFunc={handleToggleChargingModal}
            />
            <AuthCheckModal
                toggle={toggleAuthModal}
                toggleFunc={handleToggleAuthModal}
            />
        </SearchGroupWrapper>
    );
}

export default SearchGroup;
