import React from "react";
import Modal from "react-modal";
import styled from "styled-components";

const ModalDescription = styled.p`
    text-align: center;
    font-size: 16px;
    margin-top: 16px;
    margin-bottom: 36px;
`

const ModalButton = styled.button`
    display: flex;
    margin: auto;
    font-size: 16px;
    line-height: 23px;
    padding : 7px 20px;
    background-color: white;
    border-radius: 10px; 
    border: 1px solid #C4C4C4;
    color: red;
    font-weight: bold;
`

const EmptyListModal = (props) => {
    return(
        <Modal isOpen={props.toggle} onRequestClose={() => window.location.href = '/admin/notice/:1'}
            style={{
                overlay :{
                    backgroundColor : 'rgba(34, 30, 31, 0.5)'
                },
                content : {
                    height : '14%',
                    left : '35%',
                    right : '35%',
                    top : '38%',
                    borderRadius : '10px',
                    border: '1px solid #C4C4C4' ,
                }
            }}
        >
            <ModalDescription>권한이 존재하지 않습니다. 초기화면으로 돌아갑니다.</ModalDescription>
            <ModalButton onClick={() => window.location.href = '/admin/notice/:1'}>확인</ModalButton>
        </Modal>
    )
}

export default EmptyListModal;