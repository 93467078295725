import React, {useEffect} from 'react';
import styled from "styled-components";

import DeleteSearch from "../asset/img/deletesearch.png";
import SelectArrow from "../asset/img/selectarrow.png";
import Dotbogi from "../asset/img/dotbogi.png";
import SearchIndexBox from "./SearchIndexBox";
import axios from "axios";
import Cookie from "universal-cookie";
import {useParams} from "react-router";
import ManagerMemoList from "./ManagerMemoList";
import Pagination from "./Pagination";
import CheckBox from "./CheckBox";
import ManagerMemoRegModal from "./ManagerMemoRegModal";
import NotificationList from "./NotificationList";
import Redmsg from "../asset/img/redmsg.png";
import MessageSendModal from "./MessageSendModal";
import { BorderDiv , BorderDivR} from '../css/styles';

const cookies = new Cookie();

const PointManageWrapper = styled.div`
  margin-top: 0 !important;
  & > table {
    width: 100%;
    text-align: center;
    & > tbody > tr:first-child {
      line-height: 20px;
      border-bottom: 2px solid #E5E5E5;
    }
    & > tbody > tr {
      line-height: 40px;
      border-bottom: 1px solid #E5E5E5;
      font-weight: 500;
      font-size: 16px;
    }
  }
  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    & > button {
      border: 1px solid #C4C4C4;
      border-radius: 10px;
      background-color: white;
      font-size: 16px;
      line-height: 23px;
      width: 8%;
      padding : 5px 0;
    }
    & > div > button {
      border: 1px solid #C4C4C4;
      border-radius: 10px;
      background-color: white;
      font-size: 16px;
      line-height: 23px;
      padding : 5px;
      margin-right: 10px;
    }
  }
`

const SearchBox = styled.div`
    padding : 2% 0;
    display: flex;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    column-gap: 15px;
    & > div {
      display: flex;
      align-items: center;
      & > img {
        cursor: pointer;
        margin-top: 10px;
        margin-left: 7px;
        width: 20px;
      }
      & > p {
        font-size: 16px;
        margin-top: 9px;
        line-height: 23px;
        color: #C4C4C4;
      }
    } 
`

const ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
`

const SearchInput = styled.input`
  width: 97%;
  border: 1px solid #C4C4C4;
  border-radius: 10px;
  background-image: url(${Dotbogi});
  background-repeat: no-repeat;
  background-size: 17px;
  background-position: 97%;
  padding: 7px 10px;
  font-size: 16px;
  line-height: 23px;
  color: #7E7E7E;
`

const CursorBox = styled.div`
    justify-content : center !important;
  align-items: center !important;
  & > div > button {
    cursor: pointer;
  }
 & > button {
   cursor: pointer;
   color: #DF1F26;
 }
`

const MsgButton = styled.button`
 padding : 7px 27px !important;
  background-image: url(${Redmsg});
  background-size: 17px, 10px;
  background-position: 10%, 90%;
  background-repeat: no-repeat;
  background-color: white;
  border: 1px solid #c4c4c4;
  width: 11% !important;
  border-radius: 10px;
  font-size: 16px;
  line-height: 23px;
`

const Table = styled.table`
    & > tbody > tr:first-child {
        & > th:nth-child(2){
            width : 13% !important;
        }
        & > th:nth-child(3){
            width : 40% !important;
        }
        & > th:nth-child(5){
            width : 7% !important;
        }
    }
`

const SelectBox = styled.select`
    cursor: pointer;
    appearance: none;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    padding: 7px 15px;
    font-size: 16px;
    line-height: 23px;
    width: 10%;
    background-image: url(${SelectArrow});
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: 87%;
    &:focus {
        outline: none;
    }
`;

function ManagerMemo(props) {

    const {no , num , page} = useParams();
    const [memoInfo , setMemoInfo] = React.useState([]);
    const [memoPageInfo , setMemoPageInfo] = React.useState([]);

    const [checked , setChecked] = React.useState(false);
    const [checkAll , setCheckAll] = React.useState(false);
    const [checkList , setCheckList] = React.useState([]);
    const [searchData , setSearchData] = React.useState(null);
    const [startDate , setStartDate] = React.useState(null);
    const [endDate , setEndeDate] = React.useState(null);

    const [toggleMemoRegModal , setToggleMemoRegModal] = React.useState(false);

    const [toggleMsgModal , setToggleMsgModal] = React.useState(false);
    const [textMsg , setTextMsg] = React.useState('');
    const [searchType, setSearchType] = React.useState('장비 제목');
    const [searchType2, setSearchType2] = React.useState(null);

    useEffect(() => {
        axios({
            method : 'get',
            url : `${window.Config.G_API_URL}/admin/user/sending_history/alim_talk`,
            params : {
                token : cookies.get('token'),
                user_id : no.replace(':' , ''),
                page_num : num.replace(':',''),
                start_date : startDate,
                end_date : endDate,
                search_type : searchType,
                search_data :searchData,
                alim_talk_type : searchType2,
            }
        })
            .then(response => {
                if(response.data.result['code'] === '200'){
                    console.log(response);
                    setMemoPageInfo(response.data.data.result)
                    setMemoInfo(response.data.data.result.list)
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function(error){
                alert(error);
            })
    },[num, searchData ,startDate , endDate , searchType , searchType2]);


    const onSearchDataChange = (e) => {
        setSearchData(e.target.value);
    }

    const onStartDateChange = (e) => {
        setStartDate(e.target.value);
    }

    const onEndDateChange = (e) => {
        setEndeDate(e.target.value);
    }

    const handleToggleMemoRegModal = () => {
        setToggleMemoRegModal(!toggleMemoRegModal);
    }

    // //문자 발송 함수
    // const sendMsg = () => {
    //     axios({
    //         method: 'post',
    //         url: `${window.Config.G_API_URL}/admin/aligo/sms`,
    //         params: {
    //             message : textMsg,
    //             user_ids: no.replace(':' , ''),
    //         },
    //         // no : checkList 파라미터 [] 없애는 옵션
    //         paramsSerializer: function (paramObj) {
    //             const params = new URLSearchParams();
    //             for (const key in paramObj) {
    //                 params.append(key, paramObj[key]);
    //             }
    //             return params.toString();
    //         },
    //     })
    //         .then((response) => {
    //             if (response.data.result['code'] === '200') {
    //                 window.location.reload();
    //             } else if (response.data.result['code'] === '401'){
    //                 alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
    //                 window.location.href = '/'
    //             } else {
    //                 alert(response.data.result['message']);
    //             }
    //         })
    //         .catch(function (error) {
    //             alert(error);
    //         });
    // };

    const handdleToggleMsgModal = () => {
        setToggleMsgModal(!toggleMsgModal);
    }

    const handleTextMsg = (e) => {
        setTextMsg(e.target.value)
    }

    const onTypeChange = (e) => {
        setSearchType(e.target.value);
    };

    const onTypeChange2 = (e) => {
        setSearchType2(e.target.value);
    };
    
    const handleDate = (e) => {
        var date = new Date();
        var currentDate = date.toISOString().substring(0,10);
        setEndeDate(currentDate);
        switch(e.target.id){
            case 'all':
                setStartDate(null);
                break;
            case 'week':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 7));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate(currentDate);
                break;
            case 'halfMonth':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 14));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate(currentDate);
                break;
            case 'month':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 30));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate(currentDate);
                break;
            case 'threeMonth':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 91));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate(currentDate);
                break;
            case 'halfYear':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 182));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate(currentDate);
                break;
            case 'year':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 365));
                var currentDate = newDate.toISOString().substring(0,10);
                setStartDate(currentDate);
                break;
        }
    }
    return (
        <PointManageWrapper>
            <SearchBox>
                    <SelectBox
                        name="searchselect"
                        id="serachselect"
                        onChange={onTypeChange}
                    >
                        <option value={null}>전체</option>
                        <option value="스테이션 이름">스테이션명</option>
                        <option value="내용">내용</option>
                    </SelectBox>
                    <SelectBox
                        name="searchselect2"
                        id="serachselect2"
                        onChange={onTypeChange2}
                    >
                        <option value={null}>전체</option>
                        <option value="회원가입">회원가입</option>
                        <option value="예약 완료">예약 완료</option>
                        <option value="예약 시간 만료">예약 시간 만료</option>
                        <option value="충전 완료">충전 완료</option>
                        <option value="예약 취소">예약 취소</option>
                        <option value="충전 실패">충전 실패</option>
                        <option value="충전 중지">충전 중지</option>
                        <option value="충전 종료">충전 종료</option>
                        <option value="거치 상태 알림">거치 상태 알림</option>
                        <option value="문의 답변 완료">문의 답변 완료</option>
                        <option value="고장 신고 답변 완료">고장 신고 답변 완료</option>
                    </SelectBox>
                <ColumnBox>
                    <SearchInput type="text" placeholder={'검색어 입력'} onChange={onSearchDataChange}/>
                    <SearchIndexBox
                        startChange={onStartDateChange}
                        endChange={onEndDateChange}
                        handleDate={handleDate}
                        startDate={startDate}
                        endDate={endDate}
                    />
                </ColumnBox>
                <div>
                    <img src={DeleteSearch} alt=""/>
                    <p>초기화</p>
                </div>
            </SearchBox>
            <Table>
                <tbody>
                <tr>
                        <th>
                            No.
                        </th>
                        <th>
                            <BorderDiv>발송 종류</BorderDiv>
                        </th>
                        <th>내용</th>
                        <th><BorderDiv>스테이션 명</BorderDiv></th>
                        <th><BorderDivR>포트</BorderDivR></th>
                        <th>발송 일시</th>
                </tr>
                {
                    memoInfo.map((item , key) => (
                        <NotificationList
                            key={key}
                            no={item.index_no}
                            type={item.alim_talk_type}
                            content={item.contents}
                            stationName={item.station_name}
                            port={item.port}
                            notiDate={item.notify_date}
                        />
                    ))
                }
                </tbody>
            </Table>
            <CursorBox>
                {/* <MsgButton onClick={handdleToggleMsgModal}>
                    문자 발송
                </MsgButton> */}
                <Pagination
                    no={num.replace(':' , '')}
                    url={`/admin/manageuser/detail/${no}/${page}/notificationmanage/:`}
                    pages={memoPageInfo['pages']}
                    isFirstPage={memoPageInfo['isFirstPage']}
                    isLastPage={memoPageInfo['isLastPage']}
                    prePage={memoPageInfo['prePage']}
                    nextPage={memoPageInfo['nextPage']}
                    hasPrePage={memoPageInfo['hasPreviousPage']}
                    hasNextPage={memoPageInfo['hasNextPage']}
                />
                <p/>
            </CursorBox>
{/* 
            <ManagerMemoRegModal
                toggle={toggleMemoRegModal}
                toggleFunc={handleToggleMemoRegModal}
            />

            <MessageSendModal
                toggle={toggleMsgModal}
                toggleFunc={handdleToggleMsgModal}
                confirm={sendMsg}
                setTextMsg={handleTextMsg}
            /> */}
        </PointManageWrapper>
    );
}

export default ManagerMemo;