import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import ReactModal from 'react-modal';
import Conf from './Config';

window.Config = Conf;

// seongho
ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

ReactModal.setAppElement('#root');