import React from 'react'
import styled from "styled-components";
import CheckBox from "./CheckBox";
import Pin from "../asset/img/pin.png";

const Tr = styled.tr`
  & > td:nth-child(3){
    padding-left: 20px;
  }
`
const TdRight = styled.td`
    text-align : right;
`


function CouponManageList(props) {
    
  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

    const goToDetail = () => {
        window.location.href = `/admin/couponmanage/detail/:${props.couponId}/:1`
    }

    return (
        <Tr>
            <td><CheckBox no={props.couponId} onChange={props.onChange} checked={props.checked}/></td>
            <td onClick={goToDetail}>{props.rownum}</td>
            <td onClick={goToDetail}>{props.name}</td>
            <TdRight onClick={goToDetail}>{props.amount === undefined ? props.amount : numberWithCommas(props.amount)}</TdRight>
            <td onClick={goToDetail}>{props.dateTime}</td>
            <td onClick={goToDetail}>{props.couponStart}~{props.couponEnd}</td>
            <td onClick={goToDetail}>{props.company}</td>
            <td onClick={goToDetail}>{props.couponUsage} / {props.couponIssuance}</td>
        </Tr>
    )
}

export default CouponManageList