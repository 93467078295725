import axios from 'axios';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import Cookie from 'universal-cookie';

import { BcArrow, BorderDiv, Description, NoticeWrapper, Title } from '../css/styles';

import styled from 'styled-components';
import breadcrumbArrow from '../asset/img/breadcrumbarrow.png';
import DeleteSearch from '../asset/img/deletesearch.png';
import SelectArrow from '../asset/img/selectarrow.png';
import Pagination from '../components/Pagination';
import SearchIndexBox from '../components/SearchIndexBox';

import Dotbogi from '../asset/img/dotbogi.png';
import AuthCheckModal from '../components/AuthCheckModal';
import MessageList from '../components/MessageList';

const cookies = new Cookie();

const ColumnBox = styled.div`
    display: flex;
    flex-direction: column;
    width : 80%;
`;

const SearchBox = styled.div`
    padding: 2% 0;
    display: flex;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    column-gap: 15px;
    position: relative;
    & > div {
        display: flex;
        align-items: center;
        & > img {
            margin-top: 10px;
            margin-left: 7px;
            width: 20px;
        }
        & > p {
            font-size: 16px;
            margin-top: 9px;
            line-height: 23px;
            color: #c4c4c4;
        }
    }
`;

const SelectBox = styled.select`
    cursor: pointer;
    appearance: none;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    padding: 7px 15px;
    font-size: 16px;
    line-height: 23px;
    background-image: url(${SelectArrow});
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: 87%;
    &:focus {
        outline: none;
    }
`;

const SearchInput = styled.input`
    width: 70%;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    background-image: url(${Dotbogi});
    background-repeat: no-repeat;
    background-size: 17px;
    background-position: 97%;
    padding: 7px 10px;
    font-size: 16px;
    line-height: 23px;
    color: #7e7e7e;
`;

const FlexBox = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    column-gap: 10px;
    & > div {
        display: flex;
        align-items: center;
        width: 100px;
        & > img {
            cursor: pointer;
        }
    }
`;

const PaginationWrapper = styled.div`
    display : flex;
    justify-content : center !important;
`

const Table = styled.table`
    margin-top : 0px !important;
    & > thead > tr {
        & > th:nth-child(1) {
            width : 5% !important;
        }
        & > th:nth-child(2) {
            width : 10% !important;
        }
        & > th:nth-child(3) {
            width : 12%;
            text-align : center !important;
            padding-left : 0 !important;
        }
        & > th:nth-child(4) {
            width : 10%;
        }
        & > th:nth-child(5) {
            width : 35% !important;
        }
    }
`

const ContentBox = styled.td`
    position : absolute;
    top : 50%;
    left : 50%;
    width : 300px;
    display : ${props => props.hover ? 'block !important' : 'none'};
`

function ManageAdminUser() {
    const { no } = useParams();
    const [onHover , setOnHover] = React.useState(false);

    const [deleteModalToggle, setDeleteModalToggle] = React.useState(false);
    const [deleteConfirmModalToggle, setDeleteConfirmModalToggle] = React.useState(false);
    const [checked, setChecked] = React.useState(false);
    const [checkList, setCheckList] = React.useState([]);

    const [noticeInfo, setNoticeInfo] = React.useState([]);
    const [noticeListInfo, setNoticeListInfo] = React.useState([]);
    const [searchInfo , setSearchInfo] = React.useState({
        searchData : null,
        searchType : null,
        startDate : null ,
        endDate : null,
    });
    const [searchType2, setSearchType2] = React.useState(null);
    const [toggleAuthModal , setToggleAuthModal] = React.useState(false);

    const handleToggleAuthModal = () => {
        setToggleAuthModal(!toggleAuthModal);
    }

    const handleDeleteModal = () => {
        setDeleteModalToggle(!deleteModalToggle);
    };

  const setOnHoverTrue = () => {
    setOnHover(true);
  }

  const setOnHoverFalse = () => {
    setOnHover(false);
  }

    useEffect(() => {
        axios({
            method: 'get',
            url: `${window.Config.G_API_URL}/admin/sending_history/sms`,
            params: {
                page_num: no.replace(':', ''),
                token: cookies.get('token'),
                search_data : searchInfo.searchData,
                search_type : searchInfo.searchType,
                start_date : searchInfo.startDate,
                end_date : searchInfo.endDate,
                sender_type : searchType2,
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    setNoticeInfo(response.data.data.result);
                    setNoticeListInfo(response.data.data.result.list);
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                }else if(response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                }  else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    }, [no ,searchType2, searchInfo.searchData , searchInfo.searchType , searchInfo.answerState , searchInfo.startDate , searchInfo.endDate]);

    const onTypeChange = (e) => {
        setSearchInfo({...searchInfo , searchType : e.target.value});
    };

    const onSearchDataChange = (e) => {
        setSearchInfo({...searchInfo , searchData : e.target.value});
    };

    const onStartDateChange = (e) => {
        setSearchInfo({...searchInfo , startDate : e.target.value});
    }

    const onEndDateChange = (e) => {
        setSearchInfo({...searchInfo , endDate : e.target.value});
    }

    const deleteData = () => {
        setSearchInfo({...searchInfo , searchData :''});
    }

    const onTypeChange2 = (e) => {
        setSearchType2(e.target.value);
    };

    const handleDate = (e) => {
        var date = new Date();
        var currentDate1 = date.toISOString().substring(0,10);
        switch(e.target.id){
            case 'all':
                setSearchInfo({...searchInfo , startDate : null ,endDate : currentDate1});
                break;
            case 'week':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 7));
                var currentDate = newDate.toISOString().substring(0,10);
                setSearchInfo({...searchInfo , startDate : currentDate,endDate : currentDate1});
                break;
            case 'halfMonth':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 14));
                var currentDate = newDate.toISOString().substring(0,10);
                setSearchInfo({...searchInfo , startDate : currentDate,endDate : currentDate1});
                break;
            case 'month':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 30));
                var currentDate = newDate.toISOString().substring(0,10);
                setSearchInfo({...searchInfo , startDate : currentDate,endDate : currentDate1});
                break;
            case 'threeMonth':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 91));
                var currentDate = newDate.toISOString().substring(0,10);
                setSearchInfo({...searchInfo , startDate : currentDate,endDate : currentDate1});
                break;
            case 'halfYear':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 182));
                var currentDate = newDate.toISOString().substring(0,10);
                setSearchInfo({...searchInfo , startDate : currentDate,endDate : currentDate1});
                break;
            case 'year':
                var date = new Date();
                var newDate = new Date(date.setDate(date.getDate() - 365));
                var currentDate = newDate.toISOString().substring(0,10);
                setSearchInfo({...searchInfo , startDate : currentDate,endDate : currentDate1});
                break;
        }
    }

    return (
        <NoticeWrapper>
            <Title>문자</Title>
            <Description>
                발송 이력 관리 <BcArrow src={breadcrumbArrow} /> 문자
            </Description>
            <SearchBox>
                <ColumnBox>
                    <FlexBox>
                        <SelectBox name="searchselect" id="serachselect"
                    onChange={onTypeChange}>
                          <option value="all">전체</option>
                            <option value="이름">이름</option>
                            <option value="휴대폰 번호">휴대폰 번호</option>
                            <option value="내용">내용</option>
                            <option value="관리자 아이디">관리자 아이디</option>
                        </SelectBox>
                        <SelectBox
                        name="searchselect2"
                        id="serachselect2"
                        onChange={onTypeChange2}
                        >
                            <option value={null}>전체</option>
                            <option value="개인">개인</option>
                            <option value="단체">단체</option>
                        </SelectBox>
                        <SearchInput type="text" value={searchInfo.searchData||''} placeholder={'검색어 입력'} 
                    onChange={onSearchDataChange}/>
                        <div onClick={deleteData}>
                            <img src={DeleteSearch} alt="" />
                            <p>초기화</p>
                        </div>
                    </FlexBox>
                    <SearchIndexBox  startChange={onStartDateChange} endChange={onEndDateChange}
                        handleDate={handleDate}
                        startDate={searchInfo.startDate}
                        endDate={searchInfo.endDate}/>
                </ColumnBox>
            </SearchBox>
            <Table>
                <thead>
                    <tr>
                        <th>
                            No.
                        </th>
                        <th>
                            <BorderDiv>이름</BorderDiv>
                        </th>
                        <th>
                            발송 종류
                        </th>
                        <th><BorderDiv>연락처</BorderDiv></th>
                        <th>내용</th>
                        <th><BorderDiv>관리자</BorderDiv></th>
                        <th>발송 일시</th>
                    </tr>
                </thead>
                <tbody>
                    {noticeListInfo.map((item, key) => (
                        <MessageList
                            key={key}
                            no={item.index_no}
                            usId={item.us_id}
                            usName={item.us_name}
                            alimType={item.notify_send_type}
                            usPhone={item.us_phone}
                            content={item.contents}
                            adminId={item.admin_id}
                            notiDate={item.notify_date}
                            setOnHoverTrue={setOnHoverTrue}
                            setOnHoverFalse={setOnHoverFalse}
                        />
                    ))}
                </tbody>
            </Table>
            <PaginationWrapper>
                <Pagination
                    no={no.replace(':', '')}
                    url="/admin/message/:"
                    pages={noticeInfo['pages']}
                    isFirstPage={noticeInfo['isFirstPage']}
                    isLastPage={noticeInfo['isLastPage']}
                    prePage={noticeInfo['prePage']}
                    nextPage={noticeInfo['nextPage']}
                    hasPrePage={noticeInfo['hasPreviousPage']}
                    hasNextPage={noticeInfo['hasNextPage']}
                />
            </PaginationWrapper>
            <AuthCheckModal
                toggle={toggleAuthModal}
                toggleFunc={handleToggleAuthModal}
            />
        </NoticeWrapper>
    );
}

export default ManageAdminUser;
