import React from 'react';
import { NavLink } from 'react-router-dom';
import { ArrowImg, DoubleArrowImg, PaginationWrapper, RightArrowImg, RightDoubleArrowImg } from '../css/styles';

import { useParams } from "react-router";
import DoubleArrow from '../asset/img/doublepaginationarrow.png';
import Arrow from '../asset/img/paginationarrow.png';

function Pagination(props) {

    const {superadmin , chief , cs ,cscenter , partner} = useParams();

    const goToFirstPage = () => {
        if(!props.isFirstPage) {
            switch (props.type){
                case 'superadmin' :
                    window.location.href = `${props.url}:1/${chief}/${cs}/${cscenter}/${partner}`
                    break;
                case 'chief' :
                    window.location.href = `${props.url}${superadmin}/:1/${cs}/${cscenter}/${partner}`
                    break;
                case 'cs' :
                    window.location.href = `${props.url}${superadmin}/${chief}/:1/${cscenter}/${partner}`
                    break;
                case 'cscenter' :
                    window.location.href = `${props.url}${superadmin}/${chief}/${cs}/:1/${partner}`
                    break;
                case 'partner' :
                    window.location.href = `${props.url}${superadmin}/${chief}/${cs}/${cscenter}/:1`
                    break;
            }
        }
    }

    const goToLastPage = () => {
        if(!props.isLastPage) {
            switch (props.type){
                case 'superadmin' :
                    window.location.href = `${props.url}:${props.pages}/${chief}/${cs}/${cscenter}/${partner}`
                    break;
                case 'chief' :
                    window.location.href = `${props.url}${superadmin}/:${props.pages}/${cs}/${cscenter}/${partner}`
                    break;
                case 'cs' :
                    window.location.href = `${props.url}${superadmin}/${chief}/:${props.pages}/${cscenter}/${partner}`
                    break;
                case 'cscenter' :
                    window.location.href = `${props.url}${superadmin}/${chief}/${cs}/:${props.pages}/${partner}`
                    break;
                case 'partner' :
                    window.location.href = `${props.url}${superadmin}/${chief}/${cs}/${cscenter}/:${props.pages}`
                    break;
            }
        }
    }

    const goToNextPage = () => {
        if(props.hasNextPage) {
            switch (props.type){
                case 'superadmin' :
                    window.location.href = `${props.url}:${props.nextPage}/${chief}/${cs}/${cscenter}/${partner}`
                    break;
                case 'chief' :
                    window.location.href = `${props.url}${superadmin}/:${props.nextPage}/${cs}/${cscenter}/${partner}`
                    break;
                case 'cs' :
                    window.location.href = `${props.url}${superadmin}/${chief}/:${props.nextPage}/${cscenter}/${partner}`
                    break;
                case 'cscenter' :
                    window.location.href = `${props.url}${superadmin}/${chief}/${cs}/:${props.nextPage}/${partner}`
                    break;
                case 'partner' :
                    window.location.href = `${props.url}${superadmin}/${chief}/${cs}/${cscenter}/:${props.nextPage}`
                    break;
            }
        }
    }

    const goToPrePage = () => {
        if(props.hasPrePage) {
            switch (props.type){
                case 'superadmin' :
                    window.location.href = `${props.url}:${props.prePage}/${chief}/${cs}/${cscenter}/${partner}`
                    break;
                case 'chief' :
                    window.location.href = `${props.url}${superadmin}/:${props.prePage}/${cs}/${cscenter}/${partner}`
                    break;
                case 'cs' :
                    window.location.href = `${props.url}${superadmin}/${chief}/:${props.prePage}${cscenter}/${partner}`
                    break;
                case 'cscenter' :
                    window.location.href = `${props.url}${superadmin}/${chief}/${cs}/:${props.prePage}/${partner}`
                    break;
                case 'partner' :
                    window.location.href = `${props.url}${superadmin}/${chief}/${cs}/${cscenter}/:${props.prePage}`
                    break;
            }
        }
    }

    const firstPageNum = () => {
        return 1 + (10 * Math.floor( (props.no - 1) / 10))
    }
    const lastPageNum = () => {
        return (props.pages === 0 || props.pages === undefined) ? 1 : (Math.floor( (props.no - 1) / 10) + 1) * 10;
    }

    const navigatePage = () => {
        
        return (props.pages === 0 || props.pages === undefined) ? 0 : Math.floor( (props.no - 1) / 10);
    }

    const pages = () => {
        const result = [];

        for(let i = firstPageNum() ; i <= lastPageNum() ; i++){
            switch (props.type){
                case 'superadmin' :
                    result.push(<NavLink key={i} to={`${props.url}:${i}/${chief}/${cs}/${cscenter}/${partner}`}>{i}</NavLink>);
                    break;
                case 'chief' :
                    result.push(<NavLink key={i} to={`${props.url}${superadmin}/:${i}/${cs}/${cscenter}/${partner}`}>{i}</NavLink>);
                    break;
                case 'cs' :
                    result.push(<NavLink key={i} to={`${props.url}${superadmin}/${chief}/:${i}/${cscenter}/${partner}`}>{i}</NavLink>);
                    break;
                case 'cscenter' :
                    result.push(<NavLink key={i} to={`${props.url}${superadmin}/${chief}/${cs}/:${i}/${partner}`}>{i}</NavLink>);
                    break;
                case 'partner' :
                    result.push(<NavLink key={i} to={`${props.url}${superadmin}/${chief}/${cs}/${cscenter}/:${i}`}>{i}</NavLink>);
                    break;
            }
            if(i === props.pages) break;
        }
        return result;
    }

    return (
        <PaginationWrapper>
            <div>
                <DoubleArrowImg onClick={goToFirstPage} src={DoubleArrow} alt=""/>
                <ArrowImg onClick={goToPrePage} src={Arrow} alt="" />
                {pages()}
                <RightArrowImg onClick={goToNextPage} src={Arrow} alt="" />
                <RightDoubleArrowImg onClick={goToLastPage} src={DoubleArrow} alt="" />
            </div>
        </PaginationWrapper>
    )
}

export default Pagination