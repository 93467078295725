import React from 'react'
import styled from 'styled-components';

import CheckBox from "./CheckBox";
import MessageDetailDetailModal from "./MessageDetailDetailModal";


const Tr = styled.tr`
  & > td:first-child{
      width: 5%;
    }
  & > td:nth-child(2){
    width: 10%;
  }
  & > td {
    vertical-align: middle !important;
  }
`

const Td = styled.td`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 600px;
  cursor: pointer;
  text-align: left;
  text-overflow:ellipsis;
  overflow:hidden;
  line-height: 30px;
  padding-left: 30px;
`

function ManageMessageList(props) {
  const [toggleDetailModal , setToggleDetailModal] = React.useState();

  const handleToggleDetailModal = () => {
      setToggleDetailModal(!toggleDetailModal);
  }

    return (
      <>
        <Tr onClick={handleToggleDetailModal}>
            <td>{props.no}</td>
            <td>{props.type}</td>
            <Td>{props.content}</Td>
            <td>{props.adminId}</td>
            <td>{props.notiDate}</td>
        </Tr>
        <MessageDetailDetailModal 
          toggle={toggleDetailModal}
          toggleFunc={handleToggleDetailModal}
          no={props.no}
          alimType={props.type}
          content={props.content}
          usName={props.usName}
          usPhone={props.usPhone}
          notiDate={props.notiDate}
        />
      </>

    )
}

export default ManageMessageList