import React from 'react';
import CheckBox from "./CheckBox";
import styled from "styled-components";


const Tr = styled.tr`
  &:hover{
    background-color: #f6f6f6;
  }
`

function AdvertisementDetailTable(props) {

    return (
        <table>
            <tbody>
                <Tr>
                    <td>광고 시작일</td>
                    <td>{props.adStart}</td>
                </Tr>
                <Tr>
                    <td>광고 종료일</td>
                    <td>{props.adEnd}</td>
                </Tr>
                <Tr>
                    <td>위치</td>
                    <td>{props.adLocation}</td>
                </Tr>
                <Tr>
                    <td>위도</td>
                    <td>{props.adLat}</td>
                </Tr>
                <Tr>
                    <td>경도</td>
                    <td>{props.adLong}</td>
                </Tr>
                <Tr>
                    <td>타입</td>
                    <td>{props.adType === 'popup' ? '전면' : '배너'}</td>
                </Tr>
            </tbody>
        </table>
    );
}

export default AdvertisementDetailTable;