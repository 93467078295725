import React , {useEffect} from 'react';
import CheckBox from "./CheckBox";
import Cookie from "universal-cookie";
const cookies = new Cookie();

function SearchManagerList(props) {
    const [levelAuth , setLevelAuth] = React.useState(false);

    useEffect(()=>{
        if(cookies.get('level')<=0){
            setLevelAuth(true);
        }
      },[])
      
      const maskingPhone = (originStr) => {
        let maskingStr = '';
        let phoneStr =  originStr.length < 11 ? originStr.match(/\d{10}/gi) : originStr.match(/\d{11}/gi);
            
        if(originStr.length < 11)
        { // 1.1) 0110000000
            return maskingStr = originStr.toString().replace(phoneStr, phoneStr.toString().replace(/(\d{3})(\d{3})(\d{4})/gi,'$1***$3'));
        }
        else
        { // 1.2) 01000000000
            return maskingStr = originStr.toString().replace(phoneStr, phoneStr.toString().replace(/(\d{3})(\d{4})(\d{4})/gi,'$1****$3'));
        }
      }

    return (
        <tr>
            <td><CheckBox no={props.id} name={props.id} id={props.id} onChange={props.onChange} checked={props.checked}/></td>
            {/* <td>{props.no}</td> */}
            <td>{props.no}</td>
            <td>{props.id}</td>
        </tr>
    );
}

export default SearchManagerList;