import React from 'react';
import {Route ,Routes} from 'react-router-dom';

import SideMenu from '../components/SideMenu';
import Notice from './Notice';
import NoticeDetail from './NoticeDetail';
import NoticeWrite from './NoticeWrite';
import ManageUser from "./ManageUser";
import ManageUserDetail from "./ManageUserDetail";
import SearchGroup from "./SearchGroup";
import SearchStation from './SearchStation';
import SearchGroupDetail from "./SearchGroupDetail";
import SearchGroupDetailUpdate from "./SearchGroupDetailUpdate";
import SearchGroupRegister from "./SearchGroupRegister";
import DeviceDetail from "./DeviceDetail";
import DeviceDetailUpdate from "./DeviceDetailUpdate";
import PortDetail from "./PortDetail";
import SearchStationRegister from "./SearchStationRegister";
import Coupon from './Coupon';
import CouponRegister from "./CouponRegister";
import CouponUpdate from "./CouponUpdate";
import ManageCalc from "./ManageCalc";
import ManageAdminUser from "./ManageAdminUser";
import ManageAdminDetail from "./ManageAdminDetail";
import ManageAdminAuth from "./ManageAdminAuth";
import ManageAdminRegisterAdmin from "./ManageAdminRegisterAdmin";
import ManageAdminRegisterPartnerAdmin from "./ManageAdminRegisterPartnerAdmin";
import NoticeUpdate from "./NoticeUpdate";
import Advertisement from "./Advertisement";
import AdvertisementRegister from "./AdvertisementRegister";
import AdvertisementDetail from "./AdvertisementDetail";
import AdvertisementUpdate from "./AdvertisementUpdate";
import Company from './Company';
import CompanyDetail from './CompanyDetail';
import Faq from './Faq';
import FaqRegister from './FaqRegister';
import FaqDetail from './FaqDetail';
import FaqUpdate from './FaqUpdate';
import FaqCategoryManage from './FaqCategoryManage';
import VersionManage from './VersionManage';
import VersionManageUpdate from './VersionManageUpdate';
import Ask from './Ask';
import AskDetail from './AskDetail';
import AskComment from './AskComment';
import AskCommentUpdate from './AskCommentUpdate';
import Trouble from './Trouble';
import TroubleDetail from './TroubleDetail';
import TroubleComment from './TroubleComment';
import TroubleCommentUpdate from './TroubleCommentUpdate';
import ManageAdminUpdate from './ManageAdminUpdate';
import ManageAdminPwUpdate from './ManageAdminPwUpdate';
import BreakOutUser from './BreakOutUser';
import BreakOutUserDetail from './BreakOutUserDetail';
import KakaoAlimtalk from './KakaoAlimtalk';
import Message from './Message';
import Error from './Error';
import GroupStationCalc from './GroupStationCalc';
import CouponManage from './CouponManage';
import CouponManageRegister from './CouponManageRegister';
import CouponManageDetail from './CouponManageDetail';
import CouponManageUpdate from './CouponManageUpdate';

function Routing() {
  return (
    <div>
      <SideMenu/>
      <Routes>
        <Route path='/devicedetail/:no/:usage/:error' element={<DeviceDetail/>}/>
        <Route path='/devicedetail/:no/update' element={<DeviceDetailUpdate/>}/>
        <Route path='/devicedetail/:no/portdetail/:num' element={<PortDetail/>}/>

        <Route path='/searchgroup/:no' element={<SearchGroup/>}/>
        <Route path='/searchgroup/register' element={<SearchGroupRegister/>}/>
        <Route path='/searchgroup/detail/:no/:num' element={<SearchGroupDetail/>}/>
        <Route path='/searchgroup/detail/:no/update' element={<SearchGroupDetailUpdate/>}/>

        <Route path='/company/:no' element={<Company/>}/>
        <Route path='/company/detail/:name/:num' element={<CompanyDetail/>}/>

        <Route path='/managecalc/:no' element={<ManageCalc/>}/>
        <Route path='/groupstation/:no/:usage' element={<GroupStationCalc/>}/>

        <Route path='/searchstation/:no' element={<SearchStation/>}/>
        <Route path='/searchstation/register' element={<SearchStationRegister/>}/>

        <Route path='/coupon' element={<Coupon/>}/>
        <Route path='/coupon/register' element={<CouponRegister/>}/>
        <Route path='/coupon/update/:no/:price/:bonus' element={<CouponUpdate/>}/>
        <Route path='/couponmanage/:no/:usage' element={<CouponManage/>}/>
        <Route path='/couponmanage/register' element={<CouponManageRegister/>}/>
        <Route path='/couponmanage/detail/:no/:usage' element={<CouponManageDetail/>}/>
        <Route path='/couponmanage/update/:no' element={<CouponManageUpdate/>}/>

        <Route path='/advertisement/:no' element={<Advertisement/>}/>
        <Route path='/advertisement/detail/:no' element={<AdvertisementDetail/>}/>
        <Route path='/advertisement/register' element={<AdvertisementRegister/>}/>
        <Route path='/advertisement/update/:no' element={<AdvertisementUpdate/>}/>

        <Route path='/notice/:no' element={<Notice/>}/>
        <Route path='/notice/write' element={<NoticeWrite/>}/>
        <Route path='/notice/detail/:no' element={<NoticeDetail/>}/>
        <Route path='/notice/update/:no' element={<NoticeUpdate/>}/>

        <Route path='/faq/:no' element={<Faq/>}/>
        <Route path='/faq/register' element={<FaqRegister/>}/>
        <Route path='/faq/detail/:no' element={<FaqDetail/>}/>
        <Route path='/faq/update/:no' element={<FaqUpdate/>}/>
        <Route path='/faq/categorymanage' element={<FaqCategoryManage/>}/>

        <Route path='/error/:no' element={<Error/>}/>

        <Route path='/manageuser/:no' element={<ManageUser/>}/>
        <Route path='/manageuser/detail/:no/:page/*' element={<ManageUserDetail/>}/>
        <Route path='/breakoutuser/:no' element={<BreakOutUser/>}/>
        <Route path='/breakoutuser/detail/:no/:page/*' element={<BreakOutUserDetail/>}/>

        <Route path='/manageadminuser/:superadmin/:chief/:cs/:cscenter/:partner' element={<ManageAdminUser/>}/>
        <Route path='/manageadminuser/detail/:adminid/:no' element={<ManageAdminDetail/>}/>
        <Route path='/manageadminuser/updateauth/:level' element={<ManageAdminAuth/>}/>
        <Route path='/manageadminuser/register' element={<ManageAdminRegisterAdmin/>}/>
        <Route path='/manageadminuser/partnerregister' element={<ManageAdminRegisterPartnerAdmin/>}/>
        <Route path='/manageadminuser/update/:adminid' element={<ManageAdminUpdate/>}/>
        <Route path='/manageadminuser/updatepw/:adminid' element={<ManageAdminPwUpdate/>}/>

        <Route path='/versionmanage' element={<VersionManage/>}/>
        <Route path='/versionmanage/update/:os' element={<VersionManageUpdate/>}/>

        <Route path='/kakao/:no' element={<KakaoAlimtalk/>}/>
        <Route path='/message/:no' element={<Message/>}/>

        <Route path='/ask/:no' element={<Ask/>}/>
        <Route path='/ask/detail/:no' element={<AskDetail/>}/>
        <Route path='/ask/comment/:no' element={<AskComment/>}/>
        <Route path='/ask/comment/update/:no' element={<AskCommentUpdate/>}/>

        <Route path='/trouble/:no' element={<Trouble/>}/>
        <Route path='/trouble/detail/:no' element={<TroubleDetail/>}/>
        <Route path='/trouble/comment/:no' element={<TroubleComment/>}/>
        <Route path='/trouble/comment/update/:no' element={<TroubleCommentUpdate/>}/>
      </Routes>   
    </div>
  )
}

export default Routing