import React, {useEffect} from 'react';
import styled from "styled-components";
import Add from '../asset/img/addmanager.png';
import Delete from '../asset/img/deletemanager.png';
import SelectArrow from "../asset/img/selectarrow.png";
import CheckBox from "./CheckBox";
import DeviceRegisterList from "./DeviceRegisterList";
import axios from "axios";
import {useParams} from "react-router";
import Cookie from "universal-cookie";
import SearchGroupDetailInnerTable from "./SearchGroupDetailInnerTable";

const cookies = new Cookie();

const Table = styled.table`
  & > tbody > tr > td:first-child{
    width: 17% !important;
  }
  & > tbody > tr:last-child{
    border-bottom: 2px solid #E5E5E5 !important;
  }
`

const Td = styled.td`
  padding-left: 7px;
`

const BigInput = styled.input`
  border: 1px solid #C4C4C4;
  width: 25%;
  padding: 7px;
  border-radius: 10px;
`

const SmallInput = styled.input`
  border: 1px solid #C4C4C4;
  width: 2%;
  padding: 7px 5px;
  border-radius: 10px;
  text-align: center;
  -moz-appearance:textfield;
`

const TimeInput = styled.input`
  border: 1px solid #C4C4C4;
  width: 120px;
  padding: 7px 5px;
  border-radius: 10px;
  text-align: center;
  -moz-appearance:textfield;
`

const Select = styled.select`
  cursor: pointer;
  appearance:none;
  border: 1px solid #C4C4C4;
  border-radius: 10px;
  padding : 4px;
  width: 9%;
  font-size: 16px;
  line-height: 23px;
  background-image: url(${SelectArrow});
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: 85%;
  &:focus{
    outline: none;
  }
`

const Tr = styled.tr`
  border-top: none !important;
  border-bottom: none !important;
  & > td {
    vertical-align: middle;
  }
`

const Span = styled.span`
    font-size: 12px;
`

function SearchGroupDetailUpdateTable(props) {

    const maxLength = (e) => {
        if(/\D/g.test(e.target.value)) {
            e.target.value = e.target.value.replace(/\D/g,'');
        }
    }

    return (
        <Table>
            <tbody>
            <tr>
                <td>충전 요금 / <Span>10분(원)</Span></td>
                <td colSpan='2'><BigInput type='text' name='chargePrice' value={props.chargePrice} onChange={props.onTableInputChange}/></td>
            </tr>
            <tr>
                <td>거치 요금 / <Span>10분(원)</Span></td>
                <td colSpan='2'><BigInput type='text' name='holderPrice' value={props.holderPrice} onChange={props.onTableInputChange}/></td>
            </tr>
            <tr>
                <td>운영 여부</td>
                <td colSpan='2'>
                    <Select name="operationState" value={props.operation} id="" onChange={props.onTableInputChange}>
                        <option value={null}>선택</option>
                        <option value="운영 중">운영 중</option>
                        <option value="미운영">미운영</option>
                    </Select>
                </td>
            </tr>
            <Tr>
                <td>운영 시간</td>
                <Td>평일</Td>
                <td>
                    <TimeInput type='time' name='weekStart' value={props.weekStart} onChange={props.onTableInputChange}/> ~ <TimeInput type='time' name='weekEnd' value={props.weekEnd} onChange={props.onTableInputChange}/> 
                </td>
            </Tr>
            <Tr>
                <td/>
                <Td>토요일</Td>
                <td>
                  <TimeInput type='time' name='satStart' value={props.satStart} onChange={props.onTableInputChange}/> ~ <TimeInput type='time' name='satEnd' value={props.satEnd} onChange={props.onTableInputChange}/> 
                </td>
            </Tr>
            <Tr>
                <td/>
                <Td>일요일/공휴일</Td>
                <td>
                <TimeInput type='time' name='holiStart' value={props.holiStart} onChange={props.onTableInputChange}/> ~ <TimeInput type='time' name='holiEnd' value={props.holiEnd} onChange={props.onTableInputChange}/> 
                </td>
            </Tr>
            </tbody>
        </Table>
    )
}

export default SearchGroupDetailUpdateTable;