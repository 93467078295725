import axios from 'axios';
import React, { useEffect } from 'react';
import Cookie from 'universal-cookie';

import styled from 'styled-components';
import AuthCheckModal from '../components/AuthCheckModal';
import CheckBox from '../components/CheckBox';
import DeleteModal from '../components/DeleteModal';
import SelectUserModal from '../components/SelectUserModal';
import {
    BorderDiv,
    BorderDivR,
    Description,
    NoticeWrapper,
    Title,
} from '../css/styles';

const cookies = new Cookie();

const TableTitle = styled.h1`
    font-weight: bold;
    font-size: 32px;
    line-height: 46px;
    padding-top: 40px;
`;

const Table = styled.table`
    & > tbody {
        & > tr {
            line-height: 80px !important;
            & > td:last-child {
                text-align: right;
                & > button {
                    padding: 5px 10px;
                    font-size: 16px;
                    line-height: 23px;
                    color: #df1f26;
                    background-color: white;
                    border: 1px solid #df1f26;
                    border-radius: 10px;
                    margin-right: 30px;
                    cursor: pointer;
                }
            }
        }
        & > tr > td:first-child {
            width: 5%;
        }
        & > tr > td:nth-child(2) {
            font-family: notosansbold;
            font-size: 28px;
            color: #df1f26;
            width: 17%;
            & > span {
                font-family: notosans;
                font-size: 22px;
                margin-left: 3px;
            }
        }
        & > tr > td:nth-child(3) {
            width: 17%;
            text-align: center !important;
        }
        & > tr > td:nth-child(4) {
            width: 17%;
            text-align: center !important;
        }
    }
`;

const ButtonContainer = styled.div`
    justify-content: flex-start !important;
    column-gap: 20px;
    & > button:first-child {
        color: #df1f26;
        cursor: pointer;
    }
    & > button:last-child {
        color: black !important;
        cursor: pointer;
    }
`;

function Coupon() {
    const [coupon, setCoupon] = React.useState([]);
    const [toggleDeleteModal, setToggleDeleteModal] = React.useState(false);

    const [checked, setChecked] = React.useState(false);
    const [checkAll, setCheckAll] = React.useState(false);
    const [checkList, setCheckList] = React.useState([]);

    const [toggleSelectUserModal , setToggleSelectUserModal] = React.useState(false);

    const [toggleAuthModal , setToggleAuthModal] = React.useState(false);

    const handleToggleAuthModal = () => {
        setToggleAuthModal(!toggleAuthModal);
    }
    const handleToggleSelectUsetModal = () => {
        setToggleSelectUserModal(!toggleSelectUserModal);
    }

    useEffect(() => {
        setCheckList([]);
        axios({
            method: 'get',
            url: `${window.Config.G_API_URL}/admin/point-coupon`,
            params: {
                token: cookies.get('token'),
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    setCoupon(response.data.data);
                    console.log(response.data.data);
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                }else if(response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    }, []);

    useEffect(()=>{
        if(checkAll){
            let arr = [];
            coupon.map((item)=>{
                    arr.push(`${item.coupon_id}`);
            });
            setCheckList(arr);
        } else if(checkList.length==coupon.length) {
            setCheckList([]);
        }
    },[checkAll]);

    useEffect(()=>{
        if(checkList.length == coupon.length){
            setCheckAll(true);
        } else {
            setCheckAll(false);
        }
        // console.log(checkList);
    },[checkList,coupon]);

    const onDelete = () => {
        axios({
            method: 'delete',
            url: `${window.Config.G_API_URL}/admin/point-coupon/`,
            params: {
                token: cookies.get('token'),
                coupon_id: checkList,
            },
            // no : checkList 파라미터 [] 없애는 옵션
            paramsSerializer: function (paramObj) {
                const params = new URLSearchParams();
                for (const key in paramObj) {
                    params.append(key, paramObj[key]);
                }

                return params.toString();
            },
        })
            .then((response) => {
                if (response.data.result['code'] === '200') {
                    window.location.reload();
                } else if(response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function (error) {
                alert(error);
            });
    };

    const handleCheckAll = (e) => {
        setCheckAll(!checkAll);
    };

    const handleCheckEach = (e) => {
        const { id, checked } = e.target;
        let arr = [...checkList];
        if (arr.includes(id)) {
            for (let i = 0; i < arr.length; i++) {
                console.log(arr[i]);
                console.log(id);
                if (arr[i] == id) {
                    arr.splice(i, 1);
                    setChecked(!checked);
                    break;
                }
            }
            setCheckList(arr);
        } else {
            setChecked(!checked);
            setCheckList([...checkList, id]);
        }
    };

    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    const handleDeleteModal = () => {
        setToggleDeleteModal(!toggleDeleteModal);
    };

    const goToRegister = () => {
        window.location.href = '/admin/coupon/register';
    };

    const toggleModal = () => {
        if(checkList.length == 0){
            console.log('true');
            handleToggleSelectUsetModal();
        } else {
            console.log('false');
            handleDeleteModal();
        }
    }

    return (
        <NoticeWrapper>
            <Title> 포인트 관리</Title>
            <Description> 포인트 관리</Description>
            <TableTitle>등록된  포인트 리스트</TableTitle>
            <Table>
                <thead>
                    <tr>
                        <th>
                            <CheckBox
                                no="checkAll"
                                top={'35%'}
                                checked={checkAll}
                                onChange={handleCheckAll}
                            />
                        </th>
                        <th>
                            <BorderDiv>금액</BorderDiv>
                        </th>
                        <th>
                            <BorderDivR>충전 포인트</BorderDivR>
                        </th>
                        <th>보너스 포인트</th>
                    </tr>
                </thead>
                <tbody>
                    {coupon.map((item,key) => (
                        <tr key={key}>
                            <td>
                                <CheckBox
                                    top={'40%'}
                                    no={item.coupon_id}
                                    checked={checkList.includes(`${item.coupon_id}`)}
                                    onChange={handleCheckEach}
                                />
                            </td>
                            <td>
                                {numberWithCommas(item.price)}
                                <span>원</span>
                            </td>
                            <td>{numberWithCommas(item.price)}P</td>
                            <td>{numberWithCommas(item.free_point)}P</td>
                            <td>
                                <button
                                    onClick={() =>
                                        (window.location.href = `/admin/coupon/update/:${item.coupon_id}/:${item.price}/:${item.free_point}`)
                                    }
                                >
                                    금액 수정
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <ButtonContainer>
                <button onClick={goToRegister}>판매 포인트 등록</button>
                <button onClick={toggleModal}>선택 삭제</button>
            </ButtonContainer>

            <DeleteModal
                content={'포인트를'}
                toggle={toggleDeleteModal}
                toggleFunc={handleDeleteModal}
                deleteFunc={onDelete}
            />
            <SelectUserModal
                toggle={toggleSelectUserModal}
                toggleFunc={handleToggleSelectUsetModal}
                content={'포인트를'}
            />
            <AuthCheckModal
                toggle={toggleAuthModal}
                toggleFunc={handleToggleAuthModal}
            />
        </NoticeWrapper>
    );
}

export default Coupon;
