import axios from "axios";
import React, { useEffect } from 'react';
import styled from "styled-components";
import Cookie from "universal-cookie";
import SelectArrow from "../asset/img/selectarrow.png";

const cookies = new Cookie();

const Table = styled.table`
  & > tbody > tr > td:first-child{
    width: 17% !important;
  }
  & > tbody > tr:last-child{
    border-bottom: 2px solid #E5E5E5 !important;
  }
`

const BigInput = styled.input`
  border: 1px solid #C4C4C4;
  width: 25%;
  padding: 7px;
  border-radius: 10px;
  &::-webkit-calendar-picker-indicator{
    cursor: pointer;
  }
`

const AddressInput = styled.input`
  border: 1px solid #C4C4C4;
  width: 25%;
  padding: 7px;
  border-radius: 10px;
  &:focus{
    outline: none;
  }
`

const SelectBox = styled.select`
  cursor: pointer;
  appearance:none;
  border: 1px solid #C4C4C4;
  border-radius: 10px;
  padding: 4px 12px;
  font-size: 16px;
  line-height: 23px;
  width: 15%;
  background-image: url(${SelectArrow});
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: 87%;
  &:focus{
    outline: none;
  }
`

const RedBox = styled.label`
  color : #DF1F26;
  font-size: 12px;
  border-radius: 10px;
  border: 1px solid #DF1F26;
  padding : 0 7px 1px 7px;
  width: 45px;
  margin-top: 10px;
  cursor: pointer;
`

const ImgInput = styled.input`
    display: none;
`

const TableColCell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  row-gap: 10px;
`

const UploadImg = styled.img`
  width: 50%;
`

const RowBox = styled.div`
 & > p {
   color : #DF1F26;
   font-size: 12px;
   border-radius: 10px;
   border: 1px solid #DF1F26;
   padding : 0 7px 1px 7px;
   cursor: pointer;
 }
`

function AdvertisementRegisterTable(props) {

    const [companyList , setCompanyList] = React.useState([]);
    const [preImg , setPreImg] = React.useState('');

    useEffect(()=> {
        axios({
            method : 'get',
            url : `${window.Config.G_API_URL}/admin/company/list`,
            params: {
                token: cookies.get('token'),
            },
        })
            .then(response => {
                if(response.data.result['code'] === '200'){
                    setCompanyList(response.data.data);
                    console.log(response.data.data)
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else if(response.data.result['code'] === '402'){
                }  else {
                    alert(response.data.result['message']);
                }
            }).catch(function(error){
        })
    },[])

    const handleFile = (e) => {
        let reader = new FileReader();

        reader.onloadend = () => {
            const base64 = reader.result;
            if(base64){
                props.setImgBase(base64.toString());
                setPreImg(base64.toString());
            }
        }

        if(e.target.files[0]){
            reader.readAsDataURL(e.target.files[0]);
            props.setImgFile(e.target.files[0]);
        }
    }

    return (
        <Table>
            <tbody>
            <tr>
                <td>쿠폰명</td>
                <td colSpan='2'><BigInput type='text' name='title' onChange={props.onTableInputChange} placeholder={'쿠폰명을 입력해주세요.'}/></td>
            </tr>
            <tr>
                <td>쿠폰 내용</td>
                <td colSpan='2'><BigInput type='text' name='content' onChange={props.onTableInputChange} placeholder={'쿠폰 내용을 입력해주세요.'}/></td>
            </tr>
            <tr>
                <td>쿠폰 이미지</td>
                <td colSpan='2'>
                    <TableColCell>
                        <RedBox htmlFor={'file'}>파일첨부</RedBox>
                        <ImgInput type="file" id={'file'} onChange={handleFile}/>
                        <UploadImg src={preImg} alt=""/>
                    </TableColCell>
                </td>
            </tr>
            <tr>
                <td>금액 (원)</td>
                <td colSpan='2'><BigInput type='text' name='amount' onChange={props.onTableInputChange} placeholder={'금액을 입력해주세요.'}/></td>
            </tr>
            <tr>
                <td>유효기간 시작일</td>
                <td colSpan='2'><BigInput type='date' name='startDate' onChange={props.onTableInputChange}/></td>
            </tr>
            <tr>
                <td>유효기간 종료일</td>
                <td colSpan='2'><BigInput type='date' name='EndDate' onChange={props.onTableInputChange}/></td>
            </tr>
            <tr>
                <td>발행사</td>
                <td colSpan='2'>
                  <SelectBox
                        name="company"
                        id="company"
                        onChange={props.onTableInputChange}
                    >
                        <option value="전원">선택</option>
                      <option value="자사">자사</option>
                        {
                            companyList.map((item,key)=>{
                                return <option value={item} key={key}>{item}</option>
                            })
                        }
                    </SelectBox>      
                </td>
            </tr>
            <tr>
                <td>발행 갯수</td>
                <td colSpan='2'><BigInput type='text' name='issAmount' onChange={props.onTableInputChange} placeholder={'발행 갯수를 입력해주세요.'}/></td>
            </tr>
            </tbody>
        </Table>
    )
}

export default AdvertisementRegisterTable;