import React from 'react';
import CheckBox from "./CheckBox";
import styled from "styled-components";

const Tr = styled.tr`
  & > td:first-child{
    width: 10%;
  }
`

function SearchManagerList(props) {
    return (
        <Tr>
            <td><CheckBox  no={props.id} onChange={props.onChange} checked={props.checked}/></td>
            <td>{props.row}</td>
            <td>{props.name}</td>
            <td>{props.id}</td>
            <td>{props.date}</td>
        </Tr>
    );
}

export default SearchManagerList;