import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import axios from "axios";
import Cookie from "universal-cookie";
import AuthCheckModal from '../components/AuthCheckModal';

const cookies = new Cookie();

const Description = styled.p`
    font-size: 16px;
    line-height: 23px;
    text-align: center;
`

const ButtonContainer = styled.div`
    display : flex;
    justify-content : center;
    margin-top : 35px;
`


const RedButton = styled.a`
    color: #DF1F26;
    font-size: 16px;
    line-height: 23px;
    padding : 7px 20px;
    background-color: white;
    border-radius: 10px; 
    border: 1px solid #C4C4C4;
`

function UserStopModal(props) {

    const [toggleAuthModal , setToggleAuthModal] = React.useState(false);

    const handleToggleAuthModal = () => {
        setToggleAuthModal(!toggleAuthModal);
    }

    const stopUser = () => {
        axios({
            method : 'put',
            url : `${window.Config.G_API_URL}/admin/user/status`,
            params : {
                token : cookies.get('token'),
                us_id : props.checkList,
                status : '이용 정지',
            },
            // no : checkList 파라미터 [] 없애는 옵션
            paramsSerializer : function(paramObj) {
                const params = new URLSearchParams()
                for (const key in paramObj) {
                    params.append(key, paramObj[key])
                }
                return params.toString()
            }
        })
            .then(response => {
                if(response.data.result['code'] === '200'){
                    alert('변경이 완료되었습니다')
                    window.location.href = `/admin/manageuser/${props.no}`
                } else if(response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function(error){
                alert(error);
            })
    }

    return (
        <Modal isOpen={props.toggle} onRequestClose={props.toggleFunc}
               style={{
                   overlay :{
                       backgroundColor : 'rgba(34, 30, 31, 0.5)'
                   },
                   content : {
                       height : '11%',
                       left : '37%',
                       top : '40%',
                       borderRadius : '10px',
                       border: '1px solid #C4C4C4' ,
                   }
               }}
        >
            <Description>
                총 {props.length} 명의 이용 정지시키겠습니까?<br/>
            </Description>
            <ButtonContainer>
                <RedButton onClick={stopUser}>확인</RedButton>
            </ButtonContainer>
            <AuthCheckModal
                toggle={toggleAuthModal}
                toggleFunc={handleToggleAuthModal}
            />
        </Modal>
    );
}

export default UserStopModal;
