import axios from "axios";
import React from 'react';
import { useParams } from "react-router";
import styled from 'styled-components';
import Cookie from "universal-cookie";
import Add from "../asset/img/addmanager.png";
import Delete from "../asset/img/deletemanager.png";
import AuthCheckModal from '../components/AuthCheckModal';

const cookies = new Cookie();

const Table = styled.table`
  & > tbody{
    & > tr:nth-child(3) > td:first-child:after{
      content: "관리자";
    }
    & > tr:nth-last-child(2){
      border-top: none;
      border-bottom: none;
    }
    & > tr:nth-last-child(3){
      border-top: none;
      border-bottom: none;
    }
    & > tr:nth-last-child(4){
      border-top: none;
      border-bottom: none;
    }
    & > tr > td:first-child{
      width: 17% !important;
    }
  }
`

const DivWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 7px;
  cursor: pointer;
  & > img {
    width: 18px;
    margin-top: 2px;
  }
`

const Tr = styled.tr`
  border: none !important;
`

const Span = styled.span`
    font-size: 12px;
`

function SearchGroupDetailTable(props) {


    const [toggleAuthModal , setToggleAuthModal] = React.useState(false);

    const handleToggleAuthModal = () => {
        setToggleAuthModal(!toggleAuthModal);
    }
    const {no , num} = useParams();

    const deleteManager = (id) => {
        axios({
            method : 'delete',
            url : `${window.Config.G_API_URL}/admin/station/group/admin`,
            params : {
                token : cookies.get('token'),
                group_id : no.replace(':' , ''),
                admin_id : id
            }
        })
            .then(response => {
                if(response.data.result['code'] === '200'){
                    window.location.reload();
                } else if(response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                }else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            })
            .catch(function(error){
                alert(error);
            })
    }

    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };
    
    return (
        <Table>
            <tbody>
                <tr>
                    <td>충전 요금 / <Span>10분(원)</Span></td>
                    <td>{props.chargePrice === undefined ? props.chargePrice : numberWithCommas(props.chargePrice)}</td>
                </tr>
                <tr>
                    <td>거치 요금 / <Span>10분(원)</Span></td>
                    <td>{props.holderPrice === undefined ? props.holderPrice : numberWithCommas(props.holderPrice)}</td>
                </tr>
                {
                    props.admin && props.admin.map((item, key)=>(
                        <Tr key={key}>
                            <td/>
                            <td colSpan='2'>
                                <DivWrapper><p>{item.admin_name}</p><img src={Delete} alt="" onClick={() => deleteManager(item.admin_id)}/></DivWrapper>
                            </td>
                        </Tr>
                    ))
                }
                <Tr>
                    <td/>
                    <td colSpan='2' onClick={props.toggleModal}><DivWrapper><img src={Add} alt=""/><p>추가</p></DivWrapper>
                    </td>
                </Tr>
                <tr>
                    <td>운영 여부</td>
                    <td>{props.status}</td>
                </tr>
                <tr>
                    <td>운영 시간</td>
                    <td>평일 {props.weekDayStart}:{props.weekDayStartMin}~{props.weekDayEnd}:{props.weekDayEndMin}</td>
                </tr>
                <tr>
                    <td/>
                    <td>토요일 {props.satDayStart}:{props.satDayStartMin}~{props.satDayEnd}:{props.satDayEndMin}</td>
                </tr>
                <tr>
                    <td/>
                    <td>일요일/공휴일 {props.holiDayStart}:{props.holiDayStartMin}~{props.holiDayEnd}:{props.holiDayEndMin}</td>
                </tr>
                <tr>
                    <td>스테이션 수량</td>
                    <td>{props.stationCount}</td>
                </tr>
            </tbody>
            <AuthCheckModal
                toggle={toggleAuthModal}
                toggleFunc={handleToggleAuthModal}
            />
        </Table>
    );
}

export default SearchGroupDetailTable;