import React, {useEffect} from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import {BorderDiv, BorderDivR, SearchBox} from "../css/styles";
import DeleteSearch from "../asset/img/deletesearch.png";
import SelectArrow from "../asset/img/selectarrow.png";
import Dotbogi from "../asset/img/dotbogi.png";
import CouponLimitModal from "./CouponLimitModal";
import CheckBox from "./CheckBox";
import CouponCompanySendList from "./CouponCompanySendList";
import axios from "axios";
import {useParams} from "react-router";
import Cookie from "universal-cookie";
import {useNavigate} from "react-router-dom";
import AuthCheckModal from '../components/AuthCheckModal';

const cookies = new Cookie();

const Title = styled.h1`
    font-weight: bold;
    font-size: 24px;
    line-height: 35px;
    color: #221E1F;
`

const SelectBox = styled.select`
  cursor: pointer;
  appearance:none;
  text-align : center;
  border: 1px solid #C4C4C4;
  border-radius: 10px;
  padding: 7px 0;
  font-size: 16px;
  line-height: 23px;
  background-image: url(${SelectArrow});
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: 85%;
  &:focus{
    outline: none;
  }
`


const SearchInput = styled.input`
  margin-left: 21px;
  width: 60%;
  border: 1px solid #C4C4C4;
  border-radius: 10px;
  background-image: url(${Dotbogi});
  background-repeat: no-repeat;
  background-size: 17px;
  background-position: 97%;
  padding: 7px 10px;
  font-size: 16px;
  line-height: 23px;
  color: #7E7E7E;
`

const ButtonContainer = styled.div`
    display : flex;
    justify-content : space-between;
  padding-top: 20px;
`

const RedButton = styled.a`
    background: #DF1F26;
    border-radius: 10px;
    border : none;
    color : white;
    padding : 10px 10px ;
    font-weight: bold;
    font-size: 14px;
    width : 20%;
    text-align : center;
`

const RedButton1 = styled(RedButton)`
    background: white;
    color : #DF1F26;
    border: 1px solid #C4C4C4;
    margin-right : 20px;

`

const Button = styled.button`
    background-color: white;
    border-radius: 10px;
    border: 1px solid #C4C4C4;
    width : 11%;
    padding : 10px 0 ;
    font-weight: bold;
    font-size: 14px;
    color: #221E1F;
`

const Table = styled.table`
  width: 100%;
  line-height: 40px;
  text-align: center;
  & > thead > tr{
    border-bottom: 2px solid #e5e5e5;
    line-height: 20px;
    & > th:nth-child(1){
        width : 7%;
    }
    & > th:nth-child(2){
        width : 5%;
    }
    & > th:nth-child(2){
        width : 20%;
    }
    & > th:first-child > div > label {
        cursor: pointer;
    }
  }
  & > tbody > tr{
    border-bottom: 1px solid #e5e5e5;
    & > td > div > label {
        cursor: pointer;
    }
  }
`

const ScrollDiv = styled.div`
  height: 65%;
  overflow-y: scroll;
`

function SearchManagerModal(props) {

    const {no} = useParams();
    const [managerInfo , setManagerInfo] = React.useState([]);

    const [managerChecked, setmanagerChecked] = React.useState(false);
    const [managerCheckAll, setmanagerCheckAll] = React.useState(false);
    const [managerCheckList , setmanagerCheckList] = React.useState([]);
    const [searchData , setSearchData] = React.useState(null);
    const [searchType , setSearchType] = React.useState('이름');

    const [toggleAuthModal , setToggleAuthModal] = React.useState(false);

    const [toggleCouponLimitModal , setToggleCouponLimitModal] = React.useState(false);

    const handleToggleCouponLimitModal = () => {
        setToggleCouponLimitModal(!toggleCouponLimitModal);
    }

    const handleToggleAuthModal = () => {
        setToggleAuthModal(!toggleAuthModal);
    }

    useEffect(()=>{
        setmanagerCheckList([]);
        axios({
            method : 'get',
            url : `${window.Config.G_API_URL}/admin/coupon/detail/company`,
            params : {
                token : cookies.get('token'),
                search_data : searchData,
            }
        })
            .then(response => {
                if(response.data.result['code'] === '200'){
                    setManagerInfo(response.data.data)
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                } else {
                    alert(response.data.result['message']);
                }
            }).catch(function(error){
            alert(error);
        })
    }, [searchType , searchData]);

    useEffect(()=>{
        if(managerCheckAll){
            let arr = [];
            managerInfo.map((item)=>{
                    arr.push(`${item.company}`);
            });
            setmanagerCheckList(arr);
        } else if(managerCheckList.length==managerInfo.length) {
            setmanagerCheckList([]);
        }
    },[managerCheckAll]);

    useEffect(()=>{
        if(managerCheckList.length == managerInfo.length){
            setmanagerCheckAll(true);
        } else {
            setmanagerCheckAll(false);
        }
    },[managerCheckList,managerInfo]);

    const sendCoupon = () => {
        console.log(managerCheckList);
        axios({
            method : 'post',
            url : `${window.Config.G_API_URL}/admin/coupon/company`,
            params : {
                token : cookies.get('token'),
                coupon_id : no.replace(':' , ''),
                company : managerCheckList
            },
            // no : managerCheckList 파라미터 [] 없애는 옵션
            paramsSerializer : function(paramObj) {
                const params = new URLSearchParams()
                for (const key in paramObj) {
                    params.append(key, paramObj[key])
                }
                return params.toString()
            }
        })
            .then(response => {
                if(response.data.result['code'] === '200'){
                    alert('발송이 완료되었습니다.')
                    window.location.reload();
                } else if (response.data.result['code'] === '401'){
                    alert('토큰이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    window.location.href = '/'
                }  else if (response.data.result['code'] === '402'){
                    handleToggleAuthModal();
                }  else if (response.data.result['code'] === '403'){
                    handleToggleCouponLimitModal();
                }   else {
                    alert(response.data.result['message']);
                }
            }).catch(function(error){
            alert(error);
        })
    }

    const onTypeChange = (e) => {
        setSearchType(e.target.value);
    }

    const onSearchDataChange = (e) => {
        setSearchData(e.target.value);
    }

    const handlemanagerCheckAll = (e) => {
        setmanagerCheckAll(!managerCheckAll);
    };

    const handleCheckEach = (e) => {
        const {checked, id, name, value} = e.target;
        let arr = [...managerCheckList];
        if (arr.includes(value)) {
            for (let i = 0; i < arr.length; i++) {
                if (arr[i] == value) {
                    arr.splice(i, 1);
                    setmanagerChecked(!checked);
                    break;
                }
            }
            setmanagerCheckList(arr);
        } else {
            setmanagerChecked(!checked);
            setmanagerCheckList([...managerCheckList, value]);
        }
    }

    return (
        <Modal isOpen={props.toggle} onRequestClose={props.toggleFunc}
               ariaHideApp={false}
               style={{
                   overlay :{
                       backgroundColor : 'rgba(34, 30, 31, 0.5)'
                   },
                   content : {
                       height : '52%',
                       left : '25%',
                       right : '25%',
                       top : '20%',
                       borderRadius : '10px',
                   }
               }}
        >
            <Title>쿠폰 업체 발송</Title>
            <SearchBox>
                <SearchInput type="text" placeholder={'검색어 입력'} onChange={onSearchDataChange}/>
                <div>
                    <img src={DeleteSearch} alt=""/>
                    <p>초기화</p>
                </div>
            </SearchBox>
            <ScrollDiv>
                <Table>
                    <thead>
                        <tr>
                            <th>
                                <CheckBox no='checkAll2' checked={managerCheckAll} onChange={handlemanagerCheckAll}/>
                            </th>
                            <th><BorderDiv>No.</BorderDiv></th>
                            <th>업체명</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        managerInfo.map((item) => (
                            <CouponCompanySendList
                                no={item.rownum}
                                id={item.company}
                                checked={managerCheckList.includes(`${item.company}`)}
                                onChange={handleCheckEach}
                            />
                        ))
                    }
                    </tbody>
                </Table>
            </ScrollDiv>
            <ButtonContainer>
                <div>
                    <RedButton1 onClick={sendCoupon}>쿠폰 발송</RedButton1>
                </div>
                <Button onClick={props.toggleFunc}>닫기</Button>
            </ButtonContainer>
            <AuthCheckModal
                toggle={toggleAuthModal}
                toggleFunc={handleToggleAuthModal}
            />
            <CouponLimitModal
                toggle={toggleCouponLimitModal}
                toggleFunc={handleToggleCouponLimitModal}
            />
        </Modal>
    );
}

export default SearchManagerModal;
