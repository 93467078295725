import React from 'react';
import { NavLink } from 'react-router-dom';
import { ArrowImg, DoubleArrowImg, PaginationWrapper, RightArrowImg, RightDoubleArrowImg } from '../css/styles';

import DoubleArrow from '../asset/img/doublepaginationarrow.png';
import Arrow from '../asset/img/paginationarrow.png';

function Pagination(props) {

    const goToFirstPage = () => {
        if(!props.isFirstPage) {
            return(
                <NavLink to={`${props.url}1/:1`}>
                    <DoubleArrowImg onClick={goToNextPage} src={DoubleArrow} alt="" />
                </NavLink>
            ) 
        }else {
            return(
                <DoubleArrowImg onClick={goToNextPage} src={DoubleArrow} alt="" />
                )
        }
    }

    const goToLastPage = () => {
        if(!props.isLastPage) {
            return(
                <NavLink to={`${props.url}${props.pages}/:1`}>
                    <RightDoubleArrowImg onClick={goToNextPage} src={DoubleArrow} alt="" />
                </NavLink>
            ) 
        } else{
            return(
                <RightDoubleArrowImg onClick={goToNextPage} src={DoubleArrow} alt="" />
            )
        }
    }

    const goToNextPage = () => {
        if(props.hasNextPage) {
            return(
                <NavLink to={`${props.url}${props.nextPage}/:1`}>
                    <RightArrowImg onClick={goToNextPage} src={Arrow} alt="" />
                </NavLink>
            ) 
        } else{
            return(
                <RightArrowImg onClick={goToNextPage} src={Arrow} alt="" />
            )
        }
    }

    const goToPrePage = () => {
        if(props.hasPrePage) {
            return(
                <NavLink to={`${props.url}${props.prePage}/:1`}>
                    <ArrowImg onClick={goToNextPage} src={Arrow} alt="" />
                </NavLink>
            ) 
        } else{
            return(
                <ArrowImg onClick={goToNextPage} src={Arrow} alt="" />
            )
        }
    }

    const navigatePage = () => {
        return Math.floor( (props.no - 1) / 10);
    }

    const pages = () => {
        const result = [];
        for(let i = 1 + (10 * navigatePage()) ; i <= 10 * (navigatePage()+1) ; i++){
            result.push(<NavLink key={i} to={`${props.url}${i}/:1`}>{i}</NavLink>);
            if(i === props.pages) break;
        }
        return result;
    }

    return (
        <PaginationWrapper>
            <div>
                {goToFirstPage()}
                {goToPrePage()}
                {pages()}
                {goToNextPage()}
                {goToLastPage()}
            </div>
        </PaginationWrapper>
    )
}

export default Pagination