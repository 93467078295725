import React from 'react'
import styled from "styled-components";

const Tr = styled.tr`
      & > td:nth-child(2){
        text-overflow: ellipsis;
        white-space: nowrap;
      }
     & > td:nth-child(3){
       text-align: center !important;
     }
`

function ManageCalcList(props) {

    return (
        <Tr>
            <td>10</td>
            <td>{props.stationName}</td>
            <td>{props.port}</td>
            <td>{props.startTime}</td>
            <td>{props.endTime}</td>
            <td>{props.charge}</td>
            <td>{props.holding}</td>
            <td>{props.amount}</td>
            <td>{props.company}</td>
        </Tr>
    )
}

export default ManageCalcList