import React, { useEffect } from 'react';
import styled from "styled-components";
import Cookie from "universal-cookie";
import SelectArrow from "../asset/img/selectarrow.png";
import Postcode from "./Postcode";
const cookies = new Cookie();

const Table = styled.table`
  & > tbody > tr > td:first-child{
    width: 17% !important;
  }
  & > tbody > tr:last-child{
    border-bottom: 2px solid #E5E5E5 !important;
  }
`

const BigInput = styled.input`
  border: 1px solid #C4C4C4;
  width: 25%;
  padding: 7px;
  border-radius: 10px;
  &::-webkit-calendar-picker-indicator{
    cursor: pointer;
  }
`

const AddressInput = styled.input`
  border: 1px solid #C4C4C4;
  width: 25%;
  padding: 7px;
  border-radius: 10px;
  &:focus{
    outline: none;
  }
`

const SelectBox = styled.select`
  cursor: pointer;
  appearance:none;
  border: 1px solid #C4C4C4;
  border-radius: 10px;
  padding: 4px 12px;
  font-size: 16px;
  line-height: 23px;
  width: 7%;
  background-image: url(${SelectArrow});
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: 87%;
  &:focus{
    outline: none;
  }
`

const RedBox = styled.label`
  color : #DF1F26;
  font-size: 12px;
  border-radius: 10px;
  border: 1px solid #DF1F26;
  padding : 0 7px 1px 7px;
  width: 45px;
  margin-top: 10px;
  cursor: pointer;
`

const ImgInput = styled.input`
    display: none;
`

const TableColCell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  row-gap: 10px;
`

const UploadImg = styled.img`
  width: 50%;
`

const RowBox = styled.div`
 & > p {
   color : #DF1F26;
   font-size: 12px;
   border-radius: 10px;
   border: 1px solid #DF1F26;
   padding : 0 7px 1px 7px;
   cursor: pointer;
 }
`

function AdvertisementRegisterTable(props) {

    const { kakao } = window;

    const [toggleAddressModal , setToggleAddressModal] = React.useState(false);

    const [location , setLocation] = React.useState('');
    const [lat , setLat] = React.useState('');
    const [long , setLong] = React.useState('');

    const [preImg , setPreImg] = React.useState('');


    useEffect(() => {
        if(kakao) {
            const geocoder = new kakao.maps.services.Geocoder();
            geocoder.addressSearch(location, function (results, status) {
                // 정상적으로 검색이 완료됐으면
                if(parseFloat(results[0].y).toFixed(7) === 'NaN'){
                    setLat('-');
                    setLong('-');
                } else {
                    props.setLat(parseFloat(results[0].y).toFixed(7))
                    setLat(parseFloat(results[0].y).toFixed(7))
                    props.setLong(parseFloat(results[0].x).toFixed(7))
                    setLong(parseFloat(results[0].x).toFixed(7))
                }
                console.log(results[0].y , results[0].x)
            });
        }
        //광고 등록 위치 스테이트
        props.setLocation(location);
    } , [location , lat , long])

    const handleToggleAddressModal = () => {
        setToggleAddressModal(!toggleAddressModal);
    }

    const handleFile = (e) => {
        let reader = new FileReader();

        reader.onloadend = () => {
            const base64 = reader.result;
            if(base64){
                props.setImgBase(base64.toString());
                setPreImg(base64.toString());
            }
        }

        if(e.target.files[0]){
            reader.readAsDataURL(e.target.files[0]);
            props.setImgFile(e.target.files[0]);
        }
    }

    return (
        <Table>
            <tbody>
            <tr>
                <td>광고명</td>
                <td colSpan='2'><BigInput type='text' name='title' onChange={props.onTableInputChange} placeholder={'광고명을 입력해주세요.'}/></td>
            </tr>
            <tr>
                <td>광고 파일 첨부</td>
                <td colSpan='2'>
                    <TableColCell>
                        <RedBox htmlFor={'file'}>파일첨부</RedBox>
                        <ImgInput type="file" id={'file'} onChange={handleFile}/>
                        <UploadImg src={preImg} alt=""/>
                    </TableColCell>
                </td>
            </tr>
            <tr>
                <td>광고 시작일</td>
                <td colSpan='2'><BigInput type='date' name='startDate' onChange={props.onTableInputChange}/></td>
            </tr>
            <tr>
                <td>광고 종료일</td>
                <td colSpan='2'><BigInput type='date' name='EndDate' onChange={props.onTableInputChange}/></td>
            </tr>
            <tr>
                <td>위치</td>
                <td>
                    <RowBox>
                        <AddressInput type='text' name='location' value={location} onChange={props.onTableInputChange} readOnly/>
                        <p onClick={handleToggleAddressModal}>주소찾기</p>
                        {
                            toggleAddressModal &&
                                <Postcode toggle={toggleAddressModal} setLocation={setLocation} toggleFunc={handleToggleAddressModal}/>
                        }
                    </RowBox>
                </td>
            </tr>
            <tr>
                <td>위도</td>
                <td>
                    {lat}
                </td>
            </tr>
            <tr>
                <td>경도</td>
                <td>
                    {long}
                </td>
            </tr>
            <tr>
                <td>타입</td>
                <td>
                    <SelectBox name="type" id="type" onChange={props.onTableInputChange}>
                        <option value={null} hidden>선택</option>
                        <option value="map">배너</option>
                        <option value="popup">전면</option>
                    </SelectBox>
                </td>
            </tr>
            </tbody>
        </Table>
    )
}

export default AdvertisementRegisterTable;