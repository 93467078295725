import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import Bar from '../asset/img/bar.png';

const Description = styled.div`
    & > h1 {
      font-family: notosansbold;
      font-size: 20px;
      letter-spacing: -1px;
      padding-top: 10px;
      padding-bottom: 17px;
    }
  & > p {
    letter-spacing: -0.3px;
    font-weight: bold;
    padding-bottom: 27px;
  }
  & > div {
    padding : 16px 10px;
    height: 310px;
    overflow-y: scroll;
    border-radius: 10px;
    border: 1px solid #e5e5e5;
  }
`

const ButtonContainer = styled.div`
    display : flex;
    justify-content : center;
    margin-top : 35px;
    padding : 0 27%;
`

const Button = styled.a`
    font-size: 16px;
    line-height: 23px;
    padding : 7px 20px;
    background-color: white;
    border-radius: 10px; 
    border: 1px solid #C4C4C4;
    margin-left : 20px;
`

const DescriptionContainer = styled.div`
        display : flex;
        align-items : center;
        column-gap : 10px;
        padding : 0% 2% 2% 0 !important;
        height : auto !important;
        border : none !important;
        font-family : notosansmedium;
        & > img {
            height : 26px;
        }
        & > div{
            display : flex !important;
            column-gap : 16px;
            align-items : center;
            & > p:first-child{
                font-family : notosansbold;
            }
            & > p:last-child{
                color : #df1f26;
                font-size: 12px;
                border: 1px solid #DF1F26;
                border-radius: 8px;
                padding : 1px 5px 2px 5px;
                cursor : pointer;
            }
        }
        & > p:last-child {
            display : flex;
            align-items : center;
            column-gap: 10px;
            & > img {
                width : 18px;
            }
        }
`

const RedButton = styled.button`
    color : #df1f26;
    font-size: 12px;
    border: 1px solid #DF1F26;
    border-radius: 8px;
    padding : 1px 5px 2px 5px;
    cursor : pointer;
    background-color : white;
`

function ManagerMemoDetailModal(props) {

    return (
        <Modal isOpen={props.toggle} onRequestClose={props.toggleFunc}
               style={{
                   overlay :{
                       backgroundColor : 'rgba(34, 30, 31, 0.5)'
                   },
                   content : {
                       height : '60%',
                       left : '30%',
                       right : '30%',
                       top : '15%',
                       borderRadius : '10px',
                       border: '1px solid #C4C4C4' ,
                   }
               }}
        >
            <Description>
                <h1>{props.alimType}</h1>
                <DescriptionContainer>
                    <p>No.{props.no}</p>
                    <img src={Bar} alt="" />
                    <p>{props.usName}</p>
                    <img src={Bar} alt="" />
                    {
                        props.usPhone === ''?
                        ''
                        :
                        <>
                            <p>{props.usPhone}</p>
                            <img src={Bar} alt="" />
                        </>
                    }
                    <p>{props.notiDate}</p>
                    {props.usName ==='탈퇴한 회원'? 
                    '' 
                    : 
                    <>
                        <img src={Bar} alt="" />
                        <p>
                        <RedButton onClick={props.goToDetail}>회원 상세</RedButton>
                        </p>
                    </>
                    }
                </DescriptionContainer>
                <div>
                    {props.content && props.content.split("\n").map((line,key) => { //this.props.data.content: 내용
                        return (
                        <span key={key}>
                            {line}
                            <br />
                        </span>
                        );
                    })}
                </div>
            </Description>
            <ButtonContainer>
                <Button onClick={props.toggleFunc}>닫기</Button>
            </ButtonContainer>
        </Modal>
    );
}

export default ManagerMemoDetailModal;
